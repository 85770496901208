import { DefaultOptionType } from 'antd/lib/select';

export interface IMap4SelectConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sourceArray: any[];
  labelAccessor: string;
  valueAccessor: string;
}

export const map4select = (
  selectConfig: IMap4SelectConfig
): DefaultOptionType[] => {
  if (!selectConfig.sourceArray || !selectConfig.sourceArray.length) {
    return [];
  }
  return selectConfig.sourceArray.map((entity) => ({
    label: entity?.[selectConfig.labelAccessor],
    value: entity?.[selectConfig.valueAccessor],
  }));
};
