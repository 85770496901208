import { useCallback, useMemo, useState } from 'react';
import { Button, Col, Flex, notification, Row, Upload } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { UploadChangeParam } from 'antd/es/upload';
import { observer } from 'mobx-react-lite';
import { AxiosResponse } from 'axios';

import style from './RequestDecisionStatus.module.scss';
import { IFilters } from '@/modules/submittedRequests/submittedRequests.types';
import { PopconfirmButton } from '@/components/PopconfirmButton/PopconfirmButton';
import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service';
import { SubmittedRequestsModal } from '@/pages/SubmittedRequests/components/SubmittedRequestsModal/SubmittedRequestsModal';
import {
  AkcijeEnum,
  GetRequestDetailsResponse,
  ResenjeId,
  StatusOdlukaEnum,
} from '@/modules/requests/requests.types';
import { handleDownloadFile } from '@/utils/handleDownloadFile';
import { FileType } from '@/utils/uploadFile.ts';
import AllocationStatus from '@/components/Status/AllocationStatus/AllocationStatus.tsx';
import { ModalName, modalStore } from '@/modules/modal/modal.store';

interface IRequestStatus {
  title: string;
  type: StatusOdlukaEnum;
  decisionStatus:
    | (Pick<
        GetRequestDetailsResponse,
        'akcije' | 'interniStatusZahteva' | 'statusRaspodele'
      > & { resenjeId: ResenjeId })
    | null;
}

export const RequestDecisionStatus = observer(
  ({ title, type, decisionStatus }: IRequestStatus) => {
    const [isFileSent, setIsFileSent] = useState<boolean>(false);
    const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
    const [file, setFile] = useState<FileType>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [actionId, setActionId] = useState<number | null>(null);
    const { zahtevId } = useParams();

    const onActionClick = (id: number) => {
      setActionId(id);
      modalStore.openModal(ModalName.SUBMITTED_REQUEST);
    };

    const handleDownload = async () => {
      try {
        const generateActResponse =
          await submittedRequestsService.getZahteviPreuzmiAkt(
            decisionStatus?.resenjeId ?? null
          );
        handleDownloadFile(generateActResponse as AxiosResponse)
          .then(() => {
            notification.success({ message: t('file_act_success') });
          })
          .catch((err) => {
            console.error('Error generate act file', err);
            notification.error({
              message: t('file_act_error'),
            });
          });
      } catch (e) {
        console.error('Error generate act ', e);
        notification.error(e as { message: string });
      }
    };

    const handleConfirmOdobravanjeZahteva = useCallback(() => {
      submittedRequestsService
        .postZahteviObradiProcesOdobravanjaAkta({
          zahtevId: +zahtevId!,
          odobri: true,
        })
        .then(() => {
          notification.success({
            message: t('obradi_proces_odobravanja_success'),
          });
          setTimeout(() => window.location.reload(), 500);
        })
        .catch((err) => {
          notification.error(err);
        });
    }, [zahtevId]);

    const handleFileUpload = async (uploadObj: UploadChangeParam) => {
      const file = uploadObj.fileList[0];
      const uploadedFile = {
        // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
        format: file.type!?.split('/')?.[1],
        data: file.originFileObj!,
        fileName: file.name,
      };
      setFile(uploadedFile);
      setIsFileUploaded(uploadObj.fileList.length > 0);
    };

    const handleUploadDoc = () => {
      if (file) {
        submittedRequestsService
          .postZahteviDodajPotpisanAkt({
            data: JSON.stringify({
              zahtevId: +zahtevId!,
              resenjeId: decisionStatus?.resenjeId,
              fileName: file?.fileName,
            }),
            file: new Blob([file.data], {
              type: 'application/octet-stream',
            }),
          })
          .then(() => {
            setIsFileSent(true);
            notification.success({ message: t('potpisan_dokument_success') });
          })
          .catch((err) => {
            notification.error(err);
          });
      }
    };

    const actionLayout = (opcija: IFilters) => {
      switch (opcija.value) {
        case AkcijeEnum.VRATI:
        case AkcijeEnum.PROSLEDI:
        case AkcijeEnum.DODELI:
          return (
            <Button onClick={() => onActionClick(opcija.value)} type='primary'>
              {opcija.label}
            </Button>
          );
        case 4:
          return (
            <PopconfirmButton
              title='da_li_ste_sigurni_preuzmete_zahtev'
              onConfirm={() => {
                submittedRequestsService
                  .getZahteviZahtevPreuzimanje(+zahtevId!)
                  .then(() => {
                    notification.success({
                      message: t('preuzmi_zahtev_success'),
                    });
                    setTimeout(() => window.location.reload(), 500);
                  })
                  .catch((err) => {
                    console.error('Error preuzimanje ', err);
                    notification.error(err);
                  });
              }}
              children={<Button type='primary'>{opcija.label}</Button>}
            />
          );
      }
    };

    const layout = useMemo(() => {
      switch (type) {
        case StatusOdlukaEnum.RASPODELA:
          return (
            <Flex gap={10} wrap='wrap' justify='flex-end'>
              {decisionStatus?.akcije?.map((opcija: IFilters) => {
                return actionLayout(opcija);
              })}
            </Flex>
          );
        case StatusOdlukaEnum.OBRADA:
          return (
            <Flex gap={10}>
              <Button type='primary'>{t('overa_akta')}</Button>
              <Button type='primary'>{t('vratite')}</Button>
            </Flex>
          );
        case StatusOdlukaEnum.ODOBRENJE:
          return (
            <Flex gap={10}>
              <PopconfirmButton
                title='da_li_ste_sigurni_odobrite_zahtev'
                onConfirm={() => {
                  handleConfirmOdobravanjeZahteva();
                }}
                children={<Button type='primary'>{t('odobrite')}</Button>}
              />
              <Button
                onClick={() => {
                  modalStore.openModal(ModalName.SUBMITTED_REQUEST);
                  onActionClick(4);
                }}
                type='primary'
              >
                {t('vratite')}
              </Button>
            </Flex>
          );
        case StatusOdlukaEnum.POTPIS:
          return (
            <Flex gap={10}>
              <Button
                onClick={() => {
                  modalStore.openModal(ModalName.SUBMITTED_REQUEST);
                  setActionId(4);
                }}
                type='primary'
              >
                {t('vratite')}
              </Button>
            </Flex>
          );
        default:
          return null;
      }
    }, [type, decisionStatus?.akcije, handleConfirmOdobravanjeZahteva]);

    const bottomSection = () => {
      return (
        <Col span={24}>
          <Row gutter={10}>
            <Col span={8}>
              <div className={style.actContainer}>
                <div className={style.circle}>1</div>
                <div className={style.actTitleButtonContainer}>
                  <span>{t('generisan_akt')}</span>
                  <Button
                    className={style.button}
                    icon={<DownloadOutlined />}
                    onClick={handleDownload}
                    type='primary'
                  >
                    {t('forma.dugme.preuzmite')}
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className={style.uploadContainer}>
                <div className={style.uploadNumberContainer}>
                  <Flex vertical justify='center'>
                    <div className={style.circle}>2</div>
                  </Flex>
                  <div className={style.uploadContainerInner}>
                    <div className={style.bottomBorder}>
                      <span> {t('uvezite_potpisan_dokument')}</span>
                    </div>
                    <div className='m-5'>
                      <Upload
                        maxCount={1}
                        locale={{ removeFile: t('obrisite_dokument') }}
                        beforeUpload={() => false}
                        onChange={handleFileUpload}
                        onRemove={() => setIsFileUploaded(false)}
                      >
                        <Button type={'primary'}>
                          <UploadOutlined />
                        </Button>
                      </Upload>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className={style.sendButtonContainer}>
                <div className={style.sendButtonContainerInner}>
                  <div className={style.circle}>3</div>
                  {isFileSent ? (
                    <Button
                      className={style.submitButton}
                      disabled={!isFileUploaded}
                      type='text'
                      onClick={() =>
                        submittedRequestsService
                          .postZahteviZavediResenje({
                            resenjeId: decisionStatus?.resenjeId,
                            zahtevId: +zahtevId!,
                          })
                          .then(() => {
                            notification.success({
                              message: t('potpisan_dokument_success'),
                            });
                            setTimeout(() => window.location.reload(), 500);
                          })
                          .catch((err) => {
                            console.error('Error preuzimanje ', err);
                            notification.error(err);
                          })
                      }
                    >
                      {t('posaljite_dokument_u_pisarnicu')}
                    </Button>
                  ) : (
                    <Button
                      className={style.submitButton}
                      disabled={!isFileUploaded}
                      onClick={handleUploadDoc}
                      type='text'
                    >
                      {t('posaljite_potpisan_dokument')}
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      );
    };

    return (
      <Row className={style.sectionContainer}>
        <SubmittedRequestsModal actionId={actionId} zahtevId={+zahtevId!} />
        <Flex justify='space-between' className='w-100 mb-10'>
          <Flex align='center' gap={20}>
            <h2 className='m-0'>{t(title)}</h2>
            {decisionStatus?.statusRaspodele ? (
              <Flex>
                <AllocationStatus statusId={decisionStatus.statusRaspodele} />
              </Flex>
            ) : null}
          </Flex>
          {layout}
        </Flex>
        {type === StatusOdlukaEnum.POTPIS && bottomSection()}
      </Row>
    );
  }
);
