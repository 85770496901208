import { useState } from 'react';
import { Button, Dropdown, Flex, MenuProps, Tooltip } from 'antd';
import { DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { LANGUAGES, languageFromLocalStorage, changeLanguage } from '@/i18n.ts';
import { t } from 'i18next';

import style from './TopPanel.module.scss';
import Logo from '../Logo/Logo.tsx';
import { authStore } from '@/modules/auth/auth.store.ts';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '@/routes/routes.tsx';

const TopPanel = () => {
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    languageFromLocalStorage ?? LANGUAGES.SERBIAN_CYRILLIC
  );

  const handleChangeLanguage = (langugage: LANGUAGES) => {
    setSelectedLanguage(langugage);
    changeLanguage(langugage);
    window.location.reload();
  };

  const handleLogout = async () => {
    await authStore.logOut();
    window.location.replace('/api/LogoutSSO');
  };

  const languageItems: MenuProps['items'] = [
    {
      label: t(LANGUAGES.SERBIAN_LATIN),
      key: '1',
      onClick: () => handleChangeLanguage(LANGUAGES.SERBIAN_LATIN),
    },
    {
      label: t(LANGUAGES.SERBIAN_CYRILLIC),
      key: '2',
      onClick: () => handleChangeLanguage(LANGUAGES.SERBIAN_CYRILLIC),
    },
  ];
  return (
    <>
      {!authStore.currentUser && (
        <div className={style.container}>
          <div className={style.topPanelLoggedOut}>
            <Logo height={50} />
            <div className={style.dropDownContainer}>
              <Flex>
                <Dropdown menu={{ items: languageItems }}>
                  <Button>
                    {t(selectedLanguage)}
                    <DownOutlined />
                  </Button>
                </Dropdown>
                {(authStore.currentUser ||
                  location.pathname === ROUTES.REGISTRATION) && (
                  <Tooltip title={t('logout')} placement={'left'}>
                    <Button
                      icon={<LogoutOutlined />}
                      type='primary'
                      danger
                      onClick={handleLogout}
                    />
                  </Tooltip>
                )}
              </Flex>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopPanel;
