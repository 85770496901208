/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Select } from 'antd';

import { getRequestsBasedOnFilterParams } from '@/utils/flattenRequestsFromGroups';
import { map4select } from '@/utils/map4select';
import {
  IFlattenRequest,
  IZahteviSviZahteviPayload,
} from '@/modules/submittedRequests/submittedRequests.types';
import { IResenjaPayload } from '@/modules/acts/acts.types.ts';

interface IRequestsNamesSelect<T> {
  setParams: Dispatch<SetStateAction<T>>;
  params: T;
}

const RequestsNamesSelect = observer(
  <T,>({
    setParams,
    params,
  }: IRequestsNamesSelect<
    T & (IZahteviSviZahteviPayload | IResenjaPayload)
  >) => {
    const { t } = useTranslation();
    const [flattenReqs, setFlattenReqs] = useState<IFlattenRequest[]>([]);

    useEffect(() => {
      getRequestsBasedOnFilterParams(params).then((res) => {
        setFlattenReqs(res ?? []);
      });
    }, [params]);

    return (
      <Select
        allowClear
        filterOption={(input, option) => {
          return (option?.label as string)
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        showSearch
        options={
          map4select({
            sourceArray: flattenReqs,
            valueAccessor: 'id',
            labelAccessor: 'ime',
          }) || []
        }
        value={params.nazivZahtevaId}
        placeholder={t('naziv_zahteva')}
        className='w-100'
        onChange={(value) => {
          setParams((prevParams) => ({
            ...prevParams,
            nazivZahtevaId: value || null,
          }));
        }}
      />
    );
  }
);

export default RequestsNamesSelect;
