import { Spin } from 'antd';

type LoadingProps = {
  size?: 'small' | 'large' | 'default';
};
const Loading = ({ size = 'small' }: LoadingProps) => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin size={size} />
    </div>
  );
};

export default Loading;
