/* eslint-disable max-len */
import { t } from 'i18next';
import {
  DynamicFormField,
  DynamicFormSchema,
  DynamicFormSection,
  PreduzeceTypeEnum,
} from '@/modules/requests/requests.types.ts';
import { INPUTS } from '@/modules/requests/requests.inputs.ts';

const businessEntityDetailsFields = (formName: string): DynamicFormField[] => {
  return [
    {
      name: ['preduzece', formName, 'vrstaPreduzece'],
      type: 'select',
      label: t('forma.sekcija.polje.vrstaPreduzeca'),
      placeholder: t('forma.sekcija.polje.vrstaPreduzeca_placeholder'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    {
      label: t('forma.sekcija.polje.maticni_broj'),
      type: 'text',
      name: ['preduzece', formName, 'maticniBroj'],
      placeholder: t('forma.sekcija.polje.maticni_broj_placeholder'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        {
          max: 8,
          message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
        },
      ],
    },
    {
      label: t('forma.sekcija.polje.naziv_preduzeca'),
      type: 'text',
      name: ['preduzece', formName, 'punNaziv'],
      placeholder: t('forma.sekcija.polje.naziv_preduzeca_placeholder'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        {
          max: 500,
          message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
        },
      ],
    },
    {
      label: t('forma.sekcija.polje.pib'),
      type: 'text',
      name: ['preduzece', formName, 'pib'],
      placeholder: t('forma.sekcija.polje.pib_placeholder'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        {
          max: 9,
          message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
        },
      ],
    },
    {
      label: t('forma.sekcija.polje.broj_poslovnog_racuna'),
      type: 'text',
      name: ['preduzece', formName, 'brojPoslovnogRacuna'],
      placeholder: t('forma.sekcija.polje.broj_poslovnog_racuna_placeholder'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    {
      label: t('forma.sekcija.polje.ulica_i_broj'),
      type: 'text',
      name: ['preduzece', formName, 'adresa'],
      placeholder: t('forma.sekcija.polje.ulica_i_broj_placeholder'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        {
          max: 500,
          message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
        },
      ],
    },
    {
      label: t('forma.sekcija.polje.mesto'),
      type: 'select',
      name: ['preduzece', formName, 'mestoId'],
      placeholder: t('forma.sekcija.polje.mesto_placeholder'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    {
      label: t('forma.sekcija.polje.telefon'),
      type: 'text',
      name: ['preduzece', formName, 'telefon'],
      placeholder: t('forma.sekcija.polje.telefon_placeholder'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        {
          max: 20,
          message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
        },
      ],
    },
    {
      label: t('forma.sekcija.polje.email'),
      type: 'text',
      name: ['preduzece', formName, 'email'],
      placeholder: t('forma.sekcija.polje.email_placeholder'),
      rules: [
        {
          required: true,
          type: 'email',
          message: t('forma.sekcija.polje.obavezno_polje'),
        },
        {
          max: 500,
          message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
        },
      ],
    },
    {
      label: t('forma.sekcija.polje.website'),
      type: 'text',
      name: ['preduzece', formName, 'webSite'],
      placeholder: t('forma.sekcija.polje.website_placeholder'),
      rules: [
        {
          type: 'url',
          message: t('forma.sekcija.polje.podaci_nisu_validni'),
        },
        {
          max: 500,
          message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
        },
      ],
    },
    {
      label: t('forma.sekcija.polje.ime_odgovornog_lica'),
      type: 'text',
      name: ['preduzece', formName, 'imePrezimeOdgovornogLica'],
      placeholder: t('forma.sekcija.polje.ime_odgovornog_lica_placeholder'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        {
          max: 500,
          message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
        },
      ],
    },
  ];
};

const businessEntityDetailsSection = (
  sectionTitle: string,
  formName: string
): DynamicFormSection => ({
  sectionTitle: sectionTitle,
  fields: businessEntityDetailsFields(formName),
});

//#region 3.4.1.1
export const zahtevZaPrimenjenaInzenjerskoGeotehnickaIstrazivanja: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.1'),
    formName: 'primenjenaInzenjerskoGeotehnickaIstrazivanja',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVodeDisabled,
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaPodzemneVode,
            INPUTS.duzinaRokaGeoloskogIstrazivanja,
            INPUTS.liceVrsilacTehnickeKontrolePodzemneVode,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_vrsilac_kontrole'),
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTNE_DOKUMENTACIJE)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezni],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.topografskaKarta,
            INPUTS.attachments.geoloskoIstrazivanje,
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.dokazKoriscenjePodataka,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.aktZaZastituPrirode,
            INPUTS.attachments.ispunjenostUslovaZaZastituPrirode,
            INPUTS.attachments.aktOUslovimaZaZastituSpomenikaKulture,
            INPUTS.attachments.dokazOPravuKoriscenjaGeoloskeDokumentacije,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.2
export const zahtevZaPrimenjenaGeoloskaIstrazivnjaPodzemnihVoda: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.2'),
    formName: 'zahtevZaPrimenjenaGeoloskaIstrazivnjaPodzemnihVoda',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
            INPUTS.predmetIstrazivanjaId,
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaPodzemneVode,
            INPUTS.duzinaRokaGeoloskogIstrazivanja,
            INPUTS.liceVrsilacTehnickeKontrolePodzemneVode,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_vrsilac_kontrole'),
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTNE_DOKUMENTACIJE)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezni],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.topografskaKarta,
            INPUTS.attachments.geoloskoIstrazivanje,
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.dokazKoriscenjePodataka,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.aktZaZastituPrirode,
            INPUTS.attachments.ispunjenostUslovaZaZastituPrirode,
            INPUTS.attachments.aktOUslovimaZaZastituSpomenikaKulture,
            INPUTS.attachments.dokazOPravuKoriscenjaGeoloskeDokumentacije,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.3
export const zahtevZaProduzenjeRokaZaPrimenjenaGeoloskaIstrazivanja: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.3'),
    formName: 'zahtevZaProduzenjeRokaZaPrimenjenaGeoloskaIstrazivanja',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskogeoloskaGeotehnickaIstrazivanja,
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaPodzemneVode,
            INPUTS.liceVrsilacTehnickeKontrolePodzemneVode,
            INPUTS.liceVrsilacNadzoraPodzemneVode,
            INPUTS.nazivZavrsnogIzvestajaElaborata,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_vrsilac_kontrole'),
          String(PreduzeceTypeEnum.KONTROLA_IZVESTAJA)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        ),
        businessEntityDetailsSection(
          t(
            'forma.sekcija.naslov.preduzeceVrsilacTehnickeKontroleProjektneDokumentacije'
          ),
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTNE_DOKUMENTACIJE)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezni],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.topografskaKarta,
            INPUTS.attachments.geoloskoIstrazivanje,
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.izvestajOStrucnomNadzoru,
            INPUTS.attachments.izvestajElaborat,
            INPUTS.attachments
              .izvestajPotvrdaOTehnickojKontroliZavrsnogIzvestaja,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.4
export const zahtevZaProduzenjeRokaOdobrenjaZaPrimenjenaGeoloskaIstrazivanja: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.4'),
    formName: 'zahtevZaProduzenjeRokaOdobrenjaZaPrimenjenaGeoloskaIstrazivanja',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaPetrogeotermalnihResursa,
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaPodzemneVode,
            INPUTS.liceVrsilacTehnickeKontrolePodzemneVode,
            INPUTS.liceVrsilacNadzoraPodzemneVode,
            INPUTS.nazivZavrsnogIzvestajaElaborata,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_vrsilac_kontrole'),
          String(PreduzeceTypeEnum.KONTROLA_IZVESTAJA)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        ),
        businessEntityDetailsSection(
          t(
            'forma.sekcija.naslov.preduzeceVrsilacTehnickeKontroleProjektneDokumentacije'
          ),
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTNE_DOKUMENTACIJE)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezni],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.topografskaKarta,
            INPUTS.attachments.geoloskoIstrazivanje,
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.izvestajOStrucnomNadzoru,
            INPUTS.attachments
              .izvestajPotvrdaOTehnickojKontroliZavrsnogIzvestaja,
            INPUTS.attachments.izvestajElaborat,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.5
export const zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstor: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.5'),
    formName: 'zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstor',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaPetrogeotermalnihResursa,
            INPUTS.nazivProgramaAktivnosti,
            INPUTS.liceVrsilacNadzoraPodzemneVode,
            INPUTS.nazivZavrsnogIzvestajaElaborata,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_program'),
          String(PreduzeceTypeEnum.URADILO_PROGRAM)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.preduzeceVrsilacTehnickeKontroleIzvestaja'),
          String(PreduzeceTypeEnum.KONTROLA_IZVESTAJA)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezni],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.programAktivnosti,
            INPUTS.attachments.preglednaSituacionaKarta,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.6
export const zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeoloskihIstrazivanja: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.6'),
    formName:
      'zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeoloskihIstrazivanja',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskogeoloskaGeotehnickaIstrazivanja,
            INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
            INPUTS.predmetIstrazivanjaId,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.pravnoLicePrenosOdobrenja'),
          String(PreduzeceTypeEnum.PRENOS_ODOBRENJA)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.lokalitet],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.lokalitetOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.pisanaIzjavaNovogNosioca,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.7
export const zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstor: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.7'),
    formName: 'zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstor',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaZadrzavanjePravaNaIstrazniProstorZaPodzemneVodeIGeotermalneResurse,
            INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
            INPUTS.predmetIstrazivanjaId,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.pravnoLicePrenosOdobrenja'),
          String(PreduzeceTypeEnum.PRENOS_ODOBRENJA)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.lokalitet],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.lokalitetOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.pisanaIzjavaNovogNosioca,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.8
export const prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanja: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.8'),
    formName:
      'prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanja',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskogeoloskaGeotehnickaIstrazivanja,
            INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
            INPUTS.predmetIstrazivanjaId,
            INPUTS.opstinaId,
            INPUTS.datumPocetkaRadovaNaIstrazivanju,
            INPUTS.liceVrsilacNadzoraPodzemneVode,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjacu_radova'),
          String(PreduzeceTypeEnum.IZVODJAC_RADOVA)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.lokalitet],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.lokalitetOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.resenjeOImenovanjuStrucnogNadzora,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.14
export const zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjekta: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.9'),
    formName: 'zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjekta',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaPetrogeotermalnihResursa,
            INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
            INPUTS.predmetIstrazivanjaId,
            INPUTS.duzinaRokaGeoloskogIstrazivanja,
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaPodzemneVode,
            INPUTS.opstinaId,
            INPUTS.liceVrsilacTehnickeKontrolePodzemneVode,
            INPUTS.liceVrsilacNadzoraPodzemneVode,
            INPUTS.nazivZavrsnogIzvestaja,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        businessEntityDetailsSection(
          t(
            'forma.sekcija.naslov.preduzeceVrsilacTehnickeKontroleProjektneDokumentacije'
          ),
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTNE_DOKUMENTACIJE)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.preduzeceVrsilacTehnickeKontroleIzvestaja'),
          String(PreduzeceTypeEnum.KONTROLA_IZVESTAJA)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezni],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.topografskaKarta,
            {
              ...INPUTS.attachments.geoloskoIstrazivanje,
              label: t('forma.sekcija.prilog.projekat_geo_istrazivanja'),
            },
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments
              .dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnjaOpciono,
            INPUTS.attachments.aktZaZastituPrirodeOpcioni,
            INPUTS.attachments.ispunjenostUslovaZaZastituPrirode,
            INPUTS.attachments.aktOUslovimaZaZastituSpomenikaKultureOpcioni,
            INPUTS.attachments.izvestajOStrucnomNadzoru,
            INPUTS.attachments
              .izvestajPotvrdaOTehnickojKontroliZavrsnogIzvestaja,
            INPUTS.attachments.izvestajElaborat,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.15
export const zahtevZaPrestanakVazenjaOdobrenja: DynamicFormSchema = {
  formTitle: t('forma.naslov.10'),
  formName: 'zahtevZaPrestanakVazenjaOdobrenja',
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskogeoloskaGeotehnickaIstrazivanja,
          INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
          INPUTS.predmetIstrazivanjaId,
          INPUTS.opstinaId,
          INPUTS.visinaAdministrativneTakse,
        ],
      },
      {
        sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
        fields: [INPUTS.lokalitet],
      },
      {
        sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
        fields: [INPUTS.lokalitetOpcioni],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.dokazRepublickaTaksa,
          INPUTS.attachments.izvodIzRegistra,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};
//#endregion

//#region 3.4.1.16
export const zahtevZaOdustajanjeOdZahtevaPodzemneVode: DynamicFormSchema = {
  formTitle: t('forma.naslov.11'),
  formName: 'zahtevZaOdustajanjeOdZahtevaPodzemneVode',
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborZahtevaMineralneSirovine,
          INPUTS.tipZahteva,
          INPUTS.datumPodnosenjaZahteva,
          INPUTS.visinaAdministrativneTakse,
        ],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.dokazRepublickaTaksa,
          INPUTS.attachments.izvodIzRegistra,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};
//#endregion

//#region 3.4.1.9
export const zahtevZaUtvrdjivanjeIOveruRazvrstanihRezerviIResursa: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.12'),
    formName: 'zahtevZaUtvrdjivanjeIOveruRazvrstanihRezerviIResursa',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaPetrogeotermalnihResursa,
            INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
            INPUTS.predmetIstrazivanjaId,
            INPUTS.nazivElaborataPodzemneVode,
            INPUTS.datumIzradeElaborata,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
            INPUTS.datumPocetkaGeoloskogIstrazivanja,
            INPUTS.datumZavrsetkaGeoloskogIstrazivanja,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_uradilo_elaborat'),
          String(PreduzeceTypeEnum.URADILO_ELABORAT)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.lokalitet],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments
              .dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.preglednaKarta,
            INPUTS.attachments.izvodIzElaborata,
            INPUTS.attachments
              .dokazDaAutorElaborataIspunjavaUslovePropisaneZakonom,
            INPUTS.attachments
              .elaboratOResursimaIRezervamaPodzemnihVodaIGeotermalnihResursa,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.10
export const zahtevZaPrenosPotvrdeORezervamaPodzemnihVoda: DynamicFormSchema = {
  formTitle: t('forma.naslov.13'),
  formName: 'zahtevZaPrenosPotvrdeORezervamaPodzemnihVoda',
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina,
          INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
          INPUTS.predmetIstrazivanjaId,
          INPUTS.opstinaId,
          INPUTS.visinaAdministrativneTakse,
        ],
      },
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.pravnoLicePrenosPotvrde'),
        String(PreduzeceTypeEnum.PRENOS_POTVRDE)
      ),
      {
        sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
        fields: [INPUTS.lokalitet],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.pisanaIzjavaNovogNosioca,
          INPUTS.attachments.izvodIzRegistra,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};
//#endregion

//#region 3.4.1.11
export const zahtevZaDobijanjeOdobrenjaKojimSeUtvrdjujeEksploatacioniProstorIKolicineRezervi: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.14'),
    formName:
      'zahtevZaDobijanjeOdobrenjaKojimSeUtvrdjujeEksploatacioniProstorIKolicineRezervi',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina,
            INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
            INPUTS.predmetIstrazivanjaId,
            INPUTS.nazivElaborataPodzemneVode,
            INPUTS.datumIzradeElaborata,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_uradilo_elaborat'),
          String(PreduzeceTypeEnum.URADILO_ELABORAT)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezniEksploatacioniProstor],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioniEksploatacioniProstor],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.topografskaKarta,
            INPUTS.attachments.geodetskiPlan,
            INPUTS.attachments.dokazOPravuSvojineSaMestom,
            INPUTS.attachments.elaboratOUslovimaEksploatacijePodzemnihVoda,
            INPUTS.attachments.aktZaZastituPrirode,
            INPUTS.attachments
              .aktZaPosloveSanitarneZastiteIzvoristaVodosnabdevanja,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.12
export const zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaOdobrenjaEksploatacionogProstoraIKolicineRezervi: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.15'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaOdobrenjaEksploatacionogProstoraIKolicineRezervi',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacioniProstorIKolicinuRezervi,
            INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
            INPUTS.predmetIstrazivanjaId,
            INPUTS.nazivElaborataPodzemneVode,
            INPUTS.datumIzradeElaborata,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_uradilo_elaborat'),
          String(PreduzeceTypeEnum.URADILO_ELABORAT)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezniEksploatacioniProstor],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioniEksploatacioniProstor],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.elaboratOUslovimaEksploatacijePodzemnihVoda,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.13
export const zahtevZaPrenosOdobrenjaEksploatacionogProstoraIKolicineRezerviPodzemnihVoda: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.16'),
    formName:
      'zahtevZaPrenosOdobrenjaEksploatacionogProstoraIKolicineRezerviPodzemnihVoda',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacioniProstorIKolicinuRezervi,
            INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
            INPUTS.predmetIstrazivanjaId,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.pravnoLicePrenosOdobrenja'),
          String(PreduzeceTypeEnum.PRENOS_ODOBRENJA)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.lokalitet],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.lokalitetOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.pisanaIzjavaNovogNosioca,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.1.17
export const izvestajOStanjuBilansnihRezerviZaPodzemneVodeIHidrogeotermalneResurse =
  {
    formTitle: t('forma.naslov.17'),
    formName:
      'izvestajOStanjuBilansnihRezerviZaPodzemneVodeIHidrogeotermalneResurse',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaIliPetrogeotermalnihResursa,
          ],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.stanjeBilansnihRezervi,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
export const izvestajOStanjuBilansnihRezerviZaPetrogeotermalneResurse = {
  formTitle: t('forma.naslov.18'),
  formName: 'izvestajOStanjuBilansnihRezerviZaPetrogeotermalneResurse',
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaIliPetrogeotermalnihResursa,
        ],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.stanjeBilansnihRezervi,
          INPUTS.attachments.izvodIzRegistra,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};

export const godisnjiIzvestajGeoloskaIstraživanja = {
  formTitle: t('forma.naslov.19'),
  formName: 'godisnjiIzvestajGeoloskaIstraživanja',
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskoGeoloska,
          INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
          INPUTS.predmetIstrazivanjaId,
          INPUTS.opstinaId,
          INPUTS.liceVrsilacNadzoraPodzemneVode,
          INPUTS.datumPocetkaGeoloskogIstrazivanja,
          INPUTS.datumZavrsetkaGeoloskogIstrazivanja,
          {
            ...INPUTS.nazivZavrsnogIzvestaja,
            label: t('forma.sekcija.polje.nazivGodisnjegIzvestaja'),
            placeholder: t(
              'forma.sekcija.polje.nazivGodisnjegIzvestaja_placeholder'
            ),
          },
        ],
      },
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
        String(PreduzeceTypeEnum.STRUCNI_NADZOR)
      ),
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.preduzeceVrsilacTehnickeKontroleIzvestaja'),
        String(PreduzeceTypeEnum.KONTROLA_IZVESTAJA)
      ),
      {
        sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
        fields: [INPUTS.lokalitet],
      },
      {
        sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
        fields: [INPUTS.lokalitetOpcioni],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.godisnjiIzvestaj,
          INPUTS.attachments.izvestajPotvrdaOTehnickojKontroliZavrsnogIzvestaja,
          INPUTS.attachments.izvestajOStrucnomNadzoru,
          INPUTS.attachments.izvodIzRegistra,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};

export const zavrsniIzvestajGeoloskaIstraživanja = {
  formTitle: t('forma.naslov.20'),
  formName: 'zavrsniIzvestajGeoloskaIstraživanja',
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskoGeoloska,
          INPUTS.vrstaGeoloskogIstrazivanjaIdPodzemneVode,
          INPUTS.predmetIstrazivanjaId,
          INPUTS.opstinaId,
          INPUTS.liceVrsilacNadzoraPodzemneVode,
          INPUTS.datumPocetkaGeoloskogIstrazivanja,
          INPUTS.datumZavrsetkaGeoloskogIstrazivanja,
          INPUTS.nazivZavrsnogIzvestaja,
        ],
      },
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
        String(PreduzeceTypeEnum.STRUCNI_NADZOR)
      ),
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.preduzeceVrsilacTehnickeKontroleIzvestaja'),
        String(PreduzeceTypeEnum.KONTROLA_IZVESTAJA)
      ),
      {
        sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
        fields: [INPUTS.lokalitet],
      },
      {
        sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
        fields: [INPUTS.lokalitetOpcioni],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.zavrsniIzvestaj,
          INPUTS.attachments.izvestajPotvrdaOTehnickojKontroliZavrsnogIzvestaja,
          INPUTS.attachments.izvestajOStrucnomNadzoru,
          INPUTS.attachments.izvodIzRegistra,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};
//#endregion

//#region 3.4.2.1
const zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina: Pick<
  DynamicFormSchema,
  'inputs' | 'attachments'
> = {
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine,
          INPUTS.vrstaGeoloskogIstrazivanjaIdMineralneSirovine,
          INPUTS.vrstaMineralnihSirovinaId,
          INPUTS.vremeTrajanjaGeoloskihIstrazivanja,
          INPUTS.liceVrsilacTehnickeKontroleMineralneSirovine,
          INPUTS.liceVrsilacNadzoraMineralneSirovine,
          INPUTS.opstinaId,
          INPUTS.visinaAdministrativneTakse,
          INPUTS.godinaIzrade,
          INPUTS.glavniProjektantImePrezime,
          INPUTS.odgovorniProjektantImePrezime,
        ],
      },
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
        String(PreduzeceTypeEnum.URADILO_PROJEKAT)
      ),
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.podaci_o_preduzecu_vrsilac_kontrole'),
        String(PreduzeceTypeEnum.KONTROLA_PROJEKTA)
      ),
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
        String(PreduzeceTypeEnum.STRUCNI_NADZOR)
      ),
      {
        sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
        fields: [INPUTS.prostorObavezni],
      },
      {
        sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
        fields: [INPUTS.prostorOpcioni],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.topografskaKarta,
          INPUTS.attachments.geoloskoIstrazivanje,
          INPUTS.attachments.tehnickaKontrola,
          INPUTS.attachments.dokazRepublickaTaksa,
          INPUTS.attachments
            .dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja,
          INPUTS.attachments.aktOUslugamaZavoda,
          INPUTS.attachments.ispunjenostUslovaZaZastituPrirode,
          INPUTS.attachments.aktOUslovimaZaZastituSpomenikaKulture,
          INPUTS.attachments.uverenjeOPolozenomStrucnomIspitu,
          INPUTS.attachments.potvrdaOZaposlenjuIImenovanjuGlavnogProjektanta,
          INPUTS.attachments.dokazOIspunjenostiUslovaClana22,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};

export const zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaMetalicnihMineralnihSirovina: DynamicFormSchema =
  {
    formName:
      'zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina',
    formTitle: t('forma.naslov.21'),
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine,
            INPUTS.vrstaGeoloskogIstrazivanjaIdMineralneSirovine,
            INPUTS.vrstaMineralnihSirovinaId,
            INPUTS.vremeTrajanjaGeoloskihIstrazivanja,
            INPUTS.godinaIzrade,
            INPUTS.glavniProjektantImePrezime,
            INPUTS.odgovorniProjektantImePrezime,
            INPUTS.liceVrsilacTehnickeKontroleMineralneSirovine,
            INPUTS.liceVrsilacNadzoraMineralneSirovine,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_vrsilac_kontrole'),
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTA)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezni],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.topografskaKarta,
            INPUTS.attachments.geoloskoIstrazivanje,
            INPUTS.attachments.pismoONamerama,
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments
              .dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja,
            INPUTS.attachments.aktOUslugamaZavoda,
            INPUTS.attachments.ispunjenostUslovaZaZastituPrirode,
            INPUTS.attachments.aktOUslovimaZaZastituSpomenikaKulture,
            INPUTS.attachments.uverenjeOPolozenomStrucnomIspitu,
            INPUTS.attachments.potvrdaOZaposlenjuIImenovanjuGlavnogProjektanta,
            INPUTS.attachments.dokazOIspunjenostiUslovaClana22,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
export const zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaNemetalicnihMineralnihSirovina: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.30'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaNemetalicnihMineralnihSirovina',
    ...zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
  };
export const zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaEnergetskihhMineralnihSirovina: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.39'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaEnergetskihhMineralnihSirovina',
    ...zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
  };
//#endregion

//#region 3.4.2.2
export const zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.48'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine,
            INPUTS.vrstaMineralneSirovineOpisno,
            INPUTS.liceVrsilacTehnickeKontroleMineralneSirovine,
            INPUTS.liceVrsilacNadzoraMineralneSirovine,
            INPUTS.opstinaId,
            INPUTS.godinaIzrade,
            INPUTS.glavniProjektantImePrezime,
            INPUTS.odgovorniProjektantImePrezime,
            INPUTS.visinaAdministrativneTakse,
            {
              name: ['istrazivanjeAtributi', 'katastarskiBrojParcele'],
              type: 'text',
              label: t('forma.sekcija.polje.katastarski_broj_parcele'),
              placeholder: t(
                'forma.sekcija.polje.katastarski_broj_parcele_placeholder'
              ),
              rules: [
                {
                  required: true,
                  message: t('forma.sekcija.polje.obavezno_polje'),
                },
                {
                  max: 100,
                  message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
                },
              ],
            },
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_vrsilac_kontrole'),
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTA)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezni],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.topografskaKarta,
            INPUTS.attachments.geodetskiPlan,
            INPUTS.attachments.geoloskoIstrazivanje,
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.uverenjeOPolozenomStrucnomIspitu,
            INPUTS.attachments.potvrdaOZaposlenjuIImenovanjuGlavnogProjektanta,
            INPUTS.attachments.dokazOIspunjenostiUslovaClana22,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.2.3
const zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaMineralnihSirovina =
  {
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine,
            INPUTS.vrstaGeoloskogIstrazivanjaIdMineralneSirovine,
            INPUTS.vrstaMineralnihSirovinaId,
            INPUTS.brojProduzenja,
            INPUTS.liceVrsilacTehnickeKontroleMineralneSirovine,
            INPUTS.liceVrsilacNadzoraMineralneSirovine,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
            INPUTS.vremeTrajanjaGeoloskihIstrazivanja,
            INPUTS.godinaIzrade,
            INPUTS.glavniProjektantImePrezime,
            INPUTS.odgovorniProjektantImePrezime,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_vrsilac_kontrole'),
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTA)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezni],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.topografskaKarta,
            INPUTS.attachments.geoloskoIstrazivanje,
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.izvestajElaborat,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };

export const zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaMetalicnihMineralnihSirovina: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.22'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaMetalicnihMineralnihSirovina',
    inputs: {
      ...zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaMineralnihSirovina.inputs,
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.topografskaKarta,
            INPUTS.attachments.geoloskoIstrazivanje,
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.pismoONamerama,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.izvestajElaborat,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
export const zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaNemetalicnihMineralnihSirovina: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.31'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaNemetalicnihMineralnihSirovina',
    ...zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaMineralnihSirovina,
  };
export const zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaEnergetskihhMineralnihSirovina: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.40'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaEnergetskihhMineralnihSirovina',
    ...zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaMineralnihSirovina,
  };
//#endregion

//#region 3.4.2.4
const zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine =
  {
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
            INPUTS.vrstaGeoloskogIstrazivanjaIdMineralneSirovine,
            INPUTS.vrstaMineralnihSirovinaId,
            INPUTS.nazivProgramaAktivnostiIstrazivanjaMS,
            INPUTS.vremeTrajanjaGeoloskihIstrazivanja,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.prostorObavezni],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.prostorOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.programAktivnosti,
            INPUTS.attachments.zavrsniIzvestaj,
            INPUTS.attachments.preglednaSituacionaKarta,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };

export const zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMetalicneMineralneSirovine =
  {
    formTitle: t('forma.naslov.23'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMetalicneMineralneSirovine',
    ...zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine,
  };
export const zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaNemetalicneMineralneSirovine =
  {
    formTitle: t('forma.naslov.32'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaNemetalicneMineralneSirovine',
    ...zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine,
  };
export const zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaEnergetskeMineralneSirovine =
  {
    formTitle: t('forma.naslov.41'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaEnergetskeMineralneSirovine',
    ...zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine,
  };
//#endregion

//#region 3.4.2.5
const zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaMineralnihSirovina =
  {
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine,
            INPUTS.vrstaGeoloskogIstrazivanjaIdMineralneSirovine,
            INPUTS.opstinaId,
            INPUTS.brojOdobrenogIstraznogPolja,
            INPUTS.rokVazenjaOdobrenja,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.pravnoLicePrenosOdobrenja'),
          String(PreduzeceTypeEnum.PRENOS_ODOBRENJA)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.lokalitet],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.lokalitetOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.pisanaIzjavaNovogNosioca,
            INPUTS.attachments
              .izvestajNosiocaOdobrenjaZaIstrazivanjeOIzvrsenjuObavezaUVeziSanacijeIRekultivacijeProstora,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };

export const zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaMetalicnihMineralnihSirovina =
  {
    formTitle: t('forma.naslov.24'),
    formName:
      'zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaMetalicnihMineralnihSirovina',
    ...zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaMineralnihSirovina,
  };
export const zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaNemetalicnihMineralnihSirovina =
  {
    formTitle: t('forma.naslov.33'),
    formName:
      'zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaNemetalicnihMineralnihSirovina',
    ...zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaMineralnihSirovina,
  };
export const zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaEnergetskihhMineralnihSirovina =
  {
    formTitle: t('forma.naslov.42'),
    formName:
      'zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaEnergetskihhMineralnihSirovina',
    ...zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaMineralnihSirovina,
  };
//#endregion

//#region 3.4.2.6
const zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine: Pick<
  DynamicFormSchema,
  'inputs' | 'attachments'
> = {
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborResenjaOOdobravanjuZahtevaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine,
          INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine,
          INPUTS.vrstaGeoloskogIstrazivanjaIdMineralneSirovine,
          INPUTS.brojOdobrenogIstraznogPolja,
          INPUTS.rokVazenjaOdobrenja,
          INPUTS.opstinaId,
          INPUTS.visinaAdministrativneTakse,
        ],
      },
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.pravnoLicePrenosOdobrenja'),
        String(PreduzeceTypeEnum.PRENOS_ODOBRENJA)
      ),
      {
        sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
        fields: [INPUTS.lokalitet],
      },
      {
        sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
        fields: [INPUTS.lokalitetOpcioni],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.pisanaIzjavaNovogNosioca,
          INPUTS.attachments
            .izvestajNosiocaOdobrenjaZaIstrazivanjeOIzvrsenjuObavezaUVeziSanacijeIRekultivacijeProstora,
          INPUTS.attachments.dokazRepublickaTaksa,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};

export const zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMetalicneMineralneSirovine =
  {
    formTitle: t('forma.naslov.25'),
    formName:
      'zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMetalicneMineralneSirovine',
    ...zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine,
  };
export const zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaNemetalicneMineralneSirovine =
  {
    formTitle: t('forma.naslov.34'),
    formName:
      'zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaNemetalicneMineralneSirovine',
    ...zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine,
  };
export const zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaEnergetskeMineralneSirovine =
  {
    formTitle: t('forma.naslov.43'),
    formName:
      'zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaEnergetskeMineralneSirovine',
    ...zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine,
  };

//#endregion

//#region 3.4.2.7
const prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina =
  {
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine,
            INPUTS.vrstaMineralnihSirovinaId,
            INPUTS.datumPrijemaResenja,
            INPUTS.brojIstraznogProstora,
            INPUTS.vremeTrajanjaGeoloskihIstrazivanja,
            INPUTS.liceVrsilacNadzoraMineralneSirovine,
            INPUTS.datumPocetkaProbnogRadaBusotine,
            INPUTS.datumZavrsetkaProbnogRadaBusotine,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjacu_radova'),
          String(PreduzeceTypeEnum.IZVODJAC_RADOVA)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.lokalitet],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.lokalitetOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.resenjeOImenovanjuStrucnogNadzora,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };

export const prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaMetalicnihMineralnihSirovina =
  {
    formTitle: t('forma.naslov.26'),
    formName:
      'prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala',
    ...prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
  };
export const prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaNemetalicnihMineralnihSirovina =
  {
    formTitle: t('forma.naslov.35'),
    formName:
      'prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaNemetalicnihMineralnihSirovina',
    ...prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
  };
export const prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaGradjevinskihMineralnihSirovina =
  {
    formTitle: t('forma.naslov.44'),
    formName:
      'prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala',
    ...prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
  };
//#endregion

//#region 3.4.2.8
export const prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala =
  {
    formTitle: t('forma.naslov.49'),
    formName:
      'prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala,
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine,
            INPUTS.datumPrijemaResenja,
            INPUTS.liceVrsilacNadzoraMineralneSirovine,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.IZVODJAC_RADOVA)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.lokalitet],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.lokalitetOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.resenjeOImenovanjuStrucnogNadzora,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.2.9
export const zahtevZaUtvrdjivanjeIOveruRazvrstanihRezerviMineralnihSirovina: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.53'),
    formName: 'zahtevZaUtvrdjivanjeIOveruRazvrstanihRezerviMineralnihSirovina',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovinaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine,
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacionoPolje,
            INPUTS.nazivElaborataIstrazivanje,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
            INPUTS.godinaIzradeElaborata,
            INPUTS.autoriElaborata,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_uradilo_elaborat'),
          String(PreduzeceTypeEnum.URADILO_ELABORAT)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.lokalitet],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.lokalitetOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments
              .dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja,
            INPUTS.attachments.predlogZaOveruRezerviSaPreglednomKartom,
            INPUTS.attachments.elaboratOResursimaIRezervamaMineralnihSirovina,
            INPUTS.attachments
              .dokazDaAutorElaborataIspunjavaUslovePropisaneZakonom,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.2.10
export const zahtevZaPrenosPotvrdeORezervamaMineralnihSirovina: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.54'),
    formName: 'zahtevZaPrenosPotvrdeORezervamaMineralnihSirovina',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina,
            INPUTS.datumPocetkaIzvrsenjaGeoloskogIstrazivanja,
            INPUTS.datumZavrsetkaIzvrsenjaGeoloskogIstrazivanja,
            INPUTS.opstinaId,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.pravnoLicePrenosPotvrde'),
          String(PreduzeceTypeEnum.PRENOS_POTVRDE)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.lokalitet],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.lokalitetOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.pisanaIzjavaNovogNosioca,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.2.11
const zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanja = {
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
          INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine,
          INPUTS.vrstaMineralnihSirovinaId,
          INPUTS.opstinaId,
          INPUTS.liceVrsilacTehnickeKontroleMineralneSirovine,
          INPUTS.liceVrsilacNadzoraMineralneSirovine,
          INPUTS.godinaIzrade,
          INPUTS.glavniProjektantImePrezime,
          INPUTS.odgovorniProjektantImePrezime,
          INPUTS.nazivZavrsnogIzvestaja,
          INPUTS.visinaAdministrativneTakse,
        ],
      },
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
        String(PreduzeceTypeEnum.URADILO_PROJEKAT)
      ),
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.podaci_o_preduzecu_vrsilac_kontrole'),
        String(PreduzeceTypeEnum.KONTROLA_PROJEKTA)
      ),
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
        String(PreduzeceTypeEnum.STRUCNI_NADZOR)
      ),
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.preduzeceVrsilacTehnickeKontroleIzvestaja'),
        String(PreduzeceTypeEnum.KONTROLA_IZVESTAJA)
      ),
      {
        sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
        fields: [INPUTS.lokalitet],
      },
      {
        sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
        fields: [INPUTS.lokalitetOpcioni],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.topografskaKarta,
          INPUTS.attachments.geoloskoIstrazivanje,
          INPUTS.attachments.tehnickaKontrola,
          INPUTS.attachments.dokazRepublickaTaksa,
          INPUTS.attachments.aktZaZastituPrirodeOpcioni,
          INPUTS.attachments.ispunjenostUslovaZaZastituPrirode,
          INPUTS.attachments
            .dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja,
          INPUTS.attachments.izvestajElaborat,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};

export const zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaMetalicnihSirovina =
  {
    formTitle: t('forma.naslov.27'),
    formName:
      'zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaMetalicnihSirovina',
    ...zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanja,
  };
export const zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaNemetalicnihSirovina =
  {
    formTitle: t('forma.naslov.36'),
    formName:
      'zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaNemetalicnihSirovina',
    ...zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanja,
  };
export const zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaEnergetskihSirovina =
  {
    formTitle: t('forma.naslov.45'),
    formName:
      'zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaEnergetskihSirovina',
    ...zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanja,
  };
export const zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaPrirodnihSirovina =
  {
    formTitle: t('forma.naslov.50'),
    formName:
      'zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaPrirodnihSirovina',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
            INPUTS.nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine,
            INPUTS.vrstaMineralneSirovineOpisno,
            INPUTS.opstinaId,
            INPUTS.liceVrsilacTehnickeKontroleMineralneSirovine,
            INPUTS.liceVrsilacNadzoraMineralneSirovine,
            INPUTS.godinaIzrade,
            INPUTS.glavniProjektantImePrezime,
            INPUTS.odgovorniProjektantImePrezime,
            INPUTS.nazivZavrsnogIzvestajaElaborata,
            INPUTS.visinaAdministrativneTakse,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_vrsilac_kontrole'),
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTA)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_strucni_nadzor'),
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        ),
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.preduzeceVrsilacTehnickeKontroleIzvestaja'),
          String(PreduzeceTypeEnum.KONTROLA_IZVESTAJA)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
          fields: [INPUTS.lokalitet],
        },
        {
          sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
          fields: [INPUTS.lokalitetOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.topografskaKarta,
            INPUTS.attachments.geoloskoIstrazivanje,
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.aktZaZastituPrirodeOpcioni,
            INPUTS.attachments.ispunjenostUslovaZaZastituPrirode,
            INPUTS.attachments
              .dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja,
            INPUTS.attachments.izvestajElaborat,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.2.12
const zahtevZaPrestanakVazenjaResenjaOOdobravanju = {
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.opstinaId,
          INPUTS.visinaAdministrativneTakse,
          INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
          INPUTS.vrstaMineralnihSirovinaId,
          INPUTS.opstinaId,
          INPUTS.visinaAdministrativneTakse,
        ],
      },
      {
        sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
        fields: [INPUTS.lokalitet],
      },
      {
        sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
        fields: [INPUTS.lokalitetOpcioni],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.dokazRepublickaTaksa,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};

export const zahtevZaPrestanakVazenjaResenjaOOdobravanjuMetalicnihSirovina = {
  formTitle: t('forma.naslov.28'),
  formName: 'zahtevZaPrestanakVazenjaResenjaOOdobravanjuMetalicnihSirovina',
  ...zahtevZaPrestanakVazenjaResenjaOOdobravanju,
};
export const zahtevZaPrestanakVazenjaResenjaOOdobravanjuNemetalicnihSirovina = {
  formTitle: t('forma.naslov.37'),
  formName: 'zahtevZaPrestanakVazenjaResenjaOOdobravanjuNemetalicnihSirovina',
  ...zahtevZaPrestanakVazenjaResenjaOOdobravanju,
};
export const zahtevZaPrestanakVazenjaResenjaOOdobravanjuEnergetskihSirovina = {
  formTitle: t('forma.naslov.46'),
  formName: 'zahtevZaPrestanakVazenjaResenjaOOdobravanjuEnergetskihSirovina',
  ...zahtevZaPrestanakVazenjaResenjaOOdobravanju,
};
export const zahtevZaPrestanakVazenjaResenjaOOdobravanjuPrirodnihSirovina = {
  formTitle: t('forma.naslov.51'),
  formName: 'zahtevZaPrestanakVazenjaResenjaOOdobravanjuPrirodnihSirovina',
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala,
          INPUTS.vrstaMineralneSirovineOpisno,
          INPUTS.opstinaId,
          INPUTS.visinaAdministrativneTakse,
        ],
      },
      {
        sectionTitle: t('forma.sekcija.naslov.informacije_o_lokalitetu'),
        fields: [INPUTS.lokalitet],
      },
      {
        sectionTitle: `${t('forma.sekcija.naslov.informacije_o_lokalitetu')} 2`,
        fields: [INPUTS.lokalitetOpcioni],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.dokazRepublickaTaksa,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};
//#endregion

//#region 3.4.2.13
const zahtevZaOdustajanjeOdRanijePodnetogZahteva = {
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborZahtevaMineralneSirovine,
          INPUTS.tipZahteva,
          INPUTS.datumPodnosenjaZahteva,
          INPUTS.visinaAdministrativneTakse,
        ],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.dokazRepublickaTaksa,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};

export const zahtevZaOdustajanjeOdRanijePodnetogZahtevaMetalicnihSirovina = {
  formTitle: t('forma.naslov.29'),
  formName: 'zahtevZaOdustajanjeOdRanijePodnetogZahtevaMetalicnihSirovina',
  ...zahtevZaOdustajanjeOdRanijePodnetogZahteva,
};
export const zahtevZaOdustajanjeOdRanijePodnetogZahtevaNemetalicnihSirovina = {
  formTitle: t('forma.naslov.38'),
  formName: 'zahtevZaOdustajanjeOdRanijePodnetogZahtevaNemetalicnihSirovina',
  ...zahtevZaOdustajanjeOdRanijePodnetogZahteva,
};
export const zahtevZaOdustajanjeOdRanijePodnetogZahtevaEnergetskihSirovina = {
  formTitle: t('forma.naslov.47'),
  formName: 'zahtevZaOdustajanjeOdRanijePodnetogZahtevaEnergetskihSirovina',
  ...zahtevZaOdustajanjeOdRanijePodnetogZahteva,
};
export const zahtevZaOdustajanjeOdRanijePodnetogZahtevaPrirodnihSirovina = {
  formTitle: t('forma.naslov.52'),
  formName: 'zahtevZaOdustajanjeOdRanijePodnetogZahtevaPrirodnihSirovina',
  ...zahtevZaOdustajanjeOdRanijePodnetogZahteva,
};
//#endregion

//#region 3.4.2.14
export const izvestajOMineralnimResursimaZaDobijanjePrirodnihGradjevinskihMaterijala =
  {
    formTitle: t('forma.naslov.55'),
    formName:
      'izvestajOMineralnimResursimaZaDobijanjePrirodnihGradjevinskihMaterijala',
    inputs: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala,
          ],
        },
      ],
    },
  };
export const godisnjiIzvestajGeoloskaIstrazivanja = {
  formTitle: t('forma.naslov.56'),
  formName: 'godisnjiIzvestajGeoloskaIstrazivanja',
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
        ],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.izvestajPotvrdaOTehnickojKontroliZavrsnogIzvestaja,
          INPUTS.attachments.izvestajOStrucnomNadzoru,
        ],
      },
    ],
  },
};
export const zavrsniIzvestajGeoloskaIstrazivanja = {
  formTitle: t('forma.naslov.57'),
  formName: 'zavrsniIzvestajGeoloskaIstrazivanja',
  inputs: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.naslov.podaci_o_istrazivanju'),
        fields: [
          INPUTS.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina,
        ],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.izvestajPotvrdaOTehnickojKontroliZavrsnogIzvestaja,
          INPUTS.attachments.izvestajOStrucnomNadzoru,
        ],
      },
    ],
  },
};
//#endregion

//#region 3.4.3.1
export const zahtevZaDobijanjeOdobrenjaZaEksploatacionoPolje: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.58'),
    formName: 'zahtevZaDobijanjeOdobrenjaZaEksploatacionoPolje',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina,
            INPUTS.vrstaMineralnihSirovinaIdEks,
            INPUTS.nazivLezista,
            INPUTS.opstinaId,
            INPUTS.nazivStudijeIzvodljivosti,
            INPUTS.godinaIzradeStudije,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_studiju'),
          String(PreduzeceTypeEnum.URADILO_STUDIJU)
        ),
        {
          sectionTitle: t(
            'forma.sekcija.naslov.koordinate_eksploatacionog_polja'
          ),
          fields: [INPUTS.koordinate],
        },
        {
          sectionTitle: t('forma.sekcija.naslov.koordinate_zastitnog_prostora'),
          fields: [INPUTS.koordinateOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.situacionaKarta,
            INPUTS.attachments
              .aktOrganaNadleznogZaPosloveZastiteZivotneSredineOObimuISadrzajuStudijeOProceniUticajaEksploatacijeNaZivotnuSredinu,
            INPUTS.attachments.aktOrganaNadleznogZaPosloveUrbanizma,
            INPUTS.attachments
              .aktOrganaNadleznogZaPosloveVodoprivredeKojimSeOdredjujuUsloviZaVrsenjeEksploatacije,
            INPUTS.attachments
              .aktOrganaUstanoveNadlezneZaZastituKulturnogNasledjaKojimSeOdredjujuUsloviZaVrsenjeEksploatacije,
            INPUTS.attachments
              .studijaIzvodljivostiEksploatacijeLezistaMineralnihSirovina,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.2
export const zahtevZaDobijanjeOdobrenjaZaProsirenjeGraniceEksploatacionogPolja: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.59'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaProsirenjeGraniceEksploatacionogPolja',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacionoPolje,
            INPUTS.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina,
            INPUTS.nazivLezista,
            INPUTS.opstinaId,
            INPUTS.godinaIzradeStudije,
            INPUTS.vrstaMineralnihSirovinaIdEks,
            INPUTS.nazivStudijeIzvodljivosti,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_studiju'),
          String(PreduzeceTypeEnum.URADILO_STUDIJU)
        ),
        {
          sectionTitle: t(
            'forma.sekcija.naslov.koordinate_eksploatacionog_polja'
          ),
          fields: [INPUTS.koordinate],
        },
        {
          sectionTitle: t('forma.sekcija.naslov.koordinate_zastitnog_prostora'),
          fields: [INPUTS.koordinateOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.situacionaKarta,
            INPUTS.attachments.aktOrganaNadleznogZaPosloveUrbanizma,
            INPUTS.attachments
              .studijaIzvodljivostiEksploatacijeLezistaMineralnihSirovina,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };

//#endregion

//#region 3.4.3.3
export const zahtevZaDobijanjeOdobrenjaZaSmanjenjeGraniceEksploatacionogPolja: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.60'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaSmanjenjeGraniceEksploatacionogPolja',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacionoPolje,
            INPUTS.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina,
            INPUTS.nazivLezista,
            INPUTS.opstinaId,
          ],
        },
        {
          sectionTitle: t(
            'forma.sekcija.naslov.koordinate_eksploatacionog_polja'
          ),
          fields: [INPUTS.koordinate],
        },
        {
          sectionTitle: t('forma.sekcija.naslov.koordinate_zastitnog_prostora'),
          fields: [INPUTS.koordinateOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.situacionaKarta,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.4
export const zahtevZaPrenosOdobrenjaZaEksploatacionoPolje: DynamicFormSchema = {
  formTitle: t('forma.naslov.61'),
  formName: 'zahtevZaPrenosOdobrenjaZaEksploatacionoPolje',
  inputs: {
    sections: [
      {
        sectionTitle: t(
          'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
        ),
        fields: [
          INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacionoPolje,
          INPUTS.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina,
          INPUTS.nazivLezista,
          INPUTS.opstinaId,
          INPUTS.vrstaMineralnihSirovinaIdEks,
          INPUTS.brojEksploatacionogPolja,
        ],
      },
      businessEntityDetailsSection(
        t('forma.sekcija.naslov.pravnoLicePrenosOdobrenja'),
        String(PreduzeceTypeEnum.PRENOS_ODOBRENJA)
      ),
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.dokazRepublickaTaksa,
          INPUTS.attachments.izvodIzRegistra,
          INPUTS.attachments.izvestajNosiocaOdobrenjaOIzvresenjuObaveza,
          INPUTS.attachments
            .poslednjiIzvestajOInspekcijskomNadzoruIzvresnomOdStraneRudarskogInspektoraIInspektoraBezbezdnostiNaRadu,
          INPUTS.attachments
            .pisanaIzjavaBuducegNosiocaOdobrenjaOPrihvatanjuUslovaPrenosaOdobrenjaSaSvimPravimaIObavezamaKojeProisticuIzNjega,
          INPUTS.attachments.situacionaKarta,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};
//#endregion

//#region 3.4.3.5
export const zahtevZaDobijanjeOdobrenjaZaIzgradnjuRudarskihObjekataIZaIzvodjenjeRudarskihRadova: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.62'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaIzgradnjuRudarskihObjekataIZaIzvodjenjeRudarskihRadova',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacionoPolje,
            INPUTS.nazivLezista,
            INPUTS.opstinaId,
            INPUTS.glavniProjektantImePrezimeEksploatacijaPolja,
            INPUTS.odgovorniProjektantImePrezimeEksploatacijaPolja,
            INPUTS.nazivRudarskogProjekta,
            INPUTS.godisnjiKapacitet,
            INPUTS.vrstaMineralnihSirovinaIdEks,
            INPUTS.brojEksploatacionogPolja,
            INPUTS.godinaIzrade,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.rudarskiProjekat,
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.saglasnostNosioca,
            INPUTS.attachments
              .aktOrganaNadleznogZaPosloveVodoprivredeKojimSeDajeSaglasnot,
            INPUTS.attachments
              .aktOrganaNadleznogZaPosloveZastiteZivotneSredineOObimuISadrzajuStudijeOProceniUticajaEksploatacijeNaZivotnuSredinu,
            INPUTS.attachments.aktOrganaNadleznogZaPosloveUrbanizma,
            INPUTS.attachments
              .aktOrganaUstanoveNadlezneZaZastituKulturnogNasledjaKojimSeOdredjujuUsloviZaVrsenjeEksploatacije,
            INPUTS.attachments.dokazOPravuSvojineSaMestom,
            INPUTS.attachments.garancijaBanke,
            INPUTS.attachments.saglasnostNadlezneUstanove,
            INPUTS.attachments.saglasnostNaTehnickuDokumentaciju,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.7
export const zahtevZaDobijanjeOdobrenjaZaUpotrebuRudarskihObjekataUpotrebnaDozvola: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.63'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaUpotrebuRudarskihObjekataUpotrebnaDozvola',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaIzgradnjuRudarskihObjekataZaIzvodjenjeRudarskihRadova,
            INPUTS.nazivRudarskogProjekta,
            INPUTS.nazivRudarskogProjektaPoKomeJeIzgradjenRudarskiObjekat,
          ],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.rudarskiProjekat,
            INPUTS.attachments.izvestajOIzvrsenomTehnickomPregleduKomisija,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.6
export const zahtevZaDobijanjeOdobrenjaZaPustanjeUProbniRadRudarskihObjekata: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.64'),
    formName: 'zahtevZaDobijanjeOdobrenjaZaPustanjeUProbniRadRudarskihObjekata',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaIzgradnjuRudarskihObjekataZaIzvodjenjeRudarskihRadova,
            INPUTS.nazivRudarskogProjekta,
            INPUTS.nazivRudarskogProjektaPoKomeJeIzgradjenRudarskiObjekat,
            INPUTS.planiraniPocetakTrajanjeProbnogRada,
            INPUTS.planiraniKrajTrajanjeProbnogRada,
          ],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.rudarskiProjekat,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.8
export const zahtevZaPrenosOdobrenjaZaIzvodjenjeRudarskihRadovaIZaIzgradnjuRudarskihObjekata: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.66'),
    formName:
      'zahtevZaPrenosOdobrenjaZaIzvodjenjeRudarskihRadovaIZaIzgradnjuRudarskihObjekata',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaIzgradnjuRudarskihObjekataZaIzvodjenjeRudarskihRadova,
            INPUTS.nazivLezista,
            INPUTS.opstinaId,
            INPUTS.vrstaMineralnihSirovinaIdEks,
            INPUTS.brojEksploatacionogPolja,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.pravnoLicePrenosOdobrenja'),
          String(PreduzeceTypeEnum.PRENOS_ODOBRENJA)
        ),
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.dokazOPravuSvojineSaMestom,
            INPUTS.attachments.garancijaBanke,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.9
export const zahtevZaDobijanjeOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.67'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina,
            INPUTS.nazivLezista,
            INPUTS.opstinaId,
            INPUTS.odgovorniProjektantImePrezimeEksploatacijaPolja,
            INPUTS.nazivRudarskogProjekta,
            INPUTS.vrstaMineralnihSirovinaIdEks,
            INPUTS.godinaIzrade,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        {
          sectionTitle: t(
            'forma.sekcija.naslov.koordinate_eksploatacionog_polja'
          ),
          fields: [INPUTS.koordinate],
        },
        {
          sectionTitle: t('forma.sekcija.naslov.koordinate_zastitnog_prostora'),
          fields: [INPUTS.koordinateOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.situacionaKarta,
            INPUTS.attachments.rudarskiProjekat,
            INPUTS.attachments.saglasnostNosioca,
            INPUTS.attachments
              .aktOrganaNadleznogZaPosloveVodoprivredeKojimSeOdredjujuUsloviZaVrsenjeEksploatacije,
            INPUTS.attachments
              .aktOrganaNadleznogZaPosloveZastiteZivotneSredineOObimuISadrzajuStudijeOProceniUticajaEksploatacijeNaZivotnuSredinu,
            INPUTS.attachments.aktOrganaNadleznogZaPosloveUrbanizma,
            INPUTS.attachments
              .aktOrganaUstanoveNadlezneZaZastituKulturnogNasledjaKojimSeOdredjujuUsloviZaVrsenjeEksploatacije,
            {
              ...INPUTS.attachments.dokazOPravuSvojineSaMestom,
              tooltip: t('forma.sekcija.prilog.dokaz_o_pravu_svojine_3439'),
            },
            INPUTS.attachments.garancijaBanke,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.11
export const zahtevZaDobijanjeOdobrenjaZaProsirenjeGraniceEksploatacionogPoljaIzOdobrenjaZaEksploataciju: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.70'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaProsirenjeGraniceEksploatacionogPoljaIzOdobrenjaZaEksploataciju',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala,
            INPUTS.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina,
            INPUTS.nazivLezista,
            INPUTS.opstinaId,
            INPUTS.nazivStudijeIzvodljivosti,
            INPUTS.vrstaMineralnihSirovinaIdEks,
            INPUTS.godinaIzradeStudije,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_studiju'),
          String(PreduzeceTypeEnum.URADILO_STUDIJU)
        ),
        {
          sectionTitle: t(
            'forma.sekcija.naslov.koordinate_eksploatacionog_polja'
          ),
          fields: [INPUTS.koordinate],
        },
        {
          sectionTitle: t('forma.sekcija.naslov.koordinate_zastitnog_prostora'),
          fields: [INPUTS.koordinateOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.situacionaKarta,
            INPUTS.attachments.rudarskiProjekat,
            INPUTS.attachments.tehnickaKontrolaRudarskogProjekta,
            INPUTS.attachments.saglasnostNosioca,
            INPUTS.attachments.potvrdaOResursimaIRezervamaMineralnihSirovina,
            INPUTS.attachments
              .aktOrganaNadleznogZaPosloveZastiteZivotneSredineKojimSeDajeSaglasnostNaStudijuOProceniUticajaEksploatacijeNaZivotnuSredinu,
            INPUTS.attachments.aktOrganaNadleznogZaPosloveUrbanizma,
            INPUTS.attachments
              .aktOrganaNadleznogZaPosloveVodoprivredeKojimSeOdredjujuUsloviZaVrsenjeEksploatacije,
            INPUTS.attachments
              .aktOrganaUstanoveNadlezneZaZastituKulturnogNasledjaKojimSeOdredjujuUsloviZaVrsenjeEksploatacije,
            {
              ...INPUTS.attachments.dokazOPravuSvojineSaMestom,
              tooltip: t('forma.sekcija.prilog.dokaz_o_pravu_svojine_3439'),
            },
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.garancijaBanke,
            INPUTS.attachments.saglasnostNadlezneUstanove,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };

//#endregion

//#region 3.4.3.12
export const zahtevZaDobijanjeOdobrenjaZaSmanjenjeGraniceEksploatacionogPoljaIzOdobrenjaZaEksploataciju: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.71'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaSmanjenjeGraniceEksploatacionogPoljaIzOdobrenjaZaEksploataciju',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala,
            INPUTS.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina,
            INPUTS.nazivLezista,
            INPUTS.opstinaId,
          ],
        },
        {
          sectionTitle: t(
            'forma.sekcija.naslov.koordinate_eksploatacionog_polja'
          ),
          fields: [INPUTS.koordinate],
        },
        {
          sectionTitle: t('forma.sekcija.naslov.koordinate_zastitnog_prostora'),
          fields: [INPUTS.koordinateOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.situacionaKarta,
            INPUTS.attachments.izvodIzRegistra,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };

//#endregion

//#region 3.4.3.13
export const zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaGradjevinskeMaterijale: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.68'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaGradjevinskeMaterijale',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala,
            INPUTS.nazivRudarskogProjekta,
            INPUTS.odgovorniProjektantImePrezimeEksploatacijaPolja,
            INPUTS.nazivLezista,
            INPUTS.opstinaId,
            INPUTS.godinaIzradeStudije,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.situacionaKarta,
            INPUTS.attachments.rudarskiProjekat,
            {
              ...INPUTS.attachments.dokazOPravuSvojineSaMestom,
              tooltip: t('forma.sekcija.prilog.dokaz_o_pravu_svojine_34313'),
            },
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };

//#endregion

//#region 3.4.3.14
export const zahtevZaPrenosOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.69'),
    formName:
      'zahtevZaPrenosOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala,
            INPUTS.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina,
            INPUTS.nazivLezista,
            INPUTS.opstinaId,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.pravnoLicePrenosOdobrenja'),
          String(PreduzeceTypeEnum.PRENOS_ODOBRENJA)
        ),
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            {
              ...INPUTS.attachments.dokazOPravuSvojineSaMestom,
              tooltip: t('forma.sekcija.prilog.dokaz_o_pravu_svojine_34313'),
            },
            INPUTS.attachments.izvestajNosiocaOdobrenjaOIzvresenjuObaveza,
            INPUTS.attachments
              .poslednjiIzvestajOInspekcijskomNadzoruIzvresnomOdStraneRudarskogInspektoraIInspektoraBezbezdnostiNaRadu,
            INPUTS.attachments
              .pisanaIzjavaBuducegNosiocaOdobrenjaOPrihvatanjuUslovaPrenosaOdobrenjaSaSvimPravimaIObavezamaKojeProisticuIzNjega,
            INPUTS.attachments.garancijaBanke,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.10
export const zahtevZaDobijanjeOdobrenjaZaEksploatacijuPrirodnihGradjevinskihMaterijala: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.72'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaEksploatacijuPrirodnihGradjevinskihMaterijala',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborIzvestajaOMineralnimResursimaZaDobijanjePrirodnihGradjevinskihMaterijala,
            INPUTS.nazivLezista,
            INPUTS.nazivRudarskogProjekta,
            INPUTS.vrstaMineralnihSirovinaIdEks,
            INPUTS.opstinaId,
            INPUTS.odgovorniProjektantImePrezimeEksploatacijaPolja,
            INPUTS.godinaIzrade,
            INPUTS.godisnjiKapacitet,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        {
          sectionTitle: t(
            'forma.sekcija.naslov.koordinate_eksploatacionog_polja'
          ),
          fields: [INPUTS.koordinate],
        },
        {
          sectionTitle: t('forma.sekcija.naslov.koordinate_zastitnog_prostora'),
          fields: [INPUTS.koordinateOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.situacionaKarta,
            INPUTS.attachments.rudarskiProjekat,
            INPUTS.attachments.dokazOPravuSvojineSaMestom,
            INPUTS.attachments
              .izvestajOMineralnimResursimaPrirodnihGradjevinskihMaterijala,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.16
export const zahtevZaPrestanakVazenjaOdobrenjaZaEksploatacionoPoljeEksploatacijuNaZahtevNosioca: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.73'),
    formName:
      'zahtevZaPrestanakVazenjaOdobrenjaZaEksploatacionoPoljeEksploatacijuNaZahtevNosioca',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacionoPoljeIliZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijalaIliZaEksploatacijuPrirodnihGradjevinskihMaterijala,
            INPUTS.vrstaMineralnihSirovinaIdEks,
            INPUTS.nazivLezista,
            INPUTS.opstinaId,
          ],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.17
export const zahtevZaPrestanakVazenjaOdobrenjaZaEksploatacionoPoljeEksploatacijuPoOsnovuTrajneObustaveRadova: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.74'),
    formName:
      'zahtevZaPrestanakVazenjaOdobrenjaZaEksploatacionoPoljeEksploatacijuPoOsnovuTrajneObustaveRadova',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacionoPoljeIliZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijalaIliZaEksploatacijuPrirodnihGradjevinskihMaterijala,
            INPUTS.nazivLezista,
            INPUTS.vrstaMineralnihSirovinaIdEks,
            INPUTS.opstinaId,
          ],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.18
export const zahtevZaOdustajanjeOdZahteva: DynamicFormSchema = {
  formTitle: t('forma.naslov.75'),
  formName: 'zahtevZaOdustajanjeOdZahteva',
  inputs: {
    sections: [
      {
        sectionTitle: t(
          'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
        ),
        fields: [
          INPUTS.izborZahtevaMineralneSirovine,
          INPUTS.tipZahteva,
          INPUTS.datumPodnosenjaZahteva,
        ],
      },
      {
        sectionTitle: '',
        fields: [INPUTS.obrazlozenje],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.dokazRepublickaTaksa,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};
//#endregion

//#region 3.4.3.15
export const zahtevZaDobijanjeOdobrenjaZaIzvodjenjeRudarskihRadovaNaIstrazivanjuMineralnihSirovina: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.76'),
    formName:
      'zahtevZaDobijanjeOdobrenjaZaIzvodjenjeRudarskihRadovaNaIstrazivanjuMineralnihSirovina',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahteva,
            INPUTS.nazivRudarskogProjekta,
            INPUTS.nazivProjektaeoloskihIstrazivanjaUsaglasenRudarskiProjekat,
            INPUTS.vrstaMineralnihSirovinaIdEks,
            INPUTS.nazivLezista,
            INPUTS.katarstarskiBrojIstraznogProstora,
            INPUTS.opstinaId,
            INPUTS.odgovorniProjektantImePrezimeEksploatacijaPolja,
            INPUTS.godinaIzrade,
          ],
        },
        businessEntityDetailsSection(
          t('forma.sekcija.naslov.podaci_o_preduzecu_izvodjac'),
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        ),
        {
          sectionTitle: t('forma.sekcija.naslov.koordinate_zastitnog_prostora'),
          fields: [INPUTS.koordinateOpcioni],
        },
        {
          sectionTitle: '',
          fields: [INPUTS.obrazlozenje],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.dokazRepublickaTaksa,
            INPUTS.attachments.dokazOPravuSvojineSaMestom,
            INPUTS.attachments.rudarskiProjekat,
            INPUTS.attachments.saglasnostNosioca,
            INPUTS.attachments.drugaAktaPredvidjena,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };
//#endregion

//#region 3.4.3.19
export const dostavljanjeTehnickogRudarskogProjektaSaTehnickomKontrolom: DynamicFormSchema =
  {
    formTitle: t('forma.naslov.77'),
    formName: 'dostavljanjeTehnickogRudarskogProjektaSaTehnickomKontrolom',
    inputs: {
      sections: [
        {
          sectionTitle: t(
            'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
          ),
          fields: [
            INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala,
          ],
        },
      ],
    },
    attachments: {
      sections: [
        {
          sectionTitle: t('forma.sekcija.prilog.dokumenta'),
          fields: [
            INPUTS.attachments.rudarskiProjekat,
            INPUTS.attachments.tehnickaKontrola,
            INPUTS.attachments.dokazOPravuSvojineSaMestom,
            INPUTS.attachments.ostalo,
          ],
        },
      ],
    },
  };

export const dostavljanjeUproscenogRudarskogProjekta: DynamicFormSchema = {
  formTitle: t('forma.naslov.78'),
  formName: 'dostavljanjeUproscenogRudarskogProjekta',
  inputs: {
    sections: [
      {
        sectionTitle: t(
          'forma.sekcija.naslov.podaci_o_eksploataciji_mineralnih_sirovina'
        ),
        fields: [
          INPUTS.izborResenjaOOdobravanjuZahtevaZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala,
        ],
      },
    ],
  },
  attachments: {
    sections: [
      {
        sectionTitle: t('forma.sekcija.prilog.dokumenta'),
        fields: [
          INPUTS.attachments.rudarskiProjekat,
          INPUTS.attachments.dokazOPravuSvojineSaMestom,
          INPUTS.attachments.ostalo,
        ],
      },
    ],
  },
};
//#endregion
