import { Button, Col, Flex, Form, InputNumber, notification, Row } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'antd/es/form/Form';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import TextArea from 'antd/es/input/TextArea';
import { observer } from 'mobx-react-lite';

import style from './RequestDecisionEditForm.module.scss';
import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service';
import { ModalName, modalStore } from '@/modules/modal/modal.store';
import { requestsStore } from '@/modules/requests/requests.store';

interface IRequestDecisionEditForm {
  setResenjeId: Dispatch<SetStateAction<number | null>>;
}

export const RequestDecisionEditForm = observer(
  ({ setResenjeId }: IRequestDecisionEditForm) => {
    const [form] = useForm();
    const { zahtevId } = useParams();

    const handleSubmit = () => {
      const payload = {
        ...form.getFieldsValue(),
        odluka: modalStore.decision,
        zahtevId: zahtevId,
      };
      submittedRequestsService
        .postZahteviSacuvajAtributeOdluke(payload)
        .then((res) => {
          notification.success({ message: t('sacuvaj_atribute_success') });
          requestsStore?.setCanGenerateAct(true);
          setResenjeId(res.id);
        })
        .catch((err) => {
          notification.error(err);
        });
    };

    return (
      <Form
        name='requestDecisionEdit'
        className={style.form}
        form={form}
        onFinish={handleSubmit}
        layout='vertical'
      >
        <Row>
          <Col span={12}>
            <Form.Item
              colon={false}
              label={t('rok_za_dopunu_zahteva')}
              name='rokZaDopunu'
              rules={[
                {
                  required: true,
                  message: t('forma.sekcija.polje.obavezno_polje'),
                },
              ]}
            >
              <InputNumber className='w-100' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              colon={false}
              label={t('obrazlozenje')}
              name='obrazlozenje'
              rules={[
                {
                  required: true,
                  message: t('forma.sekcija.polje.obavezno_polje'),
                },
              ]}
            >
              <TextArea autoSize={{ minRows: 7, maxRows: 30 }} />
            </Form.Item>
          </Col>
        </Row>
        <Flex justify='end' gap={15}>
          <Button
            onClick={() => modalStore.openModal(ModalName.RESQUEST_DECISION)}
            type='primary'
          >
            {t('odustanite')}
          </Button>
          <Button type='primary' htmlType='submit' form='requestDecisionEdit'>
            {t('sacuvajte')}
          </Button>
        </Flex>
      </Form>
    );
  }
);
