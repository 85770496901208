/* eslint-disable no-unused-vars */
import { action, makeAutoObservable, observable } from 'mobx';
import { OdlukeEnum } from '../requests/requests.types';

export enum ModalName {
  SUBMITTED_REQUEST = 'submitted_request',
  USER = 'user',
  RESQUEST_DECISION = 'request_decision',
  ADD_BUSINESS_ENTITY = 'add_business_entity',
  OTHER = 'other',
  CREATED_REQUEST = 'created_request',
  START_PROCESSING_ACT = 'start_processing_act',
}
class ModalStore {
  modalsVisible: ModalName[] = [];
  decision: OdlukeEnum | null = null;

  constructor() {
    makeAutoObservable(this, {
      modalsVisible: observable,
      decision: observable,
      setDecision: action,
      openModal: action,
      closeModal: action,
    });
  }

  get isVisible() {
    return (modalName: ModalName) => this.modalsVisible.includes(modalName);
  }

  setDecision = (decision: number | null) => {
    this.decision = decision;
  };

  resetState() {
    this.modalsVisible = [];
  }

  openModal = (modalName: ModalName) => {
    this.modalsVisible.push(modalName);
  };

  closeModal = (modalName: ModalName) => {
    this.modalsVisible = this.modalsVisible.filter(
      (name) => name !== modalName
    );
  };
}

export const modalStore = new ModalStore();
