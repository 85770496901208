import {
  IInitialPrivredniSubjektFilters,
  IInitialPrivredniSubjektOstaloFilters,
} from './settings.types';

export const INITIAL_PRIVREDNI_SUBJEKT_OSTALO_FILTER_PARAMS: IInitialPrivredniSubjektOstaloFilters =
  {
    maticniBroj: null,
    pib: null,
    status: null,
  };

export const INITIAL_PRIVREDNI_SUBJEKT_FILTER_PARAMS: IInitialPrivredniSubjektFilters =
  {
    maticniBroj: null,
    pib: null,
    status: null,
    manuallyDisabled: null,
  };

export const EMPTY_STATE_PLACEHOLDER = '/';
