import { useNavigate, useParams } from 'react-router-dom';

import { PageWrapper } from '@/components/PageWrapper/PageWrapper.tsx';
import { getNewRequestFormDetails } from '@/modules/requests/requests.constants.tsx';
import { requestsService } from '@/modules/requests/requests.service.ts';
import { IMappedRequestDetailsResponseForForm } from '@/modules/requests/requests.types.ts';
import { mapRequestDetailsResponseForForm } from '@/utils/formMapper.ts';
import { notification } from 'antd';
import { ArgsProps } from 'antd/es/notification/interface';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import DynamicForm from '../../../components/Forms/FormBuilder/DynamicForm.tsx';
import { ROUTES } from '@/routes/routes.tsx';
import { authStore } from '@/modules/auth/auth.store.ts';
import { requestsStore } from '@/modules/requests/requests.store.ts';

const DraftRequestForm = observer(() => {
  const { zahtevId, tipZahtevaId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [zahtevNacrt, setZahtevNacrt] =
    useState<IMappedRequestDetailsResponseForForm | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStore.currentUser && authStore.isUserMRE) {
      navigate(ROUTES.SUBMITTED_REQUESTS);
    }
  }, [authStore.selectedBusinessEntity?.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestTypeId = Number(tipZahtevaId);
        const requestZahtevId = Number(zahtevId);

        await requestsService.loadConfig(requestTypeId);
        const requestDetail = await requestsService.getRequestDetails(
          Number(requestZahtevId)
        );
        const mappedData = mapRequestDetailsResponseForForm(requestDetail);
        setZahtevNacrt(mappedData);
        if (requestDetail?.dokumentaAtributi) {
          requestsStore.setDocumentUploadRes(mappedData?.dokumentaAtributi);
        }
      } catch (error: unknown) {
        notification.error(error as ArgsProps);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      requestsStore.setDocumentUploadRes(undefined);
      console.log('Component is being destroyed');
    };
  }, []);

  return tipZahtevaId && zahtevId && zahtevNacrt ? (
    <PageWrapper greyFrame isLoading={loading}>
      <DynamicForm
        schema={getNewRequestFormDetails(+tipZahtevaId)}
        zahtevTipId={+tipZahtevaId}
        zahtevIdProp={+zahtevId}
        zahtevNacrtProp={zahtevNacrt}
      />
    </PageWrapper>
  ) : null;
});

export default DraftRequestForm;
