import { Modal, notification } from 'antd';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service';
import { ModalName, modalStore } from '@/modules/modal/modal.store';

interface IStartProcessingActModal {
  handleSuccess: () => void;
}

export const StartProcessingActModal = observer(
  ({ handleSuccess }: IStartProcessingActModal) => {
    const { zahtevId } = useParams();

    const isOpen = modalStore.isVisible(ModalName.START_PROCESSING_ACT);

    const handleCancel = () => {
      modalStore.closeModal(ModalName.START_PROCESSING_ACT);
    };

    const handlePokreniProcesAkta = () => {
      submittedRequestsService
        .postZahteviPokreniProcesAkta({
          zahtevId: +zahtevId!,
          odluka: modalStore.decision,
        })
        .then(() => {
          handleCancel();
          notification.success({ message: t('pokrenite_akt_success') });
          handleSuccess();
        });
    };

    return (
      <Modal
        open={isOpen}
        cancelButtonProps={{ type: 'primary', danger: true }}
        cancelText={t('odustanite')}
        okText={t('potvrdite')}
        okButtonProps={{
          htmlType: 'submit',
        }}
        onCancel={handleCancel}
        onOk={() => handlePokreniProcesAkta()}
        title={''}
      >
        <p>{t('potvrda_pocetka_procesa_podnosenja_akta')}</p>
      </Modal>
    );
  }
);
