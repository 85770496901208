/* eslint-disable max-len */
import { AxiosResponse } from 'axios';
import {
  APRDetailsPayload,
  APRDetailsResponse,
  IInitialPrivredniSubjektFilters,
  IInitialPrivredniSubjektOstaloFilters,
  IPrivredniSubjektDetaljiResponse,
  IPrivredniSubjektiOstaloDetaljiResponse,
  IPrivredniSubjektOstaloResponse,
  IPrivredniSubjektResponse,
  IZaposleni,
  KorisnikIzmeniPayload,
  KorisnikListItem,
  OdbijOdobriPayload,
  PrivredniSubjektiAktivirajDeaktivirajPayload,
  PrivredniSubjektiOstaloAktivirajDeaktivirajPayload,
  PrivredniSubjektiOstaloPayload,
  PrivredniSubjektiPayload,
  ZaposleniDetailsPayload,
} from './settings.types';

import { RoleConfig } from '@/modules/auth/auth.types.ts';
import axios, { ApiResponse } from '../../utils/axios';

type SettingsRepoType = {
  getPrivredniSubjektDetalji: () => Promise<
    AxiosResponse<ApiResponse<IPrivredniSubjektDetaljiResponse>>
  >;

  postPrivredniSubjektKreirajMailNotifikacije: (
    email: string
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;

  putPrivredniSubjektIzmeniMailNotifikacije: (
    aktivno: boolean
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;

  getKorisnikSviZaposleniPrivrednogSubjekta: () => Promise<
    AxiosResponse<ApiResponse<KorisnikListItem[]>>
  >;

  postKorisnikDodajNovogZaposlenog: (
    payload: IZaposleni
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;

  putKorisnikIzmeniKorisnika: (
    payload: IZaposleni,
    params: KorisnikIzmeniPayload
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;

  getZaposleniDetails: (
    payload: ZaposleniDetailsPayload
  ) => Promise<AxiosResponse<ApiResponse<IZaposleni>>>;
  getRoleConfig: () => Promise<AxiosResponse<ApiResponse<RoleConfig>>>;

  getPrivredniSubjektAPRDetails: (
    payload: APRDetailsPayload
  ) => Promise<AxiosResponse<ApiResponse<APRDetailsResponse>>>;

  postPrivredniSubjektiOstalo: (
    payload: IInitialPrivredniSubjektOstaloFilters
  ) => Promise<AxiosResponse<ApiResponse<IPrivredniSubjektOstaloResponse[]>>>;

  postPrivredniSubjekti: (
    payload: IInitialPrivredniSubjektFilters
  ) => Promise<AxiosResponse<ApiResponse<IPrivredniSubjektResponse[]>>>;

  getPrivredniSubjektiOstaloDokumentPreuzmi: (
    payload: PrivredniSubjektiOstaloPayload
  ) => Promise<AxiosResponse<ApiResponse<unknown>>>;

  getPrivredniSubjektiOstaloAktivirajDeaktiviraj: (
    payload: PrivredniSubjektiOstaloAktivirajDeaktivirajPayload
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;

  getPrivredniSubjektiAktivirajDeaktiviraj: (
    payload: PrivredniSubjektiAktivirajDeaktivirajPayload
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;

  postPrivredniSubjektiOstaloOdobri: (
    payload: OdbijOdobriPayload
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;

  postPrivredniSubjektiOstaloOdbij: (
    payload: OdbijOdobriPayload
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;

  postPrivredniSubjektiOstaloDetalji: (
    payload: PrivredniSubjektiOstaloPayload
  ) => Promise<
    AxiosResponse<ApiResponse<IPrivredniSubjektiOstaloDetaljiResponse>>
  >;

  postPrivredniSubjektiDetalji: (
    payload: PrivredniSubjektiPayload
  ) => Promise<AxiosResponse<ApiResponse<IPrivredniSubjektDetaljiResponse>>>;
};

//PRIVREDNI SUBJEKT DETALJI

const getPrivredniSubjektDetalji = () => {
  return axios.get<ApiResponse<IPrivredniSubjektDetaljiResponse>>(
    '/privredni-subjekt/detalji'
  );
};

const postPrivredniSubjektIzmeniMailNotifikacije = (email: string) => {
  return axios.post<ApiResponse<boolean>>(
    '/privredni-subjekt/izmeni-mail-notifikacije',
    { email }
  );
};

const putPrivredniSubjektUkljuciMailNotifikacije = (
  iskljuciEmailNotifikacije: boolean
) => {
  return axios.put<ApiResponse<boolean>>(
    '/privredni-subjekt/ukljuci-mail-notifikacije',
    { iskljuciEmailNotifikacije }
  );
};

//KORISNICI

const getKorisnikSviZaposleniPrivrednogSubjekta = () => {
  return axios.get<ApiResponse<KorisnikListItem[]>>(
    '/korisnik/svi-zaposleni-privrednog-subjekta'
  );
};

const postKorisnikDodajNovogZaposlenog = (payload: IZaposleni) => {
  return axios.post<ApiResponse<boolean>>(
    '/korisnik/dodaj-novog-zaposlenog',
    payload
  );
};

const putKorisnikIzmeniKorisnika = (
  payload: IZaposleni,
  params: KorisnikIzmeniPayload
) => {
  return axios.put<ApiResponse<boolean>>(
    `/korisnik/izmeni-zaposlenog/${params.guid}`,
    payload
  );
};

const getZaposleniDetails = (payload: ZaposleniDetailsPayload) => {
  return axios.get(`/korisnik/zaposleni/${payload.id}`);
};

const getRoleConfig = () => {
  return axios.get('/config/role-config');
};

const getPrivredniSubjektAPRDetails = (payload: APRDetailsPayload) => {
  return axios.post('/privredni-subjekt/apr-detalji', payload);
};

//OSTALO
const postPrivredniSubjektiOstalo = (
  payload: IInitialPrivredniSubjektOstaloFilters
) => {
  return axios.post('/privredni-subjekt/privredni-subjekti-ostalo', payload);
};

const postPrivredniSubjekti = (payload: IInitialPrivredniSubjektFilters) => {
  return axios.post('/privredni-subjekt/registar-privrednih-subjekta', payload);
};

const getPrivredniSubjektiOstaloDokumentPreuzmi = (
  payload: PrivredniSubjektiOstaloPayload
) => {
  return axios.get(
    '/privredni-subjekt/privredni-subjekti-ostalo-dokument-preuzmi',
    { params: payload, responseType: 'arraybuffer' }
  );
};

const getPrivredniSubjektiOstaloAktivirajDeaktiviraj = (
  payload: PrivredniSubjektiOstaloAktivirajDeaktivirajPayload
) => {
  return axios.get(
    '/privredni-subjekt/privredni-subjekti-ostalo-aktiviraj-deaktiviraj',
    { params: payload }
  );
};

const getPrivredniSubjektiAktivirajDeaktiviraj = (
  payload: PrivredniSubjektiAktivirajDeaktivirajPayload
) => {
  return axios.get(
    '/privredni-subjekt/registar-privrednih-subjekta-aktiviraj-deaktiviraj',
    { params: payload }
  );
};

const postPrivredniSubjektiOstaloOdobri = (payload: OdbijOdobriPayload) => {
  return axios.post(
    '/privredni-subjekt/privredni-subjekti-ostalo-odobri',
    payload
  );
};

const postPrivredniSubjektiOstaloOdbij = (payload: OdbijOdobriPayload) => {
  return axios.post(
    '/privredni-subjekt/privredni-subjekti-ostalo-odbij',
    payload
  );
};

const postPrivredniSubjektiOstaloDetalji = (
  payload: PrivredniSubjektiOstaloPayload
) => {
  return axios.get('/privredni-subjekt/privredni-subjekti-ostalo-detalji', {
    params: payload,
  });
};

const postPrivredniSubjektiDetalji = (payload: PrivredniSubjektiPayload) => {
  return axios.get(
    `/privredni-subjekt/registar-privrednih-subjekta/${payload.privredniSubjektId}`
  );
};

export const settingsRepo: SettingsRepoType = {
  getPrivredniSubjektDetalji,
  postPrivredniSubjektKreirajMailNotifikacije:
    postPrivredniSubjektIzmeniMailNotifikacije,
  putPrivredniSubjektIzmeniMailNotifikacije:
    putPrivredniSubjektUkljuciMailNotifikacije,
  getKorisnikSviZaposleniPrivrednogSubjekta,
  postKorisnikDodajNovogZaposlenog,
  putKorisnikIzmeniKorisnika,
  getZaposleniDetails,
  getRoleConfig,
  getPrivredniSubjektAPRDetails,
  postPrivredniSubjektiOstalo,
  getPrivredniSubjektiOstaloDokumentPreuzmi,
  getPrivredniSubjektiOstaloAktivirajDeaktiviraj,
  postPrivredniSubjektiOstaloOdobri,
  postPrivredniSubjektiOstaloOdbij,
  postPrivredniSubjektiOstaloDetalji,
  postPrivredniSubjekti,
  postPrivredniSubjektiDetalji,
  getPrivredniSubjektiAktivirajDeaktiviraj,
};
