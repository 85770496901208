import { findRequestDocumentKey } from '@/modules/requests/requests.documents';
import { requestsStore } from '@/modules/requests/requests.store';
import { DokumentaAtributiProps } from '@/modules/requests/requests.types.ts';
import { notification } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import { t } from 'i18next';

const CHUNK_SIZE = 4 * 1024 * 1024; //4MB

export type FileType = {
  fileName: string;
  format: string;
  data: RcFile;
  requestDocumentId?: number;
};

interface DataForChunk {
  file: FileType;
  [key: string]: unknown;
}

export const uploadDocuments = async (
  documents: FileType[],
  requestId: number,
  serviceMethod: (payload: { data: string; file: Blob }) => Promise<unknown>,
  zahtevTipId?: number
) => {
  for (const doc of documents) {
    try {
      const data = {
        file: doc,
        zahtevId: requestId,
      };
      await uploadFileInChunks(data, serviceMethod, zahtevTipId);
    } catch (e) {
      console.error(`Error uploading file ${doc.fileName}`, e);
      notification.error(e as { message: string });
      return;
    }
  }
  if (documents.length) {
    notification.success({
      message: t('forma.sekcija.prilog.upload_success'),
    });
  }
};

export const uploadFileInChunks = async (
  data: DataForChunk,
  serviceMethod: (payload: { data: string; file: Blob }) => Promise<unknown>,
  zahtevTipId?: number
) => {
  const totalChunks = Math.ceil(data.file.data.size / CHUNK_SIZE);
  for (let i = 0; i < totalChunks; i++) {
    const start = i * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, data.file.data.size);
    const chunk = data.file.data.slice(start, end);

    await uploadChunk(chunk, i + 1, totalChunks, data, serviceMethod).then(
      (res) => {
        console.log('RES UPLOAD CHUNK ', res);
        const data = res as DokumentaAtributiProps;

        if (
          !data ||
          !data.requestDocumentId ||
          !data.documentId ||
          !zahtevTipId
        ) {
          return;
        }
        const documentKey = findRequestDocumentKey(
          zahtevTipId,
          data.requestDocumentId
        );

        if (documentKey) {
          requestsStore.setDocumentUploadRes({
            ...requestsStore.documentUploadRes,
            [documentKey]: data,
          });
        }
      }
    );
  }
};

const uploadChunk = async (
  chunk: Blob,
  chunkNumber: number,
  totalChunks: number,
  dataForChunk: DataForChunk,
  serviceMethod: (payload: { data: string; file: Blob }) => Promise<unknown>
) => {
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const { data, format, ...newObject } = dataForChunk.file;
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const { file, ...otherData } = dataForChunk;

  const chunkData = {
    chunkNumber: chunkNumber,
    totalChunks: totalChunks,
    ...newObject,
    ...otherData,
  };

  console.log('data chunk', chunkData);

  return serviceMethod({
    data: JSON.stringify(chunkData),
    file: new Blob([chunk], { type: 'application/octet-stream' }),
  });
};
