import { Col, Form, Input, notification, Row } from 'antd';
import { AxiosResponse } from 'axios';
import { DownloadOutlined } from '@ant-design/icons';
import { t } from 'i18next';

import style from './RequestDecisionReadOnly.module.scss';
import RequestSectionWrapper from '../RequestSectionWrapper/RequestSectionWrapper';
import { OdlukeEnum, ResenjeProps } from '@/modules/requests/requests.types';
import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service';
import { handleDownloadFile } from '@/utils/handleDownloadFile';
import { getLocalDateFromUTCDateString } from '@/utils/dateFormatter';
import { requestsService } from '@/modules/requests/requests.service';
import { useParams } from 'react-router-dom';

export const RequestDecisionReadOnly = ({
  requestDecisionReadOnly,
}: {
  requestDecisionReadOnly: ResenjeProps | null;
}) => {
  const id = requestDecisionReadOnly?.id;
  const { zahtevTipId } = useParams();

  const handleDownloadPrilog = (prilogId: number | null) => {
    if (prilogId !== null && zahtevTipId) {
      requestsService
        .downloadRequestsAttachment(+zahtevTipId, prilogId)
        .then((res) => {
          handleDownloadFile(res as AxiosResponse)
            .then(() => {
              notification.success({
                message: t('generisi_akt_success'),
              });
            })
            .catch((err) => {
              console.error('Error download akt ', err);
              notification.error({
                message: t('file_act_error'),
              });
            });
        })
        .catch((err) => {
          console.error('Error generisi akt');
          notification.error(err);
        });
    }
  };
  const handleDownloadAkt = (zahtevResenjeId: number | null) => {
    if (zahtevResenjeId !== null) {
      submittedRequestsService
        .getZahteviPreuzmiAkt(zahtevResenjeId)
        .then((res) => {
          handleDownloadFile(res as AxiosResponse)
            .then(() => {
              notification.success({
                message: t('generisi_akt_success'),
              });
            })
            .catch((err) => {
              console.error('Error download akt ', err);
              notification.error({
                message: t('file_act_error'),
              });
            });
        })
        .catch((err) => {
          console.error('Error generisi akt');
          notification.error(err);
        });
    }
  };
  const decisionReadOnlyFormLayout = () => {
    switch (requestDecisionReadOnly?.odluka) {
      case OdlukeEnum.ODOBRAVANJE:
        return (
          <>
            <Col span={8}>
              <Form.Item colon={false} label={t('zavodni_broj_akta')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.zavodniBrojResenja ?? ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('datum_i_vreme_urucenja_akta')}>
                <Input
                  disabled
                  value={
                    getLocalDateFromUTCDateString(
                      requestDecisionReadOnly.datumUrucenjaResenja
                    ) ?? ''
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('broj_istraznog_prostora')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.brojIstraznogProstora ?? ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('povrsina_istraznog_prostora')}>
                <Input
                  disabled
                  value={
                    requestDecisionReadOnly.povrsinaIstraznogProstora ?? ''
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('duzina_istraznog_roka')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.duzinaIstraznogRoka ?? ''}
                />
              </Form.Item>
            </Col>
          </>
        );
      case OdlukeEnum.DOPUNA:
        return (
          <>
            <Col span={8}>
              <Form.Item colon={false} label={t('rok_za_dopunu_zahteva')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.rokZaDopunu ?? ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('obrazlozenje')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.obrazlozenje ?? ''}
                />
              </Form.Item>
            </Col>
          </>
        );
      case OdlukeEnum.PREKID:
        return (
          <>
            <Col span={8}>
              <Form.Item colon={false} label={t('razlog_prekida')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.razlogPrekida ?? ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('obrazlozenje')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.obrazlozenje ?? ''}
                />
              </Form.Item>
            </Col>
          </>
        );
      case OdlukeEnum.ODBACIVANJE:
        return (
          <>
            <Col span={8}>
              <Form.Item colon={false} label={t('zavodni_broj_akta')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.zavodniBrojResenja ?? ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('datum_i_vreme_urucenja_akta')}>
                <Input
                  disabled
                  value={
                    getLocalDateFromUTCDateString(
                      requestDecisionReadOnly.datumUrucenjaResenja
                    ) ?? ''
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('obrazlozenje')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.obrazlozenje ?? ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('razlog_odbacivanja')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.razlogOdbacivanja ?? ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('clan_zakona')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.clanZakona ?? ''}
                />
              </Form.Item>
            </Col>
          </>
        );
      case OdlukeEnum.ODBIJANJE:
        return (
          <>
            <Col span={8}>
              <Form.Item colon={false} label={t('zavodni_broj_akta')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.zavodniBrojResenja ?? ''}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('datum_i_vreme_urucenja_akta')}>
                <Input
                  disabled
                  value={
                    getLocalDateFromUTCDateString(
                      requestDecisionReadOnly.datumUrucenjaResenja
                    ) ?? ''
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item colon={false} label={t('obrazlozenje')}>
                <Input
                  disabled
                  value={requestDecisionReadOnly.obrazlozenje ?? ''}
                />
              </Form.Item>
            </Col>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <RequestSectionWrapper title={t('odluka')}>
      <Form className={style.form} layout='vertical'>
        <Row gutter={40}>
          {decisionReadOnlyFormLayout()}
          <Col span={8}>
            <Form.Item colon={false} label={t('akt')}>
              <span
                className='pointer'
                onClick={() => {
                  if (id) {
                    handleDownloadAkt(id);
                  }
                }}
              >
                {requestDecisionReadOnly?.fileName} <DownloadOutlined />
              </span>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item colon={false} label={t('prilozi')}>
              {requestDecisionReadOnly?.resenjaPrilozi?.map((prilog) => {
                return (
                  <div
                    className='pointer'
                    onClick={() => handleDownloadPrilog(prilog.value)}
                  >
                    {prilog.label} <DownloadOutlined />
                  </div>
                );
              })}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </RequestSectionWrapper>
  );
};
