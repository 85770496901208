import { AxiosResponse } from 'axios';
import axios, { ApiResponse } from '../../utils/axios.ts';
import {
  DocumentAttributeResponse,
  GetRequestDetailsResponse,
  RequestsConfig,
  SaveDraftRequestResponse,
  CreateNewRequestResponse,
  PodnetiZahteviResponse,
} from '@/modules/requests/requests.types.ts';

type RequestsRepoType = {
  getGroupsAndRequests: () => Promise<
    AxiosResponse<ApiResponse<RequestsConfig>>
  >;
  getRequestDetails: (
    requestId: number
  ) => Promise<AxiosResponse<ApiResponse<GetRequestDetailsResponse>>>; //TODO Add type
  getVrsteGeoloskihIstrazivanja: (
    zahtevTipId: number,
    groupId: number
  ) => Promise<AxiosResponse<ApiResponse<{ value: number; label: string }[]>>>;
  getVrstePrivrednogSubjekta: () => Promise<
    AxiosResponse<ApiResponse<{ value: number; label: string }[]>>
  >;
  getOpstine: () => Promise<
    AxiosResponse<ApiResponse<{ value: number; label: string }[]>>
  >;
  getMesta: () => Promise<
    AxiosResponse<ApiResponse<{ value: number; label: string }[]>>
  >;
  getKatastarskaMestaOpstine: () => Promise<
    AxiosResponse<ApiResponse<{ value: number; label: string }[]>>
  >;
  getDelatnosti: () => Promise<
    AxiosResponse<ApiResponse<{ value: number; label: string }[]>>
  >;
  getOstaloStatus: () => Promise<
    AxiosResponse<ApiResponse<{ value: number; label: string }[]>>
  >;
  getMineralneSirovine: (
    zahtevTipId: number
  ) => Promise<AxiosResponse<ApiResponse<{ value: number; label: string }[]>>>;
  getPredmetIstrazivanja: () => Promise<
    AxiosResponse<ApiResponse<{ value: number; label: string }[]>>
  >;
  getResenja: () => Promise<
    AxiosResponse<ApiResponse<{ value: number; label: string }[]>>
  >;
  createNewRequest: (
    requestId: number
  ) => Promise<AxiosResponse<ApiResponse<CreateNewRequestResponse>>>;
  saveDraftRequest: (
    requestData: Record<string, unknown>
  ) => Promise<AxiosResponse<ApiResponse<SaveDraftRequestResponse>>>;
  uploadDocument: (
    requestData: unknown
  ) => Promise<AxiosResponse<ApiResponse<DocumentAttributeResponse>>>;
  generateXML: (
    requestId: number
  ) => Promise<AxiosResponse<ApiResponse<unknown>>>;
  getPodnetiZahtevi: () => Promise<
    AxiosResponse<ApiResponse<PodnetiZahteviResponse[]>>
  >;
  downloadRequestsAttachment: (
    zahtevId: number,
    dokumentId: number
  ) => Promise<AxiosResponse<ApiResponse<unknown>>>;
  removeRequestsAttachment: (
    zahtevId: number,
    dokumentId: number
  ) => Promise<AxiosResponse<ApiResponse<unknown>>>;
  potpisiXML: (xmlBase64: string) => unknown;
};

const getGroupsAndRequests = () => {
  return axios.get<ApiResponse<RequestsConfig>>('/config/zahtevi-config');
};

const getRequestDetails = (requestId: number) => {
  return axios.get<ApiResponse<GetRequestDetailsResponse>>(
    `/zahtevi?zahtevId=${requestId}`
  );
};

const getVrsteGeoloskihIstrazivanja = (
  zahtevTipId: number,
  groupId: number
) => {
  return axios.get('/config/vrste-geoloskih-istrazivanja-config', {
    params: {
      ZahtevGrupaId: groupId,
      ZahtevTipId: zahtevTipId,
    },
  });
};

const getVrstePrivrednogSubjekta = () => {
  return axios.get('/config/vrste-privrednog-subjekta-config');
};

const getOpstine = () => {
  return axios.get('/config/opstine');
};

const getMesta = () => {
  return axios.get('/config/mesta');
};

const getKatastarskaMestaOpstine = () => {
  return axios.get('/config/katastarska-mesta-opstine');
};

const getDelatnosti = () => {
  return axios.get('/config/delatnosti-config');
};

const getOstaloStatus = () => {
  return axios.get(
    '/config/privredni-subjekt-registracija-ostalo-status-config'
  );
};

const getMineralneSirovine = (zahtevTipId: number) => {
  return axios.get(
    `/config/vrste-mineralnih-sirovina?zahtevTipId=${zahtevTipId}`
  );
};

const getPredmetIstrazivanja = () => {
  return axios.get('/config/predmet-istrazivanja');
};

const getResenja = () => {
  return axios.get('/config/resenja');
};

const createNewRequest = (requestId: number) => {
  return axios.post<ApiResponse<CreateNewRequestResponse>>(
    `/zahtevi/podnesi?zahtevId=${requestId}`
  );
};

const saveDraftRequest = (requestData: Record<string, unknown>) => {
  return axios.post<ApiResponse<SaveDraftRequestResponse>>(
    '/zahtevi/sacuvaj-nacrt',
    requestData
  );
};

const uploadDocument = (requestData: unknown) => {
  return axios.post<ApiResponse<DocumentAttributeResponse>>(
    'zahtevi/dodaj-dokument',
    requestData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

const generateXML = (requestId: number) => {
  return axios.get(`zahtevi/generisi-xml?zahtevId=${requestId}`);
};

const getPodnetiZahtevi = () => {
  return axios.get('/config/podneti-zahtevi');
};

const downloadRequestsAttachment = (zahtevId: number, dokumentId: number) => {
  return axios.get(
    `zahtevi/dohvati-dokument-priloga?zahtevId=${zahtevId}&dokumentId=${dokumentId}`,
    {
      responseType: 'arraybuffer',
    }
  );
};

const removeRequestsAttachment = (zahtevId: number, dokumentId: number) => {
  return axios.delete('zahtevi/obrisi-dokument-zahteva', {
    params: {
      ZahtevId: zahtevId,
      DokumentId: dokumentId,
    },
  });
};

const potpisiXML = (xmlBase64: string) => {
  return axios({
    baseURL: 'https://localhost:5759',
    url: '/SignXml',
    method: 'post',
    data: JSON.stringify({ xml: xmlBase64 }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const requestsRepo: RequestsRepoType = {
  getGroupsAndRequests,
  getRequestDetails,
  getVrsteGeoloskihIstrazivanja,
  getVrstePrivrednogSubjekta,
  getOpstine,
  getMesta,
  getMineralneSirovine,
  getPredmetIstrazivanja,
  getKatastarskaMestaOpstine,
  getResenja,
  createNewRequest,
  saveDraftRequest,
  uploadDocument,
  generateXML,
  getDelatnosti,
  getOstaloStatus,
  getPodnetiZahtevi,
  downloadRequestsAttachment,
  removeRequestsAttachment,
  potpisiXML,
};
