/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {
  DocumentAttributePayload,
  GetRequestDetailsResponse,
  IMappedRequestDetailsResponseForForm,
  PreduzecaProps,
  PreduzeceTypeEnum,
} from '@/modules/requests/requests.types';
import dayjs, { Dayjs } from 'dayjs';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { mapDokumentaAtributiForForm } from '@/modules/requests/requests.documents.ts';

type PreduzeceFormData = {
  adresa: string;
  brojPoslovnogRacuna: string;
  email: string;
  mestoId: number;
  imePrezimeOdgovornogLica: string;
  maticniBroj: string;
  pib: string;
  punNaziv: string;
  telefon: string;
  vrstaPreduzece: number;
  webSite: string;
};

type Coordinates = {
  koordinataX: number;
  koordinataY: number;
};

type LocalityFormData = {
  nazivLokaliteta: string;
  koordinate: Coordinates[];
};
export interface ZahtevFormData {
  zahtevId: number;
  zahtevTipId: number;
  dokumentaAtributi: DocumentAttributePayload;
  podzemneVodeAtributi: {
    liceVrsilacTehnickeKontrole: string;
    nazivProjektaPrimenjenihGeoloskihIstrazivanja: string;
    vrstaGeoloskogIstrazivanjaId: number[];
    duzinaRokaGeoloskogIstrazivanja: number;
    nazivProgramaAktivnosti: string;
    predmetIstrazivanjaId: number[];
    datumIzradeElaborata: Dayjs;
  };
  istrazivanjeAtributi: {
    vrstaMineralneSirovineOpisno: string;
    katastarskiBrojParcele: string;
    brojProduzenja: string;
    brojOdobrenogIstraznogPolja: string;
    rokVazenjaOdobrenja: string;
    brojIstraznogProstora: string;
    datumPrijemaResenja: string;
    datumPocetkaProbnogRadaBusotine: string;
    datumZavrsetkaProbnogRadaBusotine: string;
    datumIzvrsenjaGeoloskogIstrazivanja: string;
    datumPocetkaIzvrsenjaGeoloskogIstrazivanja: string;
    datumZavrsetkaIzvrsenjaGeoloskogIstrazivanja: string;
    autoriElaborata: string;
    vrstaGeoloskogIstrazivanjaId: number[];
    nazivProjektaPrimenjenihGeoloskihIstrazivanja: string;
    liceVrsilacTehnickeKontrole: string;
    liceVrsilacNadzora: string;
    nazivElaborata: string;
    nazivProgramaAktivnosti: string;
    glavniProjektantImePrezime: string;
    odgovorniProjektantImePrezime: string;
    periodTrajanjaGeoloskihIstrazivanja: dayjs.Dayjs[];
  };
  eksploatacijaMineralnihAtributi: {
    nazivLezista: string;
    nazivStudijeIzvodljivosti: string;
    resenjeMineralneSirovineId: number;
    brojResenjaMineralneSirovine: string;
    brojEksploatacionogPolja: string;
    godisnjiKapacitet: string;
    nazivRudarskogProjekta: string;
    nazivRudarskogProjektaPoKomeJeIzgradjenRudarskiObjekat: string;
    brojResenjaOOdobravanjuZahtevaZaEksploatacionoPolje: string;
    izborResenjaOOdobravanjuZahtevaZaEksploatacionoPolje: number;
  };
  preduzece: {
    [key in PreduzeceTypeEnum]: PreduzeceFormData;
  };
  prilozi: Record<string, unknown>;
  prostorObavezni: LocalityFormData;
  prostorOpcioni: LocalityFormData;
  zahtevAtributi: {
    obrazlozenje: string;
    opstinaId: number[];
    visinaAdministrativneTakse: number;
    zahtevId: string;
    datumPodnosenjaZahteva: Dayjs;
    godinaIzrade?: number;
  };
  resenja: Record<number, DefaultOptionType>;
  referentniZahtevId: number;
}

export const mapRequestDetailsResponseForForm = (
  requestDetails: GetRequestDetailsResponse
): IMappedRequestDetailsResponseForForm => {
  console.log('Req Details ', requestDetails);

  const preduzece = requestDetails.preduzeca?.reduce(
    (previous, current: PreduzecaProps) => {
      return {
        ...previous,
        [String(current.preduzeceTip)]: current,
      };
    },
    {}
  );

  const { dokumentaAtributi, resenja, ...other } = requestDetails;

  const mappedDokumentaAtributi = mapDokumentaAtributiForForm(
    dokumentaAtributi,
    requestDetails.zahtevTipId!
  );

  const reqMappedData = {
    ...other,
    preduzece,
    selectedResenja: resenja ?? [],
    dokumentaAtributi: mappedDokumentaAtributi,
  };

  console.log('MAPPED DATA ', reqMappedData);

  return reqMappedData;
};

const getResenjaFormatted = (
  dataWithType: ZahtevFormData
): Pick<ZahtevFormData['resenja'], never> | undefined => {
  if (!dataWithType?.resenja) {
    return undefined;
  }
  const resenjaArray = Object.keys(dataWithType.resenja).map((tipResenja) => {
    if (dataWithType.resenja[+tipResenja]) {
      return {
        brojResenja: dataWithType.resenja[+tipResenja]!.label,
        resenjeId: getResenjeValue(dataWithType.resenja[+tipResenja]!.value!),
        tipResenja: +tipResenja,
      };
    } else {
      return undefined;
    }
  });

  return resenjaArray.filter((resenje) => resenje);
};

const getResenjeValue = (value: number | string) =>
  typeof value === 'string' ? undefined : value;

export const formDataMappers = (data: ZahtevFormData) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { preduzece, dokumentaAtributi, prilozi, ...rest } = data;

  const preduzeca = preduzece && mapPreduzeca(preduzece);

  return {
    ...rest,
    resenja: getResenjaFormatted(data),
    preduzeca,
  };
};

const mapPreduzeca = (preduzeca: {
  [key in PreduzeceTypeEnum]: PreduzeceFormData;
}) => {
  return Object.entries(preduzeca || {})?.map(([tipPreduzeca, preduzece]) => ({
    ...preduzece,
    preduzeceTip: Number(tipPreduzeca),
  }));
};
