import { useEffect, useState } from 'react';

type useWindowDimensionReturnType = {
  width: number;
  height: number;
};

const useWindowDimension = (): useWindowDimensionReturnType => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return {
    width,
    height,
  };
};

export default useWindowDimension;
