import { Col, Form, Input, Row, Select } from 'antd';
import { t } from 'i18next';

import CustomUpload from '@/components/Forms/Upload/CustomUpload';
import { UserRegistrationFormDataOstalo } from '@/modules/auth/auth.types';
import { requestsStore } from '@/modules/requests/requests.store';
import style from './OstaloForm.module.scss';
// import { OSTALO_TIP_SUBJEKTA } from '@/utils/mockData';

export const OstaloForm = () => {
  const form = Form.useFormInstance<UserRegistrationFormDataOstalo>();

  return (
    <Row gutter={5}>
      <Col span={8}>
        <Form.Item
          validateFirst
          className={style.inputContainer}
          name='naziv'
          label={t('naziv_privrednog_subjekta')}
          rules={[
            {
              required: true,
              message: t('obavezno_polje'),
            },
            {
              max: 500,
              message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
            },
          ]}
        >
          <Input placeholder={t('naziv_placeholder')} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          validateFirst
          className={style.inputContainer}
          name='pib'
          label={t('pib')}
          rules={[
            // {
            //   required: true,
            //   message: t('obavezno_polje'),
            // },
            {
              max: 9,
              message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
            },
          ]}
        >
          <Input placeholder={t('pib_placeholder')} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          validateFirst
          className={style.inputContainer}
          name='adresa'
          label={t('ulica_i_broj')}
          rules={[
            {
              required: true,
              message: t('obavezno_polje'),
            },
            {
              max: 500,
              message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
            },
          ]}
        >
          <Input placeholder={t('ulica_i_bro_placeholder')} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          validateFirst
          className={style.inputContainer}
          name='maticniBroj'
          label={t('maticni_broj')}
          rules={[
            {
              required: true,
              message: t('obavezno_polje'),
            },
            {
              max: 8,
              message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
            },
          ]}
        >
          <Input placeholder={t('maticni_broj_placeholder')} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          validateFirst
          className={style.inputContainer}
          name='tekuciRacun'
          label={t('broj_poslovnog_racuna')}
          rules={[
            {
              required: true,
              message: t('obavezno_polje'),
            },
          ]}
        >
          <Input placeholder={t('broj_poslovnog_racuna_placeholder')} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          validateFirst
          className={style.inputContainer}
          name='telefon'
          label={t('telefon')}
          rules={[
            {
              required: true,
              message: t('obavezno_polje'),
            },
            {
              max: 20,
              message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
            },
          ]}
        >
          <Input placeholder={t('telefon_placeholder')} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          validateFirst
          className={style.inputContainer}
          name='internetAdresa'
          label={t('veb_stranica')}
          rules={[
            {
              type: 'url',
              message: t('forma.sekcija.polje.podaci_nisu_validni'),
            },
          ]}
        >
          <Input placeholder={t('veb_placeholder')} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          validateFirst
          className={style.inputContainer}
          name='email'
          label={t('forma.sekcija.polje.email')}
          rules={[
            {
              required: true,
              message: t('obavezno_polje'),
            },
            { type: 'email', message: t('email_error') },
          ]}
        >
          <Input placeholder={t('email_placeholder')} />
        </Form.Item>
      </Col>
      {/* <Col span={8}>
        <Form.Item
          validateFirst
          className={style.inputContainer}
          name='emailNotification'
          label={t('eposta_za_prijem_notifikacije')}
          rules={[
            {
              required: true,
              message: t('obavezno_polje'),
            },
            { type: 'email', message: t('email_error') },
          ]}
        >
          <Input placeholder={t('email_placeholder')} />
        </Form.Item>
      </Col> */}
      <Col span={8}>
        <Form.Item
          name='mestoId'
          label={t('mesto')}
          rules={[
            {
              required: true,
              message: t('obavezno_polje'),
            },
          ]}
        >
          <Select
            allowClear
            options={requestsStore.config?.mesta || []}
            className='w-100'
            placeholder={t('mesto_placeholder')}
          />
        </Form.Item>
      </Col>
      {/* <Col span={8}>
        <Form.Item
          name='delatnostId'
          label={t('delatnost')}
          rules={[
            {
              required: true,
              message: t('obavezno_polje'),
            },
          ]}
        >
          <Select
            allowClear
            className='w-100'
            options={requestsStore.config?.delatnosti || []}
            placeholder={t('delatnost_placeholder')}
          />
        </Form.Item>
      </Col> */}
      {/* <Col span={8}>
        <Form.Item
          name='ostaloTipSubjekta'
          label={t('tip_subjekta')}
          rules={[
            {
              required: true,
              message: t('obavezno_polje'),
            },
          ]}
        >
          <Select
            allowClear
            className='w-100'
            options={OSTALO_TIP_SUBJEKTA || []}
            placeholder={t('tip_subjekta_placeholder')}
          />
        </Form.Item>
      </Col> */}
      <Col span={24}>
        <Form.Item
          validateFirst
          name='file'
          label={t('dokument')}
          rules={[
            {
              required: true,
              message: t('obavezno_polje'),
            },
          ]}
        >
          <div className={style.uploadWrapper}>
            <CustomUpload
              form={form}
              validateJustPassedInput
              name='file'
              accept={['.pdf']}
            />
          </div>
        </Form.Item>
      </Col>
    </Row>
  );
};
