import { AxiosResponse } from 'axios';
import axios, { ApiResponse } from '../../utils/axios';
import {
  IConfigAkcijeRaspodelaPayload,
  IConfigAkcijeRaspodelaResponse,
  IPokreniProcesAktaPayload,
  IZahteviDodajAktZahteva,
  IZahteviGenerisiAkt,
  IZahteviObradiProcesOdobravanjaAktaPayload,
  IZahteviSacuvajAtributeOdlukePayload,
  IZahteviSviZahteviPayload,
  IZahteviSviZahteviResponse,
  AkcijaResponse,
  IZahteviSacuvajAtributeOdlukeResponse,
  IDodeliZahtevPayload,
  IProslediZahtevPayload,
  IVratiZahtevPayload,
} from './submittedRequests.types';

type SubmittedRequestsRepoType = {
  postZahteviSviZahtevi: (
    payload: IZahteviSviZahteviPayload
  ) => Promise<AxiosResponse<ApiResponse<IZahteviSviZahteviResponse[]>>>;
  getZahteviZahtevPreuzimanje: (
    zahtevId: number
  ) => Promise<AxiosResponse<ApiResponse<AkcijaResponse>>>;
  postZahteviVratiZahtev: (
    payload: IVratiZahtevPayload
  ) => Promise<AxiosResponse<ApiResponse<AkcijaResponse>>>;
  postZahteviProslediZahtev: (
    payload: IProslediZahtevPayload
  ) => Promise<AxiosResponse<ApiResponse<AkcijaResponse>>>;
  postZahteviDodeliZahtev: (
    payload: IDodeliZahtevPayload
  ) => Promise<AxiosResponse<ApiResponse<AkcijaResponse>>>;
  postConfigAkcijeRaspodela: (
    payload: IConfigAkcijeRaspodelaPayload
  ) => Promise<AxiosResponse<ApiResponse<IConfigAkcijeRaspodelaResponse>>>;
  postZahteviObradiProcesOdobravanjaAkta: (
    payload: IZahteviObradiProcesOdobravanjaAktaPayload
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;
  postZahteviSacuvajAtributeOdluke: (
    payload: IZahteviSacuvajAtributeOdlukePayload
  ) => Promise<
    AxiosResponse<ApiResponse<IZahteviSacuvajAtributeOdlukeResponse>>
  >;
  postZahteviSacuvajPoljaDopune: (
    payload: IZahteviSacuvajAtributeOdlukePayload
  ) => Promise<
    AxiosResponse<ApiResponse<IZahteviSacuvajAtributeOdlukeResponse>>
  >;
  postZahteviGenerisiAkt: (
    payload: IZahteviGenerisiAkt
  ) => Promise<AxiosResponse<ApiResponse<unknown>>>;
  postZahteviDodajAktZahteva: (
    payload: IZahteviDodajAktZahteva
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;
  postZahteviPokreniProcesAkta: (
    payload: IPokreniProcesAktaPayload
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;
  postZahteviSacuvajPrilog: (
    payload: IZahteviDodajAktZahteva
  ) => Promise<AxiosResponse<ApiResponse<number>>>;
  getZahteviPreuzmiAkt: (
    requestId: number | null
  ) => Promise<AxiosResponse<ApiResponse<unknown>>>;
  postZahteviZavediResenje: (
    payload: IZahteviGenerisiAkt
  ) => Promise<AxiosResponse<ApiResponse<number>>>;
  postZahteviDodajPotpisanAkt: (
    payload: IZahteviDodajAktZahteva
  ) => Promise<AxiosResponse<ApiResponse<number>>>;
  postZahteviPrekiniPrekidPostupka: (
    zahtevId: number | null
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;
  postDopuniZahtev: (
    requestData: Record<string, unknown>
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;
};

const postZahteviSviZahtevi = (payload: IZahteviSviZahteviPayload) => {
  return axios.post<ApiResponse<IZahteviSviZahteviResponse[]>>(
    '/zahtevi/svi-zahtevi',
    payload
  );
};

const getZahteviZahtevPreuzimanje = (zahtevId: number) => {
  return axios.get<ApiResponse<AkcijaResponse>>(
    `/zahtevi/zahtev-preuzimanje?zahtevId=${zahtevId}`
  );
};

const postZahteviVratiZahtev = (payload: IVratiZahtevPayload) => {
  return axios.post<ApiResponse<AkcijaResponse>>(
    '/zahtevi/vrati-zahtev',
    payload
  );
};

const postZahteviProslediZahtev = (payload: IProslediZahtevPayload) => {
  return axios.post<ApiResponse<AkcijaResponse>>(
    '/zahtevi/prosledi-zahtev',
    payload
  );
};

const postZahteviDodeliZahtev = (payload: IDodeliZahtevPayload) => {
  return axios.post<ApiResponse<AkcijaResponse>>(
    '/zahtevi/dodeli-zahtev',
    payload
  );
};

const postConfigAkcijeRaspodela = (payload: IConfigAkcijeRaspodelaPayload) => {
  return axios.post<ApiResponse<IConfigAkcijeRaspodelaResponse>>(
    '/config/akcije-raspodela',
    payload
  );
};

const postZahteviObradiProcesOdobravanjaAkta = (
  payload: IZahteviObradiProcesOdobravanjaAktaPayload
) => {
  return axios.post<ApiResponse<boolean>>(
    '/zahtevi/obradi-proces-odobravanja-akta',
    payload
  );
};

const postZahteviSacuvajAtributeOdluke = (
  payload: IZahteviSacuvajAtributeOdlukePayload
) => {
  return axios.post<ApiResponse<IZahteviSacuvajAtributeOdlukeResponse>>(
    '/zahtevi/sacuvaj-atribute-odluke',
    payload
  );
};

const postZahteviSacuvajPoljaDopune = (
  payload: IZahteviSacuvajAtributeOdlukePayload
) => {
  return axios.post<ApiResponse<IZahteviSacuvajAtributeOdlukeResponse>>(
    '/zahtevi/sacuvaj-polja-dopune',
    payload
  );
};

const postZahteviGenerisiAkt = (payload: IZahteviGenerisiAkt) => {
  return axios.post<ApiResponse<boolean>>('/zahtevi/generis-akt', payload, {
    responseType: 'arraybuffer',
  });
};

const postZahteviDodajAktZahteva = (payload: IZahteviDodajAktZahteva) => {
  return axios.post<ApiResponse<boolean>>(
    '/zahtevi/dodaj-akt-zahteva',
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

const postZahteviSacuvajPrilog = (payload: IZahteviDodajAktZahteva) => {
  return axios.post<ApiResponse<number>>('/zahtevi/sacuvaj-prilog', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const postZahteviPokreniProcesAkta = (payload: IPokreniProcesAktaPayload) => {
  return axios.post<ApiResponse<boolean>>(
    '/zahtevi/pokreni-proces-akta',
    payload
  );
};

const getZahteviPreuzmiAkt = (requestId: number | null) => {
  return axios.get(`/zahtevi/preuzmi-akt?resenjeId=${requestId}`, {
    responseType: 'arraybuffer',
  });
};

const postZahteviZavediResenje = (payload: IZahteviGenerisiAkt) => {
  return axios.post<ApiResponse<number>>('/zahtevi/zavedi-resenje', payload);
};

const postZahteviDodajPotpisanAkt = (payload: IZahteviDodajAktZahteva) => {
  return axios.post<ApiResponse<number>>(
    '/zahtevi/dodaj-potpisan-akt',
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

const postZahteviPrekiniPrekidPostupka = (zahtevId: number | null) => {
  return axios.post<ApiResponse<boolean>>(
    `/zahtevi/prekini-prekid-postupka?zahtevId=${zahtevId}`
  );
};
const postDopuniZahtev = (requestData: Record<string, unknown>) => {
  return axios.post<ApiResponse<boolean>>(
    `/zahtevi/dopuni-zahtev`,
    requestData
  );
};

export const submittedRequestsRepo: SubmittedRequestsRepoType = {
  postZahteviSviZahtevi,
  getZahteviZahtevPreuzimanje,
  postZahteviProslediZahtev,
  postZahteviDodeliZahtev,
  postZahteviVratiZahtev,
  postConfigAkcijeRaspodela,
  postZahteviObradiProcesOdobravanjaAkta,
  postZahteviSacuvajAtributeOdluke,
  postZahteviSacuvajPoljaDopune,
  postZahteviGenerisiAkt,
  postZahteviDodajAktZahteva,
  postZahteviPokreniProcesAkta,
  postZahteviSacuvajPrilog,
  getZahteviPreuzmiAkt,
  postZahteviZavediResenje,
  postZahteviDodajPotpisanAkt,
  postZahteviPrekiniPrekidPostupka,
  postDopuniZahtev,
};
