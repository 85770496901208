export enum PS_ROLES {
  ADMINISTRATOR = 1,
  PODNOSILAC_ZAHTEVA = 2,
  PREGLEDAC_ZAHTEVA_RESENJA = 3,
  PRIVREDNI_SUBJEKT = 8,
}

export const PS_ROLES_ARRAY = [
  PS_ROLES.ADMINISTRATOR,
  PS_ROLES.PODNOSILAC_ZAHTEVA,
  PS_ROLES.PREGLEDAC_ZAHTEVA_RESENJA,
  PS_ROLES.PRIVREDNI_SUBJEKT,
];

export enum MRE_ROLES {
  POMOCNIK = 4,
  SEF_ODSEKA = 5,
  OBRADJIVAC_ZAHTEVA = 6,
  PREGLEDAC_ZAHTEVA = 7,
  SUPERVIZOR = 9,
  SEKTOR_ZA_GEOLOGIJU_I_RUDARSTVO = 10,
  OVLASCENO_LICE_ZA_POTPISIVANJE = 11,
  RUKOVODILAC_PRAVNE_SLUZBE = 12,
}

export const MRE_ROLES_ARRAY = [
  MRE_ROLES.POMOCNIK,
  MRE_ROLES.SEF_ODSEKA,
  MRE_ROLES.OBRADJIVAC_ZAHTEVA,
  MRE_ROLES.PREGLEDAC_ZAHTEVA,
  MRE_ROLES.SUPERVIZOR,
  MRE_ROLES.SEKTOR_ZA_GEOLOGIJU_I_RUDARSTVO,
  MRE_ROLES.OVLASCENO_LICE_ZA_POTPISIVANJE,
  MRE_ROLES.RUKOVODILAC_PRAVNE_SLUZBE,
];
