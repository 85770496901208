import { authStore } from '@/modules/auth/auth.store.ts';
import { getNewRequestFormDetails } from '@/modules/requests/requests.constants.tsx';
import { requestsService } from '@/modules/requests/requests.service.ts';
import { flattenRequests } from '@/utils/flattenRequestsFromGroups.ts';
import { HomeOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/breadcrumb/Breadcrumb';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ROLE_DEFAULT_ROUTE,
  Route,
  ROUTES,
  routes,
} from '../../routes/routes.tsx';
import { Typography } from 'antd';
import { settingsService } from '@/modules/settings/settings.service.ts';

const { Text } = Typography;

const useBreadcrumbs = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<{
    breadcrumbItems: ItemType[];
    pageTitle: string;
  }>();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const locationSplit = location.pathname?.split('/');
  const homeBreadcrumb: ItemType = {
    title: <HomeOutlined style={{ cursor: 'pointer' }} />,
    onClick: () =>
      navigate(ROLE_DEFAULT_ROUTE[authStore.userRoles!?.[0]?.roleId]),
  };

  useEffect(() => {
    const generateBreadcrumbs = async () => {
      const breadcrumbs: ItemType[] = [];
      let routeForLocation: Route | undefined;

      const addBreadcrumb = (
        title: React.ReactNode,
        path: string | undefined,
        isClickable: boolean
      ) => {
        breadcrumbs.push({
          title: (
            <Text
              ellipsis
              className={isClickable ? 'breadcrumbHover' : 'breadcrumbNoHover'}
            >
              {title}
            </Text>
          ),
          path,
          className: isClickable ? 'breadcrumbHover' : 'breadcrumbNoHover',
          onClick: (event) => {
            if (isClickable && path) {
              event.preventDefault();
              navigate(path);
            }
          },
        });
      };

      const findRouteForPath = (path: string) =>
        routes.find((route) => route?.path === path);

      const baseRoute = 1,
        detailsRoute = 2,
        requestId = 3;

      const locationBaseRoute = locationSplit?.[baseRoute];

      const isSubmittedRequestsPage =
        locationBaseRoute === ROUTES.SUBMITTED_REQUESTS?.slice(1) &&
        locationSplit.length > detailsRoute;

      const isRegisterBusinessPage =
        locationBaseRoute === ROUTES.BUSINESS_REGISTER?.slice(1) &&
        locationSplit.length > detailsRoute;

      const isOtherBusinessPage =
        locationBaseRoute === ROUTES.OTHER_DETAILS?.slice(1) &&
        locationSplit.length > detailsRoute;

      const isDraftRequestPage =
        locationBaseRoute === ROUTES.DRAFT_REQUEST?.slice(1) &&
        locationSplit.length > detailsRoute;

      if (isSubmittedRequestsPage) {
        const page = `/${locationBaseRoute}`;
        routeForLocation = findRouteForPath(page);

        addBreadcrumb(
          t(`stranice.${routeForLocation?.key}`),
          routeForLocation?.path,
          true
        );

        const tipZahtevaId = locationSplit[requestId];
        const groupsRes = await requestsService.getGroupsAndRequests();
        const groups = groupsRes.kategorijeZahteva;
        const tipZahteva = flattenRequests(groups)?.find(
          (zahtev) => zahtev.id === +tipZahtevaId
        );

        addBreadcrumb(tipZahteva?.ime, undefined, false);
      } else if (isRegisterBusinessPage) {
        addBreadcrumb(
          t('stranice.registar_privrednih_subjekata'),
          ROUTES.SETTINGS,
          true
        );
        const id = locationSplit[detailsRoute];
        await settingsService
          .postPrivredniSubjektiDetalji({ privredniSubjektId: +id })
          .then((res) => {
            addBreadcrumb(res.maticniBroj, undefined, false);
          });
      } else if (isOtherBusinessPage) {
        addBreadcrumb(t('stranice.ostalo'), ROUTES.SETTINGS, true);
        const id = locationSplit[detailsRoute];
        await settingsService
          .postPrivredniSubjektiOstaloDetalji({ privredniSubjektId: +id })
          .then((res) => {
            addBreadcrumb(res.maticniBroj, undefined, false);
          });
      } else if (isDraftRequestPage) {
        addBreadcrumb(
          t('stranice.podnesite_nov_zahtev'),
          ROUTES.NEW_REQUEST,
          true
        );
        const title = getNewRequestFormDetails(
          +locationSplit[requestId]
        )?.formTitle;
        addBreadcrumb(title, undefined, false);
      } else {
        locationSplit.forEach((locSplit, index) => {
          if (locSplit !== '') {
            routeForLocation = routes.find(
              (route) =>
                route.path === `/${locSplit}` ||
                route.path?.endsWith(`/${locSplit}`)
            );

            const lastElement = index === locationSplit.length - 1;
            const breadcrumbNavigatePath =
              routeForLocation?.path ||
              `${breadcrumbs?.[breadcrumbs.length - 1]?.path}/${locSplit}`;

            const title = routeForLocation?.label
              ? t(`stranice.${routeForLocation.key}`)
              : locationSplit[1] === ROUTES.NEW_REQUEST.slice(1)
                ? getNewRequestFormDetails(+locationSplit[detailsRoute])
                    ?.formTitle
                : locSplit;
            addBreadcrumb(
              title,
              !lastElement ? breadcrumbNavigatePath : undefined,
              !lastElement
            );
          }
        });
      }

      setBreadcrumbItems({
        breadcrumbItems: [homeBreadcrumb, ...breadcrumbs],
        pageTitle: routeForLocation?.label
          ? t(`stranice.${routeForLocation.key}`)
          : '',
      });
    };
    generateBreadcrumbs();
  }, [location.pathname]);

  return breadcrumbItems;
};

export default useBreadcrumbs;
