import { PageWrapper } from '@/components/PageWrapper/PageWrapper.tsx';
import style from './AccessDenied.module.scss';
import { useEffect, useState } from 'react';
import { authStore } from '@/modules/auth/auth.store.ts';
import { authService } from '@/modules/auth/auth.service.ts';
import { Button } from 'antd';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes.tsx';
import Loading from '@/components/Loading/Loading.tsx';
const AccessDenied = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setLoading(true);
    setErrorMessage(undefined);
    authService
      .getUserMainInfo()
      .then(async (res) => {
        setErrorMessage(t('access_denied_message'));
        authStore.refreshUserRoles(res);
      })
      .catch(() => {
        setErrorMessage(t('error_poruka'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleGoToHomePage = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <PageWrapper greyFrame={true}>
      <div className={style.container}>
        {loading ? (
          <Loading size={'large'} />
        ) : (
          <>
            <p>{errorMessage}</p>
            <Button onClick={handleGoToHomePage} type='primary'>
              {t('pocetna')}
            </Button>
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default AccessDenied;
