import { IResenjaPayload } from './acts.types';

export const INITIAL_ACT_FILTER_PARAMS: IResenjaPayload = {
  zahtevGrupaId: null,
  zahtevPodgrupaId: null,
  zahtevKategorijaPodgrupeId: null,
  nazivZahtevaId: null,
  zavodniBroj: null,
  tipAkta: null,
  datumOd: null,
  datumDo: null,
};
