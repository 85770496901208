import { DynamicFormField } from '@/modules/requests/requests.types';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Empty, Form, Input, Select, Space } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { t } from 'i18next';
import { DefaultOptionType } from 'rc-cascader';
import { InputRef } from 'rc-input';
import { useEffect, useRef, useState } from 'react';

export const ActsSelectInput = ({
  field,
  initialValue,
  options,
  disabled,
}: {
  field: DynamicFormField;
  initialValue?: string | number | unknown[] | null | undefined;
  options: DefaultOptionType[] | undefined;
  disabled?: boolean;
}) => {
  const form = useFormInstance();
  const [items, setItems] = useState<DefaultOptionType[] | undefined>(options);
  const [name, setName] = useState('');
  const inputRef = useRef<InputRef>(null);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setItems([...(items || []), { value: null, label: name }]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    setItems(options);
  }, [options]);

  return (
    <Form.Item
      name={field.name}
      label={field.label}
      rules={field.rules}
      initialValue={options?.find(
        (opt: DefaultOptionType) => opt?.value === initialValue
      )}
      style={{ width: '100%' }}
      tooltip={
        field.tooltip && {
          title: field.tooltip,
          icon: <InfoCircleOutlined />,
        }
      }
    >
      <Select
        placeholder={field.placeholder}
        allowClear
        disabled={disabled}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
              <Input
                placeholder={field.dropdown?.placeholder?.input}
                ref={inputRef}
                value={name}
                onChange={onNameChange}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button
                type='text'
                icon={<PlusOutlined />}
                onClick={addItem}
                disabled={!name?.trim()?.length}
              >
                {field.dropdown?.placeholder?.button}
              </Button>
            </Space>
          </>
        )}
        options={items?.map((item: DefaultOptionType) => ({
          label: item.label,
          value: item.value || item.label,
        }))}
        onChange={(_, option) => {
          form.setFieldValue(field.name, option);
        }}
        notFoundContent={<Empty description={t('nema_podataka')} />}
      />
    </Form.Item>
  );
};
