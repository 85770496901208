import { Button } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

import DynamicTitle from '@/components/DynamicTitle/DynamicTitle';
import RequestSectionWrapper from '@/pages/RequestView/components/RequestSectionWrapper/RequestSectionWrapper.tsx';
import { RequestDecisionModal } from './components/RequestDecisionModal/RequestDecisionModal';
import { RequestDecisionApproval } from './components/RequestDecisionApproval/RequestDecisionApproval';
import { RequestDecisionEdit } from './components/RequestDecisionEdit/RequestDecisionEdit';
import RequestDecisionOdbacivanje from './components/RequestDecisionOdbacivanje/RequestDecisionOdbacivanje';
import RequestDecisionOdbijanje from './components/RequestDecisionOdbijanje/RequestDecisionOdbijanje';
import RequestDecisionPrekidanjePostupka from './components/RequestDecisionPrekidanjePostupka/RequestDecisionPrekidanjePostupka';
import { OdlukeEnum, ResenjeProps } from '@/modules/requests/requests.types';
import { ModalName, modalStore } from '@/modules/modal/modal.store';

interface ReqestDecisionProps {
  handleSuccess: () => void;
  requestDecision: Pick<
    ResenjeProps,
    'zavodniBrojResenja' | 'datumUrucenjaResenja'
  > | null;
}
const RequestDecision = observer(
  ({ handleSuccess, requestDecision }: ReqestDecisionProps) => {
    const layout = () => {
      switch (modalStore.decision) {
        case OdlukeEnum.ODOBRAVANJE:
          return (
            <RequestDecisionApproval
              requestDecision={requestDecision}
              handleSuccess={handleSuccess}
            />
          );
        case OdlukeEnum.DOPUNA:
          return <RequestDecisionEdit handleSuccess={handleSuccess} />;
        case OdlukeEnum.PREKID:
          return (
            <RequestDecisionPrekidanjePostupka handleSuccess={handleSuccess} />
          );
        case OdlukeEnum.ODBACIVANJE:
          return (
            <RequestDecisionOdbacivanje
              requestDecision={requestDecision}
              handleSuccess={handleSuccess}
            />
          );
        case OdlukeEnum.ODBIJANJE:
          return (
            <RequestDecisionOdbijanje
              requestDecision={requestDecision}
              handleSuccess={handleSuccess}
            />
          );
        //
        default:
          return (
            <DynamicTitle
              title={t('odluka')}
              subComponent={
                <Button
                  onClick={() =>
                    modalStore.openModal(ModalName.RESQUEST_DECISION)
                  }
                  type='primary'
                >
                  {t('donesite_odluku')}
                </Button>
              }
            />
          );
      }
    };

    return (
      <RequestSectionWrapper title={t('odluka')}>
        <RequestDecisionModal />
        {layout()}
      </RequestSectionWrapper>
    );
  }
);

export default RequestDecision;
