import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const DATE_FORMAT_UI = 'DD.MM.YYYY.';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT_UI = 'DD.MM.YYYY. HH:mm';
export const YEAR_FORMAT = 'YYYY';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getLocalDateFromUTCDateString = (
  dateUTC: string | undefined | null
) => {
  if (!dateUTC) return null;
  return dayjs(dateUTC).format(DATE_TIME_FORMAT_UI);
};
