import { Button, Col, Flex, Form, Input, notification, Row } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'antd/es/form/Form';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import style from './RequestDecisionPrekidanjePostupkaForm.module.scss';
import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service';
import { ModalName, modalStore } from '@/modules/modal/modal.store';
import { requestsStore } from '@/modules/requests/requests.store';

interface IRequestDecisionPrekidanjePostupkaForm {
  setResenjeId: Dispatch<SetStateAction<number | null>>;
}

export const RequestDecisionPrekidanjePostupkaForm = observer(
  ({ setResenjeId }: IRequestDecisionPrekidanjePostupkaForm) => {
    const [form] = useForm();
    const { zahtevId } = useParams();

    const handleSubmit = () => {
      const payload = {
        ...form.getFieldsValue(),
        odluka: modalStore.decision,
        zahtevId: zahtevId,
      };
      submittedRequestsService
        .postZahteviSacuvajAtributeOdluke(payload)
        .then((res) => {
          notification.success({ message: t('sacuvaj_atribute_success') });
          requestsStore.setCanGenerateAct(true);
          setResenjeId(res.id);
        })
        .catch((err) => {
          notification.error(err);
        });
    };

    return (
      <Form
        className={style.form}
        layout='vertical'
        name={'requestDecisionApproval'}
        form={form}
        onFinish={handleSubmit}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              colon={false}
              label={t('razlog_prekida')}
              name={'razlogPrekida'}
              rules={[
                {
                  required: true,
                  message: t('forma.sekcija.polje.obavezno_polje'),
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              colon={false}
              label={t('obrazlozenje')}
              name={'obrazlozenje'}
              rules={[
                {
                  required: true,
                  message: t('forma.sekcija.polje.obavezno_polje'),
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Flex justify='end' gap={15}>
          <Button
            onClick={() => modalStore.openModal(ModalName.RESQUEST_DECISION)}
            type='primary'
          >
            {t('odustanite')}
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            form='requestDecisionApproval'
          >
            {t('sacuvajte')}
          </Button>
        </Flex>
      </Form>
    );
  }
);
