import { Flex, Input, Select } from 'antd';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';

import {
  IInitialPrivredniSubjektFilters,
  IInitialPrivredniSubjektOstaloFilters,
} from '@/modules/settings/settings.types';
import { DefaultOptionType } from 'antd/es/cascader';

interface SettingsPrivredniSubjektiFiltersProps {
  setParams: Dispatch<
    SetStateAction<
      IInitialPrivredniSubjektFilters | IInitialPrivredniSubjektOstaloFilters
    >
  >;
  statusesList: DefaultOptionType[] | undefined;
  manuallyDisabled?: boolean;
  manuallyDisabledList?: DefaultOptionType[] | undefined;
}

export const SettingsPrivredniSubjektiFilters = ({
  setParams,
  statusesList,
  manuallyDisabled,
  manuallyDisabledList,
}: SettingsPrivredniSubjektiFiltersProps) => {
  return (
    <Flex gap={10}>
      <Input
        placeholder={t('maticni_broj')}
        allowClear
        className='w-100'
        onChange={({ target }) => {
          setParams((prevParams) => ({
            ...prevParams,
            maticniBroj: target.value || null,
          }));
        }}
      />
      <Input
        placeholder={t('pib')}
        allowClear
        className='w-100'
        onChange={({ target }) => {
          setParams((prevParams) => ({
            ...prevParams,
            pib: target.value || null,
          }));
        }}
      />
      {manuallyDisabled && manuallyDisabledList && (
        <Select
          placeholder={t('status')}
          options={manuallyDisabledList}
          onChange={(value) => {
            setParams((prevParams) => ({
              ...prevParams,
              manuallyDisabled: value ?? null,
            }));
          }}
          allowClear
          className='w-100'
        />
      )}
      <Select
        placeholder={t('apr_status')}
        options={statusesList}
        onChange={(value) => {
          setParams((prevParams) => ({
            ...prevParams,
            status: value ?? null,
          }));
        }}
        allowClear
        className='w-100'
      />
    </Flex>
  );
};
