import { Form, Modal, notification } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';

import { settingsService } from '@/modules/settings/settings.service';
import { ModalName, modalStore } from '@/modules/modal/modal.store';

interface OtherModalProps {
  refetch: () => void;
  actionId: number | null;
  privredniSubjektId: number | null;
}

export const OtherModal = observer(
  ({ actionId, privredniSubjektId, refetch }: OtherModalProps) => {
    const [form] = useForm();

    const isOpen = modalStore.isVisible(ModalName.OTHER);

    const handleCancel = () => {
      modalStore.closeModal(ModalName.OTHER);
    };

    const handleSubmit = () => {
      const payload = {
        privredniSubjektId: privredniSubjektId,
        napomena: form.getFieldValue('napomena'),
      };
      switch (actionId) {
        case 1:
          settingsService
            .postPrivredniSubjektiOstaloOdobri(payload)
            .then(() => {
              notification.success({ message: t('vrati_zahtev_success') });
              handleCancel();
              refetch();
            })
            .catch((err) => {
              notification.error(err);
            });
          break;
        case 2:
          settingsService
            .postPrivredniSubjektiOstaloOdbij(payload)
            .then(() => {
              notification.success({ message: t('prosledi_zahtev_success') });
              handleCancel();
              refetch();
            })
            .catch((err) => {
              notification.error(err);
            });
          break;
        default:
          return null;
      }
    };

    return (
      <Modal
        open={isOpen}
        width={400}
        cancelButtonProps={{ danger: true, type: 'primary' }}
        onCancel={() => {
          handleCancel();
        }}
        destroyOnClose
        cancelText={t('odustanite')}
        okText={t('sacuvajte')}
        okButtonProps={{
          htmlType: 'submit',
          form: 'ostaloForm',
        }}
        title={t('ostalo')}
      >
        <Form
          form={form}
          name='ostaloForm'
          onFinish={handleSubmit}
          preserve={false}
        >
          <Form.Item name='napomena'>
            <TextArea placeholder={t('napomena')} className='w-100' />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
