import to from 'await-to-js';
import { authRepo } from './auth.repo.ts';
import { handleApiRequest } from '../../utils/axios.ts';
import { UserRegistrationFormData } from './auth.types.ts';
import { isDevelopment } from '../../utils/constants.ts';
import { MOCK_PR_SUB_ALL, MOCK_USER1 } from '../../utils/mockData.ts';
import { authStore } from './auth.store.ts';
import { IZahteviDodajAktZahteva } from '../submittedRequests/submittedRequests.types.ts';

const login = async () => {
  const [err, res] = await to(authRepo.login());
  if (err) {
    console.error('Error logging in ', err);
    return Promise.reject(err);
  }

  console.log('LOGIN RES ', res);
};

const logout = async () => {
  const [err, res] = await to(authRepo.logout());
  if (err) {
    console.error('Error logging out ', err);
    return Promise.reject(err);
  }

  console.log('LOGOUT RES ', res);
};

const register = async (userRegistrationData: UserRegistrationFormData) => {
  return handleApiRequest(authRepo.register(userRegistrationData));
};

const registerOstalo = async (formData: IZahteviDodajAktZahteva) => {
  return handleApiRequest(authRepo.registerOstalo(formData));
};

const getUserMainInfo = async () => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_USER1);
  }
  return handleApiRequest(authRepo.getUserMainInfo());
};

const getAllBusinessEntities = async () => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_PR_SUB_ALL);
  }
  return handleApiRequest(authRepo.getAllBussinessEntities());
};

const selectBusinessEntity = async (id: number) => {
  if (isDevelopment()) {
    authStore.setSelectedBusinessEntity(id);
  }
  return handleApiRequest(authRepo.selectBussinessEntity(id));
};
export const authService = {
  login,
  logout,
  register,
  getUserMainInfo,
  getAllBusinessEntities,
  selectBusinessEntity,
  registerOstalo,
};
