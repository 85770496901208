//ide na merge da bi se pullovao dev sa novim promenama da bi se uradio servis po novom nacinu

import { handleApiRequest } from '@/utils/axios';
import { submittedRequestsRepo } from './submittedRequests.repo';
import {
  IConfigAkcijeRaspodelaPayload,
  IPokreniProcesAktaPayload,
  IDodeliZahtevPayload,
  IZahteviDodajAktZahteva,
  IZahteviGenerisiAkt,
  IZahteviObradiProcesOdobravanjaAktaPayload,
  IZahteviSacuvajAtributeOdlukePayload,
  IZahteviSviZahteviPayload,
  IVratiZahtevPayload,
  IProslediZahtevPayload,
} from './submittedRequests.types';
import { isDevelopment } from '@/utils/constants.ts';
import {
  MOCK_AKCIJA,
  MOCK_CONFIG_AKCIJE,
  MOCK_SACUVAJ_ATRIBUTE_ODLUKE_RESPONSE,
  requests,
} from '@/utils/mockData.ts';
import { Dayjs } from 'dayjs';

const postZahteviSviZahtevi = async (payload: IZahteviSviZahteviPayload) => {
  if (isDevelopment()) {
    return Promise.resolve(requests);
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviSviZahtevi({
      ...payload,
      datumOd: (payload.datumOd as Dayjs)?.format(),
      datumDo: (payload.datumDo as Dayjs)?.format(),
    })
  );
};

const getZahteviZahtevPreuzimanje = async (zahtevId: number) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_AKCIJA);
  }
  return handleApiRequest(
    submittedRequestsRepo.getZahteviZahtevPreuzimanje(zahtevId)
  );
};

const postZahteviVratiZahtev = async (payload: IVratiZahtevPayload) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_AKCIJA);
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviVratiZahtev(payload)
  );
};

const postZahteviProslediZahtev = async (payload: IProslediZahtevPayload) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_AKCIJA);
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviProslediZahtev(payload)
  );
};

const postZahteviDodeliZahtev = async (payload: IDodeliZahtevPayload) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_AKCIJA);
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviDodeliZahtev(payload)
  );
};

const postConfigAkcijeRaspodela = async (
  payload: IConfigAkcijeRaspodelaPayload
) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_CONFIG_AKCIJE);
  }
  return handleApiRequest(
    submittedRequestsRepo.postConfigAkcijeRaspodela(payload)
  );
};

const postZahteviObradiProcesOdobravanjaAkta = async (
  payload: IZahteviObradiProcesOdobravanjaAktaPayload
) => {
  if (isDevelopment()) {
    return Promise.resolve(true);
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviObradiProcesOdobravanjaAkta(payload)
  );
};

const postZahteviSacuvajAtributeOdluke = async (
  payload: Omit<IZahteviSacuvajAtributeOdlukePayload, 'poljaZaDopunu'>
) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_SACUVAJ_ATRIBUTE_ODLUKE_RESPONSE);
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviSacuvajAtributeOdluke(payload)
  );
};

const postZahteviSacuvajPoljaDopune = async (
  payload: Pick<
    IZahteviSacuvajAtributeOdlukePayload,
    'zahtevId' | 'poljaZaIzmenu'
  >
) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_SACUVAJ_ATRIBUTE_ODLUKE_RESPONSE);
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviSacuvajPoljaDopune(payload)
  );
};

const postZahteviGenerisiAkt = async (payload: IZahteviGenerisiAkt) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  return submittedRequestsRepo.postZahteviGenerisiAkt(payload);
};

const postZahteviDodajAktZahteva = async (payload: IZahteviDodajAktZahteva) => {
  if (isDevelopment()) {
    return Promise.resolve(true);
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviDodajAktZahteva(payload)
  );
};

const postZahteviPokreniProcesAkta = async (
  payload: IPokreniProcesAktaPayload
) => {
  if (isDevelopment()) {
    return Promise.resolve(true);
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviPokreniProcesAkta(payload)
  );
};

const postZahteviSacuvajPrilog = async (payload: IZahteviDodajAktZahteva) => {
  if (isDevelopment()) {
    return Promise.resolve(1);
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviSacuvajPrilog(payload)
  );
};

const getZahteviPreuzmiAkt = async (resenjeId: number | null) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  return submittedRequestsRepo.getZahteviPreuzmiAkt(resenjeId);
};

const postZahteviZavediResenje = async (payload: IZahteviGenerisiAkt) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviZavediResenje(payload)
  );
};

const postZahteviDodajPotpisanAkt = async (
  payload: IZahteviDodajAktZahteva
) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviDodajPotpisanAkt(payload)
  );
};

const postZahteviPrekiniPrekidPostupka = async (zahtevId: number | null) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  return handleApiRequest(
    submittedRequestsRepo.postZahteviPrekiniPrekidPostupka(zahtevId)
  );
};

const postDopuniZahtev = async (requestData: Record<string, unknown>) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  return handleApiRequest(submittedRequestsRepo.postDopuniZahtev(requestData));
};

export const submittedRequestsService = {
  postZahteviSviZahtevi,
  getZahteviZahtevPreuzimanje,
  postZahteviProslediZahtev,
  postZahteviDodeliZahtev,
  postZahteviVratiZahtev,
  postConfigAkcijeRaspodela,
  postZahteviObradiProcesOdobravanjaAkta,
  postZahteviSacuvajAtributeOdluke,
  postZahteviSacuvajPoljaDopune,
  postZahteviGenerisiAkt,
  postZahteviDodajAktZahteva,
  postZahteviPokreniProcesAkta,
  postZahteviSacuvajPrilog,
  getZahteviPreuzmiAkt,
  postZahteviZavediResenje,
  postZahteviDodajPotpisanAkt,
  postZahteviPrekiniPrekidPostupka,
  postDopuniZahtev,
};
