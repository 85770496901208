import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Col, Flex, Form, Input, notification, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import style from './RequestDecisionOdbijanjeForm.module.scss';
import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service';
import { ResenjeProps } from '@/modules/requests/requests.types';
import { ModalName, modalStore } from '@/modules/modal/modal.store';
import { requestsStore } from '@/modules/requests/requests.store';

interface IRequestDecisionOdbijanjeForm {
  setResenjeId: Dispatch<SetStateAction<number | null>>;
  requestDecision: Pick<
    ResenjeProps,
    'zavodniBrojResenja' | 'datumUrucenjaResenja'
  > | null;
}

export const RequestDecisionOdbijanjeForm = observer(
  ({ setResenjeId, requestDecision }: IRequestDecisionOdbijanjeForm) => {
    const [form] = useForm();
    const { zahtevId } = useParams();
    const [zavodniBrojAkta, setZavodniBrojAkta] = useState<string>();

    //TODO REFACTOR!!!!!!!!!!!
    const handleSubmit = () => {
      const payload = {
        ...form.getFieldsValue(),
        odluka: modalStore.decision,
        zahtevId: zahtevId,
      };
      submittedRequestsService
        .postZahteviSacuvajAtributeOdluke(payload)
        .then((res) => {
          notification.success({ message: t('sacuvaj_atribute_success') });
          requestsStore.setCanGenerateAct(true);
          setResenjeId(res.id);
          setZavodniBrojAkta(res.zavodniBrojAkta);
        })
        .catch((err) => {
          notification.error(err);
        });
    };

    return (
      <Form
        className={style.form}
        layout='vertical'
        name='requestDecisionApproval'
        form={form}
        onFinish={handleSubmit}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item colon={false} label={t('zavodni_broj_akta')}>
              <Input
                disabled
                value={
                  zavodniBrojAkta || requestDecision?.zavodniBrojResenja || ''
                }
              />
            </Form.Item>
            <Form.Item colon={false} label={t('datum_donosenja_akta')}>
              <Input disabled />
            </Form.Item>
            <Form.Item colon={false} label={t('datum_urucenja_akta')}>
              <Input
                disabled
                value={requestDecision?.datumUrucenjaResenja ?? ''}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon={false}
              label={t('obrazlozenje')}
              name='obrazlozenje'
              rules={[
                {
                  required: true,
                  message: t('forma.sekcija.polje.obavezno_polje'),
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Flex justify='end' gap={15}>
          <Button
            onClick={() => modalStore.openModal(ModalName.RESQUEST_DECISION)}
            type='primary'
          >
            {t('odustanite')}
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            form='requestDecisionApproval'
          >
            {t('sacuvajte')}
          </Button>
        </Flex>
      </Form>
    );
  }
);
