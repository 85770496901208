import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import 'antd/dist/reset.css';

import style from './Sidebar.module.scss';
import { getCurrentRouteKey, routes } from '../../routes/routes.tsx';
import Logo from '../Logo/Logo.tsx';
import { userHasAccess } from '@/utils/guard.ts';
import { observer } from 'mobx-react-lite';

const { Sider } = Layout;

const Sidebar = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const currentRootPath = location.pathname.split('/')[1];
  const currentRouteKey = getCurrentRouteKey(`/${currentRootPath}`);

  const [collapsed, setCollapsed] = useState(false);

  const menuItems: MenuProps['items'] = routes
    .filter((route) => route.shouldBeInSidebar)
    .map((route) => {
      if (userHasAccess(route.requiredRoles || [])) {
        return {
          label: t(`stranice.${route.key}`),
          key: route.key,
          icon: route.icon,
          onClick: () => navigate(route.path),
        };
      } else {
        return null;
      }
    });

  return (
    <Sider
      className={style.sidebar}
      collapsedWidth={80}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={250}
    >
      <Logo collapsed={collapsed} />
      <Menu
        selectedKeys={[currentRouteKey]}
        items={menuItems}
        mode='vertical'
      />
    </Sider>
  );
});
export default Sidebar;
