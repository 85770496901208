import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Header from './Header/Header.tsx';
import Sidebar from './Sidebar/Sidebar.tsx';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs.tsx';
import TopPanel from './TopPanel/TopPanel.tsx';
import {
  authStore,
  CURRENT_USER_LOCAL_STORAGE_KEY,
} from '../modules/auth/auth.store.ts';
import { Layout } from 'antd';
import { LocalStorage } from '../utils/local-storage.ts';
import { ROUTES } from '@/routes/routes.tsx';

const MainLayout = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();

  const shouldRender =
    !!authStore.currentUser &&
    location.pathname !== ROUTES.REGISTRATION &&
    location.pathname !== ROUTES.ACCESS_DENIED &&
    location.pathname !== ROUTES.LOGIN_SUCCESS;

  useEffect(() => {
    const userFromLocalStorage = LocalStorage.getValue(
      CURRENT_USER_LOCAL_STORAGE_KEY
    );
    if (userFromLocalStorage) {
      authStore.login(userFromLocalStorage);
    }
  }, []);

  useEffect(() => {
    if (
      !authStore.currentUser &&
      location.pathname !== ROUTES.REGISTRATION &&
      location.pathname !== ROUTES.LOGIN_SUCCESS
    ) {
      LocalStorage.removeValue(CURRENT_USER_LOCAL_STORAGE_KEY);
      navigate(ROUTES.HOME);
    }
  }, [navigate, authStore.currentUser]); // eslint-disable-line

  return (
    <>
      <div className='mainContainer'>
        {shouldRender && <Sidebar />}
        <div className='content'>
          {authStore.currentUser && <Header />}
          <TopPanel />
          {shouldRender && <Breadcrumbs />}
          <Layout className='pageContainer'>
            <Outlet />
          </Layout>
        </div>
      </div>
    </>
  );
});

export default MainLayout;
