import {
  Button,
  Descriptions,
  DescriptionsProps,
  Flex,
  notification,
} from 'antd';
import { FilePdfTwoTone } from '@ant-design/icons';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import Loading from '@/components/Loading/Loading';
import { PageWrapper } from '@/components/PageWrapper/PageWrapper';
import PrivredniSubjektOstaloStatus from '@/components/Status/PrivredniSubjektOstaloStatus/PrivredniSubjektOstaloStatus';
import { requestsService } from '@/modules/requests/requests.service';
import { requestsStore } from '@/modules/requests/requests.store';
import { settingsService } from '@/modules/settings/settings.service';
import { IPrivredniSubjektiOstaloDetaljiResponse } from '@/modules/settings/settings.types';
import { ArgsProps } from 'antd/es/notification/interface';
import { OtherModal } from '../OtherModal/OtherModal';
import { ModalName, modalStore } from '@/modules/modal/modal.store';
import { handleDownloadFile } from '@/utils/handleDownloadFile';

export const OtherDetails = observer(() => {
  const [loading, setLoading] = useState<boolean>(true);
  const [actionId, setActionId] = useState<number | null>(null);
  const [details, setDetails] =
    useState<IPrivredniSubjektiOstaloDetaljiResponse>();
  const { id } = useParams();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        await requestsService.loadConfig();
        await fetchDetails();
      } catch (error: unknown) {
        console.error('Error loading config', error);
        notification.error(error as ArgsProps);
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  }, []);

  const fetchDetails = async () => {
    try {
      const res = await settingsService.postPrivredniSubjektiOstaloDetalji({
        privredniSubjektId: +id!,
      });
      setDetails(res!);
    } catch (error: unknown) {
      console.error('Error get request details ', error);
      notification.error(error as ArgsProps);
    }
  };

  const createItems = useMemo((): DescriptionsProps['items'] => {
    if (!details) {
      return undefined;
    }
    const descriptionItems: DescriptionsProps['items'] = [
      {
        key: '1',
        label: t('naziv'),
        children: settingsService.getInputValueForUI(details?.naziv),
      },
      {
        key: '2',
        label: t('pib'),
        children: settingsService.getInputValueForUI(details?.pib),
      },
      {
        key: '3',
        label: t('maticni_broj'),
        children: settingsService.getInputValueForUI(details?.maticniBroj),
      },
      {
        key: '4',
        label: t('ulica_i_broj'),
        children: settingsService.getInputValueForUI(details?.adresa),
      },
      {
        key: '5',
        label: t('mesto'),
        children: settingsService.findLabelById(
          requestsStore.config?.mesta,
          details?.mestoId
        ),
      },
      {
        key: '6',
        label: t('delatnost'),
        children: settingsService.findLabelById(
          requestsStore.config?.delatnosti,
          details?.delatnostId
        ),
      },
      {
        key: '7',
        label: t('broj_poslovnog_racuna'),
        children: settingsService.getInputValueForUI(details?.tekuciRacun),
      },
      {
        key: '8',
        label: t('telefon'),
        children: settingsService.getInputValueForUI(details?.telefon),
      },
      {
        key: '9',
        label: t('veb_stranica'),
        children: settingsService.getInputValueForUI(details?.internetAdresa),
      },
      {
        key: '10',
        label: t('email'),
        children: settingsService.getInputValueForUI(details?.email),
      },
      {
        key: '11',
        label: t('eposta_za_prijem_notifikacije'),
        children: settingsService.getInputValueForUI(
          details?.emailNotification
        ),
      },
      {
        key: '12',
        label: t('ime'),
        children: settingsService.getInputValueForUI(details?.userIme),
      },
      {
        key: '13',
        label: t('prezime'),
        children: settingsService.getInputValueForUI(details?.userPrezime),
      },
      {
        key: '15',
        label: t('eposta_korisnika'),
        children: settingsService.getInputValueForUI(details?.userEmailAddress),
      },
      {
        key: '16',
        label: t('approvalUserIme'),
        children: settingsService.getInputValueForUI(details?.approvalUserIme),
      },
      {
        key: '17',
        label: t('approvalUserPrezime'),
        children: settingsService.getInputValueForUI(
          details?.approvalUserPrezime
        ),
      },
      {
        key: '18',
        label: t('status'),
        children: details?.status && (
          <PrivredniSubjektOstaloStatus statusId={details?.status} />
        ),
      },
      {
        key: '19',
        label: t('dokaz_o_registraciji'),
        children: (
          <FilePdfTwoTone
            onClick={(event) => {
              event.stopPropagation();
              handleDownloadDokument(details.id);
            }}
            className='settingsFileIcon'
          />
        ),
      },
    ];

    return descriptionItems;
  }, [details]);

  const handleDownloadDokument = (id: number | null) => {
    if (id !== null) {
      settingsService
        .getPrivredniSubjektiOstaloDokumentPreuzmi({ privredniSubjektId: id })
        .then((res) => {
          handleDownloadFile(res as AxiosResponse)
            .then(() => {
              notification.success({
                message: t('generisi_dokument_success'),
              });
            })
            .catch(() => {
              notification.error({
                message: t('file_error'),
              });
            });
        })
        .catch((err) => {
          notification.error(err);
        });
    }
  };

  const handleClick = (value: number | null) => {
    setActionId(value);
    modalStore.openModal(ModalName.OTHER);
  };

  return (
    <PageWrapper greyFrame={true}>
      {loading ? (
        <Loading size={'large'} />
      ) : (
        <>
          <OtherModal
            actionId={actionId}
            privredniSubjektId={+id!}
            refetch={fetchDetails}
          />
          <>
            <Descriptions
              column={3}
              colon={false}
              layout='vertical'
              items={createItems}
              size='small'
            />
            <Flex gap={20} className='mt-40' justify='flex-end'>
              {details?.akcije.map((akcija) => {
                if (akcija.value === 1 || akcija.value === 2) {
                  return (
                    <Button
                      key={akcija.value}
                      type='primary'
                      danger={akcija.value === 2}
                      onClick={() => handleClick(akcija.value)}
                    >
                      {akcija.label}
                    </Button>
                  );
                }
              })}
            </Flex>
          </>
        </>
      )}
    </PageWrapper>
  );
});
