import { FilePdfTwoTone } from '@ant-design/icons';
import { Empty, notification, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import DynamicTitle from '@/components/DynamicTitle/DynamicTitle';
import { PageWrapper } from '@/components/PageWrapper/PageWrapper';
import { actsService } from '@/modules/acts/acts.service';
import { IResenjaPayload, IResenjaResponse } from '@/modules/acts/acts.types';
import { requestsService } from '@/modules/requests/requests.service';
import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service';
import { getLocalDateFromUTCDateString } from '@/utils/dateFormatter';
import { handleDownloadFile } from '@/utils/handleDownloadFile';
import style from './Acts.module.scss';
import ActsFilters from './components/ActsFilters';
import { INITIAL_ACT_FILTER_PARAMS } from '@/modules/acts/acts.constants';
import { authStore } from '@/modules/auth/auth.store.ts';

const Acts = () => {
  const [params, setParams] = useState<IResenjaPayload>(
    INITIAL_ACT_FILTER_PARAMS
  );
  const [tableData, setTableData] = useState<IResenjaResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getActs = () => {
    actsService
      .getActs(params)
      .then(async (res: IResenjaResponse[]) => {
        await requestsService.loadConfig();
        setTableData(res);
      })
      .catch((err) => {
        console.error('Error get acts ', err);
        notification.error(err);
      })
      .finally(() => setLoading(false));
  };

  const getActsDebounce = useDebouncedCallback(getActs, 500);

  useEffect(() => {
    getActsDebounce();
  }, [params, getActsDebounce, authStore.selectedBusinessEntity?.id]);

  const handleDownloadAkt = (zahtevResenjeId: number | null) => {
    if (zahtevResenjeId !== null) {
      submittedRequestsService
        .getZahteviPreuzmiAkt(zahtevResenjeId)
        .then((res) => {
          handleDownloadFile(res as AxiosResponse)
            .then(() => {
              notification.success({
                message: t('generisi_akt_success'),
              });
            })
            .catch((err) => {
              console.error('Error download akt ', err);
              notification.error({
                message: t('file_act_error'),
              });
            });
        })
        .catch((err) => {
          console.error('Error generisi akt');
          notification.error(err);
        });
    }
  };

  const colDefActs: ColumnsType<IResenjaResponse> = [
    {
      dataIndex: 'zavodniBroj',
      title: t('zavodni_broj_akta'),
      fixed: 'left',
      align: 'center',
      width: 175,
      render: (_, { zavodniBroj }) => {
        let zavodniBrojShort;

        if (zavodniBroj?.length && zavodniBroj.length > 13) {
          zavodniBrojShort = zavodniBroj.slice(0, 13);
        }
        return (
          <Typography.Paragraph
            className='m-0'
            copyable={{
              tooltips: [
                t('kopirajte_broj_zahteva'),
                t('broj_kopiranje_success'),
              ],
              text: zavodniBroj!,
            }}
          >
            <Tooltip title={zavodniBroj}>{zavodniBrojShort}...</Tooltip>
          </Typography.Paragraph>
        );
      },
    },
    {
      dataIndex: 'nazivZahteva',
      title: t('naziv_zahteva'),
      width: 300,
      align: 'center',
    },
    {
      dataIndex: 'tipAkta',
      title: t('tip_akta'),
      align: 'center',
    },
    {
      dataIndex: 'datumVremeDonosenja',
      title: t('datum_i_vreme_donosenja_akta'),
      width: 200,
      align: 'center',
      render: (_, { datumVremeDonosenja }) => {
        return getLocalDateFromUTCDateString(datumVremeDonosenja);
      },
    },
    {
      dataIndex: 'rokVazenjaAkta',
      title: t('rok_vazenja_akta'),
      width: 200,
      align: 'center',
    },
    {
      title: t('akt'),
      align: 'center',
      render: (record: IResenjaResponse) => (
        <FilePdfTwoTone
          onClick={() => handleDownloadAkt(record.zahtevResenjeId)}
          className={style.fileIcon}
        />
      ),
    },
    {
      dataIndex: 'grupaZahteva',
      title: t('grupa_zahteva'),
      align: 'center',
    },
    {
      dataIndex: 'podgrupaZahteva',
      title: t('podgrupa_zahteva'),
      align: 'center',
    },
    {
      dataIndex: 'tipMineralneSirovine',
      width: 200,
      title: t('tip_mineralne_sirovine_materijala'),
      align: 'center',
    },
  ];

  return (
    <PageWrapper isLoading={loading} greyFrame>
      <>
        <DynamicTitle title='stranice.lista_akata' />
        <ActsFilters setParams={setParams} params={params} />
        <Table
          rowKey={(record) => record.zahtevResenjeId}
          columns={colDefActs}
          pagination={{
            position: ['bottomRight'],
            pageSizeOptions: [10, 20, 50],
            showQuickJumper: true,
            showSizeChanger: true,
            locale: {
              page: t('stranu'),
              jump_to: t('idite_na'),
              items_per_page: t('/strani'),
            },
          }}
          className='mt-15'
          dataSource={tableData}
          scroll={{
            x: 2000,
            y: 300,
          }}
          locale={{
            emptyText: <Empty description={t('nema_podataka')} />,
          }}
        />
      </>
    </PageWrapper>
  );
};

export default Acts;
