import { requestsStore } from '@/modules/requests/requests.store';
import { Tag } from 'antd';
import { t } from 'i18next';

type PrivredniSubjektOstaloStatusProps = {
  statusId: number;
};
const PrivredniSubjektOstaloStatus = ({
  statusId,
}: PrivredniSubjektOstaloStatusProps) => {
  const tagColor = () => {
    switch (statusId) {
      case 1:
        return 'yellow';
      case 2:
        return 'green';
      case 3:
        return 'red';
    }
  };
  const getStatusLabel = (statusId: number) => {
    const status = requestsStore.config?.statusiOstalo?.find(
      (status) => status.value === statusId
    );
    const value = status?.value;
    if (!value) {
      return null;
    }
    return t(`statusi.privredni_subjekt_ostalo.${value as string}`);
  };

  return getStatusLabel(statusId) ? (
    <Tag color={tagColor()}>{getStatusLabel(statusId)}</Tag>
  ) : null;
};

export default PrivredniSubjektOstaloStatus;
