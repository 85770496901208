import { useEffect, useState } from 'react';
import { Button, Dropdown, MenuProps, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownOutlined, LogoutOutlined, PlusOutlined } from '@ant-design/icons';
import { changeLanguage, languageFromLocalStorage, LANGUAGES } from '@/i18n.ts';

import style from './Header.module.scss';
import { authStore } from '@/modules/auth/auth.store.ts';
import { authService } from '@/modules/auth/auth.service';
import { PrivredniSubjekt } from '@/modules/auth/auth.types';
import AddBusinessEntityModal from '../TopPanel/components/AddBusinessEntityModal/AddBusinessEntityModal';
import { observer } from 'mobx-react-lite';
import Guard from '@/components/Guard/Guard.tsx';
import useWindowDimension from '@/hooks/useWindowDimension.ts';
import { ModalName, modalStore } from '@/modules/modal/modal.store';

const Header = observer(() => {
  const { width } = useWindowDimension();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    languageFromLocalStorage ?? LANGUAGES.SERBIAN_CYRILLIC
  );

  const userName = `${authStore.currentUser?.ime} ${authStore.currentUser?.prezime}`;
  const odeljenje = authStore.selectedBusinessEntity?.odeljenje ?? null;

  const languageItems: MenuProps['items'] = [
    {
      label: t(LANGUAGES.SERBIAN_LATIN),
      key: '1',
      onClick: () => handleChangeLanguage(LANGUAGES.SERBIAN_LATIN),
    },
    {
      label: t(LANGUAGES.SERBIAN_CYRILLIC),
      key: '2',
      onClick: () => handleChangeLanguage(LANGUAGES.SERBIAN_CYRILLIC),
    },
  ];

  const handleChangeLanguage = (langugage: LANGUAGES) => {
    setSelectedLanguage(langugage);
    changeLanguage(langugage);
    window.location.reload();
  };

  const handleLogout = async () => {
    await authStore.logOut();
    window.location.replace('/api/LogoutSSO');
  };

  const handleSelect = async (bussinessEntityId: number) => {
    await authService.selectBusinessEntity(bussinessEntityId);
    authStore.setSelectedBusinessEntity(bussinessEntityId);
  };

  const mapBussinessEntities = (entities: PrivredniSubjekt[]) => {
    return entities.map((ent) => ({
      label: ent.naziv || t('privredni_subjekt_nepoznato_ime'),
      value: ent.id,
    }));
  };

  useEffect(() => {
    authService
      .getAllBusinessEntities()
      .then((res) => {
        authStore.setBusinessEntities(res);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <header className={style.header}>
      <AddBusinessEntityModal />
      <div className={style.actions}>
        <Guard requiredRole={'PS'}>
          <Tooltip title={t('dodajte_privredni_subjekt')}>
            <Button
              icon={<PlusOutlined />}
              onClick={() =>
                modalStore.openModal(ModalName.ADD_BUSINESS_ENTITY)
              }
              type='primary'
            >
              {width < 1300 ? '' : t('dodajte_privredni_subjekt')}
            </Button>
          </Tooltip>
        </Guard>
        <Select
          style={{ width: width < 1300 ? 300 : 500 }}
          disabled={loading}
          loading={loading}
          showSearch
          placeholder={t('privredni_subjekt')}
          filterOption={(input, option) =>
            ((option?.label ?? '') as string).includes(input)
          }
          options={mapBussinessEntities(authStore.businessEntities)}
          onSelect={handleSelect}
          defaultValue={authStore.selectedBusinessEntity?.id}
          value={authStore.selectedBusinessEntity?.id}
        />
      </div>
      <div className={style.nameContainer}>
        <p>{userName}</p>
        <p>{odeljenje}</p>
      </div>
      <Dropdown menu={{ items: languageItems }}>
        <Button>
          {t(selectedLanguage)}
          <DownOutlined />
        </Button>
      </Dropdown>
      <Tooltip title={t('logout')} placement={'left'}>
        <Button
          icon={<LogoutOutlined />}
          type='primary'
          danger
          onClick={handleLogout}
        />
      </Tooltip>
    </header>
  );
});

export default Header;
