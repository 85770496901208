import { authStore } from '@/modules/auth/auth.store.ts';
import { MRE_ROLES, PS_ROLES } from '@/modules/auth/auth.constants.ts';

export const userHasAccess = (requiredRoles: MRE_ROLES[] | PS_ROLES[]) => {
  if (!requiredRoles.length) return true;
  let hasAccess = false;

  requiredRoles.forEach((role) => {
    if (authStore.userRoles?.find((uRole) => uRole.roleId === role)) {
      hasAccess = true;
    }
  });
  return hasAccess;
};

export const userHasRoleAccess = (requiredRole: 'MRE' | 'PS') => {
  let hasAccess = false;
  if (requiredRole === 'PS') {
    hasAccess = authStore.isUserPS;
  } else if (requiredRole === 'MRE') {
    hasAccess = authStore.isUserMRE;
  }
  return hasAccess;
};
