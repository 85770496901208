/* eslint-disable no-unused-vars */
import { notification } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Loading from '@/components/Loading/Loading.tsx';
import { requestsService } from '@/modules/requests/requests.service.ts';
import {
  GetRequestDetailsResponse,
  IMappedRequestDetailsResponseForForm,
  ObradaIstorijaProps,
  OdlukeEnum,
  ResenjeBrojResenja,
  ResenjeId,
  ResenjeOdluka,
  ResenjeProps,
  StatusOdlukaEnum,
  ZahteviEnum,
} from '@/modules/requests/requests.types';
import RequestBasicInformation from '@/pages/RequestView/components/RequestBasicInformation.tsx';
import RequestFormDataView from '@/pages/RequestView/components/RequestFormDataView.tsx';
import RequestProcessingHistory from '@/pages/RequestView/components/RequestProcessingHistory/RequestProcessingHistory';
import RequestDecision from '@/pages/RequestView/RequestDecision/RequestDecision';
import { mapRequestDetailsResponseForForm } from '@/utils/formMapper.ts';
import { RequestDecisionStatus } from '../RequestDecisionStatus/RequestDecisionStatus';
import style from './RequestViewMRE.module.scss';
import Guard from '@/components/Guard/Guard';
import { MRE_ROLES } from '@/modules/auth/auth.constants';
import { modalStore } from '@/modules/modal/modal.store';
import { RequestDecisionReadOnly } from '../RequestDecisionReadOnly/RequestDecisionReadOnly';
import { ROUTES } from '@/routes/routes';
import { authStore } from '@/modules/auth/auth.store';
import { requestsStore } from '@/modules/requests/requests.store';

const RequestViewMRE = observer(() => {
  //TODO here we should call backend to get request details that will be provided to the components
  const [loading, setLoading] = useState<boolean>(true);
  const { zahtevId } = useParams();
  const [requestDetails, setRequestDetails] =
    useState<IMappedRequestDetailsResponseForForm>(); //TODO Add types when backend is done, we dont know the data structure yet
  const [zahtevTipId, setZahtevTipId] = useState<number>();
  const [basicInformation, setBasicInformation] = useState<
    | (Pick<
        GetRequestDetailsResponse,
        | 'zavodniBroj'
        | 'datumPodnosenja'
        | 'status'
        | 'podnosilacNaziv'
        | 'zahtevGrupa'
        | 'zahtevPodgrupa'
        | 'zahtevKategorijaPodgrupe'
        | 'zahtevNaziv'
        | 'interniStatusZahteva'
        | 'zahtevId'
      > & {
        resenjeBrojResenja: ResenjeBrojResenja;
        resenjeOdluka: ResenjeOdluka;
      })
    | null
  >(null);
  const [requestDecisionReadOnly, setRequestDecisionReadOnly] =
    useState<ResenjeProps | null>(null);
  const [requestDecision, setRequestDecision] = useState<Pick<
    ResenjeProps,
    'zavodniBrojResenja' | 'datumUrucenjaResenja'
  > | null>(null);
  const [decisionStatus, setDecisionStatus] = useState<
    | (Pick<
        GetRequestDetailsResponse,
        'akcije' | 'interniStatusZahteva' | 'statusRaspodele' | 'showOdluka'
      > & { resenjeId: ResenjeId })
    | null
  >(null);
  const [processingHistory, setProcessingHistory] = useState<
    ObradaIstorijaProps[]
  >([]);
  const navigate = useNavigate();

  const getRequestDetails = useCallback(() => {
    setLoading(true);
    requestsService
      .getRequestDetails(+zahtevId!)
      .then(async (res: GetRequestDetailsResponse) => {
        const mapped = mapRequestDetailsResponseForForm(res);
        setZahtevTipId(res!.zahtevTipId as number);
        setRequestDetails(mapped);
        if (mapped.dokumentaAtributi) {
          requestsStore.setDocumentUploadRes(mapped.dokumentaAtributi);
        }
        setBasicInformation({
          zavodniBroj: res.zavodniBroj,
          datumPodnosenja: res.datumPodnosenja,
          status: res.status,
          podnosilacNaziv: res.podnosilacNaziv,
          zahtevGrupa: res.zahtevGrupa,
          zahtevPodgrupa: res.zahtevPodgrupa,
          zahtevKategorijaPodgrupe: res.zahtevKategorijaPodgrupe,
          zahtevNaziv: res.zahtevNaziv,
          resenjeBrojResenja: res.resenje?.zavodniBrojResenja,
          resenjeOdluka: res.resenje?.odluka,
          interniStatusZahteva: res.interniStatusZahteva,
          zahtevId: res.zahtevId,
        });
        setRequestDecisionReadOnly(res.resenje),
          setRequestDecision({
            zavodniBrojResenja: res.resenje?.zavodniBrojResenja || null,
            datumUrucenjaResenja: res.resenje?.datumUrucenjaResenja || null,
          });
        setDecisionStatus({
          akcije: res.akcije,
          interniStatusZahteva: res.interniStatusZahteva,
          statusRaspodele: res.statusRaspodele,
          resenjeId: res.resenje?.id,
          showOdluka: res.showOdluka,
        });
        setProcessingHistory(res.obradaIstorija ?? []);
        await requestsService.loadConfig(res.zahtevTipId ?? undefined);
      })
      .catch((err) => {
        console.error('Error get request details ', err);
        notification.error(err);
      })
      .finally(() => {
        modalStore.setDecision(null);
        setLoading(false);
      });
  }, [zahtevId]);

  useEffect(() => {
    if (authStore.currentUser && authStore.isUserPS) {
      navigate(ROUTES.SUBMITTED_REQUESTS);
    }
  }, [authStore.selectedBusinessEntity?.id]);

  useEffect(() => {
    getRequestDetails();
  }, [getRequestDetails]);

  useEffect(() => {
    return () => {
      requestsStore.setDocumentUploadRes(undefined);
      console.log('Component is being destroyed');
    };
  }, []);

  // Prikazivanje Status raspodele/Odluke/Status odobravanja u zavisnosti u kojem je statusu proces
  const renderStatusFlow = useMemo(() => {
    switch (decisionStatus?.interniStatusZahteva) {
      case StatusOdlukaEnum.ODOBRENJE: {
        return (
          <RequestDecisionStatus
            decisionStatus={decisionStatus}
            title='status_odobravanja'
            type={StatusOdlukaEnum.ODOBRENJE}
          />
        );
      }
      case StatusOdlukaEnum.POTPIS: {
        return (
          <Guard requiredRoles={[MRE_ROLES.OVLASCENO_LICE_ZA_POTPISIVANJE]}>
            <RequestDecisionStatus
              decisionStatus={decisionStatus}
              title='status_odobravanja'
              type={StatusOdlukaEnum.POTPIS}
            />
          </Guard>
        );
      }
      // Postoji zbog pregleda statusa i nakon donosenja akta
      case StatusOdlukaEnum.OBRADJEN:
      default: {
        return <></>;
      }
    }
  }, [decisionStatus, requestDecision]);

  return (
    <div className={style.requestViewContainerMRE}>
      {loading ? (
        <Loading size={'large'} />
      ) : (
        <>
          <RequestBasicInformation basicInformation={basicInformation} />
          {renderStatusFlow}
          {decisionStatus?.akcije?.length ? (
            <RequestDecisionStatus
              decisionStatus={decisionStatus}
              title='status_raspodele'
              type={StatusOdlukaEnum.RASPODELA}
            />
          ) : null}
          {decisionStatus?.showOdluka &&
            decisionStatus?.interniStatusZahteva ===
              StatusOdlukaEnum.OBRADA && (
              <RequestDecision
                requestDecision={requestDecision}
                handleSuccess={getRequestDetails}
              />
            )}
          {decisionStatus?.interniStatusZahteva !== OdlukeEnum.ODOBRAVANJE &&
            decisionStatus?.interniStatusZahteva !== OdlukeEnum.DOPUNA && (
              <RequestDecisionReadOnly
                requestDecisionReadOnly={requestDecisionReadOnly}
              />
            )}
          {requestDetails && zahtevTipId && (
            <RequestFormDataView
              data={requestDetails}
              zahtevTipId={zahtevTipId}
              supplement={
                modalStore.decision === OdlukeEnum.DOPUNA ||
                requestDetails?.status === ZahteviEnum.DOPUNA_ZAHTEVA
              }
            />
          )}
          <RequestProcessingHistory processingHistory={processingHistory} />
        </>
      )}
    </div>
  );
});

export default RequestViewMRE;
