import {
  IConfigAkcijeRaspodelaResponse,
  IZahteviSacuvajAtributeOdlukeResponse,
  IZahteviSviZahteviResponse,
} from '../modules/submittedRequests/submittedRequests.types.ts';
import {
  PrivredniSubjekt,
  RoleConfig,
  UserData,
} from '../modules/auth/auth.types.ts';
import {
  APRDetailsResponse,
  IPrivredniSubjektDetaljiResponse,
  IPrivredniSubjektOstaloResponse,
  IPrivredniSubjektResponse,
  IZaposleni,
  KorisnikListItem,
  IPrivredniSubjektiOstaloDetaljiResponse,
} from '../modules/settings/settings.types.ts';
import { MRE_ROLES, PS_ROLES } from '@/modules/auth/auth.constants.ts';
import {
  GetRequestDetailsResponse,
  RequestsConfig,
} from '@/modules/requests/requests.types.ts';
import { IResenjaResponse } from '@/modules/acts/acts.types.ts';

export const MOCK_PR_SUB1_DETAILS: IPrivredniSubjektDetaljiResponse = {
  id: 1,
  naziv: 'Andrej DOO',
  pib: '011223344',
  maticniBroj: '333111222',
  jbkjs: '',
  tipSubjekta: 1,
  tekuciRacuni: ['111-232323223-555', '333-33214133-666'],
  ulica: 'Sarajevska 22',
  mesto: 'Beograd',
  telefoni: ['011/222-333', '022/777-111'],
  vebSajt: ['testsite.com'],
  emailAdrese: ['test@mail.com', 'test2@mail.com'],
  emailNotifikacije: '',
  iskljuciEmailNotifikacije: true,
};

export const MOCK_PR_SUB2_DETAILS: IPrivredniSubjektDetaljiResponse = {
  id: 2,
  naziv: 'Metalika DOO',
  pib: '876554433',
  maticniBroj: '779911233',
  jbkjs: '',
  tipSubjekta: 1,
  tekuciRacuni: ['555-655422344-222', '999-72245453-111'],
  ulica: 'Bulevar Mihajla Pupina 122',
  mesto: 'Beograd',
  telefoni: ['011/878-233', '022/544-311'],
  vebSajt: ['testsitemetalika.com'],
  emailAdrese: ['test@mail.com', 'testmetalika2@mail.com'],
  emailNotifikacije: '',
  iskljuciEmailNotifikacije: false,
};

export const MOCK_PR_SUB_DETAILS_ALL = [
  MOCK_PR_SUB1_DETAILS,
  MOCK_PR_SUB2_DETAILS,
];

export const MOCK_PR_SUB1: PrivredniSubjekt = {
  id: 1,
  naziv: 'Andrej DOO',
  pib: '011223344',
  roles: [
    {
      roleId: PS_ROLES.PRIVREDNI_SUBJEKT,
      roleName: 'PRIVREDNISUBJEKT',
    },
  ],
  odeljenje: null,
};

export const MOCK_PR_SUB2: PrivredniSubjekt = {
  id: 2,
  naziv: 'Metalika DOO',
  pib: '666333999',
  roles: [
    {
      roleId: MRE_ROLES.PREGLEDAC_ZAHTEVA,
      roleName: 'MREMINISTAR',
    },
  ],
  odeljenje: 'Jako odeljenje',
};

export const MOCK_ZAPOSLENI1: IZaposleni = {
  userId: '1',
  ime: 'Andrej',
  prezime: 'Mazic',
  aktivno: true,
  email: 'andrej@mail.com',
  roles: [
    {
      roleId: 1,
      roleName: 'Admin',
      datumVazenjaDo: '2024-08-10T15:35:21.607',
    },
  ],
  odeljenjeId: 6,
};

export const MOCK_USER1: UserData = {
  ime: 'Andrej',
  prezime: 'Mazic',
  emailAddress: 'test@gmail.com',
  privredniSubjekti: [MOCK_PR_SUB1] as PrivredniSubjekt[],
  lastLogin: 'Test',
};

export const MOCK_USER2: UserData = {
  ime: 'Fred',
  prezime: 'Kremenko',
  emailAddress: 'fred.kremenko@gmail.com',
  privredniSubjekti: [MOCK_PR_SUB2] as PrivredniSubjekt[],
  lastLogin: '',
};

export const MOCK_USERS: UserData[] = [MOCK_USER1, MOCK_USER2];

export const MOCK_PR_SUB_ALL = [MOCK_PR_SUB1, MOCK_PR_SUB2];

export const requests: IZahteviSviZahteviResponse[] = [
  {
    zahtevId: 9,
    zahtevTipId: 1,
    nazivZahteva: 'test1',
    status: 3,
    statusRaspodele: 3,
    datumPodnosenja: '2024-06-27T11:06:10.945Z',
    zavodniBroj: '000000001 2024',
    grupaZahteva:
      'Podzemne vode, geotermalni resuri i inženjersko-geotehnička istraživanja',
    podgrupaZahteva: 'Istrazivanje',
    tipMineralnihSirovina: '/',
    maticniBrojPrivrednogSubjekta: '12345678',
    nazivPrivrednogSubjekta: 'Najjaca firmetina',
    nazivOdeljenja: 'Najjaca firmetina',
    obradjivac: '-',
    akcije: [
      { value: 1, label: 'Vratite' },
      { value: 2, label: 'Prosledite' },
      { value: 3, label: 'Dodelite' },
      { value: 4, label: 'Preuzmite' },
    ],
  },
  {
    zahtevId: 10,
    zahtevTipId: 1,
    nazivZahteva: 'test2',
    status: 4,
    statusRaspodele: 1,
    datumPodnosenja: '2024-06-27T11:06:10.945Z',
    zavodniBroj: '000000001 2024',
    grupaZahteva:
      'Podzemne vode, geotermalni resuri i inženjersko-geotehnička istraživanja',
    podgrupaZahteva: 'Istrazivanje',
    tipMineralnihSirovina: '/',
    maticniBrojPrivrednogSubjekta: '12345678',
    nazivPrivrednogSubjekta: 'Najjaca firmetina',
    nazivOdeljenja: 'Najjaca firmetina',
    obradjivac: '-',
    akcije: [{ value: 4, label: 'Preuzmite' }],
  },
  {
    zahtevId: 182,
    zahtevTipId: 1,
    nazivZahteva: '182 Mock',
    status: 4,
    statusRaspodele: 1,
    datumPodnosenja: '2024-08-27T15:06:10.523Z',
    zavodniBroj: '000000001 2024',
    grupaZahteva:
      'Podzemne vode, geotermalni resuri i inženjersko-geotehnička istraživanja',
    podgrupaZahteva: 'Istrazivanje',
    tipMineralnihSirovina: '/',
    maticniBrojPrivrednogSubjekta: '12345678',
    nazivPrivrednogSubjekta: 'Najjaca firmetina',
    nazivOdeljenja: 'Najjaca firmetina',
    obradjivac: '-',
    akcije: [{ value: 4, label: 'Preuzmite' }],
  },
  {
    zahtevId: 183,
    zahtevTipId: 1,
    nazivZahteva: '183 Mock',
    status: 1,
    statusRaspodele: 1,
    datumPodnosenja: '2024-06-27T11:06:10.945Z',
    zavodniBroj: '000000001 2024',
    grupaZahteva:
      'Podzemne vode, geotermalni resuri i inženjersko-geotehnička istraživanja',
    podgrupaZahteva: 'Istrazivanje',
    tipMineralnihSirovina: '/',
    maticniBrojPrivrednogSubjekta: '12345678',
    nazivPrivrednogSubjekta: 'Najjaca firmetina',
    nazivOdeljenja: 'Najjaca firmetina',
    obradjivac: '-',
    akcije: [{ value: 4, label: 'Preuzmite' }],
  },
];

export const REQUEST_DETAILS_MOCK: GetRequestDetailsResponse = {
  zahtevId: 164,
  zahtevTipId: 72,
  referentniZahtevId: null,
  zavodniBroj: '00034017020241482000700531010301001',
  zahtevNaziv:
    'Zahtev za dobijanje odobrenje za eksploataciju prirodnih građevinskih materijala',
  zahtevGrupa: 'Eksploatacija mineralnih sirovina',
  zahtevPodgrupa:
    'Eksploatacija nemetaličnih mineralnih sirovina za građevinske materijale i prirodne građevinske materijale',
  zahtevKategorijaPodgrupe: null,
  datumPodnosenja: '2024-09-23T11:50:32.64Z',
  podnosilacNaziv: 'Metalication',
  podnosilacMaticniBroj: '98765433',
  zahtevAtributi: {
    opstinaId: [72],
    resenjeId: null,
    brojResenja: null,
    obrazlozenje: 'asd',
    visinaAdministrativneTakse: null,
    nazivProjekta: null,
    datumPodnosenjaZahteva: null,
    brojZahteva: null,
    godinaIzrade: 2023,
    nazivZavrsnogIzvestajaElaborata: null,
  },
  podzemneVodeAtributi: null,
  istrazivanjeAtributi: null,
  eksploatacijaMineralnihAtributi: {
    nazivRudarskogProjekta: 'asd',
    nazivRudarskogProjektaIzgradjenRudarskiObjekat: null,
    nazivLezista: 'asd',
    nazivStudijeIzvodljivosti: null,
    brojEksploatacionogPolja: null,
    godisnjiKapacitet: 123,
    vrstaMineralnihSirovinaId: [4],
    datumPocetkaProbnogRadaRudarskogObjekta: null,
    datumZavrsetkaProbnogRadaRudarskogObjekta: null,
    katastarskiBrojIstraznogProstora: null,
    glavniProjektantImePrezime: null,
    odgovorniProjektantImePrezime: null,
  },
  preduzeca: [
    {
      maticniBroj: '20667133',
      punNaziv: 'ENETEL SOLUTIONS',
      adresa: 'Трише Кацлеровића 27 Л',
      pib: '106720355',
      mestoId: 332,
      telefon: '+381 11 3693787',
      email: '22@22.com',
      webSite: '',
      brojPoslovnogRacuna: '123',
      imePrezimeOdgovornogLica: 'asd',
      preduzeceTip: 1,
      vrstaPreduzece: 1,
    },
  ],
  dokumentaAtributi: [
    {
      documentId: 1252,
      requestDocumentId: 333,
      opstinaId: null,
      mestoId: null,
      broj: null,
      brojParcele: null,
      datum: null,
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    },
    {
      documentId: 1253,
      requestDocumentId: 338,
      opstinaId: null,
      mestoId: null,
      broj: null,
      brojParcele: null,
      datum: null,
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    },
    {
      documentId: 1254,
      requestDocumentId: 336,
      opstinaId: 111,
      mestoId: 4496,
      broj: null,
      brojParcele: '123',
      datum: null,
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    },
    {
      documentId: 1255,
      requestDocumentId: 335,
      opstinaId: null,
      mestoId: null,
      broj: null,
      brojParcele: null,
      datum: null,
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    },
    {
      documentId: 1256,
      requestDocumentId: 337,
      opstinaId: null,
      mestoId: null,
      broj: '123',
      brojParcele: null,
      datum: '2024-09-22T20:00:00Z',
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    },
    {
      documentId: 1257,
      requestDocumentId: 334,
      opstinaId: null,
      mestoId: null,
      broj: null,
      brojParcele: null,
      datum: null,
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    },
    {
      documentId: 1258,
      requestDocumentId: 335,
      opstinaId: null,
      mestoId: null,
      broj: null,
      brojParcele: null,
      datum: null,
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    },
    {
      documentId: 1259,
      requestDocumentId: 334,
      opstinaId: null,
      mestoId: null,
      broj: null,
      brojParcele: null,
      datum: null,
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    },
    {
      documentId: 1260,
      requestDocumentId: 338,
      opstinaId: null,
      mestoId: null,
      broj: null,
      brojParcele: null,
      datum: null,
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    },
    {
      documentId: 1261,
      requestDocumentId: 337,
      opstinaId: null,
      mestoId: null,
      broj: '123',
      brojParcele: null,
      datum: '2024-09-22T20:00:00Z',
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    },
    {
      documentId: 1262,
      requestDocumentId: 336,
      opstinaId: 111,
      mestoId: 4496,
      broj: null,
      brojParcele: '123',
      datum: null,
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    },
    {
      documentId: 1263,
      requestDocumentId: 453,
      opstinaId: null,
      mestoId: null,
      broj: null,
      brojParcele: null,
      datum: null,
      naziv: null,
      fileName: 'front mock xml[signed].xml',
    },
  ],
  prostorObavezni: {
    nazivLokaliteta: null,
    koordinate: [],
  },
  prostorOpcioni: {
    nazivLokaliteta: null,
    koordinate: [],
  },
  obradaIstorija: [
    {
      inicijatorIme: 'Никола',
      inicijatorPrezime: 'Новаковић',
      akcija: 'Kreiran',
      zahtevStatus: 'Podnet',
      vremeAkcije: '2024-09-23T11:50:32.6393586Z',
      opis: 'Vrsta akcije: Kreiran | Inicijator: Никола Новаковић | Zahtev je u status: Podnet',
    },
    {
      inicijatorIme: 'Никола',
      inicijatorPrezime: 'Новаковић',
      akcija: 'Dodela',
      zahtevStatus: 'Podnet',
      vremeAkcije: '2024-09-25T10:03:57.8767293Z',
      opis: 'Vrsta akcije: Dodela | Inicijator: Никола Новаковић | Zahtev je u status: Podnet',
    },
    {
      inicijatorIme: 'Никола',
      inicijatorPrezime: 'Новаковић',
      akcija: 'Preuzeo',
      zahtevStatus: 'U obradi',
      vremeAkcije: '2024-09-25T10:04:22.0962221Z',
      opis: 'Vrsta akcije: Preuzeo | Inicijator: Никола Новаковић | Zahtev je u status: U obradi',
    },
  ],
  akcije: [
    {
      label: 'Dodelite',
      value: 3,
    },
    {
      label: 'Preuzmite',
      value: 4,
    },
    {
      label: 'Vratite',
      value: 1,
    },
    {
      label: 'Prosledite',
      value: 2,
    },
  ],
  resenje: null,
  resenja: [],
  interniStatusZahteva: 5,
  status: 6,
  showOdluka: true,
  statusRaspodele: 2,
};

// export const REQUEST_DETAILS_MOCK: GetRequestDetailsResponse = {
//   zahtevId: 127,
//   zahtevTipId: 1,
//   statusRaspodele: 2,
//   referentniZahtevId: 14,
//   zavodniBroj: '00033859220241482000700431102901001',
//   zahtevNaziv:
//     'Захтев за добијање одобрења за примењена геолошка истраживања металичних минералних сировина',
//   zahtevGrupa: 'Истраживање минералних сировина',
//   zahtevPodgrupa: 'Истраживање',
//   zahtevKategorijaPodgrupe: 'Металичне минералне сировине',
//   datumPodnosenja: '2024-09-06T07:20:38.527Z',
//   podnosilacNaziv: 'IMPERIAL TOBACCO SCG',
//   podnosilacMaticniBroj: '17419323',
//   zahtevAtributi: {
//     opstinaId: [71, 72],
//     resenjeId: null,
//     brojResenja: null,
//     obrazlozenje: 'Образложење',
//     visinaAdministrativneTakse: 1200,
//     nazivProjekta: null,
//     datumPodnosenjaZahteva: null,
//     brojZahteva: null,
//     godinaIzrade: 2020,
//     nazivZavrsnogIzvestajaElaborata: null,
//   },
//   podzemneVodeAtributi: null,
//   istrazivanjeAtributi: {
//     vrstaMineralneSirovineOpisno: null,
//     katastarskiBrojParcele: null,
//     brojProduzenja: null,
//     brojOdobrenogIstraznogPolja: null,
//     rokVazenjaOdobrenja: null,
//     brojIstraznogProstora: null,
//     datumPrijemaResenja: null,
//     datumPocetkaProbnogRadaBusotine: null,
//     datumZavrsetkaProbnogRadaBusotine: null,
//     datumPocetkaIzvrsenjaGeoloskogIstrazivanja: null,
//     datumZavrsetkaIzvrsenjaGeoloskogIstrazivanja: null,
//     autoriElaborata: null,
//     vrstaGeoloskogIstrazivanjaId: [4],
//     vrstaMineralnihSirovinaId: [45, 46, 47],
//     nazivProjektaPrimenjenihGeoloskihIstrazivanja:
//       'Захтев за добијање одобрења за примењена геолошка истраживања минералних сировина',
//     liceVrsilacTehnickeKontrole: 'Арса Арсић',
//     liceVrsilacNadzora: 'Лука Лукић',
//     nazivElaborata: null,
//     nazivProgramaAktivnosti: null,
//     glavniProjektantImePrezime: 'Васа Васић',
//     odgovorniProjektantImePrezime: 'Петар Перић',
//     vremeTrajanjaGeoloskogIstrazivanja: 12,
//   },
//   eksploatacijaMineralnihAtributi: null,
//   preduzeca: [
//     {
//       maticniBroj: '20544619',
//       punNaziv: 'HIDROGEOEKO INŽENJERING',
//       adresa: 'Ваљевска 8',
//       pib: '106156149',
//       mestoId: 480,
//       telefon: '+381 11 7544290',
//       email: 'test@test.rs',
//       webSite: 'www.hidrogeoeko.rs',
//       brojPoslovnogRacuna: '123-234567-12',
//       imePrezimeOdgovornogLica: 'Петар Перић',
//       preduzeceTip: 1,
//       vrstaPreduzece: 1,
//     },
//     {
//       maticniBroj: '20544619',
//       punNaziv: 'HIDROGEOEKO INŽENJERING',
//       adresa: 'Ваљевска 8',
//       pib: '106156149',
//       mestoId: 480,
//       telefon: '+381 11 7544290',
//       email: 'test@test.rs',
//       webSite: 'www.hidrogeoeko.rs',
//       brojPoslovnogRacuna: '123-234567-12',
//       imePrezimeOdgovornogLica: 'Нина Нинић',
//       preduzeceTip: 6,
//       vrstaPreduzece: 1,
//     },
//     {
//       maticniBroj: '20544619',
//       punNaziv: 'HIDROGEOEKO INŽENJERING',
//       adresa: 'Ваљевска 8',
//       pib: '106156149',
//       mestoId: 480,
//       telefon: '+381 11 7544290',
//       email: 'test@test.rs',
//       webSite: 'www.hidrogeoeko.rs',
//       brojPoslovnogRacuna: '123-234567-12',
//       imePrezimeOdgovornogLica: 'Maрко Марковић',
//       preduzeceTip: 3,
//       vrstaPreduzece: 1,
//     },
//   ],
//   dokumentaAtributi: [
//     {
//       documentId: 1053,
//       requestDocumentId: 1,
//       opstinaId: null,
//       mestoId: null,
//       broj: null,
//       brojParcele: null,
//       datum: null,
//       naziv: null,
//       fileName:
//         'Топографска карта у одговарајућој размери, са уцртаном границом и координатама истражног простора.pdf',
//     },
//     {
//       documentId: 1054,
//       requestDocumentId: 2,
//       opstinaId: null,
//       mestoId: null,
//       broj: null,
//       brojParcele: null,
//       datum: null,
//       naziv: null,
//       fileName: 'Доказ о плаћеној републичкој такси.pdf',
//     },
//     {
//       documentId: 1055,
//       requestDocumentId: 111,
//       opstinaId: null,
//       mestoId: null,
//       broj: null,
//       brojParcele: null,
//       datum: null,
//       naziv: null,
//       fileName:
//         'Писмо о намерама банке или друштва из групације у оквиру које послује подносилац захтева.pdf',
//     },
//     {
//       documentId: 1056,
//       requestDocumentId: 115,
//       opstinaId: null,
//       mestoId: null,
//       broj: null,
//       brojParcele: null,
//       datum: '2024-08-14T20:00:00Z',
//       naziv: null,
//       fileName:
//         'Доказ о праву коришћења података и резултата истраживања који су резултат геолошких истраживања другог привредног субјекта.pdf',
//     },
//     {
//       documentId: 1057,
//       requestDocumentId: 118,
//       opstinaId: null,
//       mestoId: null,
//       broj: '234/2022',
//       brojParcele: null,
//       datum: '2024-09-04T20:00:00Z',
//       naziv: null,
//       fileName: 'Акт о условима завода за заштиту природе Србије.pdf',
//     },
//     {
//       documentId: 1058,
//       requestDocumentId: 121,
//       opstinaId: null,
//       mestoId: null,
//       broj: '4-2024',
//       brojParcele: null,
//       datum: '2024-08-11T20:00:00Z',
//       naziv: null,
//       fileName:
//         'Мишљење о испуњености услова завода за заштиту природе Србије Министарства заштите животне средине.pdf',
//     },
//     {
//       documentId: 1059,
//       requestDocumentId: 127,
//       opstinaId: null,
//       mestoId: null,
//       broj: null,
//       brojParcele: null,
//       datum: null,
//       naziv: null,
//       fileName: 'Уверење о положеном стручном испиту.pdf',
//     },
//     {
//       documentId: 1060,
//       requestDocumentId: 130,
//       opstinaId: null,
//       mestoId: null,
//       broj: null,
//       brojParcele: null,
//       datum: null,
//       naziv: null,
//       fileName: 'Потврда о запослењу и именовању за главног пројектанта.pdf',
//     },
//     {
//       documentId: 1061,
//       requestDocumentId: 133,
//       opstinaId: null,
//       mestoId: null,
//       broj: null,
//       brojParcele: null,
//       datum: null,
//       naziv: null,
//       fileName: 'Доказ о испуњености услова из члана 22. Закона.pdf',
//     },
//     {
//       documentId: 1062,
//       requestDocumentId: 136,
//       opstinaId: null,
//       mestoId: null,
//       broj: null,
//       brojParcele: null,
//       datum: null,
//       naziv: null,
//       fileName: 'Остало.pdf',
//     },
//     {
//       documentId: 1063,
//       requestDocumentId: 108,
//       opstinaId: null,
//       mestoId: null,
//       broj: '324/2024',
//       brojParcele: null,
//       datum: '2024-08-05T20:00:00Z',
//       naziv: null,
//       fileName:
//         'Извештај и потврда о извршеној техничкој контроли пројекта.pdf',
//     },
//     {
//       documentId: 1064,
//       requestDocumentId: 105,
//       opstinaId: null,
//       mestoId: null,
//       broj: '1-2024',
//       brojParcele: null,
//       datum: '2024-07-30T20:00:00Z',
//       naziv: null,
//       fileName: 'Пројекат геолошких истраживања минералних сировина.pdf',
//     },
//     {
//       documentId: 1065,
//       requestDocumentId: 124,
//       opstinaId: null,
//       mestoId: null,
//       broj: '4/2022',
//       brojParcele: null,
//       datum: '2024-08-15T20:00:00Z',
//       naziv: 'Акт о условима завода',
//       fileName: 'Акт о условима завода за заштиту споменика културе.pdf',
//     },
//     {
//       documentId: 1066,
//       requestDocumentId: 402,
//       opstinaId: null,
//       mestoId: null,
//       broj: null,
//       brojParcele: null,
//       datum: null,
//       naziv: null,
//       fileName: '127_2024_09_06_09_18_38[signed].xml',
//     },
//   ],
//   prostorObavezni: {
//     nazivLokaliteta: 'Локалитет 1',
//     koordinate: [
//       {
//         koordinataX: 4630000,
//         koordinataY: 7670000,
//       },
//       {
//         koordinataX: 4630002,
//         koordinataY: 7570000,
//       },
//       {
//         koordinataX: 4630001,
//         koordinataY: 7470000,
//       },
//     ],
//   },
//   prostorOpcioni: {
//     nazivLokaliteta: null,
//     koordinate: [],
//   },
//   obradaIstorija: [
//     {
//       inicijatorIme: 'ИВАНА',
//       inicijatorPrezime: 'ГОЛУБОВИЋ',
//       akcija: 1,
//       zahtevStatus: 'Поднет',
//       vremeAkcije: '2024-09-06T07:27:13.9884034Z',
//       opis: 'Врста акције: Додела | Иницијатор: ИВАНА ГОЛУБОВИЋ | Захтев је у статусу: Поднет',
//     },
//     {
//       inicijatorIme: 'ИВАНА',
//       inicijatorPrezime: 'ГОЛУБОВИЋ',
//       akcija: 1,
//       zahtevStatus: 'Поднет',
//       vremeAkcije: '2024-09-06T07:27:44.4674001Z',
//       opis: 'Врста акције: Додела | Иницијатор: ИВАНА ГОЛУБОВИЋ | Захтев је у статусу: Поднет',
//     },
//   ],
//   akcije: [
//     {
//       label: 'Доделите',
//       value: 3,
//     },
//     {
//       label: 'Преузмите',
//       value: 4,
//     },
//     {
//       label: 'Вратите',
//       value: 1,
//     },
//   ],
//   resenje: {
//     id: 1,
//     resenjeDokumentId: 1,
//     fileName: 'file.pdf',
//     odluka: 1,
//     zavodniBrojResenja: '1',
//     brojIstraznogProstora: '1',
//     povrsinaIstraznogProstora: 1,
//     duzinaIstraznogRoka: 1,
//     duzinaRokaProduzenja: 1,
//     duzinaRokaZadrzavanjaPrava: 1,
//     datumStanjaRezervi: '1',
//     obrazlozenje: '1',
//     razlogOdbacivanja: '1',
//     clanZakona: '1',
//     datumUrucenjaResenja: '1',
//     rokZaDopunu: 1,
//     razlogPrekida: '1',
//     izborKomeDostavitiResenje: '1',
//     obradjeno: true,
//     resenjaPrilozi: [
//       {
//         label: 'nesto',
//         value: 3,
//       },
//       {
//         label: 'nesto1',
//         value: 4,
//       },
//       {
//         label: 'nesto2',
//         value: 1,
//       },
//     ],
//   },
//   resenja: [
//     {
//       id: null,
//       brojResenja: '333333',
//       tipResenja: 6,
//     },
//     {
//       id: null,
//       brojResenja: '666666',
//       tipResenja: 4,
//     },
//   ],
//   interniStatusZahteva: 1,
//   status: 2,
//   showOdluka: true,
// };

export const MOCK_ROLE_CONFIG_PS: RoleConfig = {
  roles: [
    {
      roleId: 1,
      roleName: 'Администратор привредног субјекта',
    },
    {
      roleId: 2,
      roleName: 'Подносилац захтева у име привредног субјекта',
    },
    {
      roleId: 3,
      roleName: 'Прегледач захтева/решења у име привредног субјекта',
    },
  ],
  odeljenja: [
    {
      odeljenjeId: 4,
      odeljenjeName: 'Одсек за рударство',
    },
    {
      odeljenjeId: 5,
      odeljenjeName: 'Одсек за геолошка истраживања минералних сировина',
    },
    {
      odeljenjeId: 6,
      odeljenjeName:
        'Група за подземне воде, геотермалне ресурсе и инжењерско-геолошка истраживања',
    },
  ],
};

export const MOCK_USERS_LIST_ITEMS: KorisnikListItem[] = [
  {
    editEmailOnly: false,
    id: '1',
    korisnik: 'Andrej Mazic',
    status: 'Aktiva',
    role: 'Admin, Test',
    odeljenje: 'Odeljenje',
    // odeljenje: null,
    email: 'andrej@mail.com',
  },
];

export const VRSTE_GEOLOSKIH_ISTRAZIVANJA = {
  1: [
    {
      label: 'Инжењерскогеолошка-геотехничка истраживања',
      value: 1,
    },
    {
      label: 'Истраживања подземних вода',
      value: 2,
    },
    {
      label: 'Истраживања петрогеотермалних ресурса',
      value: 3,
    },
  ],
  2: [
    {
      label: 'Истраживања металичних минералних сировина',
      value: 4,
    },
    {
      label: 'Истраживања неметаличних минералних сировина',
      value: 5,
    },
    {
      label: 'Истраживања енергетских минералних сировина',
      value: 6,
    },
    {
      label: 'Истраживања природних грађевинских материјала',
      value: 7,
    },
  ],
  3: [],
};

export const VRSTE_PRIVREDNOG_SUBJEKTA = [
  {
    label: 'Привредни субјект',
    value: 1,
  },
  {
    label: 'Предузетник',
    value: 2,
  },
  {
    label: 'Јавна установа',
    value: 3,
  },
  {
    label: 'Остало',
    value: 4,
  },
];

export const REQUESTS_CONFIG_MOCK: RequestsConfig = {
  kategorijeZahteva: [
    {
      id: 1,
      ime: 'Подземне воде, геотермални ресурси и инжењерско-геотехничка истраживања',
      podgrupe: [
        {
          id: 1,
          ime: 'Истраживања',
          zahtevi: [
            {
              id: 1,
              ime: 'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
              tip: 1,
            },
            {
              id: 2,
              ime: 'Захтев за добијање одобрења за примењена геолошка истраживања подземних вода и/или петрогеотермалних ресурса',
              tip: 1,
            },
            {
              id: 3,
              ime: 'Захтев за добијање одобрења за продужење рока одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
              tip: 2,
            },
            {
              id: 4,
              ime: 'Захтев за добијање одобрења за продужење рока одобрења за примењена геолошка истраживања подземних вода и/или петрогеотермалних ресурса',
              tip: 2,
            },
            {
              id: 5,
              ime: 'Захтев за добијање одобрења за задржавање права на истражни простор за подземне воде и геотермалне ресурсе',
              tip: 2,
            },
            {
              id: 6,
              ime: 'Захтев за пренос одобрења из области вршења примењених  геолошких истраживања',
              tip: 2,
            },
            {
              id: 7,
              ime: 'Захтев за пренос одобрења за задржавање права на истражни простор за подземне воде и геотермалне ресурсе',
              tip: 2,
            },
            {
              id: 8,
              ime: 'Пријава радова шодно решењу о одобрењу за  примењена  геолошка истраживања',
              tip: 2,
            },
            {
              id: 9,
              ime: 'Захтев за измену и допуну истраживања по анексу пројекта',
              tip: 2,
            },
            {
              id: 10,
              ime: 'Захтев за престанак важења решења о одобравању',
              tip: 2,
            },
            {
              id: 11,
              ime: 'Захтев за одустајање од захтева',
              tip: 2,
            },
          ],
          kategorijePodgrupa: [],
        },
        {
          id: 2,
          ime: 'Овера резерви и ресурса',
          zahtevi: [
            {
              id: 12,
              ime: 'Захтев за утврђивање и оверу разврстаних резерви и ресурса подземних вода и/или петрогеотермалних ресурса',
              tip: 2,
            },
            {
              id: 13,
              ime: 'Захтев за пренос потврде о резервама подземних вода и/или петрогеотермалних ресурса',
              tip: 2,
            },
          ],
          kategorijePodgrupa: [],
        },
        {
          id: 3,
          ime: 'Експлоатациони простор',
          zahtevi: [
            {
              id: 14,
              ime: 'Захтев за добијање одобрења којим се утврђује експлоатациони простор и количине резерви подземних вода и/или петрогеотермалних ресурса',
              tip: 2,
            },
            {
              id: 15,
              ime: 'Захтев за одобрење за продужење рока одобрења којим се утврђује експлоатациони простор и количине резерви подземних вода или геотермалних ресурса',
              tip: 2,
            },
            {
              id: 16,
              ime: 'Захтев за пренос одобрења којим се утврђује експлоатациони простор и количине резерви подземних вода или геотермалних ресурса',
              tip: 2,
            },
          ],
          kategorijePodgrupa: [],
        },
        {
          id: 4,
          ime: 'Достављање извештаја и биланса',
          zahtevi: [
            {
              id: 17,
              ime: 'Извештај о стању билансних резерви за подземне воде и хидрогеотермалне ресурсе',
              tip: 2,
            },
            {
              id: 18,
              ime: 'Извештај о стању билансних резерви за петрогеотермалне ресурсе',
              tip: 2,
            },
            {
              id: 19,
              ime: 'Годишњи извештај – геолошка истраживања',
              tip: 2,
            },
            {
              id: 20,
              ime: 'Завршни извештај – геолошка истраживања',
              tip: 2,
            },
          ],
          kategorijePodgrupa: [],
        },
      ],
    },
    {
      id: 2,
      ime: 'Истраживање минералних сировина',
      podgrupe: [
        {
          id: 5,
          ime: 'Истраживање',
          zahtevi: [],
          kategorijePodgrupa: [
            {
              id: 1,
              ime: 'Металичне минералне сировине',
              zahtevi: [
                {
                  id: 21,
                  ime: 'Захтев за добијање одобрења за примењена геолошка истраживања металичних минералних сировина',
                  tip: 1,
                },
                {
                  id: 22,
                  ime: 'Захтев за добијање одобрења за продужење рока одобрења за примењена геолошка истраживања металичних минералних сировина',
                  tip: 2,
                },
                {
                  id: 23,
                  ime: 'Захтев за добијање одобрења за задржавање права на истражни простор за металичне минералне сировине',
                  tip: 2,
                },
                {
                  id: 24,
                  ime: 'Захтев за пренос одобрења из области вршења примењених геолошких истраживања металичних минералних сировина',
                  tip: 2,
                },
                {
                  id: 25,
                  ime: 'Захтев за пренос одобрења за задржавање права из области вршења примењених геолошких истраживања металичних минералних сировина',
                  tip: 2,
                },
                {
                  id: 26,
                  ime: 'Пријава радова сходно решењу о одобрењу за примењена геолошка истраживања металичних минералних сировина',
                  tip: 2,
                },
                {
                  id: 27,
                  ime: 'Захтев за измену и допуну истраживања по анексу пројекта',
                  tip: 2,
                },
                {
                  id: 28,
                  ime: 'Захтев за престанак важења решења о одобравању',
                  tip: 2,
                },
                {
                  id: 29,
                  ime: 'Захтев за одустајање од захтева',
                  tip: 2,
                },
              ],
            },
            {
              id: 2,
              ime: 'Неметаличне минералне сировине',
              zahtevi: [
                {
                  id: 30,
                  ime: 'Захтев за добијање одобрења за примењена геолошка истраживања неметаличних минералних сировина',
                  tip: 1,
                },
                {
                  id: 31,
                  ime: 'Захтев за добијање одобрења за продужење рока одобрења за примењена геолошка истраживања неметаличних минералних сировина',
                  tip: 2,
                },
                {
                  id: 32,
                  ime: 'Захтев за добијање одобрења за задржавање права на истражни простор за неметаличне минералне сировине',
                  tip: 2,
                },
                {
                  id: 33,
                  ime: 'Захтев за пренос одобрења из области вршења примењених геолошких истраживања неметаличних минералних сировина',
                  tip: 2,
                },
                {
                  id: 34,
                  ime: 'Захтев за пренос одобрења за задржавање права из области вршења примењених геолошких истраживања неметаличних минералних сировина',
                  tip: 2,
                },
                {
                  id: 35,
                  ime: 'Пријава радова сходно решењу о одобрењу за  примењена  геолошка истраживања неметаличних минералних сировина',
                  tip: 2,
                },
                {
                  id: 36,
                  ime: 'Захтев за измену и допуну истраживања по анексу пројекта',
                  tip: 2,
                },
                {
                  id: 37,
                  ime: 'Захтев за престанак важења решења о одобравању',
                  tip: 2,
                },
                {
                  id: 38,
                  ime: 'Захтев за одустајање од захтева',
                  tip: 2,
                },
              ],
            },
            {
              id: 3,
              ime: 'Енергетске минералне сировине',
              zahtevi: [
                {
                  id: 39,
                  ime: 'Захтев за добијање одобрења за примењена геолошка истраживања енергетских минералних сировина',
                  tip: 1,
                },
                {
                  id: 40,
                  ime: 'Захтев за добијање одобрења за продужење рока одобрења  за примењена геолошка истраживања енергетских минералних сировина',
                  tip: 2,
                },
                {
                  id: 41,
                  ime: 'Захтев за добијање одобрења за задржавање права на истражни простор за енергетске минералне сировине',
                  tip: 2,
                },
                {
                  id: 42,
                  ime: 'Захтев за пренос одобрења из области вршења  примењених  геолошких истраживања енергетских минералних сировина',
                  tip: 2,
                },
                {
                  id: 43,
                  ime: 'Захтев за пренос одобрења за задржавање права из области вршења  примењених  геолошких истраживања енергетских минералних сировина',
                  tip: 2,
                },
                {
                  id: 44,
                  ime: 'Пријава радова сходно решењу о одобрењу за  примењена  геолошка истраживања енергетских минералних сировина',
                  tip: 2,
                },
                {
                  id: 45,
                  ime: 'Захтев за измену и допуну истраживања по анексу пројекта',
                  tip: 2,
                },
                {
                  id: 46,
                  ime: 'Захтев за престанак важења решења о одобравању',
                  tip: 2,
                },
                {
                  id: 47,
                  ime: 'Захтев за одустајање од захтева',
                  tip: 2,
                },
              ],
            },
            {
              id: 4,
              ime: 'Природни грађевински материјали',
              zahtevi: [
                {
                  id: 48,
                  ime: 'Захтев за добијање одобрења за примењена геолошка истраживања природних грађевинских материјала',
                  tip: 1,
                },
                {
                  id: 49,
                  ime: 'Пријава радова сходно решењу о одобрењу за примењена  геолошка истраживања природних грађевинских материјала',
                  tip: 2,
                },
                {
                  id: 50,
                  ime: 'Захтев за измену и допуну истраживања по анексу пројекта',
                  tip: 2,
                },
                {
                  id: 51,
                  ime: 'Захтев за престанак важења решења о одобравању',
                  tip: 2,
                },
                {
                  id: 52,
                  ime: 'Захтев за одустајање од захтева',
                  tip: 2,
                },
              ],
            },
          ],
        },
        {
          id: 6,
          ime: 'Овера резерви и ресурса',
          zahtevi: [
            {
              id: 53,
              ime: 'Захтев за добијање потврде о резервама минералних сировина',
              tip: 1,
            },
            {
              id: 54,
              ime: 'Захтев за пренос потврде о резервама минералних сировина',
              tip: 2,
            },
          ],
          kategorijePodgrupa: [],
        },
        {
          id: 7,
          ime: 'Достављање извештаја и биланса',
          zahtevi: [
            {
              id: 55,
              ime: 'Извештај о минералним ресурсима за добијање природних грађевинских материјала',
              tip: 2,
            },
            {
              id: 56,
              ime: 'Годишњи извештај – геолошка истраживања',
              tip: 2,
            },
            {
              id: 57,
              ime: 'Завршни извештај – геолошка истраживања',
              tip: 2,
            },
          ],
          kategorijePodgrupa: [],
        },
      ],
    },
    {
      id: 3,
      ime: 'Експлоатација минералних сировина',
      podgrupe: [
        {
          id: 8,
          ime: 'Експлоатационо поље',
          zahtevi: [
            {
              id: 58,
              ime: 'Захтев за добијање одобрења за експлоатационо поље',
              tip: 2,
            },
            {
              id: 59,
              ime: 'Захтев за добијање одобрења за измену граница експлоатационог поља - проширење',
              tip: 2,
            },
            {
              id: 60,
              ime: 'Захтев за добијање одобрења за смањење границе експлоатационог поља',
              tip: 2,
            },
            {
              id: 61,
              ime: 'Захтев за пренос одобрења за експлоатационо поље',
              tip: 2,
            },
            {
              id: 62,
              ime: 'Захтев за добијање одобрења за изградњу рударских објеката и/или за извођење рударских радова',
              tip: 2,
            },
            {
              id: 63,
              ime: 'Захтев за добијање одобрења за употребу рударских објеката- употребна дозвола',
              tip: 2,
            },
            {
              id: 64,
              ime: 'Захтев за добијање одобрења за пуштање у пробни рад рударских објеката',
              tip: 2,
            },
            {
              id: 65,
              ime: 'Захтев за добијање одобрења за продужење рока важења одобрења за извођење рударских радова и/или изградњу рударских објеката',
              tip: 2,
            },
            {
              id: 66,
              ime: 'Захтев за пренос одобрења за извођење рударских радова и/или изградње рударских објеката',
              tip: 2,
            },
          ],
          kategorijePodgrupa: [],
        },
        {
          id: 9,
          ime: 'Експлоатација неметаличних минералних сировина за грађевинске материјале и природне грађевинске материјале',
          zahtevi: [
            {
              id: 67,
              ime: 'Захтев за добијање одобрење за експлоатацију неметаличних минералних сировина за добијање грађевинских материјала',
              tip: 2,
            },
            {
              id: 68,
              ime: 'Захтев за добијање одобрења за продужење рока важења одобрења за експлоатацију неметаличних минералних сировина за грађевинске материјале',
              tip: 2,
            },
            {
              id: 69,
              ime: 'Захтев за пренос одобрења за експлоатацију неметаличних минералних сировина за добијање грађевинских материјала',
              tip: 2,
            },
            {
              id: 70,
              ime: 'Захтев за добијање одобрење за измену граница одобрења за експлоатацију - проширење',
              tip: 2,
            },
            {
              id: 71,
              ime: 'Захтев за добијање одобрење за смањење границе одобрења за експлоатацију',
              tip: 2,
            },
            {
              id: 72,
              ime: 'Захтев за добијање одобрење за експлоатацију природних грађевинских материјала',
              tip: 2,
            },
            {
              id: 73,
              ime: 'Захтев за престанак важења одобрења за експлоатационо поље / експлоатацију на захтев носиоца',
              tip: 2,
            },
            {
              id: 74,
              ime: 'Захтев за престанак важења одобрења за експлоатационо поље / експлоатацију по основу трајне обуставе радова',
              tip: 2,
            },
            {
              id: 75,
              ime: 'Захтев за одустајање од захтева',
              tip: 2,
            },
          ],
          kategorijePodgrupa: [],
        },
        {
          id: 10,
          ime: 'Рударски радови на истраживању  минералних сировина',
          zahtevi: [
            {
              id: 76,
              ime: 'Захтев за добијање одобрења за извођење рударских радова на истраживању минералних сировина',
              tip: 2,
            },
          ],
          kategorijePodgrupa: [],
        },
        {
          id: 11,
          ime: 'Достављање рударских пројеката',
          zahtevi: [
            {
              id: 77,
              ime: 'Достављање техничког рударског пројекта са техничком контролом',
              tip: 2,
            },
            {
              id: 78,
              ime: 'Достављање упрошћеног рударског пројекта',
              tip: 2,
            },
          ],
          kategorijePodgrupa: [],
        },
      ],
    },
  ],
  obradjivaciZahteva: [
    { id: 1, name: 'obradjivac' },
    { id: 2, name: 'obradjivac2' },
    { id: 3, name: 'obradjivac3' },
  ],
  statusRaspodele: [
    { value: 1, label: 'status1' },
    { value: 2, label: 'status2' },
    { value: 3, label: 'status3' },
  ],
  zahtevStatusi: [
    {
      id: 2,
      naziv: 'Поднет',
    },
    {
      id: 3,
      naziv: 'Обрађен',
    },
    {
      id: 4,
      naziv: 'Одбачен',
    },
    {
      id: 5,
      naziv: 'Одбијен',
    },
    {
      id: 6,
      naziv: 'Допуна захтева',
    },
    {
      id: 7,
      naziv: 'Одобрен',
    },
    {
      id: 8,
      naziv: 'Прекинут поступак',
    },
  ],
  tipAkta: [
    {
      value: 1,
      label: 'Prvi Tip Akta',
    },
    {
      value: 2,
      label: 'Drugi Tip Akta',
    },
  ],
};

export const MOCK_APR_DETAILS: APRDetailsResponse = {
  naziv: 'THE BLUE KAFFE',
  pib: '100748777',
  maticniBroj: '54888139',
  jbkjs: null,
  tipSubjekta: 2,
  tekuciRacuni: ['220-45655-91'],
  ulica: 'Мања Ћоровића 15',
  mesto: 1,
  telefoni: ['+381 (0)20 311565', '+381 (0)63 644909'],
  vebSajt: [],
  emailAdrese: [],
  validnoZaZahtev: true,
};

export const MOCK_CONFIG_AKCIJE: IConfigAkcijeRaspodelaResponse = {
  mreOdeljenja: [
    {
      label: 'Odeljenje',
      value: 1,
    },
    {
      label: 'Odeljenje2',
      value: 2,
    },
  ],
  users: [
    {
      label: 'Ime prezime',
      value: '1',
    },
    {
      label: 'Ime2 prezime2',
      value: '2',
    },
  ],
};

export const ACTS_MOCK: IResenjaResponse[] = [
  {
    zavodniBroj: '12345678912340000011233323',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '123456789123400000172346454356',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    zahtevResenjeId: 1,
    rokVazenjaAkta: 12,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    zahtevResenjeId: 1,
    rokVazenjaAkta: 12,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
  {
    zavodniBroj: '1234567891234000001',
    nazivZahteva:
      'Захтев за добијање одобрења за примењена инжењерскогеолошка-геотехничка истраживања',
    tipAkta: 'Решење о одбијању',
    datumVremeDonosenja: '2024-10-05T14:48:00.000Z',
    rokVazenjaAkta: 12,
    zahtevResenjeId: 1,
    dokument: { value: 1, label: 'akt' },
    grupaZahteva: 'Истраживање минералних сировина',
    podgrupaZahteva: 'Истраживaња',
    tipMineralneSirovine: 'Металичне минералне сировине',
  },
];

export const MOCK_AKCIJA = {
  zahtevId: 10,
  akcije: [],
};

export const MOCK_RESENJA = [
  {
    label: '0512333312324',
    value: null,
    tipResenja: 1,
  },
  {
    label: '071542112324',
    value: 2,
    tipResenja: 1,
  },
  {
    label: '093223331124',
    value: 3,
    tipResenja: 1,
  },
];

export const MOCK_SACUVAJ_ATRIBUTE_ODLUKE_RESPONSE: IZahteviSacuvajAtributeOdlukeResponse =
  {
    id: 12,
    zavodniBrojAkta: '12333222211231',
  };

export const MOCK_OSTALO: IPrivredniSubjektOstaloResponse[] = [
  {
    id: 9,
    naziv: 'test',
    status: 3,
    delatnostId: 3,
    pib: '000000001 2024',
    file: '000000001 2024',
    maticniBroj: '12345678',
    userIme: 'Najjaca firmetina',
    userPrezime: 'Najjaca firmetina',
    userEmail: 'firma@firma.com',
    email: 'firma@firma.com',
    akcije: [
      { value: 1, label: 'Odobri' },
      { value: 2, label: 'Odbij' },
      { value: 3, label: 'Aktivirajte' },
      { value: 4, label: 'Deaktivirajte' },
    ],
  },
];

export const MOCK_PRIVREDNI_SUBJEKT: IPrivredniSubjektResponse[] = [
  {
    id: 9,
    naziv: 'test',
    status: false,
    manuallyDisabled: true,
    delatnostId: 3,
    pib: '000000001 2024',
    maticniBroj: '12345678',
    userIme: 'Najjaca firmetina',
    userPrezime: 'Najjaca firmetina',
    userEmail: 'firma@firma.com',
    email: 'firma@firma.com',
    akcije: [
      { value: 1, label: 'Odobri' },
      { value: 2, label: 'Odbij' },
      { value: 3, label: 'Aktivirajte' },
      { value: 4, label: 'Deaktivirajte' },
    ],
  },
];

export const MOCK_OSTALO_DETAILS: IPrivredniSubjektiOstaloDetaljiResponse = {
  id: 9,
  naziv: 'test',
  pib: '000000001 2024',
  maticniBroj: '12345678',
  adresa: '000000001 2024',
  tekuciRacun: '000000001 2024',
  telefon: '000000001 2024',
  status: 3,
  mestoId: 3,
  delatnostId: 3,
  userIme: 'Najjaca firmetina',
  userPrezime: 'Najjaca firmetina',
  userEmailAddress: 'firma@firma.com',
  email: 'firma@firma.com',
  internetAdresa: 'Najjaca firmetina',
  emailNotification: 'firma@firma.com',
  userEncodedJmbg: 'Najjaca firmetina',
  approvalUserIme: 'Najjaca firmetina',
  approvalUserPrezime: 'Najjaca firmetina',
  akcije: [
    { value: 1, label: 'Odobri' },
    { value: 2, label: 'Odbij' },
    { value: 3, label: 'Aktivirajte' },
    { value: 4, label: 'Deaktivirajte' },
  ],
};

export const MOCK_MOCK_PRIVREDNI_SUBJEKT_DETAILS: IPrivredniSubjektDetaljiResponse =
  {
    id: 9,
    naziv: 'test',
    pib: '000000001 2024',
    maticniBroj: '12345678',
    ulica: 'Gornjogradska',
    tekuciRacuni: ['000000001 2024'],
    telefoni: ['065 2131321'],
    status: false,
    manuallyDisabled: true,
    mesto: 'Novi sad',
    delatnostId: 3,
    userIme: 'Najjaca firmetina',
    userPrezime: 'Najjaca firmetina',
    // userEmailAddress: 'firma@firma.com',
    emailAdrese: ['firma@firma.com'],
    vebSajt: ['Najjaca firmetina'],
    emailNotifikacije: 'firma@firma.com',
    jbkjs: 'Najjaca firmetina',
    akcije: [
      { value: 1, label: 'Odobri' },
      { value: 2, label: 'Odbij' },
      { value: 3, label: 'Aktivirajte' },
      { value: 4, label: 'Deaktivirajte' },
    ],
  };

export const MOCK_PODNETI_ZAHTEVI = [
  {
    id: 2,
    zahtevTipId: 21,
    zavodniBroj: '00033708420241482000700431102901001',
  },
  {
    id: 5,
    zahtevTipId: 21,
    zavodniBroj: '00033721720241482000700431102901001',
  },
  {
    id: 6,
    zahtevTipId: 21,
    zavodniBroj: '00033728020241482000700431102901001',
  },
  {
    id: 7,
    zahtevTipId: 21,
    zavodniBroj: '00033731820241482000700431102901001',
  },
  {
    id: 8,
    zahtevTipId: 21,
    zavodniBroj: '00033730920241482000700431102901001',
  },
  {
    id: 9,
    zahtevTipId: 21,
    zavodniBroj: '00033731620241482000700431102901001',
  },
  {
    id: 10,
    zahtevTipId: 21,
    zavodniBroj: '00033732620241482000700431102901001',
  },
  {
    id: 23,
    zahtevTipId: 21,
    zavodniBroj: '00033745720241482000700431102901001',
  },
  {
    id: 48,
    zahtevTipId: 39,
    zavodniBroj: '00033840520241482000700431102901001',
  },
  {
    id: 51,
    zahtevTipId: 31,
    zavodniBroj: '00033842020241482000700431110701001',
  },
  {
    id: 52,
    zahtevTipId: 40,
    zavodniBroj: '00033842120241482000700431110701001',
  },
  {
    id: 71,
    zahtevTipId: 24,
    zavodniBroj: '00033848220241482000700431111401001',
  },
];

export const PREDMET_ISTRAZIVANJA_MOCK = [
  {
    label: 'Питке воде',
    value: 1,
  },
  {
    label: 'Минералне воде',
    value: 2,
  },
  {
    label: 'Термалне воде',
    value: 3,
  },
  {
    label: 'Термоминералне воде',
    value: 4,
  },
];

export const OSTALO_TIP_SUBJEKTA = [
  {
    label: 'Škola',
    value: 1,
  },
  {
    label: 'Crkva',
    value: 2,
  },
  {
    label: 'Privredno društvo',
    value: 3,
  },
];
