/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Flex, Form, Modal, notification, Select } from 'antd';
import { t } from 'i18next';
import TextArea from 'antd/es/input/TextArea';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service';
import {
  IConfigAkcijeRaspodelaResponse,
  IFilters,
  IFiltersString,
} from '@/modules/submittedRequests/submittedRequests.types';
import { ModalName, modalStore } from '@/modules/modal/modal.store';
import { AkcijeEnum } from '@/modules/requests/requests.types';

interface ISubmittedRequestsModal {
  actionId: number | null;
  zahtevId: number | null;
  refreshRequestsTable?: () => void;
}

export const SubmittedRequestsModal = observer(
  ({ actionId, zahtevId, refreshRequestsTable }: ISubmittedRequestsModal) => {
    const [mreOdeljenja, setMreOdeljenja] = useState<IFilters[] | null>([]);
    const [users, setUsers] = useState<IFiltersString[] | null>([]);

    const [form] = useForm();

    const isOpen = modalStore.isVisible(ModalName.SUBMITTED_REQUEST);

    const handleCancel = () => {
      modalStore.closeModal(ModalName.SUBMITTED_REQUEST);
    };
    useEffect(() => {
      if (isOpen && (actionId === 1 || actionId === 2 || actionId === 3)) {
        submittedRequestsService
          .postConfigAkcijeRaspodela({ akcija: actionId, zahtevId: zahtevId })
          .then((res: IConfigAkcijeRaspodelaResponse | null) => {
            if (res) {
              setMreOdeljenja(res.mreOdeljenja);
              setUsers(res.users);
            }
          })
          .catch((err) => {
            console.error('Error get config akcije raspodela ', err);
            notification.error(err);
          });
      }
    }, [actionId, zahtevId, isOpen]);

    const handleSubmit = () => {
      const payload = {
        zahtevId: zahtevId,
        napomena: form.getFieldValue('napomena'),
        mreOdeljenjeId: form.getFieldValue('odeljenje'),
      };

      if (actionId) {
        switch (actionId) {
          case AkcijeEnum.VRATI: {
            submittedRequestsService
              .postZahteviVratiZahtev({
                zahtevId: zahtevId,
                napomena: form.getFieldValue('napomena'),
              })
              .then(() => {
                refreshRequestsTable && refreshRequestsTable();
                notification.success({ message: t('vrati_zahtev_success') });
                handleCancel();
                !refreshRequestsTable &&
                  setTimeout(() => window.location.reload(), 500);
              })
              .catch((err) => {
                notification.error(err);
              });
            break;
          }
          case AkcijeEnum.PROSLEDI: {
            submittedRequestsService
              .postZahteviProslediZahtev(payload)
              .then(() => {
                refreshRequestsTable && refreshRequestsTable();
                notification.success({ message: t('prosledi_zahtev_success') });
                handleCancel();
                !refreshRequestsTable &&
                  setTimeout(() => window.location.reload(), 500);
              })
              .catch((err) => {
                notification.error(err);
              });
            break;
          }
          case AkcijeEnum.DODELI: {
            submittedRequestsService
              .postZahteviDodeliZahtev({
                zahtevId: zahtevId,
                userId: form.getFieldValue('user'),
                napomena: form.getFieldValue('napomena'),
              })
              .then(() => {
                refreshRequestsTable && refreshRequestsTable();
                notification.success({ message: t('dodeli_zahtev_success') });
                handleCancel();
                !refreshRequestsTable &&
                  setTimeout(() => window.location.reload(), 500);
              })
              .catch((err) => {
                notification.error(err);
              });
            break;
          }
          case AkcijeEnum.OBRADI: {
            submittedRequestsService
              .postZahteviObradiProcesOdobravanjaAkta({
                zahtevId: zahtevId,
                napomena: form.getFieldValue('napomena'),
                odobri: false,
              })
              .then(() => {
                refreshRequestsTable && refreshRequestsTable();
                notification.success({ message: t('vratili_success') });
                handleCancel();
                !refreshRequestsTable &&
                  setTimeout(() => window.location.reload(), 500);
              })
              .catch((err) => {
                notification.error(err);
              });
            break;
          }
          default:
            null;
        }
      }
    };

    const handleTitle = () => {
      switch (actionId) {
        case AkcijeEnum.VRATI:
          return t('unesite_obrazlozenje');
        case AkcijeEnum.DODELI:
          return t('izaberite_obradjivaca_zahteva');
        case AkcijeEnum.OBRADI:
          return t('razlog_vracanja_dokumenta_na_obradu');
        default:
          return t('izaberite_organizacionu_jedinicu');
      }
    };

    const handlePlaceholder = () => {
      if (actionId === 2) return t('organizaciona_jedinica');
      return t('odeljenje');
    };

    return (
      <Modal
        open={isOpen}
        width={400}
        cancelButtonProps={{ danger: true, type: 'primary' }}
        onCancel={() => {
          handleCancel();
        }}
        destroyOnClose
        cancelText={t('odustanite')}
        okText={t('sacuvajte')}
        okButtonProps={{
          htmlType: 'submit',
          form: 'actionsForm',
        }}
        title={handleTitle()}
      >
        <Form
          form={form}
          name='actionsForm'
          onFinish={handleSubmit}
          preserve={false}
        >
          <Flex gap={10} vertical>
            {mreOdeljenja?.length ? (
              <Form.Item className='m-0' name='odeljenje'>
                <Select
                  options={mreOdeljenja}
                  className='w-100'
                  placeholder={handlePlaceholder()}
                />
              </Form.Item>
            ) : null}
            {users ? (
              <Form.Item className='m-0' name='user'>
                <Select
                  options={users}
                  className='w-100'
                  placeholder={t('ime_i_prezime')}
                />
              </Form.Item>
            ) : null}
            <Form.Item name='napomena'>
              <TextArea placeholder={t('napomena')} className='w-100' />
            </Form.Item>
          </Flex>
        </Form>
      </Modal>
    );
  }
);
