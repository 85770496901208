// import { MRE_ROLES, PS_ROLES } from '@/modules/auth/auth.constants.ts';
import { userHasAccess, userHasRoleAccess } from '@/utils/guard.ts';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';
import { ROLE_DEFAULT_ROUTE } from '@/routes/routes.tsx';
import { authStore } from '@/modules/auth/auth.store.ts';

type GuardProps = {
  children: React.ReactNode;
  requiredRoles?: number[];
  requiredRole?: 'MRE' | 'PS';
  isRoute?: boolean;
};
const Guard = observer(
  ({ children, requiredRoles, requiredRole, isRoute = false }: GuardProps) => {
    const userAccess = requiredRoles ? userHasAccess(requiredRoles) : false;
    const userRoleAccess = requiredRole
      ? userHasRoleAccess(requiredRole)
      : false;

    if (userAccess || userRoleAccess) {
      return <>{children}</>;
    }

    if (isRoute) {
      if (authStore.userRoles) {
        return (
          <Navigate
            to={ROLE_DEFAULT_ROUTE[authStore.userRoles![0].roleId as number]}
          />
        );
      }
    }

    return null;
  }
);

export default Guard;
