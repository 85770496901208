import { RouterProvider } from 'react-router-dom';

import './App.scss';
import './styles/globals.scss';
import { router } from './routes/routes.tsx';
import './styles/ant-overrides.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('app_title');
  }, [t]);

  return (
    <div className='app'>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
