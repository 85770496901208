import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Tabs,
  TabsProps,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import {
  DatePicker,
  RangePicker,
} from '@/components/DatePicker/DatePicker.tsx';
import UploadedFile from '@/components/Forms/Upload/UploadedFile.tsx';
import { authStore } from '@/modules/auth/auth.store.ts';
import { PrivredniSubjekt } from '@/modules/auth/auth.types.ts';
import { ModalName, modalStore } from '@/modules/modal/modal.store.ts';
import { mapDocumentsData } from '@/modules/requests/requests.documents.ts';
import { requestsService } from '@/modules/requests/requests.service.ts';
import { requestsStore } from '@/modules/requests/requests.store.ts';
import {
  CreateNewRequestResponse,
  DocumentAttributePayload,
  DynamicFormField,
  DynamicFormSchema,
  IMappedRequestDetailsResponseForForm,
  PodnetiZahteviResponse,
  PreduzeceTypeEnum,
  RequestDocumentsEnum,
  ResenjeDropdownOptionResponse,
} from '@/modules/requests/requests.types.ts';
import { settingsService } from '@/modules/settings/settings.service.ts';
import CreatedRequestModal from '@/pages/RequestView/components/CreatedRequestModal/CreatedRequestModal.tsx';
import { ROUTES } from '@/routes/routes.tsx';
import { DATE_FORMAT_UI, YEAR_FORMAT } from '@/utils/dateFormatter.ts';
import { flattenRequests } from '@/utils/flattenRequestsFromGroups.ts';
import { formDataMappers } from '@/utils/formMapper.ts';
import { map4select } from '@/utils/map4select.ts';
import { uploadDocuments } from '@/utils/uploadFile.ts';
import { Rule } from 'antd/es/form/index';
// import SigningDocument from '../../SigningDocument/SigningDocument.tsx';
import { ActsSelectInput } from '../ActsSelectInput/ActsSelectInput.tsx';
import Locality, { Koordinate, Lokalitet } from '../Locality/Locality.tsx';
import CustomUpload from '../Upload/CustomUpload.tsx';
import style from './DynamicForm.module.scss';

type DynamicFormProps = {
  schema: DynamicFormSchema;
  onSubmit?: () => void;
  zahtevTipId: number;
  zahtevIdProp?: number;
  zahtevNacrtProp?: IMappedRequestDetailsResponseForForm | null;
};

export const eventEmitter = new EventTarget();

const DynamicForm = observer(
  ({
    schema,
    zahtevTipId,
    zahtevIdProp,
    zahtevNacrtProp = null,
  }: DynamicFormProps) => {
    const [zahtevNacrt] = useState<IMappedRequestDetailsResponseForForm | null>(
      zahtevNacrtProp
    );
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const getPreduzecaNamesWatch = (fieldName: PreduzeceTypeEnum) => {
      return ['preduzece', String(fieldName), 'maticniBroj'];
    };

    const preduzeceIzvodjacMBWatch = Form.useWatch(
      getPreduzecaNamesWatch(PreduzeceTypeEnum.IZVODJAC_RADOVA),
      form
    );
    const preduzeceIzvrsilacMBWatch = Form.useWatch(
      getPreduzecaNamesWatch(PreduzeceTypeEnum.KONTROLA_PROJEKTA),
      form
    );
    const preduzeceVrsilacStrucnogNadzoraMBWatch = Form.useWatch(
      getPreduzecaNamesWatch(PreduzeceTypeEnum.STRUCNI_NADZOR),
      form
    );
    const preduzeceVrsilacTehnickeKontroleIzvestajaMBWatch = Form.useWatch(
      getPreduzecaNamesWatch(PreduzeceTypeEnum.KONTROLA_IZVESTAJA),
      form
    );
    const preduzeceVrsilacTehnickeKontroleProjektneDokumentacijeMBWatch =
      Form.useWatch(
        getPreduzecaNamesWatch(
          PreduzeceTypeEnum.KONTROLA_PROJEKTNE_DOKUMENTACIJE
        ),
        form
      );
    const preduzeceProgramMBWatch = Form.useWatch(
      getPreduzecaNamesWatch(PreduzeceTypeEnum.URADILO_PROGRAM),
      form
    );
    const pravnoLicePrenosOdobrenjaMBWatch = Form.useWatch(
      getPreduzecaNamesWatch(PreduzeceTypeEnum.PRENOS_ODOBRENJA),
      form
    );
    const preduzeceUradiloElaboratMBWatch = Form.useWatch(
      getPreduzecaNamesWatch(PreduzeceTypeEnum.URADILO_ELABORAT),
      form
    );
    const pravnoLicePrenosPotvrdeMBWatch = Form.useWatch(
      getPreduzecaNamesWatch(PreduzeceTypeEnum.PRENOS_POTVRDE),
      form
    );
    const preduzeceUradiloProjekatMBWatch = Form.useWatch(
      getPreduzecaNamesWatch(PreduzeceTypeEnum.URADILO_PROJEKAT),
      form
    );
    const preduzeceKontrolaProjektaMBWatch = Form.useWatch(
      getPreduzecaNamesWatch(
        PreduzeceTypeEnum.KONTROLA_PROJEKTNE_DOKUMENTACIJE
      ),
      form
    );
    const preduzeceUradiloStudijuMBWatch = Form.useWatch(
      getPreduzecaNamesWatch(PreduzeceTypeEnum.URADILO_STUDIJU),
      form
    );
    const katastarskaOpstinaIdWatch = Form.useWatch(
      [
        'dokumentaAtributi',
        String(RequestDocumentsEnum.DOKAZ_PRAVO_SVOJINE),
        'katastarskaOpstinaId',
      ],
      form
    );

    const datumPocetkaProbnogRadaBusotineWatch = Form.useWatch(
      ['istrazivanjeAtributi', 'datumPocetkaProbnogRadaBusotine'],
      form
    );
    const datumPocetkaGeoloskogIstrazivanjaWatch = Form.useWatch(
      ['podzemneVodeAtributi', 'datumPocetkaGeoloskogIstrazivanja'],
      form
    );
    const topografskaKartaWatch = Form.useWatch(
      ['prilozi', String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA)],
      form
    );
    const geodetskiPlanWatch = Form.useWatch(
      ['prilozi', String(RequestDocumentsEnum.GEODETSKI_PLAN)],
      form
    );

    const izborZahtevaWatch = Form.useWatch(['referentniZahtevId'], form);

    // const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    // const [isDownloaded, setIsDownloaded] = useState<boolean>(false);
    const [zahtevId, setZahtevId] = useState<number | undefined>(zahtevIdProp);
    const [resenja, setResenja] = useState<DefaultOptionType[]>([]);
    const [podnetiZahtevi, setPodnetiZahtevi] = useState<
      PodnetiZahteviResponse[]
    >([]);

    const [pisarnicaBroj, setPisarnicaBroj] = useState<number | undefined>(
      undefined
    );
    const [isLoading, setIsLoading] = useState(false);

    const getMinDateDatum = (fieldName: string) => {
      if (fieldName === 'datumZavrsetkaProbnogRadaBusotine') {
        return dayjs(datumPocetkaProbnogRadaBusotineWatch, 'YYYY-MM-DD');
      }

      if (fieldName === 'datumZavrsetkaGeoloskogIstrazivanja')
        return dayjs(datumPocetkaGeoloskogIstrazivanjaWatch, 'YYYY-MM-DD');
    };

    const vrstaMineralneSirovineMSWatch = Form.useWatch(
      ['istrazivanjeAtributi', 'vrstaMineralnihSirovinaId'],
      form
    );
    const datumProbnogRadaBusotineRequired = (fieldName: string) => {
      if (
        [
          'datumPocetkaProbnogRadaBusotine',
          'datumZavrsetkaProbnogRadaBusotine',
        ].includes(fieldName)
      ) {
        // nafta, gas, nafta i gas
        return vrstaMineralneSirovineMSWatch?.find((id: number) =>
          [41, 42, 44].includes(id)
        );
      }
    };

    const groupId = useMemo(() => {
      const flattenReqs = flattenRequests(requestsStore.groupsAndRequests);
      return flattenReqs?.find((rq) => rq.id === +zahtevTipId!)?.groupId;
    }, [zahtevTipId]);

    useEffect(() => {
      Promise.all([
        requestsService.getResenja(),
        requestsService.getPodnetiZahtevi(),
      ])
        .then((res) => {
          setResenja(res[0]);
          setPodnetiZahtevi(res[1]);
        })
        .catch((err) => {
          console.error(`Error getting resenja for ${zahtevId}`, err);
          setResenja([]);
        });
    }, [zahtevId]);

    useEffect(() => {
      if (izborZahtevaWatch) {
        const getZahtev: number = form.getFieldValue(['referentniZahtevId']);
        const zahtevTipId: number | undefined = podnetiZahtevi.find(
          (test: PodnetiZahteviResponse) => test.id === getZahtev
        )?.zahtevTipId;
        if (getZahtev && zahtevTipId) {
          const getRequestName: string | undefined = flattenRequests(
            requestsStore.groupsAndRequests
          )?.find((rq) => rq.id === +zahtevTipId)?.ime;
          form.setFieldValue(['zahtev', 'tipZahteva'], getRequestName || '');
        }
      }
    }, [izborZahtevaWatch, zahtevId]);

    const handleAprDetailsResponse = (
      maticniBroj: string,
      tipSubjekta: number,
      fieldName: string
    ) => {
      settingsService
        .getPrivredniSubjektAPRDetails({
          maticniBroj: maticniBroj,
          tipSubjekta: tipSubjekta,
        })
        .then((res) => {
          const mappedData = {
            punNaziv: res.naziv,
            maticniBroj: res.maticniBroj,
            pib: res.pib,
            brojPoslovnogRacuna: res.tekuciRacuni.length
              ? res.tekuciRacuni?.[0]
              : '',
            adresa: res.ulica,
            telefon: res.telefoni.length ? res.telefoni?.[0] : '',
            email: res.emailAdrese.length ? res.emailAdrese?.[0] : '',
            webSite: res.vebSajt.length ? res.vebSajt?.[0] : '',
            vrstaPreduzece: form.getFieldValue([
              'preduzece',
              fieldName,
              'vrstaPreduzece',
            ]),
            mestoId: res.mesto,
          };
          form.setFieldValue(['preduzece', [fieldName]], mappedData);
          form.validateFields();
        })
        .catch((err) => {
          console.error('Error getting APR details ', err);
        });
    };

    const handleAprDetailsResponseDebounce = useDebouncedCallback(
      handleAprDetailsResponse,
      750
    );

    const getTipSubjekta = useCallback(
      (fieldName: string) => {
        return form.getFieldValue(['preduzece', fieldName, 'vrstaPreduzece']);
      },
      [form]
    );

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.IZVODJAC_RADOVA)
      );
      if (!zahtevNacrt && preduzeceIzvodjacMBWatch && !!tipSubjekta) {
        handleAprDetailsResponseDebounce(
          preduzeceIzvodjacMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.IZVODJAC_RADOVA)
        );
      }
    }, [
      preduzeceIzvodjacMBWatch,
      handleAprDetailsResponseDebounce,
      form,
      getTipSubjekta,
    ]);

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.KONTROLA_PROJEKTA)
      );
      if (!zahtevNacrt && preduzeceIzvrsilacMBWatch && tipSubjekta) {
        handleAprDetailsResponseDebounce(
          preduzeceIzvrsilacMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTA)
        );
      }
    }, [
      preduzeceIzvrsilacMBWatch,
      getTipSubjekta,
      handleAprDetailsResponseDebounce,
    ]);

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.STRUCNI_NADZOR)
      );
      if (
        !zahtevNacrt &&
        preduzeceVrsilacStrucnogNadzoraMBWatch &&
        tipSubjekta
      ) {
        handleAprDetailsResponseDebounce(
          preduzeceVrsilacStrucnogNadzoraMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.STRUCNI_NADZOR)
        );
      }
    }, [
      preduzeceVrsilacStrucnogNadzoraMBWatch,
      getTipSubjekta,
      handleAprDetailsResponseDebounce,
    ]);

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.KONTROLA_IZVESTAJA)
      );
      if (
        !zahtevNacrt &&
        preduzeceVrsilacTehnickeKontroleIzvestajaMBWatch &&
        tipSubjekta
      ) {
        handleAprDetailsResponseDebounce(
          preduzeceVrsilacTehnickeKontroleIzvestajaMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.KONTROLA_IZVESTAJA)
        );
      }
    }, [
      preduzeceVrsilacTehnickeKontroleIzvestajaMBWatch,
      getTipSubjekta,
      handleAprDetailsResponseDebounce,
    ]);

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.KONTROLA_PROJEKTNE_DOKUMENTACIJE)
      );
      if (
        !zahtevNacrt &&
        preduzeceVrsilacTehnickeKontroleProjektneDokumentacijeMBWatch &&
        tipSubjekta
      ) {
        handleAprDetailsResponseDebounce(
          preduzeceVrsilacTehnickeKontroleProjektneDokumentacijeMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTNE_DOKUMENTACIJE)
        );
      }
    }, [
      preduzeceVrsilacTehnickeKontroleProjektneDokumentacijeMBWatch,
      getTipSubjekta,
      handleAprDetailsResponseDebounce,
    ]);

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.URADILO_PROGRAM)
      );
      if (!zahtevNacrt && preduzeceProgramMBWatch && tipSubjekta) {
        handleAprDetailsResponseDebounce(
          preduzeceProgramMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.URADILO_PROGRAM)
        );
      }
    }, [
      preduzeceProgramMBWatch,
      getTipSubjekta,
      handleAprDetailsResponseDebounce,
    ]);

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.PRENOS_ODOBRENJA)
      );
      if (!zahtevNacrt && pravnoLicePrenosOdobrenjaMBWatch && tipSubjekta) {
        handleAprDetailsResponseDebounce(
          pravnoLicePrenosOdobrenjaMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.PRENOS_ODOBRENJA)
        );
      }
    }, [
      pravnoLicePrenosOdobrenjaMBWatch,
      getTipSubjekta,
      handleAprDetailsResponseDebounce,
    ]);

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.URADILO_ELABORAT)
      );
      if (!zahtevNacrt && preduzeceUradiloElaboratMBWatch && tipSubjekta) {
        handleAprDetailsResponseDebounce(
          preduzeceUradiloElaboratMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.URADILO_ELABORAT)
        );
      }
    }, [
      preduzeceUradiloElaboratMBWatch,
      getTipSubjekta,
      handleAprDetailsResponseDebounce,
    ]);

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.PRENOS_POTVRDE)
      );
      if (!zahtevNacrt && pravnoLicePrenosPotvrdeMBWatch && tipSubjekta) {
        handleAprDetailsResponseDebounce(
          pravnoLicePrenosPotvrdeMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.PRENOS_POTVRDE)
        );
      }
    }, [
      pravnoLicePrenosPotvrdeMBWatch,
      getTipSubjekta,
      handleAprDetailsResponseDebounce,
    ]);

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.URADILO_PROJEKAT)
      );
      if (!zahtevNacrt && preduzeceUradiloProjekatMBWatch && tipSubjekta) {
        handleAprDetailsResponseDebounce(
          preduzeceUradiloProjekatMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.URADILO_PROJEKAT)
        );
      }
    }, [
      preduzeceUradiloProjekatMBWatch,
      getTipSubjekta,
      handleAprDetailsResponseDebounce,
    ]);

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.KONTROLA_PROJEKTNE_DOKUMENTACIJE)
      );
      if (!zahtevNacrt && preduzeceKontrolaProjektaMBWatch && tipSubjekta) {
        handleAprDetailsResponseDebounce(
          preduzeceKontrolaProjektaMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.KONTROLA_PROJEKTNE_DOKUMENTACIJE)
        );
      }
    }, [
      preduzeceKontrolaProjektaMBWatch,
      getTipSubjekta,
      handleAprDetailsResponseDebounce,
    ]);

    useEffect(() => {
      const tipSubjekta = getTipSubjekta(
        String(PreduzeceTypeEnum.URADILO_STUDIJU)
      );
      if (!zahtevNacrt && preduzeceUradiloStudijuMBWatch && tipSubjekta) {
        handleAprDetailsResponseDebounce(
          preduzeceUradiloStudijuMBWatch,
          tipSubjekta,
          String(PreduzeceTypeEnum.URADILO_STUDIJU)
        );
      }
    }, [
      preduzeceUradiloStudijuMBWatch,
      getTipSubjekta,
      handleAprDetailsResponseDebounce,
    ]);

    const handleOpenSuccessCreatedRequestModal = (pisarnicaBroj: number) => {
      setPisarnicaBroj(pisarnicaBroj);
      modalStore.openModal(ModalName.CREATED_REQUEST);
    };

    const handleCloseSucccessCreatedRequestModal = () => {
      modalStore.closeModal(ModalName.CREATED_REQUEST);
      setPisarnicaBroj(undefined);
      navigate(ROUTES.SUBMITTED_REQUESTS);
    };

    const handleSaveDraft = useCallback(async () => {
      console.log('UNMAPED ', {
        ...form.getFieldsValue(),
        zahtevTipId: +zahtevTipId!,
      });
      const data = {
        ...form.getFieldsValue(),
        zahtevTipId: +zahtevTipId!,
      };

      const mappedData = formDataMappers(data);

      console.log('Mapped ', mappedData);

      try {
        let payload;
        if (zahtevId) {
          payload = {
            ...mappedData,
            zahtevId: zahtevId,
          };
        } else {
          payload = mappedData;
        }
        const saveDraftResponse = await requestsService.saveDraftRequest(
          payload!
        );

        if (saveDraftResponse.id) {
          notification.success({ message: t('sacuvaj_nacrt_success') });
        }

        if (zahtevId || saveDraftResponse.id) {
          setZahtevId(saveDraftResponse.id);
          const dokumentaAtributi: DocumentAttributePayload =
            form?.getFieldValue('dokumentaAtributi');
          const documents = mapDocumentsData(
            +zahtevTipId!,
            {
              ...form.getFieldsValue(),
              zahtevTipId: +zahtevTipId!,
            },
            dokumentaAtributi
          );

          await uploadDocuments(
            documents,
            saveDraftResponse.id ?? zahtevId,
            requestsService.uploadDocument,
            zahtevTipId
          );
        }

        const obrisiPriloge = new Event('obrisiPriloge');
        eventEmitter.dispatchEvent(obrisiPriloge);

        return saveDraftResponse?.id ?? zahtevId;
      } catch (e) {
        console.error('Error saving draft ', e);
        notification.error(e as { message: string });
        return undefined;
      }
    }, [form, zahtevTipId, zahtevId, requestsStore.documentUploadRes, t]);

    const handleCancel = () => {
      navigate(ROUTES.NEW_REQUEST);
    };

    const handleCreateRequest = async () => {
      setIsLoading(true);

      try {
        const requestId = await handleSaveDraft();
        if (!requestId) {
          return;
        }
        const generateXMLResponse =
          await requestsService.generateXML(requestId);
        console.log('generateXMLResponse', generateXMLResponse?.data);
        if (generateXMLResponse && generateXMLResponse?.data) {
          // const convertedToBase64: string = await getBase64(generateXMLResponse?.data?.data as Blob);
          const convertedToBase64 = btoa(
            //@ts-ignore
            generateXMLResponse?.data
          );

          console.log('convertedToBase64', convertedToBase64);

          requestsService
            .potpisiXML(convertedToBase64 as string)
            .then((res) => {
              console.log('potpisano res', res);
            });
        }

        const res = await requestsService.createNewRequest(requestId);
        const createNewRequestResponse = res as CreateNewRequestResponse;
        if (!createNewRequestResponse) {
          return;
        }
        handleOpenSuccessCreatedRequestModal(
          createNewRequestResponse.pisarnicaBroj
        );
      } catch (e) {
        notification.error(e as { message: string });
        console.error('Error creating new request', e);
      } finally {
        setIsLoading(false);
      }
    };

    const getOptionsForSelectField = useCallback(
      (fieldName: string[]) => {
        switch (fieldName[fieldName.length - 1]) {
          case 'vrstaGeoloskogIstrazivanjaId':
            return requestsStore.config?.vrsteGeoloskihIstrazivanja[
              groupId as 1 | 2 | 3
            ];
          case 'predmetIstrazivanjaId':
            return requestsStore.config?.predmetIstrazivanja || [];
          case 'vrstaPreduzece':
            return requestsStore.config?.vrstePrivrednogSubjekta || [];
          case 'opstinaId':
            return requestsStore.config?.opstine;
          case 'mestoId':
            return requestsStore.config?.mesta || [];
          case 'vrstaMineralnihSirovinaId':
            return requestsStore.config?.mineralneSirovine || [];
          case 'resenjeId':
            return resenja;
          case 'katastarskaOpstinaId':
            return requestsStore.config?.katastarskaMestaOpstine || [];
          case 'katastarskoMestoId':
            const currentMesta =
              requestsStore.config?.katastarskaMestaOpstine.find(
                (opstina) => opstina.value === katastarskaOpstinaIdWatch
              )?.mesta;
            return currentMesta || [];
          case 'referentniZahtevId': {
            return map4select({
              sourceArray: podnetiZahtevi || [],
              labelAccessor: 'zavodniBroj',
              valueAccessor: 'id',
            });
          }
          default: {
            const tipResenja = +fieldName[fieldName.length - 1];
            const nacrt = zahtevNacrt;
            const newResenja: DefaultOptionType[] = [];
            if (nacrt?.selectedResenja) {
              newResenja.push(
                ...nacrt.selectedResenja.map(
                  (res: ResenjeDropdownOptionResponse) => ({
                    value: res.id ?? res.brojResenja,
                    label: res.brojResenja,
                    tipResenja: tipResenja,
                  })
                )
              );
            }
            return [...newResenja, ...resenja];
          }
        }
      },
      [resenja, katastarskaOpstinaIdWatch]
    );

    const getDefaultOptionForSelectField = (field: DynamicFormField) => {
      const arrayType = (value: unknown) => [value];
      switch (field.name[field.name.length - 1]) {
        case 'vrstaGeoloskogIstrazivanjaId': {
          const value =
            requestsStore.config?.vrsteGeoloskihIstrazivanja[
              groupId as 1 | 2 | 3
            ][getDefaultOptionIDForVrstaGeoloskogIstrazivanja].value;
          if (field.selectMode) {
            return arrayType(value);
          } else {
            return value;
          }
        }
        case 'predmetIstrazivanjaId':
          return undefined;
        case 'vrstaPreduzece':
          return requestsStore.config?.vrstePrivrednogSubjekta?.[0].value;
        case 'opstinaId':
          return undefined;
        case 'mestoId':
          return undefined;
        case 'tipZahteva':
          return undefined;
        case 'vrstaMineralnihSirovinaId':
          return undefined;
        default: {
          const tipResenja = field.name[field.name.length - 1];
          const nacrt = zahtevNacrt;
          if (nacrt) {
            const resenje = nacrt.selectedResenja?.find(
              (res: ResenjeDropdownOptionResponse) =>
                res.tipResenja === +tipResenja
            );
            return resenje?.id ?? resenje?.brojResenja;
          }
          return undefined;
        }
      }
    };

    const getDefaultOptionIDForVrstaGeoloskogIstrazivanja = useMemo(() => {
      const METALICNE_MINERALNE_SIROVINE_REQUESTS_ID_ARRAY =
        requestsStore?.groupsAndRequests?.[1]?.podgrupe?.[0]?.kategorijePodgrupa?.[0]?.zahtevi?.map(
          (request) => request.id
        );
      const NEMETALICNE_MINERALNE_SIROVINE_REQUESTS_ID_ARRAY =
        requestsStore?.groupsAndRequests?.[1]?.podgrupe?.[0]?.kategorijePodgrupa?.[1]?.zahtevi?.map(
          (request) => request.id
        );
      const ENERGETSKE_MINERALNE_SIROVINE_REQUESTS_ID_ARRAY =
        requestsStore?.groupsAndRequests?.[1]?.podgrupe?.[0]?.kategorijePodgrupa?.[2]?.zahtevi?.map(
          (request) => request.id
        );
      const PRIRODNI_GRADJEVINSKI_MATERIJALI_REQUESTS_ID_ARRAY =
        requestsStore?.groupsAndRequests?.[1]?.podgrupe?.[0]?.kategorijePodgrupa?.[3]?.zahtevi?.map(
          (request) => request.id
        );

      if (
        METALICNE_MINERALNE_SIROVINE_REQUESTS_ID_ARRAY?.includes(zahtevTipId)
      ) {
        return 0;
      } else if (
        NEMETALICNE_MINERALNE_SIROVINE_REQUESTS_ID_ARRAY?.includes(zahtevTipId)
      ) {
        return 1;
      } else if (
        ENERGETSKE_MINERALNE_SIROVINE_REQUESTS_ID_ARRAY?.includes(zahtevTipId)
      ) {
        return 2;
      } else if (
        PRIRODNI_GRADJEVINSKI_MATERIJALI_REQUESTS_ID_ARRAY?.includes(
          zahtevTipId
        )
      ) {
        return 3;
      }

      return 0;
    }, [zahtevTipId]);

    const updateAditionalFieldRules = (
      field: DynamicFormField,
      fieldName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      watch: any
    ) => {
      const transformedAdditionalFilds = field.additionalInputFields?.map(
        (additionalField) => {
          return {
            ...(additionalField && additionalField),
            rules: [
              {
                required: Boolean(
                  watch || zahtevNacrt?.dokumentaAtributi?.[fieldName]
                ),
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          };
        }
      );
      return transformedAdditionalFilds;
    };

    const aktZaZastituPrirodeOpcioniWatch = Form.useWatch(
      ['prilozi', String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA)],
      form
    );

    const aktOUslovimaZaZastituSpomenikaKultureOpcioniWatch = Form.useWatch(
      ['prilozi', String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE)],
      form
    );

    const dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnjaWatch =
      Form.useWatch(
        [
          'prilozi',
          String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
        ],
        form
      );

    const aktZaPosloveSanitarneZastiteIzvoristaVodosnabdevanjaWatch =
      Form.useWatch(
        ['prilozi', String(RequestDocumentsEnum.AKT_SANITARNA_ZASTITA)],
        form
      );

    const configPrilogaZaUpdateRulova = {
      [String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA)]: {
        name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
        watch: aktZaZastituPrirodeOpcioniWatch,
      },
      [String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE)]: {
        name: String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
        watch: aktOUslovimaZaZastituSpomenikaKultureOpcioniWatch,
      },
      [String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT)]: {
        name: String(
          RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT
        ),
        watch: dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnjaWatch,
      },
      [String(RequestDocumentsEnum.AKT_SANITARNA_ZASTITA)]: {
        name: String(RequestDocumentsEnum.AKT_SANITARNA_ZASTITA),
        watch: aktZaPosloveSanitarneZastiteIzvoristaVodosnabdevanjaWatch,
      },
    };

    const renderField = (field: DynamicFormField, disabled = false) => {
      const fieldName = field.name[1];
      if (configPrilogaZaUpdateRulova?.[fieldName]) {
        field.additionalInputFields = updateAditionalFieldRules(
          field,
          fieldName,
          configPrilogaZaUpdateRulova?.[fieldName].watch
        );
      }
      switch (field.type) {
        case 'text':
          return (
            <Form.Item
              key={
                Array.isArray(field.name) ? field.name.join('.') : field.name
              }
              name={field.name}
              label={field.label}
              rules={field.rules}
              style={{ width: '100%' }}
              tooltip={
                field.tooltip && {
                  title: field.tooltip,
                  icon: <InfoCircleOutlined />,
                }
              }
            >
              <Input
                type='text'
                placeholder={field.placeholder}
                disabled={field.disabled || disabled}
              />
            </Form.Item>
          );
        case 'number':
          return (
            <Form.Item
              name={field.name}
              label={field.label}
              rules={field.rules}
            >
              <InputNumber
                placeholder={field.placeholder}
                controls={false}
                addonAfter={field.prefix}
                style={{ width: '100%' }}
              />
            </Form.Item>
          );
        case 'numberFloat':
          return (
            <Form.Item
              name={field.name}
              label={field.label}
              rules={field.rules}
            >
              <InputNumber
                placeholder={field.placeholder}
                controls={false}
                addonAfter={field.prefix}
                style={{ width: '100%' }}
                step={'0.01'}
              />
            </Form.Item>
          );
        case 'textarea':
          return (
            <Form.Item
              name={field.name}
              label={field.label}
              rules={field.rules}
              style={{ width: '100%' }}
            >
              <Input.TextArea placeholder={field.placeholder} />
            </Form.Item>
          );
        case 'location':
          return (
            <Locality
              coordinatesTitle={field.coordinatesTitle}
              formListName={field.name}
              context={field.context}
            />
          );
        case 'koordinate': {
          return (
            <Row gutter={25} style={{ width: '100%' }}>
              <Koordinate formListName={field.name} context={field.context} />
            </Row>
          );
        }
        case 'lokalitet': {
          return (
            <Row gutter={25} style={{ width: '100%' }}>
              <Lokalitet formListName={field.name} context={field.context} />
            </Row>
          );
        }
        case 'upload': {
          const fieldName = field.name[1];
          let fieldRules: Rule[] | undefined = field?.rules;
          if (
            fieldName === String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA)
          ) {
            const entityForOstalo = authStore.businessEntities?.find(
              (entity: PrivredniSubjekt) =>
                entity.id === authStore.selectedBusinessEntity?.id
            );
            fieldRules = [
              {
                required: entityForOstalo?.isOstalo,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ];
          }
          if (fieldName === String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA)) {
            if (geodetskiPlanWatch) {
              fieldRules = undefined;
            }
          }
          if (fieldName === String(RequestDocumentsEnum.GEODETSKI_PLAN)) {
            if (topografskaKartaWatch) {
              fieldRules = undefined;
            }
          }

          return (
            <Form.Item
              name={field.name}
              rules={
                !requestsStore.documentUploadRes?.[fieldName]
                  ? fieldRules
                  : undefined
              }
            >
              <CustomUpload
                form={form}
                name={field.name}
                label={field.label!}
                tooltip={field.tooltip}
                description={field?.description}
                accept={field?.accept}
                singingDocument={field?.singingDocument}
                disabled={disabled}
                rules={fieldRules}
                disabledMassage={
                  disabled
                    ? t('forma.dugme.morate_obrisati_vec_postojeci_dokument')
                    : undefined
                }
              />
            </Form.Item>
          );
        }
        case 'date':
          return (
            <Form.Item
              label={field.label}
              name={field.name}
              rules={[
                ...(field.rules || []),

                {
                  required: datumProbnogRadaBusotineRequired(
                    field.name[field.name?.length - 1]
                  ),
                  type: 'date',
                  message: t('forma.sekcija.polje.obavezno_polje'),
                },
              ]}
              getValueProps={(value) => ({
                value: value ? dayjs(value) : null,
              })}
            >
              <DatePicker
                placeholder={field.placeholder}
                className={style.dateInput}
                format={DATE_FORMAT_UI}
                onChange={(date) =>
                  form.setFieldValue(field.name, dayjs(date).toISOString())
                }
                minDate={getMinDateDatum(field.name[field.name?.length - 1])}
              />
            </Form.Item>
          );
        case 'year':
          return (
            <Form.Item
              label={field.label}
              name={field.name}
              rules={field.rules}
              getValueProps={(value) => ({
                value: value ? dayjs(new Date(value, 1, 1)) : null,
              })}
            >
              <DatePicker
                placeholder={field.placeholder}
                className={style.dateInput}
                format={YEAR_FORMAT}
                picker={'year'}
                onChange={(date) =>
                  form.setFieldValue(field.name, dayjs(date).year())
                }
                disabledDate={(current) => {
                  return current && current.valueOf() > Date.now();
                }}
              />
            </Form.Item>
          );
        case 'select':
          return (
            <Form.Item
              name={field.name}
              label={field.label}
              rules={field.rules}
              initialValue={getDefaultOptionForSelectField(field)}
              style={{ width: '100%' }}
              tooltip={
                field.tooltip && {
                  title: field.tooltip,
                  icon: <InfoCircleOutlined />,
                }
              }
            >
              <Select
                mode={field.selectMode ?? undefined}
                placeholder={field.placeholder}
                options={field.options ?? getOptionsForSelectField(field.name)}
                filterOption={(input, option) => {
                  return ((option?.label as string) ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                disabled={field.disabled || disabled}
                showSearch={true}
                notFoundContent={<Empty description={t('nema_podataka')} />}
              />
            </Form.Item>
          );
        case 'select-input': {
          return (
            <ActsSelectInput
              field={field}
              initialValue={getDefaultOptionForSelectField(field)}
              options={field.options ?? getOptionsForSelectField(field.name)}
            />
          );
        }
        case 'range': {
          return (
            <Form.Item
              name={field.name}
              label={field.label}
              rules={field.rules}
              getValueProps={(value) => ({
                value: value ? [dayjs(value[0]), dayjs(value[1])] : null,
              })}
            >
              <RangePicker
                placeholder={[t('od'), t('do')]}
                defaultValue={dayjs(form.getFieldValue(field.name))}
                format={DATE_FORMAT_UI}
                onChange={(date: Dayjs) =>
                  form.setFieldValue(field.name, dayjs(date).toISOString())
                }
                style={{ width: '100%' }}
              />
            </Form.Item>
          );
        }
        default:
          return <div>Field type is not supported</div>;
      }
    };

    const dokumentaTemplate = () => {
      const template = zahtevId ? (
        <div className={style.dynamicFormMainContainer}>
          {schema.attachments?.sections.map((attachmentSection, index) => {
            return (
              <Row key={index} gutter={[10, 10]} align={'stretch'}>
                <Col span={24}>
                  <h3>{attachmentSection.sectionTitle}</h3>
                </Col>
                {attachmentSection.fields.map((field, index) => {
                  const documentFieldName = field.name[1];
                  const document =
                    requestsStore.documentUploadRes?.[documentFieldName];
                  return (
                    <Col key={index} span={field.span ?? 8}>
                      <div className={style.colContainer}>
                        {renderField(
                          field,
                          !!document && !!document?.documentId
                        )}
                        {document && document?.documentId && (
                          <UploadedFile
                            zahtevId={zahtevId}
                            document={document}
                            onRemove={() => removeDocument(field.name)}
                          />
                        )}
                        {field.additionalInputFields?.map(
                          (additionalField, index) => (
                            <div key={index}>
                              {renderField(additionalField)}
                            </div>
                          )
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </div>
      ) : (
        <div className={style.dynamicFormMainContainer}>
          <p style={{ color: 'red' }}>
            {t('forma.sekcija.prilog.prilog_warrning')}
          </p>
        </div>
      );
      return template;
    };

    const tabsItems: TabsProps['items'] = [
      {
        key: 'podaci',
        label: t('podaci_zahteva'),
        forceRender: true,
        children: (
          <div className={style.dynamicFormMainContainer}>
            {schema.inputs.sections.map((section, index) => {
              return (
                <Row key={index} gutter={25}>
                  <Col span={24}>
                    <h3>{section.sectionTitle}</h3>
                  </Col>
                  {section.fields.map((field, index) => {
                    return (
                      <Col key={index} span={field.span ?? 8}>
                        {renderField(field)}
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </div>
        ),
      },
      {
        key: 'dokumenta',
        forceRender: true,
        label: t('prilozi'),
        disabled: !schema.attachments?.sections.length,
        children: dokumentaTemplate(),
      },
    ];

    const removeDocument = (fieldName: string[]) => {
      requestsStore.removeDocumentUploadRes(fieldName);
      form.resetFields([fieldName]);
    };

    return (
      <>
        <h2>{schema.formTitle}</h2>
        <Form
          form={form}
          name={schema.formName}
          onFinish={handleCreateRequest}
          // onValuesChange={() => setIsFormDirty(true)}
          layout='vertical'
          initialValues={
            zahtevNacrt ?? {
              zahtevId: zahtevId,
              zahtevTipId: zahtevTipId,
            }
          }
          className={style.formContainer}
        >
          <Tabs type={'card'} defaultActiveKey='podaci' items={tabsItems} />
          {/* <SigningDocument
            isFormDirty={isFormDirty}
            isDownloaded={isDownloaded}
            form={form}
            setIsFormDirty={setIsFormDirty}
            setIsDownloaded={setIsDownloaded}
            saveDraftHandler={handleSaveDraft}
          /> */}
          <div className={style.formActionsContainer}>
            <Button type='primary' danger onClick={handleCancel}>
              {t('odustanite')}
            </Button>
            <Button type='primary' onClick={handleSaveDraft}>
              {t('sacuvajte')}
            </Button>
            <Button
              type='primary'
              form={schema.formName}
              htmlType='submit'
              loading={isLoading}
              disabled={isLoading}
            >
              {t('podnesite')}
            </Button>
          </div>
        </Form>
        <CreatedRequestModal
          onCloseModal={handleCloseSucccessCreatedRequestModal}
          pisarnicaBroj={pisarnicaBroj}
        />
      </>
    );
  }
);

export default DynamicForm;
