import { Button, Flex, Form, Input, Modal, notification, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { authService } from '@/modules/auth/auth.service.ts';
import { authStore } from '@/modules/auth/auth.store.ts';
import {
  UserRegistrationFormData,
  UserRegistrationFormDataOstalo,
} from '@/modules/auth/auth.types.ts';
import { requestsService } from '@/modules/requests/requests.service.ts';
import { ROUTES } from '@/routes/routes.tsx';
import { vrstePreduzeca } from '@/utils/constants.ts';
import Loading from '../../../Loading/Loading.tsx';
import style from './AddBusinessEntityModal.module.scss';
import { OstaloForm } from './components/OstaloForm/OstaloForm.tsx';
import { ModalName, modalStore } from '@/modules/modal/modal.store.ts';

const AddBusinessEntityModal = observer(() => {
  const isOpen = modalStore.isVisible(ModalName.ADD_BUSINESS_ENTITY);
  const { t } = useTranslation();
  const onCloseModal = () => {
    modalStore.closeModal(ModalName.ADD_BUSINESS_ENTITY);
  };
  const [sendingForm, setSendingForm] = useState<boolean>(false);

  return (
    <Modal
      title={t('unesite_identifikator_privrednog_subjekta')}
      className={style.modalWrapper}
      open={isOpen}
      okButtonProps={{
        type: 'primary',
        htmlType: 'submit',
        form: 'addBusinessEntity',
        disabled: sendingForm,
        loading: sendingForm,
      }}
      cancelButtonProps={{ danger: true, type: 'primary' }}
      onCancel={onCloseModal}
      cancelText={t('odustanite')}
      okText={t('nastavite')}
      destroyOnClose
    >
      <AddBusinessEntity
        isModal={true}
        onFinish={() => onCloseModal()}
        onSendingForm={setSendingForm}
      />
    </Modal>
  );
});

type AddBusinessEntityProps = {
  isModal: boolean;
  onFinish?: () => void;
  onSendingForm?: (value: boolean) => void;
};

export const AddBusinessEntity = ({
  isModal,
  onFinish,
  onSendingForm,
}: AddBusinessEntityProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm<
    UserRegistrationFormData | UserRegistrationFormDataOstalo
  >();
  const [ostalo, setOstalo] = useState<boolean>(false);
  const [loadingConfig, setLoadingConfig] = useState<boolean>(false);
  const [sendingForm, setSendingForm] = useState<boolean>(false);

  useEffect(() => {
    setLoadingConfig(true);
    requestsService.loadConfig().finally(() => setLoadingConfig(false));
  }, []);

  const handleOstalo = async (formData: UserRegistrationFormDataOstalo) => {
    const { file, ...other } = formData;
    const payload = {
      data: JSON.stringify({
        ...other,
        fileName: file.fileName,
      }),
      file: new Blob([file.data], {
        type: 'application/octet-stream',
      }),
    };
    authService
      .registerOstalo(payload)
      .then(() => {
        notification.success({
          message: t('dodajte_privredni_subjekt_success'),
        });
      })
      .catch((err) => {
        notification.error(err);
      })
      .finally(() => {
        setSendingForm(false);
        onSendingForm && onSendingForm(false);
      });
  };

  const handleRegistration = (formData: UserRegistrationFormData) => {
    authService
      .register(formData)
      .then(async () => {
        const entities = await authService.getAllBusinessEntities();
        const userData = await authService.getUserMainInfo();
        await authStore.login(userData);
        authStore.setBusinessEntities(entities);
        notification.success({
          message: t('dodajte_privredni_subjekt_success'),
        });
        onFinish && onFinish();
        navigate(ROUTES.SETTINGS);
      })
      .catch((err) => {
        notification.error(err);
      })
      .finally(() => {
        setSendingForm(false);
        onSendingForm && onSendingForm(false);
      });
  };

  const handleFormSubmit = async (
    formData: UserRegistrationFormData | UserRegistrationFormDataOstalo
  ) => {
    setSendingForm(true);
    onSendingForm && onSendingForm(true);
    if (ostalo) {
      handleOstalo(formData as UserRegistrationFormDataOstalo);
      return;
    }
    handleRegistration(formData as UserRegistrationFormData);
  };

  const checkIfOstalo = (value: number) => {
    if (value === 4) {
      setOstalo(true);
    } else setOstalo(false);
  };

  return (
    <div className={style.businessEntityFormWrapper}>
      {loadingConfig ? (
        <div className={style.loaderWrapper}>
          <Loading size='large' />
        </div>
      ) : (
        <>
          <Form
            form={form}
            name='addBusinessEntity'
            onFinish={handleFormSubmit}
            layout={ostalo ? 'vertical' : 'horizontal'}
          >
            {!isModal && (
              <h2>{t('unesite_identifikator_privrednog_subjekta')}</h2>
            )}
            <Form.Item
              className={style.radioGroup}
              name='tipSubjekta'
              rules={[
                {
                  required: true,
                  message: t('potreban_tip_subjekta'),
                },
              ]}
            >
              <Radio.Group
                className={style.radioGroup}
                onChange={(value) => checkIfOstalo(value.target.value)}
              >
                <div className={style.radioGroupContainer}>
                  {vrstePreduzeca.map((vrsta) => {
                    return (
                      <Radio value={vrsta.value} key={vrsta.value}>
                        {vrsta.label}
                      </Radio>
                    );
                  })}
                </div>
              </Radio.Group>
            </Form.Item>
            {!ostalo && (
              <Form.Item
                validateFirst
                className={style.inputContainer}
                name='identifikator'
                label={t('maticni_broj')}
                rules={[
                  {
                    required: true,
                    message: t('potreban_maticni_broj'),
                  },
                  {
                    max: 8,
                    message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
                  },
                ]}
              >
                <Input
                  placeholder={t('maticni_broj_placeholder')}
                  onBeforeInput={(event) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    if (!/[0-9]/.test(event.data)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            )}
            {ostalo && <OstaloForm />}
            {!isModal && (
              <Flex justify='end'>
                <Button
                  type='primary'
                  className='mb-10'
                  htmlType='submit'
                  loading={sendingForm}
                  disabled={sendingForm}
                >
                  {t('nastavite')}
                </Button>
              </Flex>
            )}
          </Form>
        </>
      )}
    </div>
  );
};

export default AddBusinessEntityModal;
