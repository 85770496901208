import { t } from 'i18next';

export const API_URL = import.meta.env.VITE_API_URL;
export const MODE = import.meta.env.MODE;
export const isDevelopment = () => MODE === 'development';

export const vrsteGeoloskihIstrazivanjaPodzemneVode = [
  {
    value: 1,
    label: t(
      'vrsta_geoloskih_istrazivanja.podzemne_vode.inzenjersko_geotehnicka'
    ),
  },
  {
    value: 2,
    label: t('vrsta_geoloskih_istrazivanja.podzemne_vode.podzemne_vode'),
  },
  {
    value: 3,
    label: t(
      'vrsta_geoloskih_istrazivanja.podzemne_vode.petrogeotermanli_resursi'
    ),
  },
];

export const vrsteGeoloskihIstrazivanjaMineralneSirovine = [
  {
    value: 4,
    label: t('vrsta_geoloskih_istrazivanja.mineralne_sirovine.metalicne'),
  },
  {
    value: 5,
    label: t('vrsta_geoloskih_istrazivanja.mineralne_sirovine.nemetalicne'),
  },
  {
    value: 6,
    label: t('vrsta_geoloskih_istrazivanja.mineralne_sirovine.energetske'),
  },
  {
    value: 7,
    label: t(
      'vrsta_geoloskih_istrazivanja.mineralne_sirovine.prirodni_gradjevinski_materijali'
    ),
  },
];

export const MOCK_DELATNOSTI = [
  {
    value: 1,
    label: 'Delatnost1',
  },
  {
    value: 2,
    label: 'Delatnost12',
  },
  {
    value: 3,
    label: 'Delatnost14',
  },
];

export const MOCK_STATUSI_OSTALO = [
  {
    value: 1,
    label: 'Kreiran',
  },
  {
    value: 2,
    label: 'Odoboren',
  },
  {
    value: 3,
    label: 'Odbijen',
  },
];

export const mesta = [
  {
    value: 1,
    label: 'Beograd',
  },
  {
    value: 2,
    label: 'Novi Sad',
  },
  {
    value: 3,
    label: 'Subotica',
  },
];

export const katastarskaOpstina = [
  {
    mesta: [
      {
        label: 'Mesto1',
        value: 1,
      },
      {
        label: 'Мрмош',
        value: 51,
      },
    ],
    label: 'Opstina1',
    value: 1,
  },
  {
    mesta: [
      {
        label: 'Mesto2',
        value: 1,
      },
      {
        label: 'Лаћислед',
        value: 50,
      },
    ],
    label: 'Opstina2',
    value: 2,
  },
];

export const mineralneSirovine = [
  {
    value: 1,
    label: 'Bakar',
  },
  {
    value: 2,
    label: 'Zlato',
  },
  {
    value: 3,
    label: 'Blato',
  },
  {
    label: 'Нафта ',
    value: 41,
  },
  {
    label: 'Нафта и гас',
    value: 42,
  },
  {
    label: 'Радиоактивне сировине',
    value: 43,
  },
  {
    label: 'Гас',
    value: 44,
  },
];

export const opstine = [
  {
    value: 3,
    label: 'Babusnica',
  },
  {
    value: 4,
    label: 'Vlasotince',
  },
  {
    value: 5,
    label: 'Vracar',
  },
  {
    value: 6,
    label: 'Valjevo',
  },
  {
    value: 7,
    label: 'Donji Milanovac - Majdanpek',
  },
];

export const vrstePreduzeca = [
  {
    value: 1,
    label: t('privredno_drustvo'),
  },
  {
    value: 2,
    label: t('preduzetnik'),
  },
  {
    value: 4,
    label: t('ostalo'),
  },
];
