import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import MainLayout from '../components/MainLayout.tsx';
import { lazy, ReactNode, Suspense } from 'react';
import {
  FormOutlined,
  ProfileOutlined,
  SettingOutlined,
  TableOutlined,
} from '@ant-design/icons';
import Login from '../pages/Login/Login.tsx';
import Loading from '../components/Loading/Loading.tsx';
import SubmittedRequests from '../pages/SubmittedRequests/SubmittedRequests.tsx';
import Registration from '../pages/Registration/Registration.tsx';
import LoginSuccess from '../pages/Login/LoginSuccess.tsx';
import { MRE_ROLES, PS_ROLES } from '@/modules/auth/auth.constants.ts';
import Settings from '@/pages/Settings/Settings.tsx';
import RequestView from '@/pages/RequestView/RequestView.tsx';
import Acts from '@/pages/Acts/Acts.tsx';
import Guard from '@/components/Guard/Guard.tsx';
import { OtherDetails } from '@/pages/Settings/Other/components/OtherDetails/OtherDetails.tsx';
import BusinessRegisterDetails from '@/pages/Settings/BusinessRegister/components/BusinessRegisterDetails/BusinessRegisterDetails.tsx';
import AccessDenied from '@/pages/AcessDenied/AccessDenied.tsx';
import DraftRequestForm from '@/pages/NewRequest/DraftRequestForm/DraftRequestForm.tsx';

const GroupsListPage = lazy(
  () =>
    import('../pages/NewRequest/GroupsAndRequestListPage/GroupsListPage.tsx')
);

const NewRequestForm = lazy(
  () => import('../pages/NewRequest/NewRequestForm/NewRequestFrom.tsx')
);

export type Route = {
  path: string;
  element: ReactNode;
  shouldBeInSidebar: boolean;
  key: string;
  children?: RouteObject[];
  label?: string;
  icon?: ReactNode;
  requiredRoles?: number[];
};

export const getCurrentRouteKey = (currentPath: string) => {
  return routes.find((route) => route.path === currentPath)?.key || '';
};

export enum ROUTES {
  HOME = '/', // eslint-disable-line
  LOGIN_SUCCESS = '/login-success', // eslint-disable-line
  ACCESS_DENIED = '/access-denied', // eslint-disable-line
  MY_ACCOUNT = '/nalog', // eslint-disable-line
  NEW_REQUEST = '/nov-zahtev', // eslint-disable-line
  DRAFT_REQUEST = '/zahtev-nacrt', // eslint-disable-line
  NEW_REQUEST_FORM = '/nov-zahtev/:zahtevTipId', // eslint-disable-line
  DRAFT_REQUEST_FORM = '/zahtev-nacrt/:zahtevId/:tipZahtevaId', // eslint-disable-line
  SUBMITTED_REQUESTS = '/zahtevi', // eslint-disable-line
  REQUEST_DETAILS = '/zahtevi/:zahtevId/:tipZahtevaId', // eslint-disable-line
  ACT = '/resenja', // eslint-disable-line
  SETTINGS = '/podesavanja', // eslint-disable-line
  REGISTRATION = '/registracija-korisnik', // eslint-disable-line
  OTHER_DETAILS = '/ostalo-detalji', // eslint-disable-line
  BUSINESS_REGISTER = '/registar-privrednih-subjekata', // eslint-disable-line
}

export const routes: Route[] = [
  {
    key: 'home',
    path: ROUTES.HOME,
    element: <Login />,
    shouldBeInSidebar: false,
  },
  {
    key: 'login-success',
    path: ROUTES.LOGIN_SUCCESS,
    element: <LoginSuccess />,
    shouldBeInSidebar: false,
  },
  {
    key: 'access-denied',
    path: ROUTES.ACCESS_DENIED,
    element: <AccessDenied />,
    shouldBeInSidebar: false,
  },
  // TBD If this route is needed
  // {
  //   key: 'nalog',
  //   path: ROUTES.MY_ACCOUNT,
  //   element: <MyAccount />,
  //   shouldBeInSidebar: false,
  //   label: 'Nalog',
  // },
  {
    key: 'podnesite_nov_zahtev',
    path: ROUTES.NEW_REQUEST,
    element: (
      <Guard
        requiredRoles={[
          PS_ROLES.PRIVREDNI_SUBJEKT,
          PS_ROLES.PODNOSILAC_ZAHTEVA,
        ]}
        isRoute={true}
      >
        <Suspense fallback={<Loading size='large' />}>
          <GroupsListPage />
        </Suspense>
      </Guard>
    ),
    shouldBeInSidebar: true,
    label: 'Podnesi nov zahtev',
    icon: <FormOutlined />,
    requiredRoles: [PS_ROLES.PRIVREDNI_SUBJEKT, PS_ROLES.PODNOSILAC_ZAHTEVA],
  },
  {
    key: 'forma_zahteva',
    path: ROUTES.NEW_REQUEST_FORM,
    element: (
      <Suspense fallback={<Loading size='large' />}>
        <NewRequestForm />
      </Suspense>
    ),
    shouldBeInSidebar: false,
  },
  {
    key: 'edit_forma_zahteva',
    path: ROUTES.DRAFT_REQUEST_FORM,
    element: (
      <Suspense fallback={<Loading size='large' />}>
        <DraftRequestForm />
      </Suspense>
    ),
    shouldBeInSidebar: false,
  },
  {
    key: 'lista_zahteva',
    path: ROUTES.SUBMITTED_REQUESTS,
    element: (
      <Guard
        requiredRoles={[
          PS_ROLES.PRIVREDNI_SUBJEKT,
          PS_ROLES.PODNOSILAC_ZAHTEVA,
          PS_ROLES.PREGLEDAC_ZAHTEVA_RESENJA,
          MRE_ROLES.POMOCNIK,
          MRE_ROLES.SEF_ODSEKA,
          MRE_ROLES.OBRADJIVAC_ZAHTEVA,
          MRE_ROLES.PREGLEDAC_ZAHTEVA,
          MRE_ROLES.SEKTOR_ZA_GEOLOGIJU_I_RUDARSTVO,
          MRE_ROLES.OVLASCENO_LICE_ZA_POTPISIVANJE,
          MRE_ROLES.RUKOVODILAC_PRAVNE_SLUZBE,
        ]}
        isRoute={true}
      >
        <SubmittedRequests />
      </Guard>
    ),
    shouldBeInSidebar: true,
    label: 'Podneti zahtevi',
    icon: <TableOutlined />,
    requiredRoles: [
      PS_ROLES.PRIVREDNI_SUBJEKT,
      PS_ROLES.PODNOSILAC_ZAHTEVA,
      PS_ROLES.PREGLEDAC_ZAHTEVA_RESENJA,
      MRE_ROLES.POMOCNIK,
      MRE_ROLES.SEF_ODSEKA,
      MRE_ROLES.OBRADJIVAC_ZAHTEVA,
      MRE_ROLES.PREGLEDAC_ZAHTEVA,
      MRE_ROLES.SEKTOR_ZA_GEOLOGIJU_I_RUDARSTVO,
      MRE_ROLES.OVLASCENO_LICE_ZA_POTPISIVANJE,
      MRE_ROLES.RUKOVODILAC_PRAVNE_SLUZBE,
    ],
  },
  {
    key: 'obrada_zahteva',
    path: ROUTES.REQUEST_DETAILS,
    element: <RequestView />,
    shouldBeInSidebar: false,
    label: 'Obrada zahteva',
  },
  {
    key: 'lista_akata',
    path: ROUTES.ACT,
    element: (
      <Guard
        requiredRoles={[
          PS_ROLES.PRIVREDNI_SUBJEKT,
          PS_ROLES.PODNOSILAC_ZAHTEVA,
          PS_ROLES.PREGLEDAC_ZAHTEVA_RESENJA,
          MRE_ROLES.POMOCNIK,
          MRE_ROLES.SEF_ODSEKA,
          MRE_ROLES.OBRADJIVAC_ZAHTEVA,
          MRE_ROLES.PREGLEDAC_ZAHTEVA,
          MRE_ROLES.SEKTOR_ZA_GEOLOGIJU_I_RUDARSTVO,
          MRE_ROLES.OVLASCENO_LICE_ZA_POTPISIVANJE,
          MRE_ROLES.RUKOVODILAC_PRAVNE_SLUZBE,
        ]}
        isRoute={true}
      >
        <Acts />
      </Guard>
    ),
    shouldBeInSidebar: true,
    label: 'Lista akata',
    icon: <ProfileOutlined />,
    requiredRoles: [
      PS_ROLES.PRIVREDNI_SUBJEKT,
      PS_ROLES.PODNOSILAC_ZAHTEVA,
      PS_ROLES.PREGLEDAC_ZAHTEVA_RESENJA,
      MRE_ROLES.POMOCNIK,
      MRE_ROLES.SEF_ODSEKA,
      MRE_ROLES.OBRADJIVAC_ZAHTEVA,
      MRE_ROLES.PREGLEDAC_ZAHTEVA,
      MRE_ROLES.SEKTOR_ZA_GEOLOGIJU_I_RUDARSTVO,
      MRE_ROLES.OVLASCENO_LICE_ZA_POTPISIVANJE,
      MRE_ROLES.RUKOVODILAC_PRAVNE_SLUZBE,
    ],
  },
  {
    key: 'podesavanja',
    path: ROUTES.SETTINGS,
    element: (
      <Guard
        requiredRoles={[
          PS_ROLES.PRIVREDNI_SUBJEKT,
          MRE_ROLES.SUPERVIZOR,
          PS_ROLES.ADMINISTRATOR,
        ]}
        isRoute={true}
      >
        <Settings />
      </Guard>
    ),
    shouldBeInSidebar: true,
    label: 'Podesavanja',
    icon: <SettingOutlined />,
    requiredRoles: [
      PS_ROLES.PRIVREDNI_SUBJEKT,
      MRE_ROLES.SUPERVIZOR,
      PS_ROLES.ADMINISTRATOR,
    ],
  },
  {
    key: 'ostalo_detalji',
    path: `${ROUTES.OTHER_DETAILS}/:id`,
    element: <OtherDetails />,
    shouldBeInSidebar: false,
    label: 'Ostalo detalji',
  },
  {
    key: 'registar-privrednih-subjekata',
    path: `${ROUTES.BUSINESS_REGISTER}/:id`,
    element: <BusinessRegisterDetails />,
    shouldBeInSidebar: false,
    label: 'Prgista privrednih subjekata detalji',
  },
  {
    key: 'registracija_korisnika',
    path: ROUTES.REGISTRATION,
    element: <Registration />,
    shouldBeInSidebar: false,
    label: 'Registracija korisnika',
  },
  // This route is used only for testing purposes, don't push uncommented on master!
  // {
  //   key: 'FormBuilder',
  //   path: '/formbuilder',
  //   element: (
  //     <DynamicForm
  //       schema={getNewRequestFormDetails(1)}
  //       onSubmit={console.log}
  //     />
  //   ),
  //   shouldBeInSidebar: true,
  //   label: 'Form builder',
  //   icon: <SettingOutlined />,
  // },
];

export const ROLE_DEFAULT_ROUTE: { [key: number]: string } = {
  1: ROUTES.SETTINGS,
  2: ROUTES.NEW_REQUEST,
  3: ROUTES.SUBMITTED_REQUESTS,
  4: ROUTES.SUBMITTED_REQUESTS,
  5: ROUTES.SUBMITTED_REQUESTS,
  6: ROUTES.SUBMITTED_REQUESTS,
  7: ROUTES.SUBMITTED_REQUESTS,
  8: ROUTES.SETTINGS,
  9: ROUTES.SETTINGS,
  10: ROUTES.SUBMITTED_REQUESTS,
  11: ROUTES.SUBMITTED_REQUESTS,
  12: ROUTES.SUBMITTED_REQUESTS,
};

const routerRoutes: RouteObject[] = [
  {
    element: <MainLayout />,
    children: [
      ...routes.map((route) => ({
        path: route.path,
        element: route.element,
        children: route.children,
      })),
      {
        path: '*',
        element: <Navigate to={'/'} />,
      },
    ],
  },
];
export const router = createBrowserRouter(routerRoutes);
