import { useTranslation } from 'react-i18next';

import logo from '../../assets/logo.png';
import style from './Logo.module.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes.tsx';

type LogoProps = {
  collapsed?: boolean;
  primary?: boolean;
  height?: number;
};
const Logo = ({
  collapsed = false,
  primary = false,
  height = 60,
}: LogoProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClickOnLogo = () => {
    navigate(ROUTES.SETTINGS);
  };

  return (
    <div className={style.logoBox} onClick={handleClickOnLogo}>
      <img height={height} alt={t('logoAlt')} src={logo} />
      {!collapsed && (
        <div>
          <p
            className={`${style.logoText} ${style.bold} ${primary && `${style.primary}`}`}
          >
            {t('republika_srbija')}
          </p>
          <p
            className={`${style.logoText} ${style.lessBold} ${primary && `${style.primary}`}`}
          >
            {t('ministarstvo')}
          </p>
          <p
            className={`${style.logoText}  ${style.lessBold} ${primary && `${style.primary}`}`}
          >
            {t('rudarstva_i_energetike')}
          </p>
        </div>
      )}
    </div>
  );
};

export default Logo;
