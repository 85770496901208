import { IZahteviSviZahteviPayload } from './submittedRequests.types';

export const INITIAL_REQUEST_FILTER_PARAMS: IZahteviSviZahteviPayload = {
  zahtevGrupaId: null,
  zahtevPodgrupaId: null,
  zahtevKategorijaPodgrupeId: null,
  zavodniBroj: null,
  datumOd: null,
  datumDo: null,
  statusZahteva: null,
  nazivZahtevaId: null,
  maticniBrojPrivrednogSubjekta: null,
  nazivPrivredniSubjekt: null,
  obradjivac: null,
  statusRaspodela: null,
};
