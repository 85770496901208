import { action, makeObservable, observable } from 'mobx';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { requestsService } from './requests.service.ts';
import {
  ActType,
  AllocationStatus,
  DokumentaAtributiProps,
  Group,
  IRequestsStore,
  Processors,
  RequestStatus,
} from './requests.types.ts';

type Config = {
  vrsteGeoloskihIstrazivanja: {
    1: DefaultOptionType[];
    2: DefaultOptionType[];
    3: DefaultOptionType[];
  };
  vrstePrivrednogSubjekta: DefaultOptionType[];
  opstine: DefaultOptionType[];
  mesta: DefaultOptionType[];
  mineralneSirovine: DefaultOptionType[];
  predmetIstrazivanja: DefaultOptionType[];
  delatnosti: DefaultOptionType[];
  statusiOstalo: DefaultOptionType[];
  katastarskaMestaOpstine: DefaultOptionType[];
};

class RequestsStore implements IRequestsStore {
  groupsAndRequests: Group[] = [];
  requestStatuses: RequestStatus[] = [];
  actTypes: ActType[] = [];
  requestProcessor: Processors[] = [];
  allocationStatuses: AllocationStatus[] = [];
  config: Config | undefined = undefined;
  canGenerateAct: boolean = false;
  documentUploadRes:
    | {
        [key: string]: DokumentaAtributiProps;
      }
    | undefined = undefined;

  constructor() {
    makeObservable(this, {
      groupsAndRequests: observable,
      requestStatuses: observable,
      requestProcessor: observable,
      actTypes: observable,
      allocationStatuses: observable,
      config: observable,
      canGenerateAct: observable,
      documentUploadRes: observable,
      setConfig: action,
      getGroupsAndRequests: action,
      setActTypes: action,
      setGroupsAndRequests: action,
      setRequestProcessor: action,
      setRequestStatuses: action,
      setAllocationStatuses: action,
      setCanGenerateAct: action,
      setDocumentUploadRes: action,
    });
  }

  setDocumentUploadRes(
    data:
      | {
          [key: string]: DokumentaAtributiProps;
        }
      | undefined
  ) {
    this.documentUploadRes = data;
  }

  getGroupsAndRequests(): Promise<unknown> {
    return requestsService.getGroupsAndRequests();
  }

  setGroupsAndRequests(groupsAndRequests: Group[]) {
    this.groupsAndRequests = groupsAndRequests;
  }

  setRequestStatuses(data: RequestStatus[]) {
    this.requestStatuses = data;
  }

  setActTypes(data: ActType[]) {
    this.actTypes = data;
  }

  setRequestProcessor(data: Processors[]) {
    this.requestProcessor = data;
  }

  setAllocationStatuses(data: AllocationStatus[]) {
    this.allocationStatuses = data;
  }

  setConfig(data: Config) {
    this.config = data;
  }

  setCanGenerateAct(data: boolean) {
    this.canGenerateAct = data;
  }

  removeDocumentUploadRes(fieldName: string[]) {
    if (!this.documentUploadRes) {
      return;
    }
    const documentFieldName = fieldName[1];
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const { [documentFieldName]: removedAtribut, ...restAtributes } =
      this.documentUploadRes;
    this.setDocumentUploadRes(restAtributes);
  }
}

export const requestsStore = new RequestsStore();
