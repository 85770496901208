import {
  DownloadOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Tooltip,
} from 'antd';
import { useEffect, useMemo, useRef } from 'react';

import { ProstorEnum } from '@/modules/requests/requests.types';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { t } from 'i18next';
import style from './Locality.module.scss';
import { writeExcel } from '@/utils/writeExcel';
import readXlsxFile from 'read-excel-file';

type LocalityProps = {
  formListName: string | string[];
  disabled?: boolean;
  context?: ProstorEnum | undefined;
  coordinatesTitle?: string;
};

const blankCoordinates = [
  {
    koordinataX: undefined,
    koordinataY: undefined,
  },
  {
    koordinataX: undefined,
    koordinataY: undefined,
  },
  {
    koordinataX: undefined,
    koordinataY: undefined,
  },
];

const COORDINATES = {
  X: { MIN: 4630000, MAX: 5130000 },
  Y: { MIN: 7300000, MAX: 7670000 },
};

const EXAMPLE_COORDINATES = [
  {
    koordinataX: 4630000,
    koordinataY: 7300000,
  },
  {
    koordinataX: 4630001,
    koordinataY: 7300001,
  },
  {
    koordinataX: 4630002,
    koordinataY: 7300002,
  },
  {
    koordinataX: 4630003,
    koordinataY: 7300003,
  },
];

const EXAMPLE_COORDINATES_COL_DEF = [
  {
    dataIndex: 'koordinataX',
    title: 'koordinataX',
  },
  {
    dataIndex: 'koordinataY',
    title: 'koordinataY',
  },
];

const Locality = ({
  formListName,
  disabled,
  context,
  coordinatesTitle,
}: LocalityProps) => {
  const form = useFormInstance();
  const nazivLokaliteaWatch = Form.useWatch(
    [...formListName, 'nazivLokaliteta'],
    form
  );

  useEffect(() => {
    if (
      !nazivLokaliteaWatch?.trim() &&
      context === ProstorEnum.PROSTOR_OPCIONI
    ) {
      form.resetFields([[...formListName, 'koordinate']]);
    }
  }, [nazivLokaliteaWatch]);

  return (
    <Row gutter={25} style={{ width: '100%' }}>
      <Lokalitet
        formListName={[...formListName, 'nazivLokaliteta']}
        disabled={disabled}
        context={context}
      />
      <Koordinate
        formListName={[...formListName, 'koordinate']}
        disabled={disabled}
        context={context}
        title={coordinatesTitle}
      />
    </Row>
  );
};

export default Locality;

export const Lokalitet = ({
  formListName,
  disabled,
  context,
}: {
  disabled?: boolean;
  formListName: string[] | string;
  isCoordinateRequired?: boolean;
  context?: ProstorEnum | undefined;
}) => {
  const form = useFormInstance();
  const nazivLokaliteaWatch = Form.useWatch(formListName, form);
  const isCoordinateRequired = useMemo(() => {
    return (
      context === ProstorEnum.PROSTOR_OBAVEZNI || nazivLokaliteaWatch?.trim()
    );
  }, [context, nazivLokaliteaWatch]);

  return (
    <Col span={12}>
      <Form.Item
        name={formListName}
        label={t('forma.sekcija.polje.naziv_lokaliteta')}
        rules={[
          {
            required: isCoordinateRequired,
            message: t('forma.sekcija.polje.obavezno_polje'),
          },
          {
            max: 100,
            message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
          },
        ]}
      >
        <Input
          disabled={disabled}
          placeholder={t('forma.sekcija.polje.naziv_lokaliteta_placeholder')}
        />
      </Form.Item>
    </Col>
  );
};

export const Koordinate = ({
  formListName,
  context,
  disabled = false,
  title = t('forma.sekcija.polje.koordinate_istraznog_prostora'),
}: {
  formListName: string[] | string;
  disabled?: boolean;
  context?: ProstorEnum | undefined;
  title?: string;
}) => {
  const form = useFormInstance();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const nazivLokaliteaWatch = Form.useWatch([context, 'nazivLokaliteta'], form);
  const isCoordinateRequired = useMemo(() => {
    return (
      context === ProstorEnum.PROSTOR_OBAVEZNI || nazivLokaliteaWatch?.trim()
    );
  }, [context, nazivLokaliteaWatch]);

  const isCoordinatesDisabled = useMemo(() => {
    return (
      (context === ProstorEnum.PROSTOR_OPCIONI &&
        !nazivLokaliteaWatch?.trim()) ||
      disabled
    );
  }, [nazivLokaliteaWatch, disabled]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (!file) return;

    readXlsxFile(file)
      .then((rows) => {
        const mappedCoordinates = rows.slice(1).map((row) => ({
          koordinataX: Number(row[0]) || 0,
          koordinataY: Number(row[1]) || 0,
        }));

        if (!mappedCoordinates.length) {
          notification.error({
            message: t('neispravan_file_format'),
          });
          return;
        }

        form.setFieldValue([...formListName], mappedCoordinates);
        setTimeout(() => {
          validateCoordinates();
        });
      })
      .catch((error) => {
        console.error('Error reading file:', error);
      })
      .finally(() => {
        event.target.value = '';
      });
  };

  const validateCoordinates = () => {
    const coordinates = form.getFieldValue(formListName);
    if (coordinates?.length) {
      coordinates.forEach((_k: unknown, index: number) => {
        form.validateFields([[...formListName, index, 'koordinataX']]);
        form.validateFields([[...formListName, index, 'koordinataY']]);
      });
    }
  };

  const handleDownloadExampleFile = () => {
    writeExcel(
      EXAMPLE_COORDINATES,
      EXAMPLE_COORDINATES_COL_DEF,
      'primer_koordinata'
    );
  };

  return (
    <Col span={12}>
      <p className={style.coordinateLabel}>{title}</p>
      <Form.List name={formListName} initialValue={blankCoordinates}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => {
              return (
                <Row key={key} gutter={[20, 20]}>
                  <Col span={12}>
                    <Form.Item
                      name={[name, 'koordinataX']}
                      rules={[
                        {
                          required: isCoordinateRequired,
                          message: t('forma.sekcija.polje.koordinate_error'),
                        },
                        {
                          type: 'number',
                          min: COORDINATES.X.MIN,
                          max: COORDINATES.X.MAX,
                          message: t(
                            'forma.sekcija.polje.koordinate_x_range_error'
                          ),
                        },
                      ]}
                      layout='horizontal'
                      colon={false}
                      label={'X'}
                      className={style.singleCoordinateColumn}
                    >
                      <InputNumber
                        disabled={isCoordinatesDisabled}
                        onChange={(e) =>
                          form.setFieldValue(
                            [
                              ...formListName,
                              'koordinate',
                              name,
                              'koordinataX',
                            ],
                            e
                          )
                        }
                        style={{ width: '100%' }}
                        controls={false}
                        value={form.getFieldValue([
                          ...formListName,
                          'koordinate',
                          name,
                          'koordinataX',
                        ])}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Flex gap={8}>
                      <Form.Item
                        className={style.singleCoordinateColumn}
                        {...restField}
                        name={[name, 'koordinataY']}
                        rules={[
                          {
                            required: isCoordinateRequired,
                            message: t('forma.sekcija.polje.koordinate_error'),
                          },
                          {
                            type: 'number',
                            min: COORDINATES.Y.MIN,
                            max: COORDINATES.Y.MAX,
                            message: t(
                              'forma.sekcija.polje.koordinate_y_range_error'
                            ),
                          },
                        ]}
                        label={'Y'}
                        layout='horizontal'
                        colon={false}
                      >
                        <InputNumber
                          disabled={isCoordinatesDisabled}
                          onChange={(e) =>
                            form.setFieldValue(
                              [
                                ...formListName,
                                'koordinate',
                                name,
                                'koordinataY',
                              ],
                              e
                            )
                          }
                          value={form.getFieldValue([
                            ...formListName,
                            'koordinate',
                            name,
                            'koordinataY',
                          ])}
                          style={{ width: '100%' }}
                          controls={false}
                        />
                      </Form.Item>
                      {index > 2 && !isCoordinatesDisabled && (
                        <Tooltip title={t('forma.dugme.obrisi_set')}>
                          <Button
                            className={style.removeCoordinateSetButton}
                            danger
                            onClick={() => remove(index)}
                            icon={<MinusCircleOutlined />}
                          />
                        </Tooltip>
                      )}
                    </Flex>
                  </Col>
                </Row>
              );
            })}
            {/*TODO Will be added in the future*/}
            {/*<Button type='primary'>*/}
            {/*  {t('forma.dugme.pregled_poligona')}*/}
            {/*</Button>*/}
            {!isCoordinatesDisabled && (
              <>
                <div className={style.coordinateActions}>
                  <div className={style.addCoordinateSetContainer}>
                    <p>{t('forma.dugme.dodajte_novi_set')}</p>
                    <Button
                      className={style.addCoordinateSetButton}
                      type={'primary'}
                      onClick={() => add()}
                      icon={<PlusCircleOutlined className={style.icon} />}
                      iconPosition={'end'}
                    ></Button>
                  </div>
                  <div className={style.addCoordinateSetContainer}>
                    <input
                      type='file'
                      onChange={handleFileUpload}
                      accept={'.xlsx'}
                      ref={inputFileRef}
                    />
                    <p>{t('forma.dugme.dodajte_novi_set_excel')}</p>
                    <Button
                      className={style.addCoordinateSetButton}
                      type={'primary'}
                      icon={<PlusCircleOutlined className={style.icon} />}
                      iconPosition={'end'}
                      onClick={() => {
                        if (inputFileRef.current) {
                          inputFileRef.current.click();
                        }
                      }}
                    ></Button>
                  </div>
                </div>
                <div className={style.downloadExampleWrapper}>
                  <Button
                    className={style.addCoordinateSetButton}
                    onClick={handleDownloadExampleFile}
                    icon={<DownloadOutlined className={style.icon} />}
                    iconPosition={'end'}
                  >
                    {t('forma.dugme.preuzmi_primer_excel_fajla')}
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </Form.List>
    </Col>
  );
};
