import axios, { ApiResponse } from '@/utils/axios';
import { IResenjaPayload, IResenjaResponse } from './acts.types';
import { AxiosResponse } from 'axios';

type ActsRepoType = {
  getActs: (
    payload: IResenjaPayload
  ) => Promise<AxiosResponse<ApiResponse<IResenjaResponse[]>>>;
};

const getActs = (payload: IResenjaPayload) => {
  return axios.post('/zahtevi/dohvati-resenja', payload);
};

export const actsRepo: ActsRepoType = {
  getActs,
};
