/* eslint-disable no-unused-vars */
import { MoreOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Empty,
  Flex,
  MenuProps,
  notification,
  Table,
  TableProps,
  Tooltip,
  Typography,
} from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import Guard from '@/components/Guard/Guard.tsx';
import { PageWrapper } from '@/components/PageWrapper/PageWrapper.tsx';
import { PopconfirmButton } from '@/components/PopconfirmButton/PopconfirmButton.tsx';
import AllocationStatus from '@/components/Status/AllocationStatus/AllocationStatus.tsx';
import RequestStatus from '@/components/Status/RequestStatus/RequestStatus.tsx';
import { PS_ROLES } from '@/modules/auth/auth.constants.ts';
import { authStore } from '@/modules/auth/auth.store.ts';
import { ModalName, modalStore } from '@/modules/modal/modal.store.ts';
import { requestsService } from '@/modules/requests/requests.service.ts';
import { INITIAL_REQUEST_FILTER_PARAMS } from '@/modules/submittedRequests/submittedRequests.constants.ts';
import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service.ts';
import {
  IFilters,
  IZahteviSviZahteviPayload,
  IZahteviSviZahteviResponse,
} from '@/modules/submittedRequests/submittedRequests.types.ts';
import { ROUTES } from '@/routes/routes.tsx';
import { getLocalDateFromUTCDateString } from '@/utils/dateFormatter.ts';
import { writeExcel } from '@/utils/writeExcel.ts';
import DynamicTitle from '../../components/DynamicTitle/DynamicTitle';
import SubmittedRequestsFilters from './components/SubmittedRequestsFilters/SubmittedRequestsFilters.tsx';
import { SubmittedRequestsModal } from './components/SubmittedRequestsModal/SubmittedRequestsModal.tsx';
import style from './SubmittedRequests.module.scss';
import { AkcijeEnum, ZahteviEnum } from '@/modules/requests/requests.types.ts';
const { Paragraph } = Typography;

const SubmittedRequests = observer(() => {
  const [params, setParams] = useState<IZahteviSviZahteviPayload>(
    INITIAL_REQUEST_FILTER_PARAMS
  );
  const [tableData, setTableData] = useState<IZahteviSviZahteviResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [actionId, setActionId] = useState<number | null>(null);
  const [zahtevId, setZahtevId] = useState<number | null>(null);
  const navigate = useNavigate();

  const getRequests = () => {
    setTableLoading(true);
    console.log('Getting reqs with params ', params);
    submittedRequestsService
      .postZahteviSviZahtevi(params)
      .then(async (res) => {
        await requestsService.loadConfig();
        setTableData(res);
      })
      .catch((err) => {
        console.error('Error getting all requests ', err);
        notification.error(err);
      })
      .finally(() => {
        setLoading(false);
        setTableLoading(false);
      });
  };

  const getRequestsDebounce = useDebouncedCallback(getRequests, 500);

  useEffect(() => {
    getRequestsDebounce();
  }, [params, authStore.selectedBusinessEntity?.id]);

  const getXlsxDataSet = () => {
    return {
      data: createDataForExcel(),
      colDefs: createColForExcel(),
      title: 'lista_zahteva',
    };
  };

  const createColForExcel = () => {
    return getColDef()?.filter((col) => col.key !== 'akcije');
  };

  const createDataForExcel = () => {
    return tableData.map((record) => {
      return {
        ...record,
        statusRaspodele: t(`statusi.raspodela.${record.statusRaspodele}`),
        status: t(`statusi.zahtev.${record.status}`),
        datumPodnosenja: getLocalDateFromUTCDateString(record.datumPodnosenja),
      };
    });
  };

  const handleExportExcel = () => {
    writeExcel(
      getXlsxDataSet().data,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getXlsxDataSet().colDefs as any,
      getXlsxDataSet().title
    );
  };

  const getRequestActions = (record: IZahteviSviZahteviResponse) => {
    if (!record.akcije) return [];
    const items: MenuProps['items'] = record?.akcije.map(
      (akcija: IFilters, index) => {
        switch (akcija.value) {
          case AkcijeEnum.VRATI:
          case AkcijeEnum.PROSLEDI:
          case AkcijeEnum.DODELI:
            return {
              label: akcija.label,
              key: akcija.value,
              onClick: (event) => {
                event.domEvent.stopPropagation();
                modalStore.openModal(ModalName.SUBMITTED_REQUEST);
                setZahtevId(record.zahtevId);
                setActionId(akcija.value);
              },
            };
          case AkcijeEnum.OBRADI:
            return {
              label: (
                <PopconfirmButton
                  title='da_li_ste_sigurni_preuzmete_zahtev'
                  onConfirm={() => {
                    submittedRequestsService
                      .getZahteviZahtevPreuzimanje(record.zahtevId)
                      .then(() => {
                        getRequests();
                        notification.success({
                          message: t('preuzmi_zahtev_success'),
                        });
                      })
                      .catch((err) => {
                        console.error('Error preuzimanje ', err);
                        notification.error(err);
                      });
                  }}
                  children={<span>{akcija.label}</span>}
                />
              ),
              key: `${akcija.value}-preuzmite-${index}`,
              onClick: (event) => {
                event.domEvent.stopPropagation();
              },
            };
          default:
            return null;
        }
      }
    );
    return items;
  };

  const getColDef = (): TableProps<IZahteviSviZahteviResponse>['columns'] => {
    return [
      {
        dataIndex: 'nazivZahteva',
        title: t('naziv_zahteva'),
        align: 'left',
        fixed: 'left',
        width: 300,
      },
      {
        dataIndex: 'zavodniBroj',
        title: t('broj_zahteva'),
        align: 'center',
        width: 175,
        render: (_, { zavodniBroj }) => {
          return zavodniBroj ? (
            <Typography.Paragraph
              className='m-0'
              copyable={{
                tooltips: [
                  t('kopirajte_broj_zahteva'),
                  t('broj_kopiranje_success'),
                ],
                text: zavodniBroj,
              }}
            >
              {zavodniBroj}
            </Typography.Paragraph>
          ) : null;
        },
      },
      {
        dataIndex: 'datumPodnosenja',
        title: t('datum_i_vreme_podnosenja_zahteva'),
        align: 'center',
        width: 200,
        render: (_, { datumPodnosenja }) => {
          return getLocalDateFromUTCDateString(datumPodnosenja);
        },
      },
      {
        dataIndex: 'maticniBrojPrivrednogSubjekta',
        title: t('maticni_broj_privrednog_subjekta'),
        align: 'center',
        hidden: authStore.isUserPS,
        width: 200,
      },
      {
        dataIndex: 'nazivPrivrednogSubjekta',
        title: t('naziv_privrednog_subjekta'),
        align: 'center',
        hidden: authStore.isUserPS,
        width: 200,
      },
      {
        dataIndex: 'obradjivac',
        align: 'center',
        title: t('obradjivac'),
        hidden: authStore.isUserPS,
        width: 120,
      },
      {
        dataIndex: 'status',
        title: t('status_zahteva'),
        align: 'center',
        width: 120,
        render: (statusId: number) => {
          return <RequestStatus statusId={statusId} />;
        },
      },
      {
        dataIndex: 'statusRaspodele',
        align: 'center',
        title: t('status_raspodele'),
        hidden: authStore.isUserPS,
        render: (statusRaspodeleId: number) => {
          return <AllocationStatus statusId={statusRaspodeleId} />;
        },
        width: 375,
      },
      {
        dataIndex: 'grupaZahteva',
        title: t('grupa_zahteva'),
        align: 'center',
        render: (record: string) => {
          return (
            <Tooltip title={record}>
              <Paragraph ellipsis>{record}</Paragraph>
            </Tooltip>
          );
        },
      },
      {
        dataIndex: 'podgrupaZahteva',
        align: 'center',
        title: t('podgrupa_zahteva'),
      },
      {
        dataIndex: 'tipMineralnihSirovina',
        width: 200,
        align: 'center',
        title: t('tip_mineralne_sirovine_materijala'),
      },
      {
        dataIndex: 'nazivOdeljenja',
        align: 'center',
        title: t('naziv_odeljenja'),
        hidden: authStore.isUserPS,
      },
      {
        title: t('akcije'),
        key: 'akcije',
        align: 'center',
        fixed: 'right',
        hidden: authStore.isUserPS,
        width: 100,
        render: (record: IZahteviSviZahteviResponse) => {
          if (!record?.akcije?.length) return null;
          const items = getRequestActions(record);
          return (
            <Dropdown menu={{ items }} trigger={['click']}>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Flex justify='center' align='center'>
                  <MoreOutlined style={{ fontSize: 25, color: 'black' }} />
                </Flex>
              </a>
            </Dropdown>
          );
        },
      },
    ];
  };

  return (
    <PageWrapper isLoading={loading} greyFrame>
      <div>
        <SubmittedRequestsModal
          actionId={actionId}
          zahtevId={zahtevId}
          refreshRequestsTable={getRequests}
        />
        <DynamicTitle
          title='stranice.lista_zahteva'
          subComponent={
            <>
              <Button
                className='mr-10'
                type='primary'
                onClick={handleExportExcel}
              >
                {t('izvezi_excel')}
              </Button>
              <Guard
                requiredRoles={[
                  PS_ROLES.PRIVREDNI_SUBJEKT,
                  PS_ROLES.PODNOSILAC_ZAHTEVA,
                ]}
              >
                <Button
                  onClick={() => navigate(ROUTES.NEW_REQUEST)}
                  type='primary'
                >
                  {t('kreirajte_novi_zahtev')}
                </Button>
              </Guard>
            </>
          }
        />
        <SubmittedRequestsFilters setParams={setParams} params={params} />
        <Table
          loading={tableLoading}
          rowKey={(record) => record.zahtevId}
          columns={getColDef()}
          className={style.table}
          pagination={{
            position: ['bottomRight'],
            pageSizeOptions: [10, 20, 50],
            showQuickJumper: true,
            showSizeChanger: true,
            locale: {
              page: t('stranu'),
              jump_to: t('idite_na'),
              items_per_page: t('/strani'),
            },
          }}
          dataSource={tableData}
          rowClassName={() => 'pointer'}
          scroll={{
            x: !authStore.isUserPS ? 3000 : 2000,
            y: 300,
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (!record?.zahtevTipId || !record?.zahtevId) {
                  return;
                }
                if (record.status === ZahteviEnum.NACRT) {
                  navigate(
                    `${ROUTES.DRAFT_REQUEST}/${record?.zahtevId}/${record?.zahtevTipId}`
                  );
                } else {
                  navigate(
                    `${ROUTES.SUBMITTED_REQUESTS}/${record?.zahtevId}/${record?.zahtevTipId}`
                  );
                }
              },
            };
          }}
          locale={{
            emptyText: <Empty description={t('nema_podataka')} />,
          }}
        />
      </div>
    </PageWrapper>
  );
});

export default SubmittedRequests;
