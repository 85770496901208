import { handleApiRequest } from '@/utils/axios';
import { isDevelopment } from '@/utils/constants';
import { actsRepo } from './acts.repo';
import { ACTS_MOCK } from '@/utils/mockData';
import { IResenjaPayload } from './acts.types';
import { Dayjs } from 'dayjs';

const getActs = async (payload: IResenjaPayload) => {
  if (isDevelopment()) {
    return Promise.resolve(ACTS_MOCK);
  }
  return handleApiRequest(
    actsRepo.getActs({
      ...payload,
      datumOd: (payload.datumOd as Dayjs)?.format(),
      datumDo: (payload.datumDo as Dayjs)?.format(),
    })
  );
};

export const actsService = {
  getActs,
};
