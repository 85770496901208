import { useState } from 'react';
import { Col, Row } from 'antd';

import { RequestDecisionApprovalActions } from './components/RequestDecisionApprovalActions/RequestDecisionApprovalActions';
import { RequestDecisionApprovalForm } from './components/RequestDecisionApprovalForm/RequestDecisionApprovalForm';
import { ResenjeProps } from '@/modules/requests/requests.types';

interface IRequestDecisionApproval {
  handleSuccess: () => void;
  requestDecision: Pick<
    ResenjeProps,
    'zavodniBrojResenja' | 'datumUrucenjaResenja'
  > | null;
}

export const RequestDecisionApproval = ({
  handleSuccess,
  requestDecision,
}: IRequestDecisionApproval) => {
  const [resenjeId, setResenjeId] = useState<number | null>(null);

  return (
    <Row gutter={25}>
      <Col span={16}>
        <RequestDecisionApprovalForm
          requestDecision={requestDecision}
          setResenjeId={setResenjeId}
          resenjeId={resenjeId}
        />
      </Col>
      <Col span={8}>
        <RequestDecisionApprovalActions
          resenjeId={resenjeId}
          handleSuccess={handleSuccess}
        />
      </Col>
    </Row>
  );
};
