import UploadedFile from '@/components/Forms/Upload/UploadedFile.tsx';
import { authStore } from '@/modules/auth/auth.store.ts';
import { mapDocumentsData } from '@/modules/requests/requests.documents.ts';
import { requestsService } from '@/modules/requests/requests.service.ts';
import { requestsStore } from '@/modules/requests/requests.store.ts';
import {
  DocumentAttributePayload,
  DynamicFormField,
  DynamicFormSchema,
  IMappedRequestDetailsResponseForForm,
  RequestDocumentsEnum,
  ResenjeDropdownOptionResponse,
  ZahteviEnum,
} from '@/modules/requests/requests.types.ts';
import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service.ts';
import { DATE_FORMAT_UI, YEAR_FORMAT } from '@/utils/dateFormatter.ts';
import { flattenRequests } from '@/utils/flattenRequestsFromGroups.ts';
import { formDataMappers } from '@/utils/formMapper.ts';
import { uploadDocuments } from '@/utils/uploadFile.ts';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tabs,
  TabsProps,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Rule } from 'antd/es/form/index';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ActsSelectInput } from '../ActsSelectInput/ActsSelectInput.tsx';
import Locality, { Koordinate, Lokalitet } from '../Locality/Locality.tsx';
import CustomUpload from '../Upload/CustomUpload.tsx';
import style from './DynamicForm.module.scss';
import clsx from 'clsx';

type DynamicFormProps = {
  schema: DynamicFormSchema;
  data: IMappedRequestDetailsResponseForForm;
  supplement?: boolean;
};

const { RangePicker } = DatePicker;

const DynamicFormReadOnly = observer(
  ({ schema, data, supplement = false }: DynamicFormProps) => {
    console.log('Data ', data);
    const { t } = useTranslation();
    const [form] = useForm();
    const [resenja, setResenja] = useState<DefaultOptionType[]>([]);
    const { zahtevId } = useParams();

    const poljaZaIzmenu = useMemo(() => {
      return data?.poljaZaIzmenu?.map((polje: string) => polje?.split('.'));
    }, [data]);

    const groupId = useMemo(() => {
      const flattenReqs = flattenRequests(requestsStore.groupsAndRequests);
      return flattenReqs.find((rq) => rq.id === data.zahtevTipId)?.groupId;
    }, [data.zahtevId]);

    useEffect(() => {
      requestsService
        .getResenja()
        .then((res) => {
          setResenja(res);
        })
        .catch((err) => {
          console.error(`Error getting resenja for ${data.zahtevId}`, err);
          setResenja([]);
        });
    }, [data.zahtevId]);

    const handleIsPoljaZaIzmenuEqual = (fieldName: string[]): boolean => {
      return Boolean(
        poljaZaIzmenu?.some((polje: string[]) => isEqual(polje, fieldName))
      );
    };

    const handleOnFinish = async () => {
      if (data?.status === ZahteviEnum.DOPUNA_ZAHTEVA && data.zahtevTipId) {
        const dataTest = {
          ...form.getFieldsValue(),
          zahtevTipId: +data.zahtevTipId!,
          zahtevId: +zahtevId!,
        };
        console.log('UNMAPED DynamicReadyOnly', dataTest);

        const mappedData = formDataMappers(dataTest);

        console.log('Mapped DynamicReadyOnly', {
          ...mappedData,
          zahtevId: +zahtevId!,
        });

        submittedRequestsService
          .postDopuniZahtev({ ...mappedData, zahtevId: +zahtevId! })
          .then(() => {
            notification.success({ message: t('uspesno_dopunjen_zahtev') });
            window.location.reload();
          })
          .catch((err) => {
            notification.error(err);
          });

        if (zahtevId) {
          const dokumentaAtributi: DocumentAttributePayload =
            form?.getFieldValue('dokumentaAtributi');
          const documents = mapDocumentsData(
            +data?.zahtevTipId,
            {
              ...form.getFieldsValue(),
              zahtevTipId: +data?.zahtevTipId,
            },
            dokumentaAtributi
          );

          if (data?.zahtevId) {
            await uploadDocuments(
              documents,
              +data?.zahtevId,
              requestsService.uploadDocument,
              +data?.zahtevTipId
            );
          }
        }
      } else {
        const supplementArray: string[] = form.getFieldValue('supplement');

        if (
          supplementArray?.length &&
          Number(zahtevId) &&
          requestsStore.canGenerateAct
        ) {
          submittedRequestsService
            .postZahteviSacuvajPoljaDopune({
              zahtevId: Number(zahtevId),
              poljaZaIzmenu: supplementArray,
            })
            .then(() => {
              notification.success({ message: t('sacuvaj_atribute_success') });
            })
            .catch((err) => {
              notification.error(err);
            });
        }
      }
    };

    const handleSupplementCheckForInput = (name: string[], value: boolean) => {
      const supplementArray: string[] = form.getFieldValue('supplement');
      const formInputName = name?.join('.');

      if (value) {
        form.setFieldValue(
          'supplement',
          supplementArray
            ? [...supplementArray, formInputName]
            : [formInputName]
        );
      } else {
        form.setFieldValue(
          'supplement',
          supplementArray.filter((element) => element !== formInputName)
        );
      }
    };

    const getOptionsForSelectField = (fieldName: string) => {
      switch (fieldName) {
        case 'vrstaGeoloskogIstrazivanjaId':
          return requestsStore.config?.vrsteGeoloskihIstrazivanja[
            groupId as 1 | 2 | 3
          ];
        case 'predmetIstrazivanjaId':
          return requestsStore.config?.predmetIstrazivanja || [];
        case 'vrstaPreduzece':
          return requestsStore.config?.vrstePrivrednogSubjekta || [];
        case 'opstinaId':
          return requestsStore.config?.opstine || [];
        case 'mestoId':
          return requestsStore.config?.mesta || [];
        case 'vrstaMineralnihSirovinaId':
          return requestsStore.config?.mineralneSirovine || [];
        case 'resenjeId':
        case 'resenja':
          return resenja;
        case 'zahtevi':
          return resenja;
        case 'tipZahteva':
          return [] as DefaultOptionType[];
        default: {
          const tipResenja = +fieldName[fieldName.length - 1];
          const newResenja: DefaultOptionType[] = [];
          if (data) {
            newResenja.push(
              ...(data.selectedResenja as ResenjeDropdownOptionResponse[]).map(
                (res: ResenjeDropdownOptionResponse) => ({
                  value: res.id ?? res.brojResenja,
                  label: res.brojResenja,
                  tipResenja: tipResenja,
                })
              )
            );
          }
          return [...newResenja, ...resenja];
        }
      }
    };

    const getDefaultOptionForSelectField = (field: DynamicFormField) => {
      const tipResenja = field.name[field.name.length - 1];
      const nacrt = data;
      if (nacrt) {
        const resenje = (
          nacrt.selectedResenja as ResenjeDropdownOptionResponse[]
        )?.find(
          (res: ResenjeDropdownOptionResponse) => res.tipResenja === +tipResenja
        );
        return resenje?.id ?? resenje?.brojResenja;
      }
      return undefined;
    };

    const removeDocument = (fieldName: string[]) => {
      requestsStore.removeDocumentUploadRes(fieldName);
      form.resetFields([fieldName]);
    };

    const renderField = (
      field: DynamicFormField,
      isDisabled: boolean = true
    ) => {
      switch (field.type) {
        case 'text':
          return (
            <Form.Item
              key={
                Array.isArray(field.name) ? field.name.join('.') : field.name
              }
              name={field.name}
              label={field.label}
              rules={field.rules}
              style={{ width: '100%' }}
              tooltip={
                field.tooltip && {
                  title: field.tooltip,
                  icon: <InfoCircleOutlined />,
                }
              }
            >
              <Input
                disabled={isDisabled}
                type='text'
                placeholder={field.placeholder}
              />
            </Form.Item>
          );
        case 'number':
          return (
            <Form.Item
              name={field.name}
              label={field.label}
              rules={field.rules}
              style={{ width: '100%' }}
            >
              <InputNumber
                disabled={isDisabled}
                placeholder={field.placeholder}
                controls={false}
                addonAfter={field.prefix}
                style={{ width: '100%' }}
              />
            </Form.Item>
          );
        case 'numberFloat':
          return (
            <Form.Item
              name={field.name}
              label={field.label}
              rules={field.rules}
            >
              <InputNumber
                disabled={isDisabled}
                placeholder={field.placeholder}
                controls={false}
                addonAfter={field.prefix}
                style={{ width: '100%' }}
                step={'0.01'}
                parser={(value) => Number(value).toFixed(2)}
              />
            </Form.Item>
          );
        case 'textarea':
          return (
            <Form.Item
              name={field.name}
              label={field.label}
              rules={field.rules}
              style={{ width: '100%' }}
            >
              <Input.TextArea
                disabled={isDisabled}
                placeholder={field.placeholder}
              />
            </Form.Item>
          );
        case 'location':
          return (
            <Locality
              disabled={isDisabled}
              formListName={field.name}
              context={field.context}
              coordinatesTitle={field.coordinatesTitle}
            />
          );
        case 'koordinate': {
          return (
            <Row gutter={25} style={{ width: '100%' }}>
              <Koordinate
                disabled={isDisabled}
                formListName={field.name}
                context={field.context}
              />
            </Row>
          );
        }
        case 'lokalitet': {
          return (
            <Row gutter={25} style={{ width: '100%' }}>
              <Lokalitet
                disabled={isDisabled}
                formListName={field.name}
                context={field.context}
              />
            </Row>
          );
        }
        case 'upload': {
          const fieldName = field.name[1];
          let fieldRules: Rule[] | undefined = field?.rules;
          if (
            fieldName === String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA)
          ) {
            fieldRules = [
              {
                required: data?.isOstalo,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ];
          }
          return (
            <Form.Item
              name={field.name}
              rules={
                !requestsStore.documentUploadRes?.[fieldName]
                  ? fieldRules
                  : undefined
              }
            >
              <CustomUpload
                form={form}
                name={field.name}
                label={field.label!}
                rules={field?.rules}
                readOnly={isDisabled}
              />
            </Form.Item>
          );
        }
        case 'date':
          return (
            <Form.Item
              label={field.label}
              name={field.name}
              rules={field.rules}
              getValueProps={(value) => ({
                value: value ? dayjs(value) : null,
              })}
            >
              <DatePicker
                disabled={isDisabled}
                placeholder={field.placeholder}
                className={style.dateInput}
                format={DATE_FORMAT_UI}
              />
            </Form.Item>
          );
        case 'select':
          return (
            <Form.Item
              name={field.name}
              label={field.label}
              rules={field.rules}
              style={{ width: '100%' }}
            >
              <Select
                disabled={isDisabled}
                mode={field.selectMode ?? undefined}
                placeholder={field.placeholder}
                options={
                  field.options ??
                  getOptionsForSelectField(field.name[field.name.length - 1])
                }
              />
            </Form.Item>
          );
        case 'select-input': {
          return (
            <ActsSelectInput
              field={field}
              initialValue={getDefaultOptionForSelectField(field)}
              options={
                field.options ??
                getOptionsForSelectField(field.name[field.name.length - 1])
              }
              disabled={isDisabled}
            />
          );
        }
        case 'year':
          return (
            <Form.Item
              label={field.label}
              name={field.name}
              rules={field.rules}
              getValueProps={(value) => ({
                value: value ? dayjs(new Date(value, 1, 1)) : null,
              })}
            >
              <DatePicker
                placeholder={field.placeholder}
                className={style.dateInput}
                format={YEAR_FORMAT}
                picker={'year'}
                onChange={(date) =>
                  form.setFieldValue(field.name, dayjs(date).year())
                }
                disabled={isDisabled}
                style={{ width: '100%' }}
              />
            </Form.Item>
          );
        case 'range': {
          return (
            <Form.Item
              name={field.name}
              label={field.label}
              rules={field.rules}
              getValueProps={(value) => ({
                value: value ? [dayjs(value[0]), dayjs(value[1])] : null,
              })}
            >
              <RangePicker
                disabled={isDisabled}
                placeholder={[t('od'), t('do')]}
                style={{ width: '100%' }}
              />
            </Form.Item>
          );
        }
        default:
          return <div>Field type is not supported</div>;
      }
    };

    const tabsItems: TabsProps['items'] = [
      {
        key: 'podaci',
        label: t('podaci_zahteva'),
        forceRender: true,
        children: (
          <div className={style.dynamicFormMainContainer}>
            {schema.inputs.sections.map((section, index) => {
              return (
                <Row key={index} gutter={25}>
                  <Col span={24}>
                    <h3>{section.sectionTitle}</h3>
                  </Col>
                  {section.fields.map((field, index) => {
                    return (
                      <Col key={index} span={field.span ?? 8}>
                        <Flex align='center' gap={5}>
                          {renderField(
                            field,
                            !(
                              data?.status === ZahteviEnum.DOPUNA_ZAHTEVA &&
                              authStore.isUserPS &&
                              handleIsPoljaZaIzmenuEqual(field.name)
                            )
                          )}
                          {supplement && (
                            <Checkbox
                              defaultChecked={handleIsPoljaZaIzmenuEqual(
                                field.name
                              )}
                              disabled={
                                data?.status === ZahteviEnum.DOPUNA_ZAHTEVA
                              }
                              onChange={(e) =>
                                handleSupplementCheckForInput(
                                  field.name,
                                  e.target.checked
                                )
                              }
                            />
                          )}
                        </Flex>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </div>
        ),
      },
      {
        key: 'dokumenta',
        forceRender: true,
        label: t('prilozi'),
        disabled: !schema.attachments?.sections.length,
        children: (
          <div className={style.dynamicFormMainContainer}>
            {schema.attachments?.sections.map((attachmentSection, index) => {
              return (
                <Row key={index} gutter={[10, 10]} align={'stretch'}>
                  <Col span={24}>
                    <h3>{attachmentSection.sectionTitle}</h3>
                  </Col>
                  {attachmentSection.fields.map((field, key) => {
                    const documentFieldName = field.name[1];
                    const document =
                      requestsStore.documentUploadRes?.[documentFieldName];
                    return (
                      <Col key={key} span={field.span ?? 8}>
                        <div
                          className={clsx(
                            { [style.hasSupplement]: supplement },
                            style.boxWrapper
                          )}
                        >
                          <div className={style.colContainer}>
                            {renderField(
                              field,
                              !(
                                data?.status === ZahteviEnum.DOPUNA_ZAHTEVA &&
                                authStore.isUserPS &&
                                handleIsPoljaZaIzmenuEqual(field.name)
                              )
                            )}
                            {document && (
                              <UploadedFile
                                zahtevId={data.zahtevId as number}
                                removeable={
                                  data?.status === ZahteviEnum.DOPUNA_ZAHTEVA &&
                                  authStore.isUserPS &&
                                  handleIsPoljaZaIzmenuEqual(field.name)
                                }
                                document={document}
                                onRemove={() => removeDocument(field.name)}
                              />
                            )}
                            {field.additionalInputFields?.map(
                              (additionalField) =>
                                renderField(
                                  additionalField,
                                  !(
                                    data?.status ===
                                      ZahteviEnum.DOPUNA_ZAHTEVA &&
                                    authStore.isUserPS &&
                                    handleIsPoljaZaIzmenuEqual(field.name)
                                  )
                                )
                            )}
                          </div>
                          {supplement && (
                            <Checkbox
                              defaultChecked={handleIsPoljaZaIzmenuEqual(
                                field.name
                              )}
                              disabled={
                                data?.status === ZahteviEnum.DOPUNA_ZAHTEVA
                              }
                              onChange={(e) =>
                                handleSupplementCheckForInput(
                                  field.name,
                                  e.target.checked
                                )
                              }
                            />
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </div>
        ),
      },
    ];

    return (
      <>
        <h2>{schema.formTitle}</h2>
        <Form
          form={form}
          name={schema.formName}
          layout='vertical'
          initialValues={data}
          className={style.formContainer}
        >
          <Tabs defaultActiveKey='podaci' items={tabsItems} />
          {supplement && (
            <div className={style.formActionsContainer}>
              <Button
                type='primary'
                form={schema.formName}
                onClick={handleOnFinish}
                disabled={
                  !(
                    requestsStore.canGenerateAct ||
                    (data?.status === ZahteviEnum.DOPUNA_ZAHTEVA &&
                      authStore.isUserPS)
                  )
                }
              >
                {t('sacuvajte')}
              </Button>
            </div>
          )}
        </Form>
      </>
    );
  }
);

export default DynamicFormReadOnly;
