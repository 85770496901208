import { IAuthStore, PrivredniSubjekt, UserData } from './auth.types.ts';
import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed,
} from 'mobx';
import { LocalStorage } from '@/utils/local-storage.ts';
import {
  MRE_ROLES_ARRAY,
  PS_ROLES_ARRAY,
} from '@/modules/auth/auth.constants.ts';

export const CURRENT_USER_LOCAL_STORAGE_KEY = 'currentUser';

class AuthStore implements IAuthStore {
  currentUser: UserData | undefined = undefined;
  businessEntities: PrivredniSubjekt[] = [];
  selectedBusinessEntity: PrivredniSubjekt | undefined = undefined;

  constructor() {
    makeObservable(this, {
      currentUser: observable,
      businessEntities: observable,
      selectedBusinessEntity: observable,
      login: action,
      logOut: action,
      clearUser: action,
      setBusinessEntities: action,
      setSelectedBusinessEntity: action,
      refreshUserRoles: action,
      userRoles: computed,
      isUserPS: computed,
      isUserMRE: computed,
    });
  }

  async logOut() {
    try {
      this.clearUser();
    } catch (e) {
      this.clearUser();
    }
  }

  async login(userData: UserData): Promise<UserData | undefined> {
    runInAction(() => {
      this.currentUser = userData;
      this.selectedBusinessEntity = userData.privredniSubjekti?.[0];
    });
    LocalStorage.setValue(CURRENT_USER_LOCAL_STORAGE_KEY, userData);
    return Promise.resolve(userData);
  }

  refreshUserRoles(userData: UserData) {
    this.currentUser = userData;
    this.selectedBusinessEntity = userData.privredniSubjekti?.[0];
    LocalStorage.setValue(CURRENT_USER_LOCAL_STORAGE_KEY, userData);
  }

  setBusinessEntities(businessEntities: PrivredniSubjekt[]) {
    this.businessEntities = businessEntities;
  }

  setSelectedBusinessEntity(businessEntityId: number) {
    const businessEntity = this.businessEntities.find(
      (entitiy) => entitiy.id === businessEntityId
    );
    this.selectedBusinessEntity = businessEntity;
    LocalStorage.setValue(CURRENT_USER_LOCAL_STORAGE_KEY, {
      ...this.currentUser,
      privredniSubjekti: [businessEntity],
    });
  }

  clearUser() {
    runInAction(() => {
      this.currentUser = undefined;
    });
    LocalStorage.removeValue(CURRENT_USER_LOCAL_STORAGE_KEY);
  }

  get userRoles() {
    return this.selectedBusinessEntity?.roles;
  }

  get isUserPS() {
    console.log('SELECTED ', this.selectedBusinessEntity);
    if (this.selectedBusinessEntity?.roles?.length) {
      return PS_ROLES_ARRAY.includes(
        this.selectedBusinessEntity.roles[0].roleId
      );
    }
    return false;
  }

  get isUserMRE() {
    if (this.selectedBusinessEntity?.roles?.length) {
      return MRE_ROLES_ARRAY.includes(
        this.selectedBusinessEntity.roles[0].roleId
      );
    }
    return false;
  }
}

export const authStore = new AuthStore();
