import { Flex } from 'antd';
import styles from './Registration.module.scss';

import { AddBusinessEntity } from '@/components/TopPanel/components/AddBusinessEntityModal/AddBusinessEntityModal.tsx';

const Registration = () => {
  return (
    <Flex className={styles.registrationWrapper} vertical>
      <AddBusinessEntity isModal={false} />
    </Flex>
  );
};

export default Registration;
