import { Collapse, CollapseProps } from 'antd';
import style from './RequestSectionWrapper.module.scss';

type RequestSectionWrapperProps = {
  children: React.ReactNode;
  title: string;
  openDefault?: boolean;
};

const RequestSectionWrapper = ({
  children,
  title,
  openDefault = false,
}: RequestSectionWrapperProps) => {
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: <span style={{ fontSize: 20 }}>{title}</span>,
      children: <div className={style.sectionContainer}>{children}</div>,
    },
  ];

  return (
    <Collapse
      defaultActiveKey={openDefault ? '1' : undefined}
      items={items}
      expandIconPosition='end'
    />
  );
};

export default RequestSectionWrapper;
