/* eslint-disable max-len */
import {
  zahtevZaDobijanjeOdobrenjaZaEksploatacionoPolje,
  prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanja,
  zahtevZaDobijanjeOdobrenjaKojimSeUtvrdjujeEksploatacioniProstorIKolicineRezervi,
  zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaEnergetskihhMineralnihSirovina,
  zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaMetalicnihMineralnihSirovina,
  zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaNemetalicnihMineralnihSirovina,
  zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala,
  zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaMetalicnihMineralnihSirovina,
  zahtevZaDobijanjeOdobrenjaZaProsirenjeGraniceEksploatacionogPolja,
  zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstor,
  zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjekta,
  zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaEnergetskihSirovina,
  zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaMetalicnihSirovina,
  zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaNemetalicnihSirovina,
  zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaPrirodnihSirovina,
  zahtevZaOdustajanjeOdRanijePodnetogZahtevaEnergetskihSirovina,
  zahtevZaOdustajanjeOdRanijePodnetogZahtevaMetalicnihSirovina,
  zahtevZaOdustajanjeOdRanijePodnetogZahtevaNemetalicnihSirovina,
  zahtevZaOdustajanjeOdRanijePodnetogZahtevaPrirodnihSirovina,
  zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeoloskihIstrazivanja,
  zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstor,
  zahtevZaPrenosPotvrdeORezervamaMineralnihSirovina,
  zahtevZaPrenosPotvrdeORezervamaPodzemnihVoda,
  zahtevZaPrestanakVazenjaOdobrenja,
  zahtevZaPrestanakVazenjaResenjaOOdobravanjuEnergetskihSirovina,
  zahtevZaPrestanakVazenjaResenjaOOdobravanjuMetalicnihSirovina,
  zahtevZaPrestanakVazenjaResenjaOOdobravanjuNemetalicnihSirovina,
  zahtevZaPrestanakVazenjaResenjaOOdobravanjuPrirodnihSirovina,
  zahtevZaPrimenjenaGeoloskaIstrazivnjaPodzemnihVoda,
  zahtevZaPrimenjenaInzenjerskoGeotehnickaIstrazivanja,
  zahtevZaProduzenjeRokaOdobrenjaZaPrimenjenaGeoloskaIstrazivanja,
  zahtevZaProduzenjeRokaZaPrimenjenaGeoloskaIstrazivanja,
  zahtevZaUtvrdjivanjeIOveruRazvrstanihRezerviIResursa,
  zahtevZaUtvrdjivanjeIOveruRazvrstanihRezerviMineralnihSirovina,
  zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaOdobrenjaEksploatacionogProstoraIKolicineRezervi,
  prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaMetalicnihMineralnihSirovina,
  prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaNemetalicnihMineralnihSirovina,
  prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaGradjevinskihMineralnihSirovina,
  prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala,
  zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMetalicneMineralneSirovine,
  zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaNemetalicneMineralneSirovine,
  zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaEnergetskeMineralneSirovine,
  zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaMetalicnihMineralnihSirovina,
  zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaNemetalicnihMineralnihSirovina,
  zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaEnergetskihhMineralnihSirovina,
  zahtevZaPrenosOdobrenjaEksploatacionogProstoraIKolicineRezerviPodzemnihVoda,
  zahtevZaOdustajanjeOdZahtevaPodzemneVode,
  zahtevZaDobijanjeOdobrenjaZaSmanjenjeGraniceEksploatacionogPolja,
  zahtevZaPrenosOdobrenjaZaEksploatacionoPolje,
  zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaNemetalicnihMineralnihSirovina,
  zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaEnergetskihhMineralnihSirovina,
  zahtevZaDobijanjeOdobrenjaZaIzgradnjuRudarskihObjekataIZaIzvodjenjeRudarskihRadova,
  zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMetalicneMineralneSirovine,
  zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaNemetalicneMineralneSirovine,
  zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaEnergetskeMineralneSirovine,
  zahtevZaDobijanjeOdobrenjaZaPustanjeUProbniRadRudarskihObjekata,
  zahtevZaDobijanjeOdobrenjaZaUpotrebuRudarskihObjekataUpotrebnaDozvola,
  zahtevZaPrenosOdobrenjaZaIzvodjenjeRudarskihRadovaIZaIzgradnjuRudarskihObjekata,
  zahtevZaDobijanjeOdobrenjaZaEksploatacijuPrirodnihGradjevinskihMaterijala,
  zahtevZaDobijanjeOdobrenjaZaIzvodjenjeRudarskihRadovaNaIstrazivanjuMineralnihSirovina,
  dostavljanjeTehnickogRudarskogProjektaSaTehnickomKontrolom,
  dostavljanjeUproscenogRudarskogProjekta,
  zahtevZaDobijanjeOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala,
  zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaGradjevinskeMaterijale,
  zahtevZaPrenosOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala,
  zahtevZaPrestanakVazenjaOdobrenjaZaEksploatacionoPoljeEksploatacijuNaZahtevNosioca,
  zahtevZaPrestanakVazenjaOdobrenjaZaEksploatacionoPoljeEksploatacijuPoOsnovuTrajneObustaveRadova,
  zahtevZaOdustajanjeOdZahteva,
  izvestajOMineralnimResursimaZaDobijanjePrirodnihGradjevinskihMaterijala,
  godisnjiIzvestajGeoloskaIstrazivanja,
  zavrsniIzvestajGeoloskaIstrazivanja,
  zahtevZaDobijanjeOdobrenjaZaProsirenjeGraniceEksploatacionogPoljaIzOdobrenjaZaEksploataciju,
  zahtevZaDobijanjeOdobrenjaZaSmanjenjeGraniceEksploatacionogPoljaIzOdobrenjaZaEksploataciju,
  izvestajOStanjuBilansnihRezerviZaPodzemneVodeIHidrogeotermalneResurse,
  izvestajOStanjuBilansnihRezerviZaPetrogeotermalneResurse,
  godisnjiIzvestajGeoloskaIstraživanja,
  zavrsniIzvestajGeoloskaIstraživanja,
} from '@/modules/requests/requests.forms.ts';
import { DynamicFormSchema } from '@/modules/requests/requests.types.ts';

const requestsForms: { [key: number]: DynamicFormSchema } = {
  1: zahtevZaPrimenjenaInzenjerskoGeotehnickaIstrazivanja,
  2: zahtevZaPrimenjenaGeoloskaIstrazivnjaPodzemnihVoda,
  3: zahtevZaProduzenjeRokaZaPrimenjenaGeoloskaIstrazivanja,
  4: zahtevZaProduzenjeRokaOdobrenjaZaPrimenjenaGeoloskaIstrazivanja,
  5: zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstor,
  6: zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeoloskihIstrazivanja,
  7: zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstor,
  8: prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanja,
  9: zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjekta,
  10: zahtevZaPrestanakVazenjaOdobrenja,
  11: zahtevZaOdustajanjeOdZahtevaPodzemneVode,
  12: zahtevZaUtvrdjivanjeIOveruRazvrstanihRezerviIResursa,
  13: zahtevZaPrenosPotvrdeORezervamaPodzemnihVoda,
  14: zahtevZaDobijanjeOdobrenjaKojimSeUtvrdjujeEksploatacioniProstorIKolicineRezervi,
  15: zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaOdobrenjaEksploatacionogProstoraIKolicineRezervi,
  16: zahtevZaPrenosOdobrenjaEksploatacionogProstoraIKolicineRezerviPodzemnihVoda,
  17: izvestajOStanjuBilansnihRezerviZaPodzemneVodeIHidrogeotermalneResurse,
  18: izvestajOStanjuBilansnihRezerviZaPetrogeotermalneResurse,
  19: godisnjiIzvestajGeoloskaIstraživanja,
  20: zavrsniIzvestajGeoloskaIstraživanja,
  21: zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaMetalicnihMineralnihSirovina,
  22: zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaMetalicnihMineralnihSirovina,
  23: zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMetalicneMineralneSirovine,
  24: zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaMetalicnihMineralnihSirovina,
  25: zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaMetalicneMineralneSirovine,
  26: prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaMetalicnihMineralnihSirovina,
  27: zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaMetalicnihSirovina,
  28: zahtevZaPrestanakVazenjaResenjaOOdobravanjuMetalicnihSirovina,
  29: zahtevZaOdustajanjeOdRanijePodnetogZahtevaMetalicnihSirovina,
  30: zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaNemetalicnihMineralnihSirovina,
  31: zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaNemetalicnihMineralnihSirovina,
  32: zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaNemetalicneMineralneSirovine,
  33: zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaNemetalicnihMineralnihSirovina,
  34: zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaNemetalicneMineralneSirovine,
  35: prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaNemetalicnihMineralnihSirovina,
  36: zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaNemetalicnihSirovina,
  37: zahtevZaPrestanakVazenjaResenjaOOdobravanjuNemetalicnihSirovina,
  38: zahtevZaOdustajanjeOdRanijePodnetogZahtevaNemetalicnihSirovina,
  39: zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaEnergetskihhMineralnihSirovina,
  40: zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaPrimenjenaGeologoskaIstrazivanjaEnergetskihhMineralnihSirovina,
  41: zahtevZaDobijanjeOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaEnergetskeMineralneSirovine,
  42: zahtevZaPrenosOdobrenjaIzOblastiVrsenjaPrimenjenihGeologskihIstrazivanjaEnergetskihhMineralnihSirovina,
  43: zahtevZaPrenosOdobrenjaZaZadrzavanjePravaNaIstrazniProstorZaEnergetskeMineralneSirovine,
  44: prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaGradjevinskihMineralnihSirovina,
  45: zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaEnergetskihSirovina,
  46: zahtevZaPrestanakVazenjaResenjaOOdobravanjuEnergetskihSirovina,
  47: zahtevZaOdustajanjeOdRanijePodnetogZahtevaEnergetskihSirovina,
  48: zahtevZaDobijanjeOdobrenjaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala,
  49: prijavaRadovaShodnoResenjuOOdobrenjuZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala,
  50: zahtevZaIzmenuIDopunuIstrazivanjaPoAneksuProjektaIstrazivanjaPrirodnihSirovina,
  51: zahtevZaPrestanakVazenjaResenjaOOdobravanjuPrirodnihSirovina,
  52: zahtevZaOdustajanjeOdRanijePodnetogZahtevaPrirodnihSirovina,
  53: zahtevZaUtvrdjivanjeIOveruRazvrstanihRezerviMineralnihSirovina,
  54: zahtevZaPrenosPotvrdeORezervamaMineralnihSirovina,
  55: izvestajOMineralnimResursimaZaDobijanjePrirodnihGradjevinskihMaterijala,
  56: godisnjiIzvestajGeoloskaIstrazivanja,
  57: zavrsniIzvestajGeoloskaIstrazivanja,
  58: zahtevZaDobijanjeOdobrenjaZaEksploatacionoPolje,
  59: zahtevZaDobijanjeOdobrenjaZaProsirenjeGraniceEksploatacionogPolja,
  60: zahtevZaDobijanjeOdobrenjaZaSmanjenjeGraniceEksploatacionogPolja,
  61: zahtevZaPrenosOdobrenjaZaEksploatacionoPolje,
  62: zahtevZaDobijanjeOdobrenjaZaIzgradnjuRudarskihObjekataIZaIzvodjenjeRudarskihRadova,
  63: zahtevZaDobijanjeOdobrenjaZaUpotrebuRudarskihObjekataUpotrebnaDozvola,
  64: zahtevZaDobijanjeOdobrenjaZaPustanjeUProbniRadRudarskihObjekata,
  66: zahtevZaPrenosOdobrenjaZaIzvodjenjeRudarskihRadovaIZaIzgradnjuRudarskihObjekata,
  67: zahtevZaDobijanjeOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala,
  68: zahtevZaDobijanjeOdobrenjaZaProduzenjeRokaVazenjaOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaGradjevinskeMaterijale,
  69: zahtevZaPrenosOdobrenjaZaEksploatacijuNemetalickihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala,
  70: zahtevZaDobijanjeOdobrenjaZaProsirenjeGraniceEksploatacionogPoljaIzOdobrenjaZaEksploataciju,
  71: zahtevZaDobijanjeOdobrenjaZaSmanjenjeGraniceEksploatacionogPoljaIzOdobrenjaZaEksploataciju,
  72: zahtevZaDobijanjeOdobrenjaZaEksploatacijuPrirodnihGradjevinskihMaterijala,
  73: zahtevZaPrestanakVazenjaOdobrenjaZaEksploatacionoPoljeEksploatacijuNaZahtevNosioca,
  74: zahtevZaPrestanakVazenjaOdobrenjaZaEksploatacionoPoljeEksploatacijuPoOsnovuTrajneObustaveRadova,
  75: zahtevZaOdustajanjeOdZahteva,
  76: zahtevZaDobijanjeOdobrenjaZaIzvodjenjeRudarskihRadovaNaIstrazivanjuMineralnihSirovina,
  77: dostavljanjeTehnickogRudarskogProjektaSaTehnickomKontrolom,
  78: dostavljanjeUproscenogRudarskogProjekta,
};

export const getNewRequestFormDetails = (id: number) => {
  return requestsForms[id];
};
