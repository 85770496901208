import DynamicTitle from '@/components/DynamicTitle/DynamicTitle';
import Loading from '@/components/Loading/Loading';
import { PopconfirmButton } from '@/components/PopconfirmButton/PopconfirmButton';
import PrivredniSubjektStatus from '@/components/Status/PrivredniSubjektStatus/PrivredniSubjektStatus';
import { requestsStore } from '@/modules/requests/requests.store';
import {
  EMPTY_STATE_PLACEHOLDER,
  INITIAL_PRIVREDNI_SUBJEKT_FILTER_PARAMS,
} from '@/modules/settings/settings.constants';
import { settingsService } from '@/modules/settings/settings.service';
import {
  Actions,
  IInitialPrivredniSubjektFilters,
  IPrivredniSubjektResponse,
} from '@/modules/settings/settings.types';
import { ROUTES } from '@/routes/routes';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Empty, Flex, MenuProps, notification, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { SettingsPrivredniSubjektiFilters } from '../../../components/SettingsPrivredniSubjektiFilters/SettingsPrivredniSubjektiFilters';
import { DefaultOptionType } from 'antd/es/cascader';
import { authStore } from '@/modules/auth/auth.store';

const BusinessRegister = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [params, setParams] = useState<IInitialPrivredniSubjektFilters>(
    INITIAL_PRIVREDNI_SUBJEKT_FILTER_PARAMS
  );
  const [tableData, setTableData] = useState<IPrivredniSubjektResponse[]>([]);
  const navigate = useNavigate();

  const fetchData = () => {
    const transformedParams: IInitialPrivredniSubjektFilters = {
      ...params,
      manuallyDisabled:
        params.manuallyDisabled !== null ? !!params.manuallyDisabled : null,
      status: params.status !== null ? !!params.status : null,
    };
    settingsService
      .postPrivredniSubjekti(transformedParams)
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        console.error('Error getting all others ', err);
        notification.error(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchDataDebounce = useDebouncedCallback(fetchData, 500);

  useEffect(() => {
    fetchDataDebounce();
  }, [params, authStore.selectedBusinessEntity?.id]);

  const handleConfirm = (
    manuallyDisabled: boolean,
    successMessage: string,
    id: number
  ) => {
    settingsService
      .getPrivredniSubjektiAktivirajDeaktiviraj({
        privredniSubjektId: id!,
        manuallyDisabled,
      })
      .then(() => {
        notification.success({ message: t(successMessage) });
        fetchData();
      })
      .catch((err) => {
        notification.error(err);
      });
  };

  const getRequestActions = (record: IPrivredniSubjektResponse) => {
    if (!record.akcije) return [];
    const items: MenuProps['items'] = record?.akcije.map((akcija: Actions) => {
      switch (akcija.value) {
        case 1:
          return {
            label: (
              <PopconfirmButton
                title='da_li_ste_sigurni_da_zelite_da_aktivirate_privredni_subjekt'
                onConfirm={() =>
                  handleConfirm(false, 'aktiviraj_success', record.id)
                }
                children={<span>{akcija.label}</span>}
              />
            ),
            key: akcija.value,
            onClick: (event) => {
              event.domEvent.stopPropagation();
            },
          };
        case 2:
          return {
            label: (
              <PopconfirmButton
                title='da_li_ste_sigurni_da_zelite_da_deaktivirate_privredni_subjekt'
                onConfirm={() =>
                  handleConfirm(true, 'deaktiviraj_success', record.id)
                }
                children={<span>{akcija.label}</span>}
              />
            ),
            key: akcija.value,
            onClick: (event) => {
              event.domEvent.stopPropagation();
            },
          };
        default:
          return null;
      }
    });
    return items;
  };

  const colDef: ColumnsType<IPrivredniSubjektResponse> = [
    {
      dataIndex: 'naziv',
      title: t('naziv_privrednog_subjekta'),
      width: 300,
      align: 'center',
      render: (record: string) => {
        return settingsService.getInputValueForUI(record);
      },
    },
    {
      dataIndex: 'maticniBroj',
      title: t('maticni_broj'),
      align: 'center',
      render: (record: string) => {
        return settingsService.getInputValueForUI(record);
      },
    },

    {
      dataIndex: 'pib',
      title: t('pib'),
      align: 'center',
      render: (record: string) => {
        return settingsService.getInputValueForUI(record);
      },
    },
    {
      title: t('delatnost'),
      align: 'center',
      render: (record: IPrivredniSubjektResponse) =>
        settingsService.findLabelById(
          requestsStore.config?.delatnosti,
          record?.delatnostId
        ),
    },
    {
      dataIndex: 'email',
      title: t('eposta'),
      align: 'center',
      render: (record: string) => {
        return settingsService.getInputValueForUI(record);
      },
    },
    {
      title: t('ime_i_prezime'),
      align: 'center',
      render: (record: IPrivredniSubjektResponse) =>
        record?.userIme && record?.userPrezime
          ? `${record.userIme} ${record.userPrezime}`
          : EMPTY_STATE_PLACEHOLDER,
    },
    {
      dataIndex: 'userEmail',
      title: t('eposta_korisnika'),
      align: 'center',
      render: (record: string) => {
        return settingsService.getInputValueForUI(record);
      },
    },
    {
      title: t('status'),
      align: 'center',
      render: (record: IPrivredniSubjektResponse) => {
        return record?.manuallyDisabled === undefined ||
          record?.manuallyDisabled === null ? (
          EMPTY_STATE_PLACEHOLDER
        ) : (
          <PrivredniSubjektStatus status={!record?.manuallyDisabled} />
        );
      },
    },
    {
      title: t('apr_status'),
      align: 'center',
      render: (record: IPrivredniSubjektResponse) => {
        return <PrivredniSubjektStatus status={record?.status} />;
      },
    },
    {
      title: t('akcije'),
      fixed: 'right',
      hidden: !tableData[0]?.akcije,
      width: 100,
      render: (record: IPrivredniSubjektResponse) => {
        if (!record?.akcije?.length) return EMPTY_STATE_PLACEHOLDER;
        const items = getRequestActions(record);
        return (
          <Dropdown menu={{ items }} trigger={['click']}>
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Flex justify='center' align='center'>
                <MoreOutlined style={{ fontSize: 25, color: 'black' }} />
              </Flex>
            </a>
          </Dropdown>
        );
      },
    },
  ];

  const statusesList: DefaultOptionType[] = [
    {
      value: 1,
      label: t('statusi.privredni_subjekt.aktivan'),
    },
    {
      value: 0,
      label: t('statusi.privredni_subjekt.deaktviran'),
    },
  ];

  const manuallyDisabledList: DefaultOptionType[] = [
    {
      value: 0,
      label: t('statusi.privredni_subjekt.aktivan'),
    },
    {
      value: 1,
      label: t('statusi.privredni_subjekt.deaktviran'),
    },
  ];

  return (
    <>
      {loading ? (
        <div className='settingsLoaderWrapper'>
          <Loading size='large' />
        </div>
      ) : (
        <>
          <DynamicTitle
            title='stranice.registar_privrednih_subjekata'
            className='mt-20 mb-20'
          />
          <SettingsPrivredniSubjektiFilters
            setParams={setParams}
            statusesList={statusesList}
            manuallyDisabled
            manuallyDisabledList={manuallyDisabledList}
          />
          <Table
            columns={colDef}
            className='mt-15'
            rowKey={(record) => record.id}
            rowClassName={() => 'pointer'}
            dataSource={tableData}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [10, 20, 50],
              showQuickJumper: true,
              showSizeChanger: true,
              locale: {
                page: t('stranu'),
                jump_to: t('idite_na'),
                items_per_page: t('/strani'),
              },
            }}
            scroll={{
              x: 2000,
              y: 300,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(`${ROUTES.BUSINESS_REGISTER}/${record.id}`);
                },
              };
            }}
            locale={{
              emptyText: <Empty description={t('nema_podataka')} />,
            }}
          />
        </>
      )}
    </>
  );
};

export default BusinessRegister;
