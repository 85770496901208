import { requestsService } from '@/modules/requests/requests.service.ts';
import { DokumentaAtributiProps } from '@/modules/requests/requests.types.ts';
import { handleDownloadFile } from '@/utils/handleDownloadFile.ts';
import {
  DeleteOutlined,
  DownloadOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { Button, Flex, notification, Tooltip, Typography } from 'antd';
import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import style from './CustomUpload.module.scss';
const { Paragraph } = Typography;

type UploadedFile = {
  document: DokumentaAtributiProps;
  zahtevId: number;
  removeable?: boolean;
  onRemove?: () => void;
};

const UploadedFile = ({
  document,
  zahtevId,
  removeable = true,
  onRemove,
}: UploadedFile) => {
  const handleDownload = async () => {
    if (zahtevId && document.documentId) {
      const downloadRequestsAttachmentResponse =
        await requestsService.downloadRequestsAttachment(
          zahtevId,
          document.documentId!
        );
      handleDownloadFile(downloadRequestsAttachmentResponse as AxiosResponse)
        .then(() => {
          notification.success({ message: t('preuzmite_prilog_success') });
        })
        .catch((err) => {
          console.error('Error downloading attachment ', err);
          notification.error({
            message: t('preuzmite_prilog_error'),
          });
        });
    } else {
      console.error(
        `Error downloading attachment, zahtevId: ${zahtevId} | documentId: ${document.documentId}`
      );
    }
  };

  const handleRemove = async () => {
    if (zahtevId && document?.documentId) {
      try {
        await requestsService.removeRequestsAttachment(
          zahtevId,
          document.documentId
        );
        onRemove && onRemove();
      } catch {
        console.error(
          `Error removing attachment, zahtevId: ${zahtevId} | documentId: ${document.documentId}`
        );
      }
    }
  };

  return (
    <Flex
      className={style.uploadedFileContainer}
      gap={4}
      justify={'space-between'}
      align={'center'}
    >
      <Tooltip title={document.fileName}>
        <Flex gap={4} align='center' className={style.fileName}>
          <FileOutlined />
          <Paragraph ellipsis className='m-0'>
            {document.fileName}
          </Paragraph>
        </Flex>
      </Tooltip>
      <Flex gap={4}>
        <Tooltip title={t('forma.sekcija.prilog.preuzmite_dokument')}>
          <Button onClick={handleDownload} className={style.iconButton}>
            <DownloadOutlined />
          </Button>
        </Tooltip>

        {removeable && (
          <Tooltip title={t('forma.sekcija.prilog.obrisite_dokument')}>
            <Button onClick={handleRemove} className={style.iconButton}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

export default UploadedFile;
