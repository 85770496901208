import { useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

import CompanyDetails from '@/pages/Settings/CompanyDetails/CompanyDetails.tsx';
import { PageWrapper } from '@/components/PageWrapper/PageWrapper';
import Users from '@/pages/Settings/Users/Users.tsx';
import { authStore } from '@/modules/auth/auth.store.ts';
import { Other } from './Other/Other';
import { requestsService } from '@/modules/requests/requests.service';
import BusinessRegister from './BusinessRegister/BusinessRegister';

const Settings = observer(() => {
  const [loadingConfig, setLoadingConfig] = useState<boolean>(false);
  const visibleItems = useMemo(() => {
    const label = authStore.isUserMRE
      ? t('podaci_o_ministarstvu')
      : t('podaci_o_privrednom_subjektu');

    const items = [
      {
        key: '1',
        label: label,
        children: <CompanyDetails />,
        visible: true,
      },
      {
        key: '2',
        label: t('stranice.korisnici'),
        children: <Users />,
        visible: true,
      },
      {
        key: '3',
        label: t('stranice.registar_privrednih_subjekata'),
        children: <BusinessRegister />,
        visible: authStore.isUserMRE,
      },
      {
        key: '4',
        label: t('stranice.ostalo'),
        children: <Other />,
        visible: authStore.isUserMRE,
      },
    ];

    return items.filter((item) => item.visible);
  }, [authStore.userRoles]);

  useEffect(() => {
    setLoadingConfig(true);
    requestsService.loadConfig().finally(() => setLoadingConfig(false));
  }, []);

  return (
    <PageWrapper greyFrame isLoading={loadingConfig}>
      <Tabs defaultActiveKey='1' items={visibleItems} />
    </PageWrapper>
  );
});

export default Settings;
