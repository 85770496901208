import { handleApiRequest } from '@/utils/axios.ts';
import { requestsRepo } from '@/modules/requests/requests.repo.ts';
import {
  isDevelopment,
  katastarskaOpstina,
  mesta,
  mineralneSirovine,
  MOCK_DELATNOSTI,
  MOCK_STATUSI_OSTALO,
  opstine,
} from '@/utils/constants.ts';
import {
  MOCK_PODNETI_ZAHTEVI,
  MOCK_RESENJA,
  PREDMET_ISTRAZIVANJA_MOCK,
  REQUEST_DETAILS_MOCK,
  REQUESTS_CONFIG_MOCK,
  VRSTE_GEOLOSKIH_ISTRAZIVANJA,
  VRSTE_PRIVREDNOG_SUBJEKTA,
} from '@/utils/mockData.ts';
import { requestsStore } from '@/modules/requests/requests.store.ts';
import { DefaultOptionType } from 'rc-select/lib/Select';
import {
  CreateNewRequestResponse,
  RequestsConfig,
} from '@/modules/requests/requests.types.ts';

const getGroupsAndRequests = async () => {
  if (isDevelopment()) {
    return Promise.resolve(REQUESTS_CONFIG_MOCK);
  }
  return handleApiRequest(requestsRepo.getGroupsAndRequests());
};

const getRequestDetails = (requestId: number) => {
  if (isDevelopment()) {
    return Promise.resolve(REQUEST_DETAILS_MOCK);
  }

  return handleApiRequest(requestsRepo.getRequestDetails(requestId));
};

const createNewRequest = async (
  requestId: number
): Promise<CreateNewRequestResponse | boolean> => {
  if (isDevelopment()) {
    return Promise.resolve(true);
  }
  return handleApiRequest(requestsRepo.createNewRequest(requestId));
};

const getVrsteGeoloskihIstrazivanja = async (
  zahtevTipId?: number,
  groupId?: number
) => {
  if (isDevelopment()) {
    return Promise.resolve(VRSTE_GEOLOSKIH_ISTRAZIVANJA[1]);
  }
  if (!zahtevTipId || !groupId) {
    return [];
  }
  return handleApiRequest(
    requestsRepo.getVrsteGeoloskihIstrazivanja(zahtevTipId, groupId)
  );
};

const getVrstePrivrednogSubjekta = async () => {
  if (isDevelopment()) {
    return Promise.resolve(VRSTE_PRIVREDNOG_SUBJEKTA);
  }
  return handleApiRequest(requestsRepo.getVrstePrivrednogSubjekta());
};

const getOpstine = async () => {
  if (isDevelopment()) {
    return Promise.resolve(opstine);
  }
  return handleApiRequest(requestsRepo.getOpstine());
};

const getMesta = async () => {
  if (isDevelopment()) {
    return Promise.resolve(mesta);
  }
  return handleApiRequest(requestsRepo.getMesta());
};

const getKatastarskaMestaOpstine = async () => {
  if (isDevelopment()) {
    return Promise.resolve(katastarskaOpstina);
  }
  return handleApiRequest(requestsRepo.getKatastarskaMestaOpstine());
};

const getDelatnosti = async () => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_DELATNOSTI);
  }
  return handleApiRequest(requestsRepo.getDelatnosti());
};

const getOstaloStatus = async () => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_STATUSI_OSTALO);
  }
  return handleApiRequest(requestsRepo.getOstaloStatus());
};

const getMineralneSirovine = async (zahtevTipId?: number) => {
  if (isDevelopment()) {
    return Promise.resolve(mineralneSirovine);
  }
  return zahtevTipId
    ? handleApiRequest(requestsRepo.getMineralneSirovine(zahtevTipId))
    : [];
};

const getPredmetIstrazivanja = async () => {
  if (isDevelopment()) {
    return Promise.resolve(PREDMET_ISTRAZIVANJA_MOCK);
  }
  return handleApiRequest(requestsRepo.getPredmetIstrazivanja());
};

const getResenja = async () => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_RESENJA);
  }

  return handleApiRequest(requestsRepo.getResenja());
};

const saveDraftRequest = async (requestData: Record<string, unknown>) => {
  if (isDevelopment()) {
    return Promise.resolve({
      id: 1,
    });
  }
  return handleApiRequest(requestsRepo.saveDraftRequest(requestData));
};

const uploadDocument = (requestData: unknown) => {
  if (isDevelopment()) {
    return Promise.resolve({
      documentId: 56,
      //@ts-ignore
      requestDocumentId: requestData?.requestDocumentId,
      opstinaId: null,
      mestoId: null,
      broj: null,
      brojParcele: null,
      datum: null,
      naziv: null,
      fileName: 'revers bojan signed.pdf',
    });
  }

  return handleApiRequest(requestsRepo.uploadDocument(requestData));
};

const generateXML = (requestId: number) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }

  return requestsRepo.generateXML(requestId);
};

const getPodnetiZahtevi = async () => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_PODNETI_ZAHTEVI);
  }

  return handleApiRequest(requestsRepo.getPodnetiZahtevi());
};

const downloadRequestsAttachment = (zahtevId: number, dokumentId: number) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }

  return requestsRepo.downloadRequestsAttachment(zahtevId, dokumentId);
};

const removeRequestsAttachment = (zahtevId: number, dokumentId: number) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }

  return requestsRepo.removeRequestsAttachment(zahtevId, dokumentId);
};

const loadConfig = async (zahtevTipId?: number) => {
  const configPromises = [
    getVrsteGeoloskihIstrazivanja(zahtevTipId, 1),
    getVrsteGeoloskihIstrazivanja(zahtevTipId, 2),
    getVrsteGeoloskihIstrazivanja(zahtevTipId, 3),
    getVrstePrivrednogSubjekta(),
    getOpstine(),
    getMesta(),
    getGroupsAndRequests(),
    getMineralneSirovine(zahtevTipId),
    getDelatnosti(),
    getOstaloStatus(),
    getPredmetIstrazivanja(),
    getKatastarskaMestaOpstine(),
  ];

  try {
    const res: unknown[] = await Promise.all(configPromises);

    requestsStore.setConfig({
      vrsteGeoloskihIstrazivanja: {
        1: res[0] as DefaultOptionType[],
        2: res[1] as DefaultOptionType[],
        3: res[2] as DefaultOptionType[],
      },
      vrstePrivrednogSubjekta: res[3] as DefaultOptionType[],
      opstine: res[4] as DefaultOptionType[],
      mesta: res[5] as DefaultOptionType[],
      mineralneSirovine: res[7] as DefaultOptionType[],
      delatnosti: res[8] as DefaultOptionType[],
      statusiOstalo: res[9] as DefaultOptionType[],
      predmetIstrazivanja: res[10] as DefaultOptionType[],
      katastarskaMestaOpstine: res[11] as DefaultOptionType[],
    });
    requestsStore.setGroupsAndRequests(
      (res[6] as RequestsConfig).kategorijeZahteva
    );
    requestsStore.setRequestStatuses((res[6] as RequestsConfig).zahtevStatusi);
    requestsStore.setActTypes((res[6] as RequestsConfig).tipAkta);
    requestsStore.setAllocationStatuses(
      (res[6] as RequestsConfig).statusRaspodele
    );
    requestsStore.setRequestProcessor(
      (res[6] as RequestsConfig).obradjivaciZahteva
    );

    return Promise.resolve();
  } catch (e) {
    console.error('Error loading config', e);
    return Promise.reject();
  }
};

const potpisiXML = async (xmlBase64: string) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }

  return requestsRepo.potpisiXML(xmlBase64);
};

export const requestsService = {
  getGroupsAndRequests,
  getRequestDetails,
  getVrsteGeoloskihIstrazivanja,
  getVrstePrivrednogSubjekta,
  getOpstine,
  getMesta,
  getMineralneSirovine,
  getPredmetIstrazivanja,
  getKatastarskaMestaOpstine,
  getResenja,
  createNewRequest,
  saveDraftRequest,
  uploadDocument,
  loadConfig,
  generateXML,
  getDelatnosti,
  getOstaloStatus,
  getPodnetiZahtevi,
  downloadRequestsAttachment,
  removeRequestsAttachment,
  potpisiXML,
};
