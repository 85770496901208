import { Table } from 'antd';
import { t } from 'i18next';

import RequestSectionWrapper from '@/pages/RequestView/components/RequestSectionWrapper/RequestSectionWrapper.tsx';
import { ObradaIstorijaProps } from '@/modules/requests/requests.types';
import { ColumnsType } from 'antd/es/table';
import { getLocalDateFromUTCDateString } from '@/utils/dateFormatter';

interface IRequestProcessingHistory {
  processingHistory: ObradaIstorijaProps[];
}

const RequestProcessingHistory: React.FC<IRequestProcessingHistory> = ({
  processingHistory,
}) => {
  const colDef: ColumnsType<ObradaIstorijaProps> = [
    {
      dataIndex: 'akcija',
      title: t('naziv_akcije'),
      align: 'center',
    },
    {
      dataIndex: 'vremeAkcije',
      title: t('datum_akcije'),
      align: 'center',
      render: (_, { vremeAkcije }) => {
        return getLocalDateFromUTCDateString(vremeAkcije);
      },
    },
    {
      render: (record: ObradaIstorijaProps) => {
        return `${record.inicijatorIme} ${record.inicijatorPrezime}`;
      },
      title: t('izvrsilac_akcije'),
      align: 'center',
    },
    {
      dataIndex: 'opis',
      align: 'center',
      title: t('opis'),
    },
  ];

  return (
    <RequestSectionWrapper title={t('istorija_obrade')}>
      <Table
        columns={colDef}
        dataSource={processingHistory}
        pagination={{
          position: ['bottomRight'],
          pageSizeOptions: [10, 20, 50],
          showQuickJumper: true,
          showSizeChanger: true,
          locale: {
            page: t('stranu'),
            jump_to: t('idite_na'),
            items_per_page: t('/strani'),
          },
        }}
      />
    </RequestSectionWrapper>
  );
};

export default RequestProcessingHistory;
