/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { useTranslation } from 'react-i18next';
import generatePicker, {
  PickerProps,
} from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import { localeObjectSerbianDayjs } from '@/locales/antLocales';

import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);

const DayjsDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export const DatePicker = (props: PickerProps) => {
  const { t } = useTranslation();
  //@ts-ignore
  dayjs.updateLocale('en', localeObjectSerbianDayjs(t));
  return (
    <DayjsDatePicker
      showNow={false}
      {...props}
      data-test={`date-picker-${props.name}`}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RangePicker = (props: any) => {
  const { t } = useTranslation();
  //@ts-ignore
  dayjs.updateLocale('en', localeObjectSerbianDayjs(t));
  return <DayjsDatePicker.RangePicker {...props} />;
};
