import Loading from '@/components/Loading/Loading.tsx';
import { requestsService } from '@/modules/requests/requests.service';
import {
  GetRequestDetailsResponse,
  IMappedRequestDetailsResponseForForm,
  ResenjeBrojResenja,
  ResenjeOdluka,
  ZahteviEnum,
} from '@/modules/requests/requests.types';
import { mapRequestDetailsResponseForForm } from '@/utils/formMapper.ts';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RequestBasicInformation from '../RequestBasicInformation';
import RequestFormDataView from '../RequestFormDataView';
import style from './RequestViewPS.module.scss';
import { ROUTES } from '@/routes/routes';
import { authStore } from '@/modules/auth/auth.store';
import { requestsStore } from '@/modules/requests/requests.store';

const RequestViewPS = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [requestDetails, setRequestDetails] =
    useState<IMappedRequestDetailsResponseForForm>();
  const [basicInformation, setBasicInformation] = useState<
    | (Pick<
        GetRequestDetailsResponse,
        | 'zavodniBroj'
        | 'datumPodnosenja'
        | 'status'
        | 'podnosilacNaziv'
        | 'zahtevGrupa'
        | 'zahtevPodgrupa'
        | 'zahtevKategorijaPodgrupe'
        | 'zahtevNaziv'
        | 'interniStatusZahteva'
        | 'zahtevId'
      > & {
        resenjeBrojResenja: ResenjeBrojResenja;
        resenjeOdluka: ResenjeOdluka;
      })
    | null
  >(null);

  const { zahtevId } = useParams();
  const navigate = useNavigate();
  const [zahtevTipId, setZahtevTipId] = useState<number>();

  useEffect(() => {
    if (authStore.currentUser && authStore.isUserMRE) {
      navigate(ROUTES.SUBMITTED_REQUESTS);
    }
  }, [authStore.selectedBusinessEntity?.id]);

  useEffect(() => {
    requestsService.loadConfig(zahtevTipId).finally(() => setLoading(false));
  }, [zahtevTipId]);

  useEffect(() => {
    requestsService
      .getRequestDetails(+zahtevId!)
      .then(async (res: GetRequestDetailsResponse) => {
        setBasicInformation({
          zavodniBroj: res.zavodniBroj,
          datumPodnosenja: res.datumPodnosenja,
          status: res.status,
          podnosilacNaziv: res.podnosilacNaziv,
          zahtevGrupa: res.zahtevGrupa,
          zahtevPodgrupa: res.zahtevPodgrupa,
          zahtevKategorijaPodgrupe: res.zahtevKategorijaPodgrupe,
          zahtevNaziv: res.zahtevNaziv,
          resenjeBrojResenja: res.resenje?.zavodniBrojResenja,
          resenjeOdluka: res.resenje?.odluka,
          interniStatusZahteva: res?.interniStatusZahteva,
          zahtevId: res?.zahtevId,
        });
        const mapped = mapRequestDetailsResponseForForm(res);
        setZahtevTipId(res!.zahtevTipId as number);

        setRequestDetails(mapped);
        if (mapped.dokumentaAtributi) {
          requestsStore.setDocumentUploadRes(mapped.dokumentaAtributi);
        }
      })
      .catch((err) => {
        console.error('Error get request details ', err);
        notification.error(err);
      });
    return () => {
      requestsStore.setDocumentUploadRes(undefined);
      console.log('Component is being destroyed');
    };
  }, [zahtevId]);

  return (
    <div className={style.requestViewContainerPS}>
      {loading ? (
        <Loading size={'large'} />
      ) : (
        <>
          <RequestBasicInformation basicInformation={basicInformation} />
          {requestDetails && zahtevTipId ? (
            <RequestFormDataView
              data={requestDetails}
              zahtevTipId={zahtevTipId}
              supplement={requestDetails?.status === ZahteviEnum.DOPUNA_ZAHTEVA}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default RequestViewPS;
