/* eslint-disable no-unused-vars */
import { Flex, Form, Modal, Radio, Space } from 'antd';
import { t } from 'i18next';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { ModalName, modalStore } from '@/modules/modal/modal.store';
import { OdlukeEnum } from '@/modules/requests/requests.types';

interface IFormData {
  odluka: string;
}

export const ODLUKE = [
  { value: OdlukeEnum.ODOBRAVANJE, label: t('odobravanje') },
  { value: OdlukeEnum.DOPUNA, label: t('dopuna_zahteva') },
  { value: OdlukeEnum.PREKID, label: t('prekid_postupka') },
  { value: OdlukeEnum.ODBACIVANJE, label: t('odbacivanje') },
  { value: OdlukeEnum.ODBIJANJE, label: t('odbijanje') },
];

export const RequestDecisionModal = observer(() => {
  const [form] = useForm();

  const isOpen = modalStore.isVisible(ModalName.RESQUEST_DECISION);

  const handleCancel = () => {
    modalStore.closeModal(ModalName.RESQUEST_DECISION);
  };

  const handleDecision = (formData: IFormData) => {
    modalStore.setDecision(+formData.odluka);
    handleCancel();
  };

  return (
    <Modal
      open={isOpen}
      title={t('izaberite_odluku')}
      cancelButtonProps={{ type: 'primary', danger: true }}
      cancelText={t('odustanite')}
      okText={t('potvrdite')}
      okButtonProps={{
        htmlType: 'submit',
        form: 'decision',
      }}
      onCancel={() => {
        handleCancel();
      }}
    >
      <Form name={'decision'} onFinish={handleDecision} form={form}>
        <Form.Item
          name='odluka'
          rules={[
            {
              required: true,
              message: t('potrebna_odluka'),
            },
          ]}
        >
          <Flex align='center' justify='center'>
            <Radio.Group>
              <Space direction='vertical'>
                {ODLUKE.map((odluka) => (
                  <Radio key={odluka.value} value={odluka.value}>
                    {odluka.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
});
