import { Breadcrumb } from 'antd';

import style from './Breadcrumbs.module.scss';
import useBreadcrumbs from './useBreadcrumbs.tsx';

const Breadcrumbs = () => {
  const breadcrumbItems = useBreadcrumbs();

  return (
    <div className={style.breadcrumbsContainer}>
      {breadcrumbItems && (
        <Breadcrumb
          items={breadcrumbItems?.breadcrumbItems}
          className={style.breadcrumbs}
        />
      )}
    </div>
  );
};

export default Breadcrumbs;
