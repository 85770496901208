import { handleApiRequest } from '@/utils/axios.ts';
import { isDevelopment } from '@/utils/constants.ts';
import {
  MOCK_APR_DETAILS,
  MOCK_MOCK_PRIVREDNI_SUBJEKT_DETAILS,
  MOCK_OSTALO,
  MOCK_OSTALO_DETAILS,
  MOCK_PR_SUB1_DETAILS,
  MOCK_PR_SUB_DETAILS_ALL,
  MOCK_PRIVREDNI_SUBJEKT,
  MOCK_ROLE_CONFIG_PS,
  MOCK_USERS_LIST_ITEMS,
  MOCK_ZAPOSLENI1,
} from '@/utils/mockData.ts';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { authStore } from '../auth/auth.store.ts';
import { EMPTY_STATE_PLACEHOLDER } from './settings.constants.ts';
import { settingsRepo } from './settings.repo';
import {
  APRDetailsPayload,
  IInitialPrivredniSubjektFilters,
  IInitialPrivredniSubjektOstaloFilters,
  IZaposleni,
  KorisnikIzmeniPayload,
  OdbijOdobriPayload,
  PrivredniSubjektiAktivirajDeaktivirajPayload,
  PrivredniSubjektiOstaloAktivirajDeaktivirajPayload,
  PrivredniSubjektiOstaloPayload,
  PrivredniSubjektiPayload,
  ZaposleniDetailsPayload,
} from './settings.types';

//PRIVREDNI SUBJEKT

const getPrivredniSubjektDetalji = async () => {
  if (isDevelopment()) {
    return Promise.resolve(
      MOCK_PR_SUB_DETAILS_ALL.find(
        (pr) => pr.id === authStore.selectedBusinessEntity?.id
      ) || MOCK_PR_SUB1_DETAILS
    );
  }
  return handleApiRequest(settingsRepo.getPrivredniSubjektDetalji());
};

const postPrivredniSubjektKreirajMailNotifikacije = async (email: string) => {
  return handleApiRequest(
    settingsRepo.postPrivredniSubjektKreirajMailNotifikacije(email)
  );
};

const putPrivredniSubjektIzmeniMailNotifikacije = async (aktivno: boolean) => {
  return handleApiRequest(
    settingsRepo.putPrivredniSubjektIzmeniMailNotifikacije(aktivno)
  );
};

//KORISNICI

const getKorisnikSviZaposleniPrivrednogSubjekta = async () => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_USERS_LIST_ITEMS);
  }
  return handleApiRequest(
    settingsRepo.getKorisnikSviZaposleniPrivrednogSubjekta()
  );
};

const postKorisnikDodajNovogZaposlenog = async (payload: IZaposleni) => {
  return handleApiRequest(
    settingsRepo.postKorisnikDodajNovogZaposlenog(payload)
  );
};

const putKorisnikIzmeniKorisnika = async (
  payload: IZaposleni,
  params: KorisnikIzmeniPayload
) => {
  return handleApiRequest(
    settingsRepo.putKorisnikIzmeniKorisnika(payload, params)
  );
};

const getZaposleniDetails = async (payload: ZaposleniDetailsPayload) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_ZAPOSLENI1);
  }
  return handleApiRequest(settingsRepo.getZaposleniDetails(payload));
};

const getRoleConfig = async () => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_ROLE_CONFIG_PS);
  }
  return handleApiRequest(settingsRepo.getRoleConfig());
};

const getPrivredniSubjektAPRDetails = async (payload: APRDetailsPayload) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_APR_DETAILS);
  }

  return handleApiRequest(settingsRepo.getPrivredniSubjektAPRDetails(payload));
};

//OSTALO
const postPrivredniSubjektiOstalo = async (
  payload: IInitialPrivredniSubjektOstaloFilters
) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_OSTALO);
  }
  return handleApiRequest(settingsRepo.postPrivredniSubjektiOstalo(payload));
};

const postPrivredniSubjekti = async (
  payload: IInitialPrivredniSubjektFilters
) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_PRIVREDNI_SUBJEKT);
  }
  return handleApiRequest(settingsRepo.postPrivredniSubjekti(payload));
};

const getPrivredniSubjektiOstaloDokumentPreuzmi = async (
  payload: PrivredniSubjektiOstaloPayload
) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  return settingsRepo.getPrivredniSubjektiOstaloDokumentPreuzmi(payload);
};

const getPrivredniSubjektiOstaloAktivirajDeaktiviraj = async (
  payload: PrivredniSubjektiOstaloAktivirajDeaktivirajPayload
) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  return handleApiRequest(
    settingsRepo.getPrivredniSubjektiOstaloAktivirajDeaktiviraj(payload)
  );
};

const getPrivredniSubjektiAktivirajDeaktiviraj = async (
  payload: PrivredniSubjektiAktivirajDeaktivirajPayload
) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  return handleApiRequest(
    settingsRepo.getPrivredniSubjektiAktivirajDeaktiviraj(payload)
  );
};

const postPrivredniSubjektiOstaloOdobri = async (
  payload: OdbijOdobriPayload
) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  return handleApiRequest(
    settingsRepo.postPrivredniSubjektiOstaloOdobri(payload)
  );
};

const postPrivredniSubjektiOstaloOdbij = async (
  payload: OdbijOdobriPayload
) => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  return handleApiRequest(
    settingsRepo.postPrivredniSubjektiOstaloOdbij(payload)
  );
};

const postPrivredniSubjektiOstaloDetalji = async (
  payload: PrivredniSubjektiOstaloPayload
) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_OSTALO_DETAILS);
  }
  return handleApiRequest(
    settingsRepo.postPrivredniSubjektiOstaloDetalji(payload)
  );
};

const postPrivredniSubjektiDetalji = async (
  payload: PrivredniSubjektiPayload
) => {
  if (isDevelopment()) {
    return Promise.resolve(MOCK_MOCK_PRIVREDNI_SUBJEKT_DETAILS);
  }
  return handleApiRequest(settingsRepo.postPrivredniSubjektiDetalji(payload));
};

const findLabelById = (
  list: DefaultOptionType[] | undefined,
  id: number | null | undefined,
  returnItem: string | null = EMPTY_STATE_PLACEHOLDER
) => {
  return list?.find((item) => item.value === id)?.label || returnItem;
};

const getInputValueForUI = (value: string | number | null | undefined) => {
  return value || EMPTY_STATE_PLACEHOLDER;
};

export const settingsService = {
  getPrivredniSubjektDetalji,
  postPrivredniSubjektKreirajMailNotifikacije,
  putPrivredniSubjektIzmeniMailNotifikacije,
  getKorisnikSviZaposleniPrivrednogSubjekta,
  postKorisnikDodajNovogZaposlenog,
  putKorisnikIzmeniKorisnika,
  getZaposleniDetails,
  getRoleConfig,
  getPrivredniSubjektAPRDetails,
  postPrivredniSubjektiOstalo,
  getPrivredniSubjektiOstaloDokumentPreuzmi,
  getPrivredniSubjektiOstaloAktivirajDeaktiviraj,
  postPrivredniSubjektiOstaloOdobri,
  postPrivredniSubjektiOstaloOdbij,
  postPrivredniSubjektiOstaloDetalji,
  findLabelById,
  getInputValueForUI,
  postPrivredniSubjekti,
  postPrivredniSubjektiDetalji,
  getPrivredniSubjektiAktivirajDeaktiviraj,
};
