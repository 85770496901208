import Axios, { AxiosError, AxiosResponse, CreateAxiosDefaults } from 'axios';
import { API_URL, isDevelopment } from './constants.ts';
import { LocalStorage } from './local-storage.ts';
import { LANGUAGES } from '../i18n.ts';
import { t } from 'i18next';
import to from 'await-to-js';
import { notification } from 'antd';
import { authStore } from '../modules/auth/auth.store.ts';

export type ApiResponse<T> = {
  data: T;
  success: boolean;
  errorMessage: string;
};

const config: CreateAxiosDefaults = {
  baseURL: API_URL,
  headers: {
    'content-type': 'application/json',
  },
};

const axios = Axios.create(config);

axios.interceptors.response.use(
  (response: AxiosResponse<ApiResponse<unknown>>) => {
    const url = response.config.url;
    if (
      url?.includes('generisi-xml') ||
      url?.includes('generis-akt') ||
      url?.includes('preuzmi-akt') ||
      url?.includes('privredni-subjekti-ostalo-dokument-preuzmi') ||
      url?.includes('dohvati-dokument-priloga')
    ) {
      return response;
    }
    if (response.data && !response.data.success) {
      return Promise.reject({
        message: response.data.errorMessage || t('error_poruka'),
      });
    }
    return response;
  },
  async (axiosError: AxiosError) => {
    console.error('Error ', axiosError);
    if (axiosError.code === 'ERR_NETWORK' && !isDevelopment()) {
      if (authStore.currentUser) {
        authStore.clearUser();
        notification.warning({
          message: t('sesija_istekla_error'),
        });
      }
      return null;
    }
    if (authStore.currentUser) {
      return Promise.reject({
        message: t('error_poruka'),
      });
    }
  }
);

axios.defaults.headers.common['Accept-Language'] =
  LocalStorage.getValue('language') || LANGUAGES.SERBIAN_CYRILLIC;

export const handleApiRequest = async <T>(
  request: Promise<AxiosResponse<ApiResponse<T>>>
) => {
  const [err, res] = await to(request);
  if (err) {
    return Promise.reject(err);
  }

  return res?.data?.data as T;
};

export const executePromisesSequentially = async (
  promises: Promise<unknown>[]
): Promise<unknown[]> => {
  const results = [];

  for (const promiseFunc of promises) {
    try {
      const result = await promiseFunc;
      results.push(result);
    } catch (error) {
      console.error('Promise failed:', error);
      break;
    }
  }

  return results;
};

export default axios;
