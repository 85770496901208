// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const localeObjectSerbianDayjs = (t: any) => ({
  weekdaysMin: [
    t('antNed'),
    t('antPon'),
    t('antUto'),
    t('antSre'),
    t('antCet'),
    t('antPet'),
    t('antSub'),
  ],
  monthsShort: [
    t('antJan'),
    t('antFeb'),
    t('antMar'),
    t('antApr'),
    t('antMaj'),
    t('antJun'),
    t('antJul'),
    t('antAvg'),
    t('antSep'),
    t('antOkt'),
    t('antNov'),
    t('antDec'),
  ],
  weekStart: 1,
});
