import { FilePdfTwoTone, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Empty, Flex, MenuProps, notification, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import DynamicTitle from '@/components/DynamicTitle/DynamicTitle';
import Loading from '@/components/Loading/Loading';
import PrivredniSubjektOstaloStatus from '@/components/Status/PrivredniSubjektOstaloStatus/PrivredniSubjektOstaloStatus';
import {
  EMPTY_STATE_PLACEHOLDER,
  INITIAL_PRIVREDNI_SUBJEKT_OSTALO_FILTER_PARAMS,
} from '@/modules/settings/settings.constants';
import { settingsService } from '@/modules/settings/settings.service';
import {
  Actions,
  IInitialPrivredniSubjektOstaloFilters,
  IPrivredniSubjektOstaloResponse,
} from '@/modules/settings/settings.types';
import { ROUTES } from '@/routes/routes';
import { handleDownloadFile } from '@/utils/handleDownloadFile';
import { SettingsPrivredniSubjektiFilters } from '../../../components/SettingsPrivredniSubjektiFilters/SettingsPrivredniSubjektiFilters';
import { OtherModal } from './components/OtherModal/OtherModal';
import { requestsStore } from '@/modules/requests/requests.store';
import { authStore } from '@/modules/auth/auth.store';
import { ModalName, modalStore } from '@/modules/modal/modal.store';

export const Other = observer(() => {
  const [loading, setLoading] = useState<boolean>(true);
  const [params, setParams] = useState<IInitialPrivredniSubjektOstaloFilters>(
    INITIAL_PRIVREDNI_SUBJEKT_OSTALO_FILTER_PARAMS
  );
  const [actionId, setActionId] = useState<number | null>(null);
  const [privredniSubjektId, setPrivredniSubjektId] = useState<number | null>(
    null
  );
  const [tableData, setTableData] = useState<IPrivredniSubjektOstaloResponse[]>(
    []
  );
  const navigate = useNavigate();

  const handleDownloadDokument = (id: number | null) => {
    if (id !== null) {
      settingsService
        .getPrivredniSubjektiOstaloDokumentPreuzmi({ privredniSubjektId: id })
        .then((res) => {
          console.log('res', res);
          handleDownloadFile(res as AxiosResponse)
            .then(() => {
              notification.success({
                message: t('generisi_dokument_success'),
              });
            })
            .catch(() => {
              notification.error({
                message: t('file_error'),
              });
            });
        })
        .catch((err) => {
          notification.error(err);
        });
    }
  };

  const fetchData = () => {
    settingsService
      .postPrivredniSubjektiOstalo(params)
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        console.error('Error getting all others ', err);
        notification.error(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchDataDebounce = useDebouncedCallback(fetchData, 500);

  useEffect(() => {
    fetchDataDebounce();
  }, [params, authStore.selectedBusinessEntity?.id]);

  const getRequestActions = (record: IPrivredniSubjektOstaloResponse) => {
    if (!record.akcije) return [];
    const items: MenuProps['items'] = record?.akcije.map((akcija: Actions) => {
      switch (akcija.value) {
        case 1:
        case 2:
          return {
            label: akcija.label,
            key: akcija.value,
            onClick: (event) => {
              event.domEvent.stopPropagation();
              modalStore.openModal(ModalName.OTHER);
              setPrivredniSubjektId(record.id);
              setActionId(akcija.value);
            },
          };
        default:
          return null;
      }
    });
    return items;
  };

  const colDef: ColumnsType<IPrivredniSubjektOstaloResponse> = [
    {
      dataIndex: 'naziv',
      title: t('naziv_privrednog_subjekta'),
      width: 300,
      align: 'center',
      render: (record: string) => {
        return settingsService.getInputValueForUI(record);
      },
    },
    {
      dataIndex: 'maticniBroj',
      title: t('maticni_broj'),
      align: 'center',
      render: (record: string) => {
        return settingsService.getInputValueForUI(record);
      },
    },

    {
      dataIndex: 'pib',
      title: t('pib'),
      align: 'center',
      render: (record: string) => {
        return settingsService.getInputValueForUI(record);
      },
    },
    {
      dataIndex: 'email',
      title: t('eposta'),
      align: 'center',
      render: (record: string) => {
        return settingsService.getInputValueForUI(record);
      },
    },
    {
      title: t('dokaz_o_registraciji'),
      align: 'center',
      render: (record: IPrivredniSubjektOstaloResponse) => (
        <FilePdfTwoTone
          onClick={(event) => {
            event.stopPropagation();
            handleDownloadDokument(record.id);
          }}
          className='settingsFileIcon'
        />
      ),
    },
    {
      title: t('ime_i_prezime'),
      align: 'center',
      render: (record: IPrivredniSubjektOstaloResponse) =>
        record?.userIme && record?.userPrezime
          ? `${record.userIme} ${record.userPrezime}`
          : EMPTY_STATE_PLACEHOLDER,
    },
    {
      dataIndex: 'userEmail',
      title: t('eposta_korisnika'),
      align: 'center',
      render: (record: string) => {
        return settingsService.getInputValueForUI(record);
      },
    },
    {
      title: t('status'),
      align: 'center',
      render: (record: IPrivredniSubjektOstaloResponse) => {
        return <PrivredniSubjektOstaloStatus statusId={record.status} />;
      },
    },
    {
      title: t('akcije'),
      fixed: 'right',
      hidden: !tableData[0]?.akcije,
      width: 100,
      render: (record: IPrivredniSubjektOstaloResponse) => {
        if (!record?.akcije?.length) return EMPTY_STATE_PLACEHOLDER;
        const items = getRequestActions(record);
        return (
          <Dropdown menu={{ items }} trigger={['click']}>
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Flex justify='center' align='center'>
                <MoreOutlined style={{ fontSize: 25, color: 'black' }} />
              </Flex>
            </a>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <div className='settingsLoaderWrapper'>
          <Loading size='large' />
        </div>
      ) : (
        <>
          <OtherModal
            actionId={actionId}
            privredniSubjektId={privredniSubjektId}
            refetch={fetchData}
          />
          <DynamicTitle title='stranice.ostalo' className='mt-20 mb-20' />
          <SettingsPrivredniSubjektiFilters
            setParams={setParams}
            statusesList={requestsStore.config?.statusiOstalo}
          />
          <Table
            columns={colDef}
            className='mt-15'
            rowKey={(record) => record.id}
            rowClassName={() => 'pointer'}
            dataSource={tableData}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [10, 20, 50],
              showQuickJumper: true,
              showSizeChanger: true,
              locale: {
                page: t('stranu'),
                jump_to: t('idite_na'),
                items_per_page: t('/strani'),
              },
            }}
            scroll={{
              x: 2000,
              y: 300,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(`${ROUTES.OTHER_DETAILS}/${record.id}`);
                },
              };
            }}
            locale={{
              emptyText: <Empty description={t('nema_podataka')} />,
            }}
          />
        </>
      )}
    </>
  );
});
