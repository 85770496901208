import { Group, SubGroup } from '@/modules/requests/requests.types.ts';
import { IZahteviSviZahteviPayload } from '@/modules/submittedRequests/submittedRequests.types.ts';
import { requestsStore } from '@/modules/requests/requests.store.ts';
import { requestsService } from '@/modules/requests/requests.service.ts';
import { IResenjaPayload } from '@/modules/acts/acts.types.ts';

const extractRequestsFromSubGroup = (subGroup: SubGroup, groupId: number) => {
  // Extract requests from the current subgroup with groupId
  let requests = subGroup.zahtevi.map((request) => ({
    ...request,
    groupId: groupId,
  }));

  // Recursively extract requests from nested subgroups, if any
  if (subGroup.kategorijePodgrupa) {
    requests = requests.concat(
      ...subGroup.kategorijePodgrupa.map((sg) =>
        extractRequestsFromSubGroup(sg, groupId)
      )
    );
  }

  return requests;
};

export const flattenRequests = (groups: Group[]) => {
  // Extract requests from all subgroups in all groups
  return groups.flatMap((group) =>
    group.podgrupe.flatMap((subGroup) =>
      extractRequestsFromSubGroup(subGroup, group.id)
    )
  );
};

const flattenRequestsFromSingleGroup = (group: Group) => {
  return group.podgrupe.flatMap((subGroup) =>
    extractRequestsFromSubGroup(subGroup, group.id)
  );
};
const flattenRequestsFromSingleSubGroup = (group: SubGroup) => {
  const reqs = group.zahtevi.map((rq) => ({
    ...rq,
    groupId: group.id,
  }));

  const requests = group.kategorijePodgrupa?.flatMap((subGroup) =>
    extractRequestsFromSubGroup(subGroup, group.id)
  );

  return requests ? [...reqs, ...requests] : reqs;
};

export const getRequestsBasedOnFilterParams = async (
  params: IZahteviSviZahteviPayload | IResenjaPayload
) => {
  let groups: Group[];
  if (!requestsStore.groupsAndRequests.length) {
    const groupsRes = await requestsService.getGroupsAndRequests();
    groups = groupsRes.kategorijeZahteva;
  } else {
    groups = requestsStore.groupsAndRequests;
  }

  if (
    params.zahtevGrupaId === null &&
    params.zahtevPodgrupaId === null &&
    params.zahtevKategorijaPodgrupeId === null
  ) {
    return flattenRequests(groups);
  }

  if (params.zahtevKategorijaPodgrupeId) {
    const foundKategorijaPodgrupe = groups
      .find((group) => group.id === params.zahtevGrupaId)
      ?.podgrupe.find((subGroup) => subGroup.id === params.zahtevPodgrupaId)
      ?.kategorijePodgrupa?.find(
        (katSubGroup) => katSubGroup.id === params.zahtevKategorijaPodgrupeId
      );

    return foundKategorijaPodgrupe
      ? flattenRequestsFromSingleSubGroup(foundKategorijaPodgrupe)
      : [];
  }

  if (params.zahtevPodgrupaId) {
    const foundSubGroup = groups
      .find((group) => group.id === params.zahtevGrupaId)
      ?.podgrupe.find((subGroup) => subGroup.id === params.zahtevPodgrupaId);

    return foundSubGroup
      ? flattenRequestsFromSingleSubGroup(foundSubGroup)
      : [];
  }

  if (params.zahtevGrupaId) {
    const foundGroup = groups.find(
      (group) => group.id === params.zahtevGrupaId
    );
    return foundGroup ? flattenRequestsFromSingleGroup(foundGroup) : [];
  }
};
