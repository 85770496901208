/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Button, notification, Upload } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { UploadChangeParam } from 'antd/es/upload';
import { observer } from 'mobx-react-lite';
import { AxiosResponse } from 'axios';
import { t } from 'i18next';

import style from './RequestDecisionApprovalActions.module.scss';
import { StartProcessingActModal } from '../../../StartProcessingActModal/StartProcessingActModal';
import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service';
import { handleDownloadFile } from '@/utils/handleDownloadFile';
import { FileType } from '@/utils/uploadFile.ts';
import { ModalName, modalStore } from '@/modules/modal/modal.store';
import { requestsStore } from '@/modules/requests/requests.store';

interface IRequestDecisionApprovalActions {
  resenjeId: number | null;
  handleSuccess: () => void;
}

export const RequestDecisionApprovalActions = observer(
  ({ resenjeId, handleSuccess }: IRequestDecisionApprovalActions) => {
    const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
    const [file, setFile] = useState<FileType>();
    const { zahtevId } = useParams();

    const handleFileUpload = async (uploadObj: UploadChangeParam) => {
      const file = uploadObj.fileList[0];
      const uploadedFile = {
        format: file.type!.split('/')[1],
        data: file.originFileObj!,
        fileName: file.name,
      };
      setFile(uploadedFile);
      setIsFileUploaded(!!uploadedFile);
    };

    const handleDownloadAct = () => {
      submittedRequestsService
        .postZahteviGenerisiAkt({
          zahtevId: +zahtevId!,
          resenjeId: resenjeId,
        })
        .then((res) => {
          handleDownloadFile(res as AxiosResponse)
            .then(() => {
              notification.success({ message: t('generisi_akt_success') });
            })
            .catch((err) => {
              notification.error({
                message: err,
              });
            });
        })
        .catch((err) => {
          notification.error(err);
        });
    };

    const handleUploadAct = () => {
      if (file) {
        submittedRequestsService
          .postZahteviDodajAktZahteva({
            data: JSON.stringify({
              zahtevId: +zahtevId!,
              resenjeId: resenjeId,
              fileName: file?.fileName,
            }),
            file: new Blob([file.data], {
              type: 'application/octet-stream',
            }),
          })
          .then(() => {
            modalStore.openModal(ModalName.START_PROCESSING_ACT);
            notification.success({ message: t('finalni_akt_success') });
          })
          .catch((err) => {
            notification.error(err);
          });
      }
    };

    return (
      <>
        <StartProcessingActModal handleSuccess={handleSuccess} />
        <div className={style.actions}>
          <h3>{t('akt')}</h3>
          <div className={style.actContainer}>
            <span>{t('generisan_akt')}</span>
            <Button
              className={style.button}
              icon={<DownloadOutlined />}
              type='primary'
              onClick={() => handleDownloadAct()}
              disabled={!requestsStore.canGenerateAct && !resenjeId}
            >
              {t('forma.dugme.preuzmite')}
            </Button>
          </div>
          <div className={style.uploadContainer}>
            <div className={style.bottomBorder}>
              <span> {t('uvezite_finalni_akt')}</span>
            </div>
            <div className='m-15'>
              <Upload
                maxCount={1}
                onRemove={() => setIsFileUploaded(false)}
                locale={{ removeFile: t('obrisite_dokument') }}
                onChange={handleFileUpload}
                beforeUpload={() => false}
                accept={'.pdf'}
              >
                <Button type='primary'>
                  <UploadOutlined />
                </Button>
              </Upload>
            </div>
          </div>
          <Button
            className={style.submitButton}
            type='text'
            disabled={!isFileUploaded}
            onClick={() => handleUploadAct()}
          >
            {t('pokrenite_proces_odobrenja_akta')}
          </Button>
        </div>
      </>
    );
  }
);
