import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { omit } from 'lodash';
import {
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Switch,
} from 'antd';
import { t } from 'i18next';
import { useForm, useWatch } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import style from './UserModal.module.scss';
import { IZaposleni } from '@/modules/settings/settings.types';
import { settingsService } from '@/modules/settings/settings.service';
import Loading from '@/components/Loading/Loading.tsx';
import { IOdeljenja, IRole, IRoles } from '@/modules/auth/auth.types.ts';
import { DATE_FORMAT, DATE_FORMAT_UI } from '@/utils/dateFormatter.ts';
import { map4select } from '@/utils/map4select';
import { RangePicker } from '@/components/DatePicker/DatePicker';
import { authService } from '@/modules/auth/auth.service.ts';
import { authStore } from '@/modules/auth/auth.store.ts';
import { ModalName, modalStore } from '@/modules/modal/modal.store';

type UserModalProps = {
  editEmailOnly: boolean;
  handleClose: () => void;
  id?: string;
};

type RoleForm = {
  active: boolean;
  duration: [dayjs.Dayjs | null, dayjs.Dayjs | null];
};

const UserModal = observer(
  ({ handleClose, id, editEmailOnly }: UserModalProps) => {
    const [form] = useForm();
    const roles = useWatch(['roles'], form);

    const [userData, setUserData] = useState<IZaposleni>();
    const [rolesConfig, setRolesConfig] = useState<IRoles[]>([]);
    const [odeljenjaConfig, setOdeljenjaConfig] = useState<IOdeljenja[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const isOpen = modalStore.isVisible(ModalName.USER);

    const handelCancel = () => {
      handleClose();
      modalStore.closeModal(ModalName.USER);
    };

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const allPromises: Promise<any>[] = [settingsService.getRoleConfig()];

      if (id) {
        allPromises.push(settingsService.getZaposleniDetails({ id }));
      }

      Promise.all(allPromises)
        .then((res) => {
          setRolesConfig(res[0]?.roles as IRoles[]);
          setOdeljenjaConfig(res[0]?.odeljenja as IOdeljenja[]);
          setUserData(res[1] as IZaposleni);
        })
        .catch((err) => {
          notification.error(err);
        })
        .finally(() => setLoading(false));
    }, [id, isOpen]);

    const mapUserRoles = (roles: RoleForm[]) => {
      const filteredRoles = rolesConfig.filter(
        (role) => roles[role.roleId]?.active
      );
      return filteredRoles.map((role) => {
        return {
          ...omit(role, 'roleName'),
          datumVazenjaOd:
            roles[role.roleId].duration?.[0]?.format(DATE_FORMAT) || null,
          datumVazenjaDo:
            roles[role.roleId].duration?.[1]?.format(DATE_FORMAT) || null,
        };
      });
    };

    const mapUserRolesForForm = (roles: IRole[]) => {
      const mappedUserRoles: Record<number, RoleForm> = {};
      roles.forEach((role) => {
        mappedUserRoles[`${role.roleId}`] = {
          active: true,
          duration: [
            role.datumVazenjaOd ? dayjs(role.datumVazenjaOd) : null,
            role.datumVazenjaDo ? dayjs(role.datumVazenjaDo) : null,
          ],
        };
      });
      return mappedUserRoles;
    };

    const handleSubmit = () => {
      const roles = mapUserRoles(form.getFieldValue('roles'));

      const payload = {
        ...form.getFieldsValue(),
        roles,
      };
      if (userData) {
        //Edit
        settingsService
          .putKorisnikIzmeniKorisnika(payload, { guid: userData.userId! })
          .then(() => {
            notification.success({ message: t('korisnik_izmenjen') });
            handelCancel();
            authService.getUserMainInfo().then(async (res) => {
              authStore.refreshUserRoles(res);
            });
          })
          .catch((err) => notification.error(err));
      } else {
        //Add
        settingsService
          .postKorisnikDodajNovogZaposlenog(payload)
          .then(() => {
            notification.success({ message: t('korisnik_dodat') });
            handelCancel();
          })
          .catch((err) => notification.error(err));
      }
    };

    const validateRoles = (_: unknown, value: RoleForm[]) => {
      const hasRole = rolesConfig.some((role) => value?.[role.roleId]?.active);
      if (!hasRole) {
        return Promise.reject(new Error(t('forma.sekcija.polje.uloga_error')));
      }
      return Promise.resolve();
    };

    const isDatePickerDisabled = (id: number) => {
      return (roles ? !roles[id]?.active : true) || editEmailOnly;
    };

    const isRoleActive = (roleId: number) => {
      return !!userData?.roles?.find((role) => role.roleId === roleId);
    };

    return (
      <Modal
        onCancel={() => {
          handelCancel();
        }}
        destroyOnClose
        open={isOpen}
        width={1200}
        okButtonProps={{
          htmlType: 'submit',
          form: 'addUserForm',
        }}
        cancelButtonProps={{ type: 'primary', danger: true }}
        cancelText={t('odustanite')}
        okText={t('sacuvajte')}
      >
        {loading ? (
          <Loading size='large' />
        ) : (
          <Form
            form={form}
            name='addUserForm'
            layout='vertical'
            initialValues={
              userData
                ? {
                    ...userData, // eslint-disable-line
                    roles: mapUserRolesForForm(userData?.roles || []), // eslint-disable-line
                  } // eslint-disable-line
                : { aktivno: true }
            }
            onFinish={handleSubmit}
            labelCol={{ span: 8 }}
            labelAlign='right'
            preserve={false}
          >
            <div className={style.form}>
              <div className={style.userContainer}>
                <Form.Item
                  rules={[
                    { required: true, message: t('potrebno_je_uneti_ime') },
                  ]}
                  name='ime'
                  label={t('ime')}
                  colon={false}
                >
                  <Input disabled={editEmailOnly} />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: t('potrebno_je_uneti_prezime') },
                  ]}
                  name='prezime'
                  label={t('prezime')}
                  colon={false}
                >
                  <Input disabled={editEmailOnly} />
                </Form.Item>
                <Form.Item
                  name='email'
                  rules={[
                    { required: true, message: t('potrebno_je_uneti_email') },
                  ]}
                  label={t('eposta')}
                  labelCol={{ span: 24 }}
                  colon={false}
                >
                  <Input />
                </Form.Item>
                {odeljenjaConfig && (
                  <Form.Item
                    name='odeljenjeId'
                    rules={[
                      {
                        required: true,
                        message: t('potrebno_je_izabrati_odeljenje'),
                      },
                    ]}
                    label={t('odeljenje')}
                    colon={false}
                  >
                    <Select
                      options={
                        map4select({
                          sourceArray: odeljenjaConfig,
                          valueAccessor: 'odeljenjeId',
                          labelAccessor: 'odeljenjeName',
                        }) || []
                      }
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name='aktivno'
                  colon={false}
                  valuePropName='checked'
                  labelCol={{ span: 8 }}
                  label={t('aktivno')}
                >
                  <Switch disabled={editEmailOnly} />
                </Form.Item>
              </div>
              <div className={style.permissionContainer}>
                {rolesConfig.length && (
                  <Form.Item
                    name='roles'
                    rules={[{ validator: validateRoles }]}
                  >
                    {rolesConfig.map((role) => {
                      return (
                        <Row key={role.roleName}>
                          <Col span={15} className={style.roleColumn}>
                            <Form.Item
                              colon={false}
                              name={['roles', role.roleId, 'active']}
                              valuePropName={'checked'}
                            >
                              <div className={style.checkBoxContainer}>
                                <span className={style.roleName}>
                                  {role.roleName}
                                </span>
                                <Checkbox
                                  defaultChecked={isRoleActive(role.roleId)}
                                  disabled={editEmailOnly}
                                />
                              </div>
                            </Form.Item>
                          </Col>
                          <Col span={9} className={style.roleColumn}>
                            <Form.Item
                              shouldUpdate
                              name={['roles', role.roleId, 'duration']}
                            >
                              <RangePicker
                                format={DATE_FORMAT_UI}
                                disabled={isDatePickerDisabled(role.roleId)}
                                allowEmpty={[true, true]}
                                placeholder={[t('od'), t('do')]}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    })}
                  </Form.Item>
                )}
              </div>
            </div>
          </Form>
        )}
      </Modal>
    );
  }
);

export default UserModal;
