import { useEffect } from 'react';
import { authService } from '@/modules/auth/auth.service.ts';
import { notification } from 'antd';
import {
  authStore,
  CURRENT_USER_LOCAL_STORAGE_KEY,
} from '@/modules/auth/auth.store.ts';
import { useNavigate } from 'react-router-dom';
import { LocalStorage } from '@/utils/local-storage.ts';
import { PageWrapper } from '@/components/PageWrapper/PageWrapper.tsx';
import { ROUTES } from '@/routes/routes.tsx';
import { t } from 'i18next';
import style from './Login.module.scss';

const LoginSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    LocalStorage.removeValue(CURRENT_USER_LOCAL_STORAGE_KEY); //TODO This could be a solution for the infinite loop bug, should be checked
    authService
      .getUserMainInfo()
      .then(async (res) => {
        await authStore.login(res);
        navigate(ROUTES.SETTINGS);
      })
      .catch((err) => {
        notification.error(err);
      });
  }, [navigate]);
  return (
    <PageWrapper greyFrame>
      <div className={style.loginSuccessContainer}>
        {t('login_success')}
      </div>
    </PageWrapper>
  );
};

export default LoginSuccess;
