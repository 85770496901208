import { AxiosResponse } from 'axios';
import axios, { ApiResponse } from '../../utils/axios.ts';
import {
  PrivredniSubjekt,
  UserData,
  UserRegistrationFormData,
} from './auth.types.ts';
import { IZahteviDodajAktZahteva } from '../submittedRequests/submittedRequests.types.ts';

type AuthRepoType = {
  login: () => Promise<AxiosResponse<ApiResponse<unknown>>>; // TODO Type!
  logout: () => Promise<AxiosResponse<ApiResponse<unknown>>>; // TODO Type!
  register: (
    userRegistrationData: UserRegistrationFormData
  ) => Promise<AxiosResponse<ApiResponse<PrivredniSubjekt>>>;
  registerOstalo: (
    formData: IZahteviDodajAktZahteva
  ) => Promise<AxiosResponse<ApiResponse<boolean>>>;
  getUserMainInfo: () => Promise<AxiosResponse<ApiResponse<UserData>>>;
  getAllBussinessEntities: () => Promise<
    AxiosResponse<ApiResponse<PrivredniSubjekt[]>>
  >;
  selectBussinessEntity: (
    id: number
  ) => Promise<AxiosResponse<ApiResponse<unknown>>>;
};

const login = () => {
  return axios.get('/LoginSSO');
};

const logout = () => {
  return axios.get('/LogoutSSO');
};

const register = (userRegistrationData: UserRegistrationFormData) => {
  return axios.post('/korisnik/registracija', { ...userRegistrationData });
};

const registerOstalo = (formData: IZahteviDodajAktZahteva) => {
  return axios.post('/korisnik/registracija-ostalo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getUserMainInfo = () => {
  return axios.get('/korisnik/osnovne-informacije');
};

const getAllBussinessEntities = () => {
  return axios.get('/korisnik/svi-privredni-subjekti');
};

const selectBussinessEntity = (id: number) => {
  return axios.get(`/korisnik/izaberi-privredni-subjekt?drustvoId=${id}`);
};

export const authRepo: AuthRepoType = {
  login,
  logout,
  register,
  getUserMainInfo,
  getAllBussinessEntities,
  selectBussinessEntity,
  registerOstalo,
};
