import React from 'react';
import { Popconfirm as ANTPopconfirm, PopconfirmProps } from 'antd';
import { useTranslation } from 'react-i18next';

interface IPopconfirmProps extends PopconfirmProps {
  title: string;
  children?: JSX.Element;
  onConfirm?: (e: unknown) => unknown;
}

export const PopconfirmButton: React.FC<IPopconfirmProps> = ({
  title,
  onConfirm,
  children,
}) => {
  const { t } = useTranslation();
  const translatedTitle = t(`${title}`);

  return (
    <ANTPopconfirm
      title={translatedTitle}
      onConfirm={onConfirm}
      onCancel={(e) => e?.stopPropagation()}
      okText={t('da')}
      cancelText={t('ne')}
    >
      {children}
    </ANTPopconfirm>
  );
};
