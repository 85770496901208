import { useEffect } from 'react';
import { Button, Flex } from 'antd';
import Logo from '../../assets/eid_logo.png';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import style from './Login.module.scss';
import { authStore } from '@/modules/auth/auth.store.ts';
import { API_URL, isDevelopment } from '@/utils/constants.ts';
import { MOCK_USER1 } from '@/utils/mockData.ts';
import { ROLE_DEFAULT_ROUTE } from '@/routes/routes.tsx';

const keyFunctionalitiesTexts = [
  t('kljucne_funkcionalnosti.elektronsko_podnosenje'),
  t('kljucne_funkcionalnosti.pracenje_statusa'),
  t('kljucne_funkcionalnosti.preuzimanje_dokumenata'),
  t('kljucne_funkcionalnosti.obavestenja_i_notifikacije'),
  t('kljucne_funkcionalnosti.sigurnost'),
];

const advantagesTexts = [
  t('prednosti.efikasnost'),
  t('prednosti.transparentnost'),
  t('prednosti.pristupacnost'),
  t('prednosti.usteda'),
];

const Login = observer(() => {
  const navigate = useNavigate();

  const handleLogin = () => {
    if (isDevelopment()) {
      authStore.login(MOCK_USER1);
    } else {
      window.location.href = `${API_URL}/LoginSSO`;
    }
  };

  useEffect(() => {
    if (authStore.currentUser) {
      navigate(ROLE_DEFAULT_ROUTE[authStore.userRoles![0].roleId]);
    }
  }, [navigate, authStore.currentUser]);

  return (
    <div className={style.loginContainer}>
      <div className={style.imagesContainer}>
        <div className={`${style.imageRow} ${style.bg}`} />
        <div className={style.descriptionContainer}>
          <div className={style.descriptionModal}>
            <h2>{t('energetski_portal_MRE')}</h2>
            <p>{t('opis_portala')}</p>
            <Flex align='center' justify='center'>
              <Button
                type='primary'
                onClick={handleLogin}
                className={style.prijaviSeButton}
              >
                <Flex align={'center'} gap={8}>
                  <img src={Logo} alt='eid-logo' height={30} />
                  <span>
                    {t('prijava_preko_eportala', { site: 'eid.gov.rs' })}
                  </span>
                </Flex>
              </Button>
            </Flex>
          </div>
        </div>
      </div>
      <div className={style.informationsContainer}>
        <div className={style.informationsWrapperContainer}>
          <BulletListWithTitle
            title={t('kljucne_funkcionalnosti.naslov')}
            textRows={keyFunctionalitiesTexts}
          />
          <BulletListWithTitle
            title={t('prednosti.naslov')}
            textRows={advantagesTexts}
          />
        </div>
      </div>
    </div>
  );
});

type BulletListWithTitleProps = {
  title: string;
  textRows: string[];
};

const BulletListWithTitle = ({ title, textRows }: BulletListWithTitleProps) => {
  return (
    <div className={style.bulletListContainer}>
      <p className={style.informationTitle}>{title}</p>
      <ul style={{ paddingLeft: 20 }}>
        {textRows.map((row, index) => (
          <li className={style.informationText} key={index}>
            {row}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default Login;
