import { observer } from 'mobx-react-lite';

import style from './RequestView.module.scss';
import { authStore } from '@/modules/auth/auth.store.ts';
import RequestViewPS from '@/pages/RequestView/components/RequestViewPS/RequestViewPS.tsx';
import RequestViewMRE from '@/pages/RequestView/components/RequestViewMRE/RequestViewMRE.tsx';

const RequestView = observer(() => {
  return (
    <div className={style.container}>
      {authStore.isUserPS && <RequestViewPS />}
      {authStore.isUserMRE && <RequestViewMRE />}
    </div>
  );
});

export default RequestView;
