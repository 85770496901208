const MRE_LOCAL_STORAGE_KEY = '@MRE_';

const getFullKey = (key: string) => `${MRE_LOCAL_STORAGE_KEY}${key}`;

const getValue = (key: string) => {
  const data = localStorage.getItem(getFullKey(key));

  if (!data) {
    console.error('No data in Local Storage with key ', getFullKey(key));
    return null;
  }

  return JSON.parse(data);
};

const setValue = (key: string, value: unknown) => {
  const stringifyData = JSON.stringify(value);

  try {
    localStorage.setItem(getFullKey(key), stringifyData);
  } catch (e) {
    console.error(
      `Error storing value in Local Storage with key ${getFullKey(key)}`,
      e
    );
  }
};

const removeValue = (key: string) => {
  localStorage.removeItem(getFullKey(key));
};

export const LocalStorage = {
  getValue,
  setValue,
  removeValue,
};
