import { ZahteviEnum } from '@/modules/requests/requests.types';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

type RequestStatusProps = {
  statusId: ZahteviEnum | null;
};
const RequestStatus = ({ statusId }: RequestStatusProps) => {
  const { t } = useTranslation();

  const color = () => {
    switch (statusId) {
      case ZahteviEnum.NACRT:
        return 'gold';
      case ZahteviEnum.PODNET:
        return 'blue';
      case ZahteviEnum.OBRADA:
        return 'geekblue';
      case ZahteviEnum.ODBACEN:
        return 'red';
      case ZahteviEnum.ODBIJEN:
        return 'red';
      case ZahteviEnum.DOPUNA_ZAHTEVA:
        return 'purple';
      case ZahteviEnum.ODOBREN:
        return 'green';
      case ZahteviEnum.PREKINUT_POSTUPAK:
        return 'volcano';
    }
  };
  const text = t(`statusi.zahtev.${statusId}`);

  return <Tag color={color()}>{text}</Tag>;
};

export default RequestStatus;
