import { Col, Row } from 'antd';
import { t } from 'i18next';

import style from './DynamicTitle.module.scss';

interface IDynamicTitleProps {
  title: string;
  subComponent?: JSX.Element;
  className?: string;
}

const DynamicTitle: React.FC<IDynamicTitleProps> = ({
  title,
  subComponent,
  className,
}) => {
  return (
    <Row className={className}>
      <Col span={12}>
        <h2>{t(title)}</h2>
      </Col>
      <Col className={style.subComponentContainer} span={12}>
        {subComponent}
      </Col>
    </Row>
  );
};

export default DynamicTitle;
