import { Tooltip } from 'antd';

type InfoWithTooltipProps = {
  icon: React.ReactNode;
  tooltipMessage: string;
};
const IconWithTooltip = ({ icon, tooltipMessage }: InfoWithTooltipProps) => {
  return <Tooltip title={tooltipMessage}>{icon}</Tooltip>;
};

export default IconWithTooltip;
