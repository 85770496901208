import { Button, Col, Flex, Form, Input, Row, Select } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction } from 'react';

import { RangePicker } from '@/components/DatePicker/DatePicker';
import Guard from '@/components/Guard/Guard';
import RequestsNamesSelect from '@/components/RequestsNamesSelect/RequestsNamesSelect';
import RequestsTreeSelect from '@/components/RequestsTreeSelect/RequestsTreeSelect';
import { requestsStore } from '@/modules/requests/requests.store';
import {
  IFilterZahteva,
  IZahteviSviZahteviPayload,
} from '@/modules/submittedRequests/submittedRequests.types.ts';
import { DATE_FORMAT_UI } from '@/utils/dateFormatter.ts';
import { map4select } from '@/utils/map4select';
import style from './SubmittedRequestsFilters.module.scss';
import { Dayjs } from 'dayjs';
import { INITIAL_REQUEST_FILTER_PARAMS } from '@/modules/submittedRequests/submittedRequests.constants.ts';

interface ISubmittedRequestsFilters {
  setParams: Dispatch<SetStateAction<IZahteviSviZahteviPayload>>;
  params: IZahteviSviZahteviPayload;
}

const SubmittedRequestsFilters = observer(
  ({ setParams, params }: ISubmittedRequestsFilters) => {
    const [form] = Form.useForm();

    // const shouldRenderFilterTipMineralneSirovine = () => {
    //   // return params.zahtevGrupaId === 2 || params.zahtevPodgrupaId === 5;
    //   return false; //Return false until backend is done
    // };

    const handleResetFilterClick = () => {
      form.resetFields();
      setParams(INITIAL_REQUEST_FILTER_PARAMS);
    };

    return (
      <Form form={form} layout='vertical'>
        <Row gutter={[5, 10]} className='mt-20'>
          <Col span={24}>
            <Form.Item<IFilterZahteva> name='zahtevGrupa' className='m-0'>
              <RequestsTreeSelect<IZahteviSviZahteviPayload>
                setParams={setParams}
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item<IFilterZahteva>
              name='tipMineralneSirovine'
              className='w-100 m-0'
            >
              <Select
                disabled={!shouldRenderFilterTipMineralneSirovine()}
                placeholder={t('tip_mineralne_sirovine_materijala')}
                options={requestsStore.config?.mineralneSirovine}
                onChange={(value) => {
                  setParams((prevParams) => ({
                    ...prevParams,
                    statusZahteva: value || null,
                  }));
                }}
                allowClear
                className='w-100'
              />
            </Form.Item>
          </Col> */}
          <Col span={4}>
            <Form.Item<IFilterZahteva>
              name='zavodniBrZahteva'
              className='w-100 m-0'
            >
              <Input
                placeholder={t('broj_zahteva')}
                allowClear
                className='w-100'
                onChange={({ target }) => {
                  setParams((prevParams) => ({
                    ...prevParams,
                    zavodniBroj: target.value || null,
                  }));
                }}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item<IFilterZahteva>
              name='nazivZahteva'
              className='w-100 m-0'
            >
              <RequestsNamesSelect<IZahteviSviZahteviPayload>
                setParams={setParams}
                params={params}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <div className={style.dateFilterContainer}>
              <Form.Item<IFilterZahteva>
                name='datumPodnosenjaZahteva'
                className='w-100 m-0'
              >
                <Flex justify='end' align='center' gap={5}>
                  <span className='alignRight'>
                    {t('datum_podnosenja_zahteva')}
                  </span>
                  <RangePicker
                    allowClear
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(range: Dayjs[] | null[]) => {
                      setParams((prevParams) => ({
                        ...prevParams,
                        datumOd: range?.[0] ?? null,
                        datumDo: range?.[1]
                          ? range[1]
                              .set('hour', 23)
                              .set('minute', 59)
                              .set('second', 59)
                          : null,
                      }));
                    }}
                    format={DATE_FORMAT_UI}
                    placeholder={[t('od'), t('do')]}
                  />
                </Flex>
              </Form.Item>
            </div>
          </Col>
          <Guard requiredRole='MRE'>
            <Col span={4}>
              <Form.Item<IFilterZahteva>
                name='maticniBrPrivrednogSubjekta'
                className='w-100 m-0'
              >
                <Input
                  placeholder={t('maticni_broj_privrednog_subjekta')}
                  allowClear
                  onChange={({ target }) => {
                    setParams((prevParams) => ({
                      ...prevParams,
                      maticniBrojPrivrednogSubjekta: target.value || null,
                    }));
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={20}>
              <Form.Item<IFilterZahteva>
                name='nazivPrivredniSubjekt'
                className='w-100 m-0'
              >
                <Input
                  placeholder={t('naziv_privrednog_subjekta')}
                  allowClear
                  onChange={({ target }) => {
                    setParams((prevParams) => ({
                      ...prevParams,
                      nazivPrivredniSubjekt: target.value || null,
                    }));
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item<IFilterZahteva>
                name='obradjivac'
                className='w-100 m-0'
              >
                <Select
                  placeholder={t('obradjivac')}
                  allowClear
                  onChange={(value) => {
                    setParams((prevParams) => ({
                      ...prevParams,
                      obradjivac: value || null,
                    }));
                  }}
                  options={
                    map4select({
                      sourceArray: requestsStore.requestProcessor,
                      valueAccessor: 'id',
                      labelAccessor: 'name',
                    }) || []
                  }
                  className='w-100'
                />
              </Form.Item>
            </Col>
          </Guard>
          <Col span={6}>
            <Form.Item<IFilterZahteva>
              name='statusZahteva'
              className='w-100 m-0'
            >
              <Select
                placeholder={t('status_zahteva')}
                options={
                  map4select({
                    sourceArray: requestsStore.requestStatuses,
                    valueAccessor: 'id',
                    labelAccessor: 'naziv',
                  }) || []
                }
                onChange={(value) => {
                  setParams((prevParams) => ({
                    ...prevParams,
                    statusZahteva: value || null,
                  }));
                }}
                allowClear
                className='w-100'
              />
            </Form.Item>
          </Col>
          <Guard requiredRole='MRE'>
            <Col span={6}>
              <Form.Item<IFilterZahteva>
                name='statusRaspodela'
                className='w-100 m-0'
              >
                <Select
                  className='w-100'
                  placeholder={t('status_raspodele')}
                  options={requestsStore.allocationStatuses}
                  onChange={(value) => {
                    setParams((prevParams) => ({
                      ...prevParams,
                      statusRaspodela: value || null,
                    }));
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Guard>
          <Col flex={1}>
            <Flex justify='end'>
              <Button onClick={handleResetFilterClick} type='primary'>
                {t('ponistite_pretragu')}
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    );
  }
);

export default SubmittedRequestsFilters;
