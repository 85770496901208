import { useState } from 'react';
import { Col, Row } from 'antd';
import { t } from 'i18next';

import style from './RequestDecisionEdit.module.scss';
import { RequestDecisionEditForm } from './components/RequestDecisionEditForm/RequestDecisionEditForm';
import { RequestDecisionApprovalActions } from '../RequestDecisionApproval/components/RequestDecisionApprovalActions/RequestDecisionApprovalActions';

interface IRequestDecisionEdit {
  handleSuccess: () => void;
}
export const RequestDecisionEdit = ({
  handleSuccess,
}: IRequestDecisionEdit) => {
  const [resenjeId, setResenjeId] = useState<number | null>(null);

  return (
    <>
      <Row gutter={25}>
        <Col span={16}>
          <RequestDecisionEditForm setResenjeId={setResenjeId} />
        </Col>
        <Col span={8}>
          <RequestDecisionApprovalActions
            resenjeId={resenjeId}
            handleSuccess={handleSuccess}
          />
        </Col>
      </Row>
      <div className={style.infoContainer}>{t('potrebno_je')}</div>
    </>
  );
};
