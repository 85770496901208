import { useCallback, useEffect, useMemo, useState } from 'react';
import type { DescriptionsProps } from 'antd';
import {
  Button,
  Col,
  Descriptions,
  Input,
  notification,
  Row,
  Switch,
} from 'antd';
import { t } from 'i18next';

// import './CompanyDetails.scss';
import style from './CompanyDetails.module.scss';
import { IPrivredniSubjektDetaljiResponse } from '@/modules/settings/settings.types';
import { settingsService } from '@/modules/settings/settings.service';
import Loading from '@/components/Loading/Loading';
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle';
import { observer } from 'mobx-react-lite';
import { authStore } from '@/modules/auth/auth.store.ts';

const EMPTY_STATE_ELEMENT = '/';

const CompanyDetails = observer(() => {
  const [loading, setLoading] = useState<boolean>(true);
  const [companyDetails, setCompanyDetails] = useState<
    IPrivredniSubjektDetaljiResponse | undefined
  >(undefined);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const getInputValueForUI = (value?: string) => {
    return value || EMPTY_STATE_ELEMENT;
  };

  const mapResponse = (response: string[] | undefined) => {
    return response && response.length ? (
      <div>
        {response.map((entity: string, index: number) => {
          return <p key={index}>{entity}</p>;
        })}
      </div>
    ) : (
      EMPTY_STATE_ELEMENT
    );
  };

  const getFieldLabel = () => {
    if (companyDetails?.tipSubjekta === 1 || companyDetails?.tipSubjekta === 2)
      return t('maticni_broj');
    return t('jbkjs');
  };

  const getFieldChildren = () => {
    if (companyDetails?.tipSubjekta === 1 || companyDetails?.tipSubjekta === 2)
      return getInputValueForUI(companyDetails.maticniBroj);
    return getInputValueForUI(companyDetails?.jbkjs);
  };

  useEffect(() => {
    setLoading(true);
    settingsService
      .getPrivredniSubjektDetalji()
      .then((res) => {
        setCompanyDetails(res);
      })
      .catch((err) => {
        notification.error(err);
      })
      .finally(() => setLoading(false));
  }, [authStore.selectedBusinessEntity?.id]);

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: t('naziv_privrednog_subjekta'),
      children: getInputValueForUI(companyDetails?.naziv),
    },
    {
      key: '2',
      label: t('ulica_i_broj'),
      children: getInputValueForUI(companyDetails?.ulica),
    },
    { key: '3', label: getFieldLabel(), children: getFieldChildren() },
    {
      key: '4',
      label: t('mesto'),
      children: getInputValueForUI(companyDetails?.mesto),
    },
    {
      key: '5',
      label: t('pib'),
      children: getInputValueForUI(companyDetails?.pib),
    },
    {
      key: '6',
      label: t('eposta'),
      children: mapResponse(companyDetails?.emailAdrese),
    },
    {
      key: '7',
      label: t('telefon'),
      children: mapResponse(companyDetails?.telefoni),
    },
    {
      key: '8',
      label: t('broj_poslovnog_racuna'),
      children: mapResponse(companyDetails?.tekuciRacuni),
    },
    {
      key: '9',
      label: t('veb_stranica'),
      children: mapResponse(companyDetails?.vebSajt),
    },
  ];

  const handleChangeEmail = useCallback(() => {
    settingsService
      .postPrivredniSubjektKreirajMailNotifikacije(email)
      .then(() => {
        notification.success({
          message: t('email_promena_success'),
        });
      })
      .catch((err) => {
        console.error('Error changing email ', err);
        notification.error(err);
      });
  }, [email]);

  const title = useMemo(() => {
    if (authStore.isUserPS) {
      return t('podaci_o_privrednom_subjektu');
    } else if (authStore.isUserMRE) {
      return t('podaci_o_ministarstvu');
    } else {
      return '';
    }
  }, [authStore.selectedBusinessEntity]);

  return (
    <>
      {loading ? (
        <div className='settingsLoaderWrapper'>
          <Loading size='large' />
        </div>
      ) : (
        <>
          <Col span={24}>
            <DynamicTitle title={title} className='mt-20 mb-20' />
          </Col>
          <Descriptions colon={false} layout='vertical' items={items} />
          <Col span={24}>
            <Col span={12}>
              <h2 className={style.titleSettings}>
                {t('stranice.podesavanja')}
              </h2>
            </Col>
            <Row>
              <Col span={16}>
                {t('eposta_za_prijem_notifikacije')}
                <Input
                  onChange={(e) => {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
                    setIsEmailValid(emailRegex.test(e.target.value));
                    setEmail(e.target.value);
                  }}
                  className={style.emailInput}
                  defaultValue={companyDetails?.emailNotifikacije}
                  allowClear
                />
                <Button
                  disabled={!isEmailValid}
                  className={style.saveButton}
                  onClick={handleChangeEmail}
                  type='primary'
                >
                  {t('sacuvajte')}
                </Button>
              </Col>
              <Col span={8}>
                {t('iskljucite_notifikacije_na_eposti')}
                <Switch
                  className={style.switch}
                  defaultValue={companyDetails?.iskljuciEmailNotifikacije}
                  onChange={(value) =>
                    settingsService.putPrivredniSubjektIzmeniMailNotifikacije(
                      value
                    )
                  }
                />
              </Col>
            </Row>
          </Col>
        </>
      )}
    </>
  );
});

export default CompanyDetails;
