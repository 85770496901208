import { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Upload,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { UploadChangeParam } from 'antd/es/upload';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

import style from './RequestDecisionApprovalForm.module.scss';
import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service';
import { uploadFileInChunks } from '@/utils/uploadFile.ts';
import { ResenjeProps } from '@/modules/requests/requests.types';
import { ModalName, modalStore } from '@/modules/modal/modal.store';
import { requestsStore } from '@/modules/requests/requests.store';

interface IRequestDecisionApprovalForm {
  requestDecision: Pick<
    ResenjeProps,
    'zavodniBrojResenja' | 'datumUrucenjaResenja'
  > | null;
  setResenjeId: Dispatch<SetStateAction<number | null>>;
  resenjeId: number | null;
}

export const RequestDecisionApprovalForm = observer(
  ({
    requestDecision,
    resenjeId,
    setResenjeId,
  }: IRequestDecisionApprovalForm) => {
    const { zahtevId } = useParams();
    const [form] = useForm();
    const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [file, setFile] = useState<any>();
    const [zavodniBrojAkta, setZavodniBrojAkta] = useState<string>();

    const handleSubmit = () => {
      const payload = {
        ...form.getFieldsValue(),
        zahtevId: zahtevId,
        odluka: modalStore.decision,
      };
      submittedRequestsService
        .postZahteviSacuvajAtributeOdluke(payload)
        .then((res) => {
          setResenjeId(res.id);
          setZavodniBrojAkta(res.zavodniBrojAkta);
          notification.success({ message: t('sacuvaj_atribute_success') });
        })
        .catch((err) => {
          notification.error(err);
        });
    };

    const mapSelectedFile = async (uploadObj: UploadChangeParam) => {
      const file = uploadObj.fileList[0];
      const uploadedFile = {
        format: file.type?.split('/')[1],
        data: file.originFileObj,
        fileName: file.name,
      };
      setFile(uploadedFile);
      setIsFileUploaded(!!uploadedFile);
    };

    const handleUploadPriloga = async () => {
      uploadFileInChunks(
        {
          zahtevId: +zahtevId!,
          resenjeId: resenjeId,
          file: file,
        },
        submittedRequestsService.postZahteviSacuvajPrilog
      )
        .then(() => {
          notification.success({
            message: t('forma.sekcija.prilog.prilog_upload_success'),
          });
          requestsStore?.setCanGenerateAct(true);
        })
        .catch((err) => {
          notification.error(err);
          console.error('Error uploading prilog ', err);
        });
    };

    return (
      <Form
        className={style.form}
        layout='vertical'
        name='requestDecisionApproval'
        form={form}
        onFinish={handleSubmit}
      >
        <Row gutter={40}>
          <Col span={12}>
            <Form.Item colon={false} label={t('zavodni_broj_akta')}>
              <Input
                disabled
                value={
                  zavodniBrojAkta || requestDecision?.zavodniBrojResenja || ''
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='brojIstraznogProstora'
              colon={false}
              label={t('broj_istraznog_prostora')}
              rules={[
                {
                  required: true,
                  message: t('forma.sekcija.polje.obavezno_polje'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item colon={false} label={t('datum_i_vreme_zavodjenja_akta')}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='povrsinaIstraznogProstora'
              colon={false}
              label={t('povrsina_istraznog_prostora')}
              rules={[
                {
                  required: true,
                  message: t('obavezno_polje'),
                },
              ]}
            >
              <InputNumber className='w-100' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item colon={false} label={t('datum_i_vreme_urucenja_akta')}>
              <Input
                disabled
                value={requestDecision?.datumUrucenjaResenja ?? ''}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='duzinaIstraznogRoka'
              colon={false}
              label={t('duzina_istraznog_roka')}
              rules={[
                {
                  required: true,
                  message: t('obavezno_polje'),
                },
              ]}
            >
              <InputNumber className='w-100' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon={false}
              label={t('vrsta_geoloskih_istrazivanja_input')}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col span={12}>
            <Form.Item>
              <Flex justify='center'>
                <div className={style.uploadContainer}>
                  <div className={style.bottomBorder}>
                    <span>{t('prilog')}</span>
                  </div>
                  <div className='m-15'>
                    <Upload
                      maxCount={1}
                      locale={{ removeFile: t('obrisite_dokument') }}
                      onChange={mapSelectedFile}
                      beforeUpload={() => false}
                      accept={'.pdf'}
                    >
                      <Button disabled={!resenjeId} type={'primary'}>
                        <UploadOutlined />
                      </Button>
                    </Upload>
                  </div>
                </div>
              </Flex>
            </Form.Item>
          </Col>
        </Row>
        <Flex justify='end' gap={15}>
          <Button
            onClick={() => modalStore.openModal(ModalName.RESQUEST_DECISION)}
            type='primary'
          >
            {t('odustanite')}
          </Button>
          {resenjeId && isFileUploaded ? (
            <Button onClick={() => handleUploadPriloga()} type='primary'>
              {t('posaljite_prilog')}
            </Button>
          ) : (
            <Button
              type='primary'
              htmlType='submit'
              form='requestDecisionApproval'
            >
              {t('sacuvajte')}
            </Button>
          )}
        </Flex>
      </Form>
    );
  }
);
