import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import sr_cyrl from './locales/sr-Cyrl.json';
import sr_latn from './locales/sr-Latn.json';
import { LocalStorage } from './utils/local-storage.ts';

export enum LANGUAGES {
  SERBIAN_LATIN = 'sr-Latn', // eslint-disable-line
  SERBIAN_CYRILLIC = 'sr-Cyrl', // eslint-disable-line
}

export const languageFromLocalStorage = LocalStorage.getValue('language');

if (!languageFromLocalStorage) {
  LocalStorage.setValue('language', LANGUAGES.SERBIAN_CYRILLIC);
}

i18n.use(initReactI18next).init({
  lng: LocalStorage.getValue('language'),
  fallbackLng: LANGUAGES.SERBIAN_LATIN,
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    'sr-Cyrl': sr_cyrl,
    'sr-Latn': sr_latn,
  },
});

export const changeLanguage = async (language: LANGUAGES) => {
  i18n
    .changeLanguage(language)
    .then(() => {
      LocalStorage.setValue('language', language);
    })
    .catch((err) => {
      console.error('Error changing the language', err);
    });
};

export default i18n;
