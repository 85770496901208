import { Button, Flex, Modal, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

import styles from './CreatedRequestModal.module.scss';
import { ModalName, modalStore } from '@/modules/modal/modal.store';

const { Title, Paragraph } = Typography;

interface CreatedRequestModalProps {
  onCloseModal: () => void;
  pisarnicaBroj?: number;
}

const CreatedRequestModal: React.FC<CreatedRequestModalProps> = observer(
  ({ onCloseModal, pisarnicaBroj }) => {
    const isOpen = modalStore.isVisible(ModalName.CREATED_REQUEST);
    return (
      <Modal
        open={isOpen}
        width={400}
        onCancel={() => {
          onCloseModal();
        }}
        destroyOnClose
        closable={false}
        footer={
          <Flex justify='center'>
            <Button type='primary' onClick={() => onCloseModal()}>
              {t('forma.dugme.u_redu')}
            </Button>
          </Flex>
        }
      >
        <div className={styles.createdRequestModalBody}>
          <Title level={5} className='m-0'>
            {t('forma.modal.vas_zahtev_je_uspesno_podnet')}
          </Title>
          <Paragraph className='m-0'>
            {t('forma.modal.broj_zahteva_je')}
          </Paragraph>
          <Paragraph strong className='m-0'>
            {pisarnicaBroj}
          </Paragraph>
          <Paragraph className='m-0'>
            {t('forma.modal.status_zahteva_mozete_pratiti_na_listi_zahteva')}
          </Paragraph>
        </div>
      </Modal>
    );
  }
);

export default CreatedRequestModal;
