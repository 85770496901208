import Loading from '@/components/Loading/Loading';
import { PageWrapper } from '@/components/PageWrapper/PageWrapper';
import { PopconfirmButton } from '@/components/PopconfirmButton/PopconfirmButton';
import PrivredniSubjektStatus from '@/components/Status/PrivredniSubjektStatus/PrivredniSubjektStatus';
import { requestsService } from '@/modules/requests/requests.service';
import { requestsStore } from '@/modules/requests/requests.store';
import { EMPTY_STATE_PLACEHOLDER } from '@/modules/settings/settings.constants';
import { settingsService } from '@/modules/settings/settings.service';
import { IPrivredniSubjektDetaljiResponse } from '@/modules/settings/settings.types';
import {
  Button,
  Descriptions,
  DescriptionsProps,
  Flex,
  notification,
} from 'antd';
import { ArgsProps } from 'antd/es/notification/interface';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const BusinessRegisterDetails = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [details, setDetails] = useState<IPrivredniSubjektDetaljiResponse>();
  const { id } = useParams();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        await requestsService.loadConfig();
        await fetchDetails();
      } catch (error) {
        console.error('Error loading config', error);
        notification.error(error as ArgsProps);
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  }, []);

  const fetchDetails = async () => {
    try {
      const res = await settingsService.postPrivredniSubjektiDetalji({
        privredniSubjektId: +id!,
      });
      setDetails(res);
    } catch (error: unknown) {
      console.error('Error get request details ', error);
      notification.error(error as ArgsProps);
    }
  };

  const mapResponse = (response: string[] | undefined) => {
    return response && response.length ? (
      <div>
        {response.map((entity: string, index: number) => {
          return <p key={index}>{entity}</p>;
        })}
      </div>
    ) : (
      EMPTY_STATE_PLACEHOLDER
    );
  };

  const createItems = useMemo((): DescriptionsProps['items'] => {
    if (!details) {
      return undefined;
    }
    const descriptionItems: DescriptionsProps['items'] = [
      {
        key: '1',
        label: t('naziv'),
        children: <>{settingsService.getInputValueForUI(details?.naziv)}</>,
      },
      {
        key: '2',
        label: t('pib'),
        children: <>{settingsService.getInputValueForUI(details?.pib)}</>,
      },
      {
        key: '3',
        label: t('maticni_broj'),
        children: (
          <>{settingsService.getInputValueForUI(details?.maticniBroj)}</>
        ),
      },
      {
        key: '4',
        label: t('ulica_i_broj'),
        children: <>{settingsService.getInputValueForUI(details?.ulica)}</>,
      },
      {
        key: '5',
        label: t('mesto'),
        children: <>{settingsService.getInputValueForUI(details?.mesto)}</>,
      },
      {
        key: '6',
        label: t('delatnost'),
        children: (
          <>
            {settingsService.findLabelById(
              requestsStore.config?.delatnosti,
              details?.delatnostId
            )}
          </>
        ),
      },
      {
        key: '7',
        label: t('broj_poslovnog_racuna'),
        children: <>{mapResponse(details?.tekuciRacuni)}</>,
      },
      {
        key: '8',
        label: t('telefon'),
        children: <>{mapResponse(details?.telefoni)}</>,
      },
      {
        key: '9',
        label: t('veb_stranica'),
        children: <>{mapResponse(details?.vebSajt)}</>,
      },
      {
        key: '10',
        label: t('email'),
        children: mapResponse(details?.emailAdrese),
      },
      {
        key: '11',
        label: t('eposta_za_prijem_notifikacije'),
        children: (
          <>{settingsService.getInputValueForUI(details?.emailNotifikacije)}</>
        ),
      },
      {
        key: '12',
        label: t('ime'),
        children: <>{settingsService.getInputValueForUI(details?.userIme)}</>,
      },
      {
        key: '13',
        label: t('prezime'),
        children: (
          <>{settingsService.getInputValueForUI(details?.userPrezime)}</>
        ),
      },
      {
        key: '14',
        label: t('status'),
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        children:
          details?.manuallyDisabled === undefined ||
          details?.manuallyDisabled === null ? (
            EMPTY_STATE_PLACEHOLDER
          ) : (
            <PrivredniSubjektStatus status={!details.manuallyDisabled} />
          ),
      },
      {
        key: '15',
        label: t('apr_status'),
        children: <PrivredniSubjektStatus status={details.status} />,
      },
    ];

    return descriptionItems;
  }, [details]);

  const handleConfirm = (manuallyDisabled: boolean, successMessage: string) => {
    settingsService
      .getPrivredniSubjektiAktivirajDeaktiviraj({
        privredniSubjektId: +id!,
        manuallyDisabled,
      })
      .then(() => {
        notification.success({ message: t(successMessage) });
        fetchDetails();
      })
      .catch((err) => {
        notification.error(err);
      });
  };

  return (
    <PageWrapper greyFrame={true}>
      {loading ? (
        <Loading size={'large'} />
      ) : (
        <>
          <Descriptions
            column={3}
            colon={false}
            layout='vertical'
            items={createItems}
            size='small'
          />
          <Flex gap={20} className='mt-40' justify='flex-end'>
            {details?.id &&
              details?.akcije &&
              details?.akcije.map((akcija) => {
                if (akcija.value === 1) {
                  return (
                    <PopconfirmButton
                      key={akcija.value}
                      title={t(
                        'da_li_ste_sigurni_da_zelite_da_aktivirate_privredni_subjekt'
                      )}
                      onConfirm={() =>
                        handleConfirm(false, 'aktiviraj_success')
                      }
                    >
                      <Button type='primary'>{akcija.label}</Button>
                    </PopconfirmButton>
                  );
                }

                if (akcija.value === 2) {
                  return (
                    <PopconfirmButton
                      key={akcija.value}
                      title={t(
                        'da_li_ste_sigurni_da_zelite_da_deaktivirate_privredni_subjekt'
                      )}
                      onConfirm={() =>
                        handleConfirm(true, 'deaktiviraj_success')
                      }
                    >
                      <Button type='primary' danger>
                        {akcija.label}
                      </Button>
                    </PopconfirmButton>
                  );
                }
              })}
          </Flex>
        </>
      )}
    </PageWrapper>
  );
};

export default BusinessRegisterDetails;
