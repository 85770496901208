import IconWithTooltip from '@/components/IconWithTooltip/IconWithTooltip.tsx';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, FormInstance, Tooltip, Upload, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { t } from 'i18next';
import styles from './CustomUpload.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { Rule } from 'antd/lib/form';
import { eventEmitter } from '../FormBuilder/DynamicForm';

const DEFAULT_ACCEPT_EXTENSIONS = [
  '.doc',
  '.docx',
  '.dwg',
  '.jpg',
  '.jpeg',
  '.png',
  '.tiff',
  '.pdf',
  '.xls',
  '.xlsx',
];

type CustomUploadProps = {
  form: FormInstance;
  name: string | string[];
  description?: string;
  disabled?: boolean;
  label?: string;
  accept?: string[];
  readOnly?: boolean;
  tooltip?: string;
  validateJustPassedInput?: boolean;
  singingDocument?: boolean;
  disabledMassage?: string;
  rules?: Rule[];
};
const CustomUpload = ({
  form,
  name,
  label,
  description,
  disabled,
  accept = DEFAULT_ACCEPT_EXTENSIONS,
  readOnly,
  tooltip,
  validateJustPassedInput: justValidateInput = false,
  singingDocument,
  disabledMassage,
  rules,
}: CustomUploadProps) => {
  const [fileList, setFileList] = useState<UploadFile[] | undefined>([]);

  useEffect(() => {
    const clearFileList = () => {
      setFileList([]);
      form.setFieldValue(name, undefined);
    };
    eventEmitter.addEventListener('obrisiPriloge', clearFileList);
    return () => {
      eventEmitter.removeEventListener('obrisiPriloge', clearFileList);
    };
  }, []);

  const handleFileUpload = async (uploadObj: UploadChangeParam) => {
    setFileList(uploadObj.fileList);
    const file = uploadObj.fileList[0];
    if (file) {
      const uploadedFile = {
        format: file.type?.split('/')[1],
        data: file.originFileObj,
        fileName: file.name,
      };
      form.setFieldValue(name, uploadedFile);
      justValidateInput ? form.validateFields([name]) : form.validateFields();
    }
  };

  const handleFileRemove = () => {
    form.setFieldValue(name, undefined);
    justValidateInput ? form.validateFields([name]) : form.validateFields();
  };

  const acceptExtensions = useMemo(() => {
    if (singingDocument) {
      return '.pdf';
    }
    return accept?.toString();
  }, [accept, singingDocument]);

  const requiredRule = useMemo(() => {
    return rules?.some((rule) => 'required' in rule && rule.required);
  }, [rules]);

  return (
    <>
      {label && (
        <p>
          {requiredRule ? (
            <span className={styles.requiredChar}>* </span>
          ) : null}
          <span>{label} </span>
          {tooltip && (
            <IconWithTooltip
              icon={<InfoCircleOutlined />}
              tooltipMessage={tooltip}
            />
          )}
        </p>
      )}
      {label && <hr />}
      <Upload
        accept={acceptExtensions}
        locale={{ removeFile: t('obrisite_dokument') }}
        maxCount={1}
        beforeUpload={() => false}
        onChange={handleFileUpload}
        onRemove={handleFileRemove}
        disabled={disabled}
        className={styles.uploadWrapper}
        fileList={fileList}
      >
        {!readOnly && (
          <div className={styles.buttonContainer}>
            {description && <p>{description}</p>}
            <div className={styles.buttonWrapper}>
              {acceptExtensions && (
                <div className={styles.acceptDescription}>
                  <p>
                    {t('tip_fajla', {
                      tipFajla: acceptExtensions
                        .replaceAll('.', ' ')
                        .toUpperCase(),
                    })}
                  </p>
                </div>
              )}
              <Tooltip title={disabledMassage}>
                <Button
                  disabled={disabled}
                  type={'primary'}
                  style={{ borderRadius: 0 }}
                >
                  <UploadOutlined />
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </Upload>
    </>
  );
};

export default CustomUpload;
