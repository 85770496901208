/* eslint-disable max-len */
import { Button, Descriptions, DescriptionsProps, notification } from 'antd';
import { t } from 'i18next';

import RequestSectionWrapper from '@/pages/RequestView/components/RequestSectionWrapper/RequestSectionWrapper.tsx';
import {
  GetRequestDetailsResponse,
  ResenjeBrojResenja,
  ResenjeOdluka,
} from '@/modules/requests/requests.types';
import { getLocalDateFromUTCDateString } from '@/utils/dateFormatter';
import RequestStatus from '@/components/Status/RequestStatus/RequestStatus';
import { ODLUKE } from '../RequestDecision/components/RequestDecisionModal/RequestDecisionModal';
import { submittedRequestsService } from '@/modules/submittedRequests/submittedRequests.service';
import { authStore } from '@/modules/auth/auth.store';

interface IRequestBasicInformation {
  basicInformation:
    | (Pick<
        GetRequestDetailsResponse,
        | 'zavodniBroj'
        | 'datumPodnosenja'
        | 'status'
        | 'podnosilacNaziv'
        | 'zahtevGrupa'
        | 'zahtevPodgrupa'
        | 'zahtevKategorijaPodgrupe'
        | 'zahtevNaziv'
        | 'interniStatusZahteva'
        | 'zahtevId'
      > & {
        resenjeBrojResenja: ResenjeBrojResenja;
        resenjeOdluka: ResenjeOdluka;
      })
    | null;
}

const RequestBasicInformation: React.FC<IRequestBasicInformation> = ({
  basicInformation,
}) => {
  const EMPTY_STATE_ELEMENT = '/';

  const getInputValueForUI = (value?: string | number | null) => {
    return value || EMPTY_STATE_ELEMENT;
  };

  const getOdlukaLabel = (odlukaId: number | null | undefined) => {
    if (!odlukaId) return '/';
    const odluka = ODLUKE.find((odluka) => odluka.value === odlukaId)?.label;
    return odluka;
  };

  const items1: DescriptionsProps['items'] = [
    {
      key: '1',
      label: t('zavodni_broj'),
      children: getInputValueForUI(basicInformation?.zavodniBroj),
    },
    {
      key: '2',
      label: t('status'),
      children: <RequestStatus statusId={basicInformation?.status || null} />,
    },
    {
      key: '3',
      children: basicInformation?.interniStatusZahteva === 5 &&
        basicInformation.status === 8 &&
        authStore.isUserMRE && (
          <Button
            onClick={() =>
              submittedRequestsService
                .postZahteviPrekiniPrekidPostupka(basicInformation.zahtevId)
                .then(() => {
                  notification.success({ message: t('prekid_success') });
                  setTimeout(() => window.location.reload(), 500);
                })
                .catch((err) => {
                  notification.error(err);
                })
            }
            type='primary'
            danger
          >
            {t('prekini_prekid_postupka')}
          </Button>
        ),
    },
  ];
  const items2: DescriptionsProps['items'] = [
    {
      key: '1',
      label: t('datum_i_vreme_podnosenja_zahteva'),
      children: (
        <p style={{ whiteSpace: 'nowrap' }}>
          {getInputValueForUI(
            getLocalDateFromUTCDateString(basicInformation?.datumPodnosenja)
          )}
        </p>
      ),
    },
    {
      key: '2',
      label: t('podnosilac'),
      children: getInputValueForUI(basicInformation?.podnosilacNaziv),
    },
    {
      key: '3',
      label: t('naziv_zahteva'),
      children: getInputValueForUI(basicInformation?.zahtevNaziv),
    },
    {
      key: '4',
      label: `${t('grupa')} / ${t('podgrupa')} / ${t('tip')}`,
      children: `${getInputValueForUI(basicInformation?.zahtevGrupa)} / ${getInputValueForUI(basicInformation?.zahtevPodgrupa)} / ${getInputValueForUI(basicInformation?.zahtevKategorijaPodgrupe)}`,
    },
    {
      key: '5',
      label: t('osnovno_resenje'),
      children: getInputValueForUI(basicInformation?.resenjeBrojResenja),
    },
    {
      key: '6',
      label: t('tip_odluke'),
      children: getOdlukaLabel(basicInformation?.resenjeOdluka),
    },
  ];
  return (
    <RequestSectionWrapper openDefault title={t('osnovne_informacije')}>
      <Descriptions
        column={3}
        colon={false}
        layout='horizontal'
        className='mb-15'
        items={items1}
      />
      <Descriptions
        column={1}
        colon={false}
        layout='horizontal'
        items={items2}
      />
    </RequestSectionWrapper>
  );
};

export default RequestBasicInformation;
