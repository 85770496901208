/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import writeXlsxFile from 'write-excel-file';

interface IColDefs {
  title: string;
  dataIndex: string;
  excelWidth?: number;
  excelValueFormatter?: (val: any) => string;
}

export const writeExcel = async (
  dataArray: object[],
  colDefs: IColDefs[],
  fileNamePrefix: string
) => {
  const columns = colDefs.map((col) => ({
    column: col.title,
    type: String,
    width: col.excelWidth || 80,
    value: (record: any) => {
      const cellText = record[col.dataIndex];
      if (col.excelValueFormatter) return col.excelValueFormatter(cellText);
      return `${cellText}` || '';
    },
  }));

  await writeXlsxFile(dataArray, {
    schema: columns,
    fileName: `${fileNamePrefix}_${dayjs().format('DD.MM.YYYY')}.xlsx`,
  });
};
