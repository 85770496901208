import { Rule } from 'antd/lib/form';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { Dayjs } from 'dayjs';

export type DocumentAttributeResponse = {
  requestDocumentId: number;
  documentId?: number | null;
  opstinaId?: number | null;
  mestoId?: number | null;
  broj?: string | null;
  brojParcele?: string | null;
  datum?: Dayjs | string | null;
  naziv?: string | null;
  fileName?: string | null;
};

export type DocumentAttributePayload = {
  [key: string]: Pick<
    DocumentAttributeResponse,
    | 'opstinaId'
    | 'mestoId'
    | 'broj'
    | 'brojParcele'
    | 'datum'
    | 'naziv'
    | 'fileName'
  >;
};

export interface IRequestsStore {
  groupsAndRequests: Group[];
  requestStatuses: RequestStatus[];
  getGroupsAndRequests: () => Promise<unknown>;
  setGroupsAndRequests: (data: Group[]) => void;
  setRequestStatuses: (data: RequestStatus[]) => void;
}

export type Request = {
  id: number;
  ime: string;
  tip: 1 | 2;
};

export type SubGroup = {
  id: number;
  ime: string;
  zahtevi: Request[]; //ovo je naziv zahteva
  kategorijePodgrupa?: SubGroup[];
};

export type Group = {
  id: number;
  ime: string;
  podgrupe: SubGroup[];
};

export type RequestStatus = {
  id: number;
  naziv: string;
};

export type ActType = {
  value: number;
  label: string;
};

export type Processors = {
  id: number;
  name: string;
};

export type AllocationStatus = {
  value: number;
  label: string;
};

export type RequestsConfig = {
  kategorijeZahteva: Group[];
  zahtevStatusi: RequestStatus[];
  statusRaspodele: AllocationStatus[];
  obradjivaciZahteva: Processors[];
  tipAkta: ActType[];
};

export type SaveDraftRequestResponse = {
  id: number;
  status: number;
  zahtevTipId: number;
  datumPodnosenja: string;
  pisarnicaBroj: string;
};

export type DynamicFormField = DynamicFormInput;

type DynamicFormInput = {
  name: string[];
  type:
    | 'text'
    | 'number'
    | 'numberFloat'
    | 'textarea'
    | 'upload'
    | 'location'
    | 'koordinate'
    | 'lokalitet'
    | 'select'
    | 'range'
    | 'date'
    | 'year'
    | 'select-input';
  additionalInputFields?: DynamicFormInput[];
  rules?: Rule[];
  placeholder?: string;
  label?: string;
  span?: number;
  options?: DefaultOptionType[];
  defaultOption?: number[];
  selectMode?: 'multiple';
  prefix?: string;
  tooltip?: string;
  disabled?: boolean;
  dropdown?: {
    placeholder?: {
      input?: string;
      button?: string;
    };
  };
  context?: ProstorEnum;
  accept?: string[];
  description?: string;
  singingDocument?: boolean;
  coordinatesTitle?: string;
};

export type DynamicFormSection = {
  sectionTitle: string;
  fields: DynamicFormField[];
};

export type DynamicFormSchema = {
  formTitle: string;
  formName: string;
  inputs: {
    sections: DynamicFormSection[];
  };
  attachments?: {
    sections: DynamicFormSection[];
  };
};

export interface PodzemneVodeAtributiProps {
  duzinaRokaGeoloskogIstrazivanja: number | null;
  predmetIstrazivanjaId: number[];
  datumPocetkaGeoloskogIstrazivanja: string | null;
  zavrsetkaGeoloskogIstrazivanja: string | null;
  pocetkaRadovaNaIstrazivanju: string | null;
  izradeElaborata: string | null;
  vremeVazenjaPrethodnoIzdatogOdobrenjaEksploatacioniProstor: string | null;
  vrstaGeoloskogIstrazivanjaId: number[];
  nazivProjektaPrimenjenihGeoloskihIstrazivanja: string | null;
  liceVrsilacTehnickeKontrole: string | null;
  liceVrsilacNadzora: string | null;
  nazivElaborata: string | null;
  nazivProgramaAktivnosti: string | null;
}

export interface IstrazivanjeAtributiProps {
  vrstaMineralneSirovineOpisno: string | null;
  katastarskiBrojParcele: string | null;
  brojProduzenja: string | null;
  brojOdobrenogIstraznogPolja: string | null;
  rokVazenjaOdobrenja: string | null;
  brojIstraznogProstora: string | null;
  datumPrijemaResenja: string | null;
  datumPocetkaProbnogRadaBusotine: string | null;
  datumZavrsetkaProbnogRadaBusotine: string | null;
  datumPocetkaIzvrsenjaGeoloskogIstrazivanja: string | null;
  datumZavrsetkaIzvrsenjaGeoloskogIstrazivanja: string | null;
  autoriElaborata: string | null;
  vrstaGeoloskogIstrazivanjaId: number[];
  vrstaMineralnihSirovinaId: number[];
  nazivProjektaPrimenjenihGeoloskihIstrazivanja: string | null;
  liceVrsilacTehnickeKontrole: string | null;
  liceVrsilacNadzora: string | null;
  nazivElaborata: string | null;
  nazivProgramaAktivnosti: string | null;
  odgovorniProjektantImePrezime: string | null;
  glavniProjektantImePrezime: string | null;
  vremeTrajanjaGeoloskogIstrazivanja: number | null;
}

export interface EksploatacijaMineralnihAtributiProps {
  nazivRudarskogProjekta: string | null;
  nazivRudarskogProjektaIzgradjenRudarskiObjekat: string | null;
  nazivLezista: string | null;
  nazivStudijeIzvodljivosti: string | null;
  brojEksploatacionogPolja: string | null;
  godisnjiKapacitet: number[] | number;
  vrstaMineralnihSirovinaId: number[];
  datumPocetkaProbnogRadaRudarskogObjekta: string | null;
  datumZavrsetkaProbnogRadaRudarskogObjekta: string | null;
  katastarskiBrojIstraznogProstora: string | null;
  glavniProjektantImePrezime: string | null;
  odgovorniProjektantImePrezime: string | null;
}

export interface PreduzecaProps {
  maticniBroj: string | null;
  punNaziv: string | null;
  adresa: string | null;
  pib: string | null;
  mestoId: number | null;
  telefon: string | null;
  email: string | null;
  webSite: string | null;
  brojPoslovnogRacuna: string | null;
  imePrezimeOdgovornogLica: string | null;
  preduzeceTip: number | null;
  vrstaPreduzece: number | null;
}

export interface DokumentaAtributiProps {
  broj: string | null;
  brojParcele: string | null;
  datum: string | null;
  documentId: number | null;
  fileName: string | null;
  mestoId: number | null;
  naziv: string | null;
  opstinaId: number | null;
  requestDocumentId: number | null;
}

export interface ZahtevAtributiProps {
  opstinaId: number[];
  resenjeId: number | null;
  brojResenja: string | null;
  obrazlozenje: string | null;
  visinaAdministrativneTakse: number | null;
  nazivProjekta: string | null;
  datumPodnosenjaZahteva: string | null;
  brojZahteva: string | null;
  godinaIzrade: number | null;
  nazivZavrsnogIzvestajaElaborata: string | null;
}

export interface KoordinateProps {
  koordinataX: number;
  koordinataY: number;
}

export interface ProstorProps {
  nazivLokaliteta: string | null;
  koordinate: KoordinateProps[];
}

export interface ResenjeProps {
  id: number | null | undefined;
  resenjeDokumentId: number | null;
  fileName: string | null;
  odluka: number | null | undefined;
  zavodniBrojResenja: string | null | undefined;
  brojIstraznogProstora: string | null;
  povrsinaIstraznogProstora: number | null;
  duzinaIstraznogRoka: number | null;
  duzinaRokaProduzenja: number | null;
  duzinaRokaZadrzavanjaPrava: number | null;
  datumStanjaRezervi: string | null;
  datumUrucenjaResenja: string | null;
  obrazlozenje: string | null;
  razlogOdbacivanja: string | null;
  clanZakona: string | null;
  izborKomeDostavitiResenje: string | null;
  obradjeno: boolean;
  rokZaDopunu: number | null;
  razlogPrekida: string | null;
  resenjaPrilozi: AllocationStatus[];
}

export type ResenjeId = ResenjeProps['id'];

export type ResenjeBrojResenja = ResenjeProps['zavodniBrojResenja'];

export type ResenjeOdluka = ResenjeProps['odluka'];

export interface ObradaIstorijaProps {
  inicijatorIme: string | null;
  inicijatorPrezime: string | null;
  akcija: number | string | null;
  zahtevStatus: string | null;
  opis: string | null;
  vremeAkcije: string | null;
}

export type ResenjeDropdownOptionResponse = {
  id: number | null;
  brojResenja: string;
  tipResenja: number;
};

export interface GetRequestDetailsResponse {
  zahtevId: number | null;
  zahtevTipId: number | null;
  zavodniBroj: string | null;
  zahtevNaziv: string | null;
  zahtevGrupa: string | null;
  zahtevPodgrupa: string | null;
  zahtevKategorijaPodgrupe: string | null;
  datumPodnosenja: string | null;
  podnosilacNaziv: string | null;
  podnosilacMaticniBroj: string | null;
  zahtevAtributi: ZahtevAtributiProps | null;
  podzemneVodeAtributi: PodzemneVodeAtributiProps | null;
  istrazivanjeAtributi: IstrazivanjeAtributiProps | null;
  eksploatacijaMineralnihAtributi: EksploatacijaMineralnihAtributiProps | null;
  preduzeca: PreduzecaProps[];
  dokumentaAtributi: DokumentaAtributiProps[];
  prostorObavezni: ProstorProps;
  prostorOpcioni: ProstorProps;
  obradaIstorija: ObradaIstorijaProps[] | null;
  akcije: AllocationStatus[] | null;
  resenje: ResenjeProps | null;
  resenja: ResenjeDropdownOptionResponse[] | null;
  interniStatusZahteva: number | null;
  status: ZahteviEnum | null;
  statusRaspodele: number | null;
  showOdluka: boolean;
  referentniZahtevId?: number | null;
  poljaZaIzmenu?: string[] | null;
  isOstalo?: boolean;
}

export interface IMappedRequestDetailsResponseForForm
  extends Omit<GetRequestDetailsResponse, 'dokumentaAtributi' | 'resenja'> {
  dokumentaAtributi: { [key: string]: DokumentaAtributiProps };
  selectedResenja: ResenjeDropdownOptionResponse[];
}

export enum AkcijeEnum {
  VRATI = 1,
  PROSLEDI = 2,
  DODELI = 3,
  OBRADI = 4,
}

export enum ZahteviEnum {
  NACRT = 1,
  PODNET = 2,
  OBRADA = 3,
  ODBACEN = 4,
  ODBIJEN = 5,
  DOPUNA_ZAHTEVA = 6,
  ODOBREN = 7,
  PREKINUT_POSTUPAK = 8,
}

export enum OdlukeEnum {
  ODOBRAVANJE = 1,
  DOPUNA = 2,
  PREKID = 3,
  ODBACIVANJE = 4,
  ODBIJANJE = 5,
}

export enum StatusOdlukaEnum {
  RASPODELA = 1,
  OBRADA = 2,
  ODOBRENJE = 3,
  POTPIS = 4,
  OBRADJEN = 5,
}

export enum ProstorEnum {
  'PROSTOR_OBAVEZNI' = 'prostorObavezni',
  'PROSTOR_OPCIONI' = 'prostorOpcioni',
}
export interface CreateNewRequestResponse {
  pisarnicaBroj: number;
}

export interface PodnetiZahteviResponse {
  id: number;
  zahtevTipId: number;
  zavodniBroj: string;
}

export type Attachments = {
  rudarskiProjekat?: DokumentaAtributiProps;
  saglasnostNosioca?: DokumentaAtributiProps;
  aktOrganaNadleznogZaPosloveVodoprivredeKojimSeDajeSaglasnot?: DokumentaAtributiProps;
  garancijaBanke?: DokumentaAtributiProps;
  saglasnostNadlezneUstanove?: DokumentaAtributiProps;
  saglasnostNaTehnickuDokumentaciju?: DokumentaAtributiProps;
  izvestajNosiocaOdobrenjaOIzvresenjuObaveza?: DokumentaAtributiProps;
  poslednjiIzvestajOInspekcijskomNadzoruIzvresnomOdStraneRudarskogInspektoraIInspektoraBezbezdnostiNaRadu?: DokumentaAtributiProps;
  pisanaIzjavaBuducegNosiocaOdobrenjaOPrihvatanjuUslovaPrenosaOdobrenjaSaSvimPravimaIObavezamaKojeProisticuIzNjega?: DokumentaAtributiProps;
  topografskaKarta?: DokumentaAtributiProps;
  izvestajOMineralnimResursimaPrirodnihGradjevinskihMaterijala?: DokumentaAtributiProps;
  geoloskoIstrazivanje?: DokumentaAtributiProps;
  tehnickaKontrola?: DokumentaAtributiProps;
  dokazKoriscenjePodataka?: DokumentaAtributiProps;
  dokazRepublickaTaksa?: DokumentaAtributiProps;
  dokazOPravuKoriscenjaGeoloskeDokumentacije?: DokumentaAtributiProps;
  aktZaZastituPrirode?: DokumentaAtributiProps;
  aktOUslovimaZaZastituSpomenikaKulture?: DokumentaAtributiProps;
  ispunjenostUslovaZaZastituPrirode?: DokumentaAtributiProps;
  izvodIzRegistra?: DokumentaAtributiProps;
  izvestajOStrucnomNadzoru?: DokumentaAtributiProps;
  izvestajElaborat?: DokumentaAtributiProps;
  izvestajPotvrdaOTehnickojKontroliZavrsnogIzvestaja?: DokumentaAtributiProps;
  programAktivnosti?: DokumentaAtributiProps;
  pisanaIzjavaNovogNosioca?: DokumentaAtributiProps;
  pismoONamerama?: DokumentaAtributiProps;
  dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja?: DokumentaAtributiProps;
  aktOUslugamaZavoda?: DokumentaAtributiProps;
  uverenjeOPolozenomStrucnomIspitu?: DokumentaAtributiProps;
  potvrdaOZaposlenjuIImenovanjuGlavnogProjektanta?: DokumentaAtributiProps;
  dokazOIspunjenostiUslovaClana22?: DokumentaAtributiProps;
  situacionaKarta?: DokumentaAtributiProps;
  aktOrganaNadleznogZaPosloveZastiteZivotneSredineOObimuISadrzajuStudijeOProceniUticajaEksploatacijeNaZivotnuSredinu?: DokumentaAtributiProps;
  aktOrganaNadleznogZaPosloveUrbanizma?: DokumentaAtributiProps;
  aktOrganaNadleznogZaPosloveVodoprivredeKojimSeOdredjujuUsloviZaVrsenjeEksploatacije?: DokumentaAtributiProps;
  aktOrganaUstanoveNadlezneZaZastituKulturnogNasledjaKojimSeOdredjujuUsloviZaVrsenjeEksploatacije?: DokumentaAtributiProps;
  studijaIzvodljivostiEksploatacijeLezistaMineralnihSirovina?: DokumentaAtributiProps;
  predlogZaOveruRezerviSaPreglednomKartom?: DokumentaAtributiProps;
  elaboratOResursimaIRezervamaMineralnihSirovina?: DokumentaAtributiProps;
  resenjeOImenovanjuStrucnogNadzora?: DokumentaAtributiProps;
  preglednaKarta?: DokumentaAtributiProps;
  preglednaSituacionaKarta?: DokumentaAtributiProps;
  izvodIzElaborata?: DokumentaAtributiProps;
  dokazDaAutorElaborataIspunjavaUslovePropisaneZakonom?: DokumentaAtributiProps;
  elaboratOResursimaIRezervamaPodzemnihVodaIGeotermalnihResursa?: DokumentaAtributiProps;
  dokazOPravuSvojine?: DokumentaAtributiProps;
  elaboratOUslovimaEksploatacijePodzemnihVoda?: DokumentaAtributiProps;
  aktZaPosloveSanitarneZastiteIzvoristaVodosnabdevanja?: DokumentaAtributiProps;
  geodetskiPlan?: DokumentaAtributiProps;
  zavrsniIzvestaj?: DokumentaAtributiProps;
  izvestajNosiocaOdobrenjaZaIstrazivanjeOIzvrsenjuObavezaUVeziSanacijeIRekultivacijeProstora?: DokumentaAtributiProps;
  ostalo?: DokumentaAtributiProps;
};

export const RequestDocumentsEnum = {
  TOPOGRAFSKA_KARTA: 1,
  GEODETSKI_PLAN: 2,
  PROJEKAT_GEOLOSKIH_ISTRAZIVANJA: 3,
  IZVESTAJ_TEHNICKA_KONTROLA: 4,
  DOKAZ_PRAVO_KORISCENJA_PODATAKA: 5,
  DOKAZ_PLACENA_TAKSA: 6,
  AKT_ZASTITA_PRIRODE_SRBIJA: 7,
  AKT_ZAVOD_SPOMENICI_KULTURE: 8,
  MISLJENJE_ZASTITA_PRIRODE_SRBIJA: 9,
  DOKAZ_PRAVO_KORISCENJA_DOKUMENTACIJE: 10,
  GODISNJI_IZVESTAJ: 11,
  ZAVRSNI_IZVESTAJ_ELABORAT: 12,
  IZVOD_REGISTRA_SUBJEKTA: 13,
  IZVESTAJ_TEHNICKA_KONTROLA_ZAVRSNI: 14,
  IZVESTAJ_STRUCNI_NADZOR: 15,
  DOKAZ_PRAVO_SVOJINE: 16,
  ELABORAT_USLOVI_EKSPLOATACIJE: 17,
  AKT_ZASTITA_PRIRODE: 18,
  AKT_SANITARNA_ZASTITA: 19,
  PROGRAM_AKTIVNOSTI: 20,
  PREGLEDNA_SITUACIONA_KARTA: 21,
  DOKAZ_IZMIRENJE_NAKNADE_ISTRAZIVANJA: 22,
  FOTOKOPIJA_ODOBRENJA: 23,
  PISANA_IZJAVA_NOVOG_NOSIOCA: 24,
  RESENJE_ISTRAZNO_PRAVO: 25,
  RESENJE_IMENOVANJE_STRUCNOG_NADZORA: 26,
  PREGLEDNA_KARTA: 27,
  IZVOD_IZ_ELABORATA: 28,
  DOKAZ_AUTOR_ELABORATA: 29,
  ELABORAT_RESURSI_VODE: 30,
  IZVESTAJ_BILANSNE_REZERVE: 31,
  POTVRDA_REZERVE_VODE: 32,
  PROJEKAT_ISTRAZIVANJA_MINERALNIH_SIROVINA: 33,
  PISMO_NAMERE_BANKE: 34,
  DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT: 35,
  UVERENJE_STRUCNI_ISPIT: 36,
  POTVRDA_ZAPOSLENJA: 37,
  DOKAZ_IZ_CLANA_22: 38,
  PROGRAM_AKTIVNOSTI_2: 39,
  ZAVRSNI_IZVESTAJ: 40,
  IZVESTAJ_SANACIJA_REKULTIVACIJA: 41,
  PISANA_IZJAVA_PRENOS: 42,
  DOKAZ_PLACENA_NAKNADA: 43,
  DOKAZ_IZMIRENJE_NAKNADE_MINERALNE_SIROVINE: 44,
  RESENJE_ISTRAZNO_PRAVO_2: 45,
  PREDLOG_OVERA_REZERVI: 46,
  ELABORAT_RESURSI_MINERALNE_SIROVINE: 47,
  IZVESTAJ_OCENA_ELABORATA: 48,
  DOKAZ_AUTOR_ELABORATA_2: 49,
  IZVOD_IZ_REGISTRA: 50,
  DOKAZ_PRAVO_SVOJINE_EKSPLATACIJA: 51,
  IZVESTAJ_OBAVEZE: 52,
  IZVESTAJ_INSPEKCIJSKI_NADZOR: 53,
  PISANA_IZJAVA_BUDUCI_NOSILAC: 54,
  SITUACIONA_KARTA: 55,
  RUDARSKI_PROJEKAT: 56,
  TEHNICKA_KONTROLA_RUDARSKI_PROJEKAT: 57,
  SAGLASNOST_NOSIOCA_EKSPLATACIJE: 58,
  POTVRDA_REZERVE_MINERALNIH_SIROVINA: 59,
  AKT_ZIVOTNA_SREDINA_PROCENA_UTICAJA: 60,
  AKT_ZIVOTNA_SREDINA_SAGLASNOST: 61,
  AKT_URBANIZAM: 62,
  AKT_VODOPRIVREDA_USLOVI: 63,
  AKT_VODOPRIVREDA_SAGLASNOST: 64,
  AKT_KULTURNO_NASLEDJE: 65,
  DOKAZ_SVOJINA_RUDARSKI_OBJEKTI: 66,
  IZVOD_REGISTRA_PRIVREDNIH_SUBJEKATA: 67,
  GARANCIJE_BANKA_SANACIJA_REKULTIVACIJA: 68,
  STUDIJA_IZVODLJIVOSTI: 69,
  IZVESTAJ_MINERALNI_RESURSI: 70,
  SAGLASNOST_ZA_SPOMENIKE_KULTURE: 71,
  SAGLASNOST_TEHNICKA_DOKUMENTACIJA: 72,
  IZVESTAJ_TEHNICKI_PREGLED: 73,
  DRUGA_AKTA_POSEBNI_ZAKONI: 74,
  OSTALO: 75,
  DIGITALNI_POTPIS: 76,
  STANJE_BILANSNIH_REZERVI: 77,
};

export enum PreduzeceTypeEnum {
  URADILO_PROJEKAT = 1,
  KONTROLA_PROJEKTNE_DOKUMENTACIJE = 2,
  STRUCNI_NADZOR = 3,
  KONTROLA_IZVESTAJA = 4,
  URADILO_PROGRAM = 5,
  KONTROLA_PROJEKTA = 6,
  PRENOS_ODOBRENJA = 7,
  URADILO_STUDIJU = 8,
  IZVODJAC_RADOVA = 9,
  URADILO_ELABORAT = 10,
  PRENOS_POTVRDE = 11,
}

export enum IzborResenjaEnum {
  // 3.4.2.3
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_GEOLOSKA_ISTRAZIVANJA_MINERALNIH_SIROVINA = 1,
  // 3.4.2.6
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_ZADRZAVANJE_PRAVA_NA_ISTRAZNI_PROSTOR_ZA_MINERALNE_SIROVINE,
  // 3.4.2.9
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_GEOLOSKA_ISTRAZIVANJA_PRIRODNIH_GRADJEVINSKIH_MATERIJALA,
  // 3.4.2.9
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_EKSPLOATACIONO_POLJE,
  // 3.4.2.10
  RESENJA_O_UTVRDJENIM_I_RAZVRSTANIM_REZERVAMA_MINERLNIH_SIROVINA,
  // 3.4.2.9
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_GEOLOSKA_ISTRAZIVANJA_PRIRODNIH_GRADJEVINSKIH_MATERIJALA_ILI_ZADRZAVANJE_PRAVA_NA_ISTRAZNI_PROSTOR_ZA_MINERALNE_SIROVINE,
  // 3.4.3.7
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_IZGRADNJU_RUDARSKIH_OBJEKATA_ZA_IZVODJENJE_RUDARSKIH_RADOVA,
  // 3.4.3.9
  RESENJA_O_UTVRDJENIM_I_RAZVRSTANIM_REZERVAMA_MINERALNIH_SIROVINA,
  // 3.4.3.10
  IZVESTAJA_O_MINERALNIM_RESURSIMA_ZA_DOBIJANJE_PRIRODNIH_GRADJEVINSKIH_MATERIJALA,
  // 3.4.3.19
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_EKSPLOATACIJU_NEMETALICNIH_MINERALNIH_SIROVINA_ZA_DOBIJANJE_GRADJEVINSKIH_MATERIJALA,
  // 3.4.2.9
  RESENJA_O_ODOBRAVANJU_ZAHTEVA,
  // 3.4.3.16
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_EKSPLOATACIONO_POLJE_ILI_ZA_EKSPLOATACIJU_NEMETALICNIH_MINERALNIH_SIROVINA_ZA_DOBIJANJE_GRADJEVINSKIH_MATERIJALA_ILI_ZA_EKSPLOATACIJU_PRIRODNIH_GRADJEVINSKIH_MATERIJALA,
  // 3.4.1.3
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_INZENJERSKOGEOLOSKA_GEOTEHNICKA_ISTRAZIVANJA,
  // 3.4.1.4
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_GEOLOSKA_ISTRAZIVANJA_PODZEMNIH_VODA_PETROGEOTERMALNIH_RESURSA,
  // 3.4.1.7
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_ZADRZAVANJE_PRAVA_NA_ISTRAZNI_PROSTOR_ZA_PODZEMNE_VODE_I_GEOTERMALNE_RESURSE,
  // 3.4.1.12
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_EKPLOATACIONI_PROSTOR_I_KOLICINU_REZERVI,
  // 3.4.1.17
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_GEOLOSKA_ISTRAZIVANJA_PODZEMNIH_VODA_ILI_PETROGEOTERMALNIH_RESURSA,
  // 3.4.1.17
  RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_INZENJERSKO_GEOLOSKA,
}
