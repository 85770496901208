import { EMPTY_STATE_PLACEHOLDER } from '@/modules/settings/settings.constants';
import { Tag } from 'antd';
import { t } from 'i18next';

type PrivredniSubjektStatusProps = {
  status?: boolean;
};
const PrivredniSubjektStatus = ({ status }: PrivredniSubjektStatusProps) => {
  const tagColor = () => (status ? 'green' : 'red');

  const tagText = () => {
    return status
      ? 'statusi.privredni_subjekt.aktivan'
      : 'statusi.privredni_subjekt.deaktviran';
  };

  return status === undefined || status === null ? (
    EMPTY_STATE_PLACEHOLDER
  ) : (
    <Tag color={tagColor()}>{t(tagText())}</Tag>
  );
};

export default PrivredniSubjektStatus;
