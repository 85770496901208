import { useEffect, useState } from 'react';
import { Button, notification, Table } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ColumnsType } from 'antd/es/table';

import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.tsx';
import { authStore } from '@/modules/auth/auth.store.ts';
import { settingsService } from '@/modules/settings/settings.service.ts';
import { KorisnikListItem } from '@/modules/settings/settings.types.ts';
import UserModal from '@/pages/Settings/Users/components/UserModal/UserModal.tsx';
import Loading from '@/components/Loading/Loading';
import { ModalName, modalStore } from '@/modules/modal/modal.store';

const Users = observer(() => {
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<KorisnikListItem[]>([]);
  const [selectedUser, setSelectedUser] = useState<KorisnikListItem>();
  const [editEmailOnly, setEditEmailOnly] = useState<boolean>(false);

  const getUsers = () => {
    setLoading(true);
    settingsService
      .getKorisnikSviZaposleniPrivrednogSubjekta()
      .then((res) => {
        setUsers(res);
      })
      .catch((err) => {
        notification.error(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getUsers();
  }, [authStore.selectedBusinessEntity?.id]);

  const handleClose = () => {
    getUsers();
    setSelectedUser(undefined);
    setEditEmailOnly(false);
    modalStore.closeModal(ModalName.USER);
  };

  const colDef: ColumnsType<KorisnikListItem> = [
    {
      title: t('korisnik'),
      align: 'center',
      render: (record: KorisnikListItem) => record.korisnik,
    },
    { dataIndex: 'role', title: t('uloge'), align: 'center' },
    {
      dataIndex: 'odeljenje',
      title: t('organizaciona_jedinica'),
      align: 'center',
      hidden: !users[0]?.odeljenje, //Ako imaju odeljenje useri podrazumeva se da su MRE ako nemaju onda su obicni
    },
    { dataIndex: 'status', title: t('status'), align: 'center' },
    { dataIndex: 'email', title: t('eposta'), align: 'center' },
  ];

  return (
    <>
      {loading ? (
        <div className='settingsLoaderWrapper'>
          <Loading size='large' />
        </div>
      ) : (
        <>
          {modalStore.isVisible(ModalName.USER) && (
            <UserModal
              editEmailOnly={editEmailOnly}
              id={selectedUser?.id}
              handleClose={handleClose}
            />
          )}
          <DynamicTitle
            title='administracija_korisnika'
            subComponent={
              <Button
                onClick={() => modalStore.openModal(ModalName.USER)}
                type='primary'
              >
                + {t('dodajte_novog_korisnika')}
              </Button>
            }
            className='mt-20 mb-20'
          />
          <Table
            columns={colDef}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [10, 20, 50],
              showQuickJumper: true,
              showSizeChanger: true,
              locale: {
                page: t('stranu'),
                jump_to: t('idite_na'),
                items_per_page: t('/strani'),
              },
            }}
            dataSource={users}
            rowClassName='pointer'
            rowKey={(record) => record.id}
            onRow={(record: KorisnikListItem) => {
              return {
                onClick: () => {
                  setSelectedUser(record);
                  setEditEmailOnly(record.editEmailOnly);
                  modalStore.openModal(ModalName.USER);
                },
              };
            }}
          />
        </>
      )}
    </>
  );
});

export default Users;
