import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

type AllocationStatusProps = {
  statusId: number;
};

const AllocationStatus = ({ statusId }: AllocationStatusProps) => {
  const { t } = useTranslation();
  const text = t(`statusi.raspodela.${statusId}`);

  return <Tag color='purple'>{text}</Tag>;
};

export default AllocationStatus;
