import {
  DocumentAttributePayload,
  DokumentaAtributiProps,
  RequestDocumentsEnum,
} from '@/modules/requests/requests.types.ts';
import { ZahtevFormData } from '@/utils/formMapper.ts';
import { FileType } from '@/utils/uploadFile.ts';

interface IRequestDocument {
  name: string;
  id: number;
}

const documentRequestIds: { [key: number]: IRequestDocument[] } = {
  1: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 1,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 2,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 3,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_PODATAKA),
      id: 4,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 5,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      id: 6,
    },
    {
      name: String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
      id: 7,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
      id: 8,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DOKUMENTACIJE),
      id: 9,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 10,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 11,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 382,
    },
  ],
  2: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 12,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 13,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 14,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_PODATAKA),
      id: 15,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 381,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      id: 16,
    },
    {
      name: String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
      id: 17,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
      id: 18,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DOKUMENTACIJE),
      id: 19,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 20,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 21,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 383,
    },
  ],
  3: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 22,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 23,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 24,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 25,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_STRUCNI_NADZOR),
      id: 26,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA_ZAVRSNI),
      id: 27,
    },
    {
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
      id: 28,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 29,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 30,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 384,
    },
  ],
  4: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 31,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 32,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 33,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 34,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_STRUCNI_NADZOR),
      id: 35,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA_ZAVRSNI),
      id: 36,
    },
    {
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
      id: 37,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 38,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 39,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 385,
    },
  ],
  5: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 40,
    },
    {
      name: String(RequestDocumentsEnum.PROGRAM_AKTIVNOSTI),
      id: 41,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 42,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 43,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 386,
    },
  ],
  6: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 44,
    },
    {
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      id: 45,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 46,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 47,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 387,
    },
  ],
  7: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 48,
    },
    {
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      id: 49,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 50,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 51,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 388,
    },
  ],
  8: [
    {
      name: String(RequestDocumentsEnum.RESENJE_IMENOVANJE_STRUCNOG_NADZORA),
      id: 52,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 53,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 54,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 389,
    },
  ],
  9: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 82,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 83,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 84,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 85,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      id: 87,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      id: 88,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
      id: 89,
    },
    {
      name: String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
      id: 90,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_STRUCNI_NADZOR),
      id: 91,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA_ZAVRSNI),
      id: 92,
    },
    {
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
      id: 93,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 94,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 95,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 390,
    },
  ],
  10: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 96,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 97,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 98,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 391,
    },
  ],
  11: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 99,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 100,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 101,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 392,
    },
  ],
  12: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      id: 55,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 56,
    },
    {
      name: String(RequestDocumentsEnum.PREGLEDNA_KARTA),
      id: 57,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_IZ_ELABORATA),
      id: 58,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_AUTOR_ELABORATA),
      id: 59,
    },
    {
      name: String(RequestDocumentsEnum.ELABORAT_RESURSI_VODE),
      id: 60,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 61,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 62,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 393,
    },
  ],
  13: [
    {
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      id: 461,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 64,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 65,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 394,
    },
  ],
  14: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 66,
    },
    {
      name: String(RequestDocumentsEnum.GEODETSKI_PLAN),
      id: 67,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_SVOJINE),
      id: 68,
    },
    {
      name: String(RequestDocumentsEnum.ELABORAT_USLOVI_EKSPLOATACIJE),
      id: 69,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      id: 70,
    },
    {
      name: String(RequestDocumentsEnum.AKT_SANITARNA_ZASTITA),
      id: 71,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 72,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 73,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 74,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 395,
    },
  ],
  15: [
    {
      name: String(RequestDocumentsEnum.ELABORAT_USLOVI_EKSPLOATACIJE),
      id: 75,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 76,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 77,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 78,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 396,
    },
  ],
  16: [
    {
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      id: 79,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 80,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 81,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 397,
    },
  ],
  17: [
    {
      name: String(RequestDocumentsEnum.STANJE_BILANSNIH_REZERVI),
      id: 478,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 480,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 483,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 398,
    },
  ],
  18: [
    {
      name: String(RequestDocumentsEnum.STANJE_BILANSNIH_REZERVI),
      id: 479,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 481,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 482,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 399,
    },
  ],
  19: [
    {
      name: String(RequestDocumentsEnum.GODISNJI_IZVESTAJ),
      id: 484,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA_ZAVRSNI),
      id: 486,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_STRUCNI_NADZOR),
      id: 490,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 493,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 494,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 400,
    },
  ],
  20: [
    {
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ),
      id: 488,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA_ZAVRSNI),
      id: 487,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_STRUCNI_NADZOR),
      id: 491,
    },
    {
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
      id: 495,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 494,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 401,
    },
  ],
  21: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 102,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 105,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 108,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 112,
    },
    {
      name: String(RequestDocumentsEnum.PISMO_NAMERE_BANKE),
      id: 111,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      id: 115,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      id: 118,
    },
    {
      name: String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
      id: 121,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
      id: 124,
    },
    {
      name: String(RequestDocumentsEnum.UVERENJE_STRUCNI_ISPIT),
      id: 127,
    },
    {
      name: String(RequestDocumentsEnum.POTVRDA_ZAPOSLENJA),
      id: 130,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_IZ_CLANA_22),
      id: 133,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 136,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 402,
    },
  ],
  22: [
    {
      id: 148,
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
    },
    {
      id: 462,
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
    },
    {
      id: 154,
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
    },
    {
      id: 157,
      name: String(RequestDocumentsEnum.PISMO_NAMERE_BANKE),
    },
    {
      id: 158,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 161,
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
    },
    {
      id: 164,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 403,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  23: [
    {
      name: String(RequestDocumentsEnum.PROGRAM_AKTIVNOSTI),
      id: 167,
    },
    {
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ),
      id: 170,
    },
    {
      name: String(RequestDocumentsEnum.PREGLEDNA_SITUACIONA_KARTA),
      id: 173,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 176,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 179,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 404,
    },
  ],
  24: [
    {
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      id: 188,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_SANACIJA_REKULTIVACIJA),
      id: 185,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 182,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 191,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 405,
    },
  ],
  25: [
    {
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      id: 465,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_SANACIJA_REKULTIVACIJA),
      id: 197,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 194,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 200,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 406,
    },
  ],
  26: [
    {
      name: String(RequestDocumentsEnum.RESENJE_IMENOVANJE_STRUCNOG_NADZORA),
      id: 203,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 206,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 407,
    },
  ],
  27: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 220,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 224,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 228,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 232,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      id: 244,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      id: 236,
    },
    {
      name: String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
      id: 240,
    },
    {
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
      id: 248,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 252,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 408,
    },
  ],
  28: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 256,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 260,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 409,
    },
  ],
  29: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 264,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 268,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 410,
    },
  ],
  30: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 103,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 106,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 109,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 113,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      id: 116,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      id: 119,
    },
    {
      name: String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
      id: 122,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
      id: 125,
    },
    {
      name: String(RequestDocumentsEnum.UVERENJE_STRUCNI_ISPIT),
      id: 128,
    },
    {
      name: String(RequestDocumentsEnum.POTVRDA_ZAPOSLENJA),
      id: 131,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_IZ_CLANA_22),
      id: 134,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 137,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 411,
    },
  ],
  31: [
    {
      id: 149,
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
    },
    {
      id: 463,
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
    },
    {
      id: 155,
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
    },
    {
      id: 152,
      name: String(RequestDocumentsEnum.PISMO_NAMERE_BANKE),
    },
    {
      id: 159,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 162,
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
    },
    {
      id: 165,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 412,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  32: [
    {
      name: String(RequestDocumentsEnum.PROGRAM_AKTIVNOSTI),
      id: 168,
    },
    {
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ),
      id: 171,
    },
    {
      name: String(RequestDocumentsEnum.PREGLEDNA_SITUACIONA_KARTA),
      id: 174,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 177,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 180,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 413,
    },
  ],
  33: [
    {
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      id: 189,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_SANACIJA_REKULTIVACIJA),
      id: 186,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 183,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 192,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 414,
    },
  ],
  34: [
    {
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      id: 466,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_SANACIJA_REKULTIVACIJA),
      id: 198,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 195,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 201,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 415,
    },
  ],
  35: [
    {
      name: String(RequestDocumentsEnum.RESENJE_IMENOVANJE_STRUCNOG_NADZORA),
      id: 204,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 207,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 416,
    },
  ],
  36: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 221,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 225,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 229,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 233,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      id: 245,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      id: 237,
    },
    {
      name: String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
      id: 241,
    },
    {
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
      id: 249,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 253,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 417,
    },
  ],
  37: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 257,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 261,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 418,
    },
  ],
  38: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 265,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 269,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 419,
    },
  ],
  39: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 104,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 107,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 110,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 114,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      id: 117,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      id: 120,
    },
    {
      name: String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
      id: 123,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
      id: 126,
    },
    {
      name: String(RequestDocumentsEnum.UVERENJE_STRUCNI_ISPIT),
      id: 129,
    },
    {
      name: String(RequestDocumentsEnum.POTVRDA_ZAPOSLENJA),
      id: 132,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_IZ_CLANA_22),
      id: 135,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 138,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 420,
    },
  ],
  40: [
    {
      id: 150,
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
    },
    {
      id: 464,
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
    },
    {
      id: 156,
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
    },
    {
      id: 153,
      name: String(RequestDocumentsEnum.PISMO_NAMERE_BANKE),
    },
    {
      id: 160,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 163,
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
    },
    {
      id: 166,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 421,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  41: [
    {
      name: String(RequestDocumentsEnum.PROGRAM_AKTIVNOSTI),
      id: 169,
    },
    {
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ),
      id: 172,
    },
    {
      name: String(RequestDocumentsEnum.PREGLEDNA_SITUACIONA_KARTA),
      id: 175,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 178,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 181,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 422,
    },
  ],
  42: [
    {
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      id: 190,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_SANACIJA_REKULTIVACIJA),
      id: 187,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 184,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 193,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 423,
    },
  ],
  43: [
    {
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      id: 467,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_SANACIJA_REKULTIVACIJA),
      id: 199,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 196,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 202,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 424,
    },
  ],
  44: [
    {
      name: String(RequestDocumentsEnum.RESENJE_IMENOVANJE_STRUCNOG_NADZORA),
      id: 205,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 208,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 425,
    },
  ],
  45: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 222,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 226,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 230,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 234,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      id: 246,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      id: 238,
    },
    {
      name: String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
      id: 242,
    },
    {
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
      id: 250,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 254,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 426,
    },
  ],
  46: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 258,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 262,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 427,
    },
  ],
  47: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 266,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 270,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 428,
    },
  ],
  48: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 139,
    },
    {
      name: String(RequestDocumentsEnum.GEODETSKI_PLAN),
      id: 140,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 142,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 141,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 143,
    },
    {
      name: String(RequestDocumentsEnum.UVERENJE_STRUCNI_ISPIT),
      id: 144,
    },
    {
      name: String(RequestDocumentsEnum.POTVRDA_ZAPOSLENJA),
      id: 145,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_IZ_CLANA_22),
      id: 146,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 147,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 429,
    },
  ],
  49: [
    {
      name: String(RequestDocumentsEnum.RESENJE_IMENOVANJE_STRUCNOG_NADZORA),
      id: 209,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 210,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 430,
    },
  ],
  50: [
    {
      name: String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA),
      id: 223,
    },
    {
      name: String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      id: 227,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      id: 231,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 235,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      id: 245,
    },
    {
      name: String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      id: 239,
    },
    {
      name: String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
      id: 243,
    },
    {
      name: String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
      id: 251,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 255,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 431,
    },
  ],
  51: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 259,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 263,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 432,
    },
  ],
  52: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 267,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 271,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 433,
    },
  ],
  53: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 211,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      id: 212,
    },
    {
      name: String(RequestDocumentsEnum.PREDLOG_OVERA_REZERVI),
      id: 213,
    },
    {
      name: String(RequestDocumentsEnum.ELABORAT_RESURSI_MINERALNE_SIROVINE),
      id: 214,
    },
    {
      name: String(RequestDocumentsEnum.DOKAZ_AUTOR_ELABORATA),
      id: 215,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 216,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 434,
    },
  ],
  54: [
    {
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
      id: 217,
    },
    {
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      id: 460,
    },
    {
      name: String(RequestDocumentsEnum.OSTALO),
      id: 219,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 435,
    },
  ],
  55: [
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 436,
    },
  ],
  56: [
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA_ZAVRSNI),
      id: 468,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_STRUCNI_NADZOR),
      id: 470,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 437,
    },
  ],
  57: [
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA_ZAVRSNI),
      id: 469,
    },
    {
      name: String(RequestDocumentsEnum.IZVESTAJ_STRUCNI_NADZOR),
      id: 471,
    },
    {
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
      id: 438,
    },
  ],
  58: [
    {
      id: 272,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 273,
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
    },
    {
      id: 274,
      name: String(RequestDocumentsEnum.SITUACIONA_KARTA),
    },
    {
      id: 275,
      name: String(RequestDocumentsEnum.AKT_ZIVOTNA_SREDINA_PROCENA_UTICAJA),
    },
    {
      id: 276,
      name: String(RequestDocumentsEnum.AKT_URBANIZAM),
    },
    {
      id: 277,
      name: String(RequestDocumentsEnum.AKT_VODOPRIVREDA_USLOVI),
    },
    {
      id: 278,
      name: String(RequestDocumentsEnum.AKT_KULTURNO_NASLEDJE),
    },
    {
      id: 279,
      name: String(RequestDocumentsEnum.STUDIJA_IZVODLJIVOSTI),
    },
    {
      id: 280,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 439,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  59: [
    {
      id: 281,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 282,
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
    },
    {
      id: 283,
      name: String(RequestDocumentsEnum.SITUACIONA_KARTA),
    },
    {
      id: 284,
      name: String(RequestDocumentsEnum.AKT_URBANIZAM),
    },
    {
      id: 285,
      name: String(RequestDocumentsEnum.STUDIJA_IZVODLJIVOSTI),
    },
    {
      id: 286,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 440,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  60: [
    {
      id: 287,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 288,
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
    },
    {
      id: 289,
      name: String(RequestDocumentsEnum.SITUACIONA_KARTA),
    },
    {
      id: 290,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 441,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  61: [
    {
      id: 291,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 292,
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
    },
    {
      id: 293,
      name: String(RequestDocumentsEnum.IZVESTAJ_OBAVEZE),
    },
    {
      id: 294,
      name: String(RequestDocumentsEnum.IZVESTAJ_INSPEKCIJSKI_NADZOR),
    },
    {
      id: 295,
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_BUDUCI_NOSILAC),
    },
    {
      id: 296,
      name: String(RequestDocumentsEnum.SITUACIONA_KARTA),
    },
    {
      id: 297,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 442,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  62: [
    {
      id: 298,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 299,
      name: String(RequestDocumentsEnum.RUDARSKI_PROJEKAT),
    },
    {
      id: 300,
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
    },
    {
      id: 301,
      name: String(RequestDocumentsEnum.SAGLASNOST_NOSIOCA_EKSPLATACIJE),
    },
    {
      id: 302,
      name: String(RequestDocumentsEnum.AKT_ZIVOTNA_SREDINA_PROCENA_UTICAJA),
    },
    {
      id: 303,
      name: String(RequestDocumentsEnum.AKT_URBANIZAM),
    },
    {
      id: 304,
      name: String(RequestDocumentsEnum.AKT_VODOPRIVREDA_SAGLASNOST),
    },
    {
      id: 305,
      name: String(RequestDocumentsEnum.AKT_KULTURNO_NASLEDJE),
    },
    {
      id: 306,
      name: String(RequestDocumentsEnum.DOKAZ_SVOJINA_RUDARSKI_OBJEKTI),
    },
    {
      id: 307,
      name: String(RequestDocumentsEnum.GARANCIJE_BANKA_SANACIJA_REKULTIVACIJA),
    },
    {
      id: 308,
      name: String(RequestDocumentsEnum.SAGLASNOST_ZA_SPOMENIKE_KULTURE),
    },
    {
      id: 309,
      name: String(RequestDocumentsEnum.SAGLASNOST_TEHNICKA_DOKUMENTACIJA),
    },
    {
      id: 310,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 443,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  63: [
    {
      id: 314,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 315,
      name: String(RequestDocumentsEnum.RUDARSKI_PROJEKAT),
    },
    {
      id: 316,
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKI_PREGLED),
    },
    {
      id: 317,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 444,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  64: [
    {
      id: 311,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 312,
      name: String(RequestDocumentsEnum.RUDARSKI_PROJEKAT),
    },
    {
      id: 313,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 445,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  // 65: Ne postoji
  66: [
    {
      id: 318,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 319,
      name: String(RequestDocumentsEnum.DOKAZ_SVOJINA_RUDARSKI_OBJEKTI),
    },
    {
      id: 320,
      name: String(RequestDocumentsEnum.GARANCIJE_BANKA_SANACIJA_REKULTIVACIJA),
    },
    {
      id: 321,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 447,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  67: [
    {
      id: 322,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 323,
      name: String(RequestDocumentsEnum.SITUACIONA_KARTA),
    },
    {
      id: 324,
      name: String(RequestDocumentsEnum.RUDARSKI_PROJEKAT),
    },
    {
      id: 325,
      name: String(RequestDocumentsEnum.SAGLASNOST_NOSIOCA_EKSPLATACIJE),
    },
    {
      id: 326,
      name: String(RequestDocumentsEnum.AKT_ZIVOTNA_SREDINA_PROCENA_UTICAJA),
    },
    {
      id: 327,
      name: String(RequestDocumentsEnum.AKT_URBANIZAM),
    },
    {
      id: 329,
      name: String(RequestDocumentsEnum.AKT_VODOPRIVREDA_USLOVI),
    },
    {
      id: 328,
      name: String(RequestDocumentsEnum.AKT_KULTURNO_NASLEDJE),
    },
    {
      id: 330,
      name: String(RequestDocumentsEnum.DOKAZ_SVOJINA_RUDARSKI_OBJEKTI),
    },
    {
      id: 331,
      name: String(RequestDocumentsEnum.GARANCIJE_BANKA_SANACIJA_REKULTIVACIJA),
    },
    {
      id: 332,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 448,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  68: [
    {
      id: 357,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 359,
      name: String(RequestDocumentsEnum.SITUACIONA_KARTA),
    },
    {
      id: 360,
      name: String(RequestDocumentsEnum.RUDARSKI_PROJEKAT),
    },
    {
      id: 358,
      name: String(RequestDocumentsEnum.DOKAZ_SVOJINA_RUDARSKI_OBJEKTI),
    },
    {
      id: 361,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 449,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  69: [
    {
      id: 362,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 364,
      name: String(RequestDocumentsEnum.IZVESTAJ_OBAVEZE),
    },
    {
      id: 365,
      name: String(RequestDocumentsEnum.IZVESTAJ_INSPEKCIJSKI_NADZOR),
    },
    {
      id: 366,
      name: String(RequestDocumentsEnum.PISANA_IZJAVA_BUDUCI_NOSILAC),
    },
    {
      id: 363,
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_SVOJINE),
    },
    {
      id: 367,
      name: String(RequestDocumentsEnum.GARANCIJE_BANKA_SANACIJA_REKULTIVACIJA),
    },
    {
      id: 368,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 450,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  70: [
    {
      id: 339,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 340,
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
    },
    {
      id: 341,
      name: String(RequestDocumentsEnum.SITUACIONA_KARTA),
    },
    {
      id: 342,
      name: String(RequestDocumentsEnum.RUDARSKI_PROJEKAT),
    },
    {
      id: 496,
      name: String(RequestDocumentsEnum.TEHNICKA_KONTROLA_RUDARSKI_PROJEKAT),
    },
    {
      id: 497,
      name: String(RequestDocumentsEnum.POTVRDA_REZERVE_MINERALNIH_SIROVINA),
    },
    {
      id: 344,
      name: String(RequestDocumentsEnum.SAGLASNOST_NOSIOCA_EKSPLATACIJE),
    },
    {
      id: 345,
      name: String(RequestDocumentsEnum.AKT_ZIVOTNA_SREDINA_SAGLASNOST),
    },
    {
      id: 346,
      name: String(RequestDocumentsEnum.AKT_URBANIZAM),
    },
    {
      id: 347,
      name: String(RequestDocumentsEnum.AKT_VODOPRIVREDA_USLOVI),
    },
    {
      id: 348,
      name: String(RequestDocumentsEnum.AKT_KULTURNO_NASLEDJE),
    },
    {
      id: 349,
      name: String(RequestDocumentsEnum.DOKAZ_SVOJINA_RUDARSKI_OBJEKTI),
    },
    {
      id: 343,
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
    },
    {
      id: 350,
      name: String(RequestDocumentsEnum.GARANCIJE_BANKA_SANACIJA_REKULTIVACIJA),
    },
    {
      id: 351,
      name: String(RequestDocumentsEnum.SAGLASNOST_ZA_SPOMENIKE_KULTURE),
    },
    {
      id: 352,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 451,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  71: [
    {
      id: 353,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 354,
      name: String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA),
    },
    {
      id: 355,
      name: String(RequestDocumentsEnum.SITUACIONA_KARTA),
    },
    {
      id: 356,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 452,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  72: [
    {
      id: 333,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 334,
      name: String(RequestDocumentsEnum.SITUACIONA_KARTA),
    },
    {
      id: 335,
      name: String(RequestDocumentsEnum.RUDARSKI_PROJEKAT),
    },
    {
      id: 336,
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_SVOJINE),
    },
    {
      id: 337,
      name: String(RequestDocumentsEnum.IZVESTAJ_MINERALNI_RESURSI),
    },
    {
      id: 338,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 453,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  73: [
    {
      id: 375,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 376,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 454,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  74: [
    {
      id: 377,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 378,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 455,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  75: [
    {
      id: 379,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 380,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 456,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  76: [
    {
      id: 369,
      name: String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA),
    },
    {
      id: 372,
      name: String(RequestDocumentsEnum.DOKAZ_PRAVO_SVOJINE),
    },
    {
      id: 371,
      name: String(RequestDocumentsEnum.RUDARSKI_PROJEKAT),
    },
    {
      id: 370,
      name: String(RequestDocumentsEnum.SAGLASNOST_NOSIOCA_EKSPLATACIJE),
    },
    {
      id: 373,
      name: String(RequestDocumentsEnum.DRUGA_AKTA_POSEBNI_ZAKONI),
    },
    {
      id: 374,
      name: String(RequestDocumentsEnum.OSTALO),
    },
    {
      id: 457,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  77: [
    {
      id: 472,
      name: String(RequestDocumentsEnum.RUDARSKI_PROJEKAT),
    },
    {
      id: 473,
      name: String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
    },
    {
      id: 474,
      name: String(RequestDocumentsEnum.DOKAZ_SVOJINA_RUDARSKI_OBJEKTI),
    },
    {
      id: 458,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
  78: [
    {
      id: 475,
      name: String(RequestDocumentsEnum.RUDARSKI_PROJEKAT),
    },
    {
      id: 477,
      name: String(RequestDocumentsEnum.DOKAZ_SVOJINA_RUDARSKI_OBJEKTI),
    },
    {
      id: 459,
      name: String(RequestDocumentsEnum.DIGITALNI_POTPIS),
    },
  ],
};

export const mapDocumentsData = (
  zahtevId: number,
  data: ZahtevFormData,
  dokumentaAtributi?: DocumentAttributePayload
): FileType[] => {
  if (!zahtevId) return [];

  const documents: Partial<FileType>[] = [];
  documentRequestIds[zahtevId].forEach((doc) => {
    const atributi = dokumentaAtributi?.[doc.name] || undefined;

    if (data.prilozi[doc.name] !== undefined) {
      let docObject = {
        ...(data.prilozi[doc.name] as Record<string, unknown>),
        requestDocumentId: doc.id,
        documentId: undefined,
      };

      if (atributi) {
        docObject = {
          ...atributi,
          ...docObject,
        };
      }

      documents.push(docObject);
    }
  });
  return documents as FileType[];
};

export const mapDokumentaAtributiForForm = (
  dokumentaAtributi: DokumentaAtributiProps[],
  zahtevTipId: number
) => {
  const newData: {
    [key: string]: DokumentaAtributiProps;
  } = {};

  dokumentaAtributi.forEach((dokument) => {
    const documentsForRequest = documentRequestIds[zahtevTipId];

    const documentKey = documentsForRequest.find(
      (doc) => doc.id === dokument.requestDocumentId
    )?.name;

    if (documentKey) {
      newData[documentKey] = dokument;
    }
  });

  return newData;
};

export const findRequestDocumentKey = (
  zahtevTipId: number,
  requestDocumentId: number
): number | undefined => {
  const document = documentRequestIds[zahtevTipId];
  const documentKey = document.find(
    (doc) => doc.id === requestDocumentId
  )?.name;

  if (documentKey) {
    return Number(documentKey);
  }
  return undefined;
};
