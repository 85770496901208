import { useState } from 'react';
import { Col, Row } from 'antd';

import { RequestDecisionApprovalActions } from '../RequestDecisionApproval/components/RequestDecisionApprovalActions/RequestDecisionApprovalActions';
import { RequestDecisionPrekidanjePostupkaForm } from './RequestDecisionPrekidanjePostupkaForm/RequestDecisionPrekidanjePostupkaForm';

interface IRequestDecisionPrekidanjePostupka {
  handleSuccess: () => void;
}

const RequestDecisionPrekidanjePostupka = ({
  handleSuccess,
}: IRequestDecisionPrekidanjePostupka) => {
  const [resenjeId, setResenjeId] = useState<number | null>(null);

  return (
    <>
      <Row gutter={25}>
        <Col span={16}>
          <RequestDecisionPrekidanjePostupkaForm setResenjeId={setResenjeId} />
        </Col>
        <Col span={8}>
          <RequestDecisionApprovalActions
            resenjeId={resenjeId}
            handleSuccess={handleSuccess}
          />
        </Col>
      </Row>
    </>
  );
};

export default RequestDecisionPrekidanjePostupka;
