import Loading from '../Loading/Loading';

interface IPageWrapper {
  children: JSX.Element;
  isLoading?: boolean;
  greyFrame: boolean;
}

export const PageWrapper = ({
  children,
  greyFrame,
  isLoading,
}: IPageWrapper) => {
  if (isLoading) return <Loading size='large' />;
  if (greyFrame)
    return <div className={'pageContentContainer'}>{children}</div>;
  return <div className={'pageContentContainerWithoutFrame'}>{children}</div>;
};
