import { t } from 'i18next';
import RequestSectionWrapper from '@/pages/RequestView/components/RequestSectionWrapper/RequestSectionWrapper.tsx';
import DynamicFormReadOnly from '@/components/Forms/FormBuilder/DynamicFormReadOnly.tsx';
import { getNewRequestFormDetails } from '@/modules/requests/requests.constants.tsx';
import { IMappedRequestDetailsResponseForForm } from '@/modules/requests/requests.types';

type RequestFormDataViewProps = {
  zahtevTipId: number;
  data: IMappedRequestDetailsResponseForForm;
  supplement?: boolean;
};
const RequestFormDataView = ({
  zahtevTipId,
  data,
  supplement = false,
}: RequestFormDataViewProps) => {
  return (
    <RequestSectionWrapper title={t('podaci_zahteva')}>
      <DynamicFormReadOnly
        schema={getNewRequestFormDetails(zahtevTipId)}
        data={data}
        supplement={supplement}
      />
    </RequestSectionWrapper>
  );
};

export default RequestFormDataView;
