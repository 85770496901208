/* eslint-disable max-len */
import { t } from 'i18next';
import {
  DynamicFormField,
  IzborResenjaEnum,
  ProstorEnum,
  RequestDocumentsEnum,
} from '@/modules/requests/requests.types.ts';
import { requestsStore } from '@/modules/requests/requests.store.ts';

interface IInputs {
  vrstaGeoloskogIstrazivanjaIdPodzemneVode: DynamicFormField;
  vrstaGeoloskogIstrazivanjaIdPodzemneVodeDisabled: DynamicFormField;
  vrstaGeoloskogIstrazivanjaIdMineralneSirovine: DynamicFormField;
  vrstaMineralnihSirovinaId: DynamicFormField;
  vrstaMineralnihSirovinaIdEks: DynamicFormField;
  vrstaMineralneSirovineOpisno: DynamicFormField;
  predmetIstrazivanjaId: DynamicFormField;
  brojResenjaIzvodjenjeRudarskihRadova: DynamicFormField;
  duzinaRokaGeoloskogIstrazivanja: DynamicFormField;
  nazivProjektaPrimenjenihGeoloskihIstrazivanjaPodzemneVode: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaIliPetrogeotermalnihResursa: DynamicFormField;
  nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine: DynamicFormField;
  nazivProgramaAktivnosti: DynamicFormField;
  nazivProgramaAktivnostiIstrazivanjaMS: DynamicFormField;
  liceVrsilacTehnickeKontrolePodzemneVode: DynamicFormField;
  liceVrsilacTehnickeKontroleMineralneSirovine: DynamicFormField;
  opstinaId: DynamicFormField;
  visinaAdministrativneTakse: DynamicFormField;
  prostorObavezni: DynamicFormField;
  prostorObavezniEksploatacioniProstor: DynamicFormField;
  prostorOpcioniEksploatacioniProstor: DynamicFormField;
  lokalitet: DynamicFormField;
  lokalitetOpcioni: DynamicFormField;
  koordinate: DynamicFormField;
  koordinateOpcioni: DynamicFormField;
  prostorOpcioni: DynamicFormField;
  obrazlozenje: DynamicFormField;
  nazivRudarskogProjekta: DynamicFormField;
  godisnjiKapacitet: DynamicFormField;
  planiraniPocetakTrajanjeProbnogRada: DynamicFormField;
  planiraniKrajTrajanjeProbnogRada: DynamicFormField;
  liceVrsilacNadzoraPodzemneVode: DynamicFormField;
  liceVrsilacNadzoraMineralneSirovine: DynamicFormField;
  nazivZavrsnogIzvestaja: DynamicFormField;
  nazivZavrsnogIzvestajaElaborata: DynamicFormField;
  godinaIzrade: DynamicFormField;
  godinaIzradeElaborata: DynamicFormField;
  godinaIzradeStudije: DynamicFormField;
  nazivRudarskogProjektaPoKomeJeIzgradjenRudarskiObjekat: DynamicFormField;
  nazivStudijeIzvodljivosti: DynamicFormField;
  brojEksploatacionogPolja: DynamicFormField;
  izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaZadrzavanjePravaNaIstrazniProstorZaPodzemneVodeIGeotermalneResurse: DynamicFormField;
  glavniProjektantImePrezime: DynamicFormField;
  glavniProjektantImePrezimeEksploatacijaPolja: DynamicFormField;
  nazivLezista: DynamicFormField;
  odgovorniProjektantImePrezime: DynamicFormField;
  odgovorniProjektantImePrezimeEksploatacijaPolja: DynamicFormField;
  izborIzvestajaOMineralnimResursimaZaDobijanjePrirodnihGradjevinskihMaterijala: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaEksploatacionoPoljeIliZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijalaIliZaEksploatacijuPrirodnihGradjevinskihMaterijala: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaEksploatacionoPolje: DynamicFormField;
  brojResenjaOOdobravanjuZahtevaZaEksploatacionoPolje: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaEksploatacioniProstorIKolicinuRezervi: DynamicFormField;
  izborResenjaOOdobravanjuZahteva: DynamicFormField;
  brojResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanja: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaPetrogeotermalnihResursa: DynamicFormField;
  brojResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala: DynamicFormField;
  nazivElaborataIstrazivanje: DynamicFormField;
  nazivElaborataPodzemneVode: DynamicFormField;
  autoriElaborata: DynamicFormField;
  datumPocetkaRadovaNaIstrazivanju: DynamicFormField;
  datumIzradeElaborata: DynamicFormField;
  datumPocetkaGeoloskogIstrazivanja: DynamicFormField;
  datumZavrsetkaGeoloskogIstrazivanja: DynamicFormField;
  periodTrajanjaGeoloskihIstrazivanja: DynamicFormField;
  datumPocetkaIzvrsenjaGeoloskogIstrazivanja: DynamicFormField;
  datumZavrsetkaIzvrsenjaGeoloskogIstrazivanja: DynamicFormField;
  datumPodnosenjaZahteva: DynamicFormField;
  datumPrijemaResenja: DynamicFormField;
  brojIstraznogProstora: DynamicFormField;
  vremeTrajanjaGeoloskihIstrazivanja: DynamicFormField;
  datumPocetkaProbnogRadaBusotine: DynamicFormField;
  datumZavrsetkaProbnogRadaBusotine: DynamicFormField;
  tipZahteva: DynamicFormField;
  rokVazenjaOdobrenja: DynamicFormField;
  brojOdobrenogIstraznogPolja: DynamicFormField;
  brojProduzenja: DynamicFormField;
  izborZahtevaMineralneSirovine: DynamicFormField;
  brojZahtevaMineralneSirovine: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskoGeoloska: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovinaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskogeoloskaGeotehnickaIstrazivanja: DynamicFormField;
  izborResenjaOOdobravanjuZahtevaZaIzgradnjuRudarskihObjekataZaIzvodjenjeRudarskihRadova: DynamicFormField;
  katarstarskiBrojIstraznogProstora: DynamicFormField;
  nazivProjektaeoloskihIstrazivanjaUsaglasenRudarskiProjekat: DynamicFormField;
  attachments: {
    rudarskiProjekat: DynamicFormField;
    stanjeBilansnihRezervi: DynamicFormField;
    saglasnostNosioca: DynamicFormField;
    izvestajOIzvrsenomTehnickomPregleduKomisija: DynamicFormField;
    drugaAktaPredvidjena: DynamicFormField;
    aktOrganaNadleznogZaPosloveVodoprivredeKojimSeDajeSaglasnot: DynamicFormField;
    aktOrganaNadleznogZaPosloveZastiteZivotneSredineKojimSeDajeSaglasnostNaStudijuOProceniUticajaEksploatacijeNaZivotnuSredinu: DynamicFormField;
    garancijaBanke: DynamicFormField;
    saglasnostNadlezneUstanove: DynamicFormField;
    saglasnostNaTehnickuDokumentaciju: DynamicFormField;
    izvestajNosiocaOdobrenjaOIzvresenjuObaveza: DynamicFormField;
    poslednjiIzvestajOInspekcijskomNadzoruIzvresnomOdStraneRudarskogInspektoraIInspektoraBezbezdnostiNaRadu: DynamicFormField;
    pisanaIzjavaBuducegNosiocaOdobrenjaOPrihvatanjuUslovaPrenosaOdobrenjaSaSvimPravimaIObavezamaKojeProisticuIzNjega: DynamicFormField;
    topografskaKarta: DynamicFormField;
    izvestajOMineralnimResursimaPrirodnihGradjevinskihMaterijala: DynamicFormField;
    geoloskoIstrazivanje: DynamicFormField;
    tehnickaKontrola: DynamicFormField;
    dokazKoriscenjePodataka: DynamicFormField;
    dokazRepublickaTaksa: DynamicFormField;
    dokazOPravuKoriscenjaGeoloskeDokumentacije: DynamicFormField;
    aktZaZastituPrirode: DynamicFormField;
    aktZaZastituPrirodeOpcioni: DynamicFormField;
    aktOUslovimaZaZastituSpomenikaKulture: DynamicFormField;
    aktOUslovimaZaZastituSpomenikaKultureOpcioni: DynamicFormField;
    ispunjenostUslovaZaZastituPrirode: DynamicFormField;
    izvodIzRegistra: DynamicFormField;
    izvestajOStrucnomNadzoru: DynamicFormField;
    izvestajElaborat: DynamicFormField;
    izvestajPotvrdaOTehnickojKontroliZavrsnogIzvestaja: DynamicFormField;
    programAktivnosti: DynamicFormField;
    pisanaIzjavaNovogNosioca: DynamicFormField;
    pismoONamerama: DynamicFormField;
    dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja: DynamicFormField;
    dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnjaOpciono: DynamicFormField;
    aktOUslugamaZavoda: DynamicFormField;
    uverenjeOPolozenomStrucnomIspitu: DynamicFormField;
    potvrdaOZaposlenjuIImenovanjuGlavnogProjektanta: DynamicFormField;
    dokazOIspunjenostiUslovaClana22: DynamicFormField;
    situacionaKarta: DynamicFormField;
    aktOrganaNadleznogZaPosloveZastiteZivotneSredineOObimuISadrzajuStudijeOProceniUticajaEksploatacijeNaZivotnuSredinu: DynamicFormField;
    aktOrganaNadleznogZaPosloveUrbanizma: DynamicFormField;
    aktOrganaNadleznogZaPosloveVodoprivredeKojimSeOdredjujuUsloviZaVrsenjeEksploatacije: DynamicFormField;
    aktOrganaUstanoveNadlezneZaZastituKulturnogNasledjaKojimSeOdredjujuUsloviZaVrsenjeEksploatacije: DynamicFormField;
    studijaIzvodljivostiEksploatacijeLezistaMineralnihSirovina: DynamicFormField;
    predlogZaOveruRezerviSaPreglednomKartom: DynamicFormField;
    elaboratOResursimaIRezervamaMineralnihSirovina: DynamicFormField;
    resenjeOImenovanjuStrucnogNadzora: DynamicFormField;
    preglednaKarta: DynamicFormField;
    preglednaSituacionaKarta: DynamicFormField;
    izvodIzElaborata: DynamicFormField;
    dokazDaAutorElaborataIspunjavaUslovePropisaneZakonom: DynamicFormField;
    elaboratOResursimaIRezervamaPodzemnihVodaIGeotermalnihResursa: DynamicFormField;
    dokazOPravuSvojine: DynamicFormField;
    dokazOPravuSvojineSaMestom: DynamicFormField;
    elaboratOUslovimaEksploatacijePodzemnihVoda: DynamicFormField;
    aktZaPosloveSanitarneZastiteIzvoristaVodosnabdevanja: DynamicFormField;
    geodetskiPlan: DynamicFormField;
    zavrsniIzvestaj: DynamicFormField;
    godisnjiIzvestaj: DynamicFormField;
    izvestajNosiocaOdobrenjaZaIstrazivanjeOIzvrsenjuObavezaUVeziSanacijeIRekultivacijeProstora: DynamicFormField;
    ostalo: DynamicFormField;
    tehnickaKontrolaRudarskogProjekta: DynamicFormField;
    potvrdaOResursimaIRezervamaMineralnihSirovina: DynamicFormField;
  };
}

export const INPUTS: IInputs = {
  brojResenjaIzvodjenjeRudarskihRadova: {
    name: [
      'eksploatacijaMineralnihAtributi',
      'brojResenjaIzvodjenjeRudarskihRadova',
    ],
    type: 'text',
    label: t('forma.sekcija.polje.brojResenjaIzvodjenjeRudarskihRadova'),
    placeholder: t(
      'forma.sekcija.polje.brojResenjaIzvodjenjeRudarskihRadova_placeholder'
    ),
  },
  planiraniPocetakTrajanjeProbnogRada: {
    name: [
      'eksploatacijaMineralnihAtributi',
      'datumPocetkaProbnogRadaRudarskogObjekta',
    ],
    type: 'date',
    label: t('forma.sekcija.polje.planiraniPocetakTrajanjeProbnogRada'),
    placeholder: t(
      'forma.sekcija.polje.planiraniPocetakTrajanjeProbnogRada_placeholder'
    ),
    rules: [
      {
        required: true,
        type: 'date',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  planiraniKrajTrajanjeProbnogRada: {
    name: [
      'eksploatacijaMineralnihAtributi',
      'datumZavrsetkaProbnogRadaRudarskogObjekta',
    ],
    type: 'date',
    label: t('forma.sekcija.polje.planiraniKrajTrajanjeProbnogRada'),
    placeholder: t(
      'forma.sekcija.polje.planiraniKrajTrajanjeProbnogRada_placeholder'
    ),
    rules: [
      {
        required: true,
        type: 'date',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  nazivRudarskogProjektaPoKomeJeIzgradjenRudarskiObjekat: {
    name: [
      'eksploatacijaMineralnihAtributi',
      'nazivRudarskogProjektaIzgradjenRudarskiObjekat',
    ],
    type: 'text',
    label: t(
      'forma.sekcija.polje.nazivRudarskogProjektaPoKomeJeIzgradjenRudarskiObjekat'
    ),
    placeholder: t(
      'forma.sekcija.polje.nazivRudarskogProjektaPoKomeJeIzgradjenRudarskiObjekat_placeholder'
    ),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  katarstarskiBrojIstraznogProstora: {
    name: [
      'eksploatacijaMineralnihAtributi',
      'katastarskiBrojIstraznogProstora',
    ],
    type: 'text',
    label: t('forma.sekcija.polje.katastarski_broj_istraznog_prostora'),
    placeholder: t(
      'forma.sekcija.polje.katastarski_broj_istraznog_prostora_placeholder'
    ),
    rules: [
      {
        required: true,
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
      { max: 50, message: t('forma.sekcija.maksimalan_broj_karaktera') },
    ],
  },
  nazivProjektaeoloskihIstrazivanjaUsaglasenRudarskiProjekat: {
    name: [
      'eksploatacijaMineralnihAtributi',
      'nazivRudarskogProjektaIzgradjenRudarskiObjekat',
    ],
    type: 'text',
    label: t(
      'forma.sekcija.polje.naziv_projekta_geoloskih_istrazivanja_usaglasen_rudarski_projekat'
    ),
    placeholder: t(
      'forma.sekcija.polje.naziv_projekta_geoloskih_istrazivanja_usaglasen_rudarski_projekat_placeholder'
    ),
    rules: [
      {
        required: true,
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
      { max: 1000, message: t('forma.sekcija.maksimalan_broj_karaktera') },
    ],
  },
  godisnjiKapacitet: {
    name: ['eksploatacijaMineralnihAtributi', 'godisnjiKapacitet'],
    type: 'number',
    label: t('forma.sekcija.polje.godisnjiKapacitet'),
    placeholder: t('forma.sekcija.polje.godisnjiKapacitet_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  nazivRudarskogProjekta: {
    name: ['eksploatacijaMineralnihAtributi', 'nazivRudarskogProjekta'],
    type: 'text',
    label: t('forma.sekcija.polje.nazivRudarskogProjekta'),
    placeholder: t('forma.sekcija.polje.nazivRudarskogProjekta_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  brojEksploatacionogPolja: {
    name: ['eksploatacijaMineralnihAtributi', 'brojEksploatacionogPolja'],
    type: 'text',
    label: t('forma.sekcija.polje.brojEksploatacionogPolja'),
    placeholder: t('forma.sekcija.polje.brojEksploatacionogPolja_placeholder'),
  },
  izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina: {
    name: [
      'resenja',
      String([
        IzborResenjaEnum.RESENJA_O_UTVRDJENIM_I_RAZVRSTANIM_REZERVAMA_MINERALNIH_SIROVINA,
      ]),
    ],
    type: 'select-input',
    label: t('forma.sekcija.polje.izborResenja'),
    tooltip: t(
      'forma.sekcija.polje.izborResenjaOUtvrdjenimIRazvrstanimRezervamaMineralnihSirovina_tooltip'
    ),
    placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
    dropdown: {
      placeholder: {
        input: t('forma.sekcija.polje.unesiteResenje'),
        button: t('forma.sekcija.polje.dodajteResenje'),
      },
    },
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaIliPetrogeotermalnihResursa:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_GEOLOSKA_ISTRAZIVANJA_PODZEMNIH_VODA_ILI_PETROGEOTERMALNIH_RESURSA,
        ]),
      ],
      type: 'select-input',
      label: t('forma.sekcija.polje.izborResenja'),
      tooltip: t(
        'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaIliPetrogeotermalnihResursa_tooltip'
      ),
      placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  izborResenjaOOdobravanjuZahtevaZaEksploatacioniProstorIKolicinuRezervi: {
    name: [
      'resenja',
      String([
        IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_EKPLOATACIONI_PROSTOR_I_KOLICINU_REZERVI,
      ]),
    ],
    type: 'select-input',
    label: t('forma.sekcija.polje.izborResenja'),
    tooltip: t(
      'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaEksploatacioniProstorIKolicinuRezervi_tooltip'
    ),
    placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
    dropdown: {
      placeholder: {
        input: t('forma.sekcija.polje.unesiteResenje'),
        button: t('forma.sekcija.polje.dodajteResenje'),
      },
    },
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  izborIzvestajaOMineralnimResursimaZaDobijanjePrirodnihGradjevinskihMaterijala:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.IZVESTAJA_O_MINERALNIM_RESURSIMA_ZA_DOBIJANJE_PRIRODNIH_GRADJEVINSKIH_MATERIJALA,
        ]),
      ],
      type: 'select-input',
      label: t('forma.sekcija.polje.izborIzvestaj'),
      tooltip: t(
        'forma.sekcija.polje.izborIzvestajaOMineralnimResursimaZaDobijanjePrirodnihGradjevinskihMaterijala_tooltip'
      ),
      placeholder: t('forma.sekcija.polje.izborIzvestaj_placeholder'),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  izborResenjaOOdobravanjuZahtevaZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_EKSPLOATACIJU_NEMETALICNIH_MINERALNIH_SIROVINA_ZA_DOBIJANJE_GRADJEVINSKIH_MATERIJALA,
        ]),
      ],
      type: 'select-input',
      label: t('forma.sekcija.polje.izborResenja'),
      tooltip: t(
        'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijala_tooltip'
      ),
      placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  izborResenjaOOdobravanjuZahtevaZaEksploatacionoPoljeIliZaEksploatacijuNemetalicnihMineralnihSirovinaZaDobijanjeGradjevinskihMaterijalaIliZaEksploatacijuPrirodnihGradjevinskihMaterijala:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_EKSPLOATACIONO_POLJE_ILI_ZA_EKSPLOATACIJU_NEMETALICNIH_MINERALNIH_SIROVINA_ZA_DOBIJANJE_GRADJEVINSKIH_MATERIJALA_ILI_ZA_EKSPLOATACIJU_PRIRODNIH_GRADJEVINSKIH_MATERIJALA,
        ]),
      ],
      type: 'select-input',
      label: t('forma.sekcija.polje.izborResenja'),
      placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
      tooltip: t(
        'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaEksploatacionoPoljeIli_tooltip'
      ),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  nazivStudijeIzvodljivosti: {
    name: ['eksploatacijaMineralnihAtributi', 'nazivStudijeIzvodljivosti'],
    type: 'text',
    label: t('forma.sekcija.polje.naziv_studije_izvodljivosti'),
    placeholder: t(
      'forma.sekcija.polje.naziv_studije_izvodljivosti_placeholder'
    ),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  nazivLezista: {
    name: ['eksploatacijaMineralnihAtributi', 'nazivLezista'],
    type: 'text',
    label: t('forma.sekcija.polje.naziv_lezista'),
    placeholder: t('forma.sekcija.polje.naziv_lezista_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  vrstaGeoloskogIstrazivanjaIdPodzemneVode: {
    name: ['podzemneVodeAtributi', 'vrstaGeoloskogIstrazivanjaId'],
    type: 'select',
    label: t('forma.sekcija.polje.vrsta_geoloskih_istrazivanja'),
    placeholder: t(
      'forma.sekcija.polje.vrsta_geoloskih_istrazivanja_placeholder'
    ),
    selectMode: 'multiple',
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  vrstaGeoloskogIstrazivanjaIdPodzemneVodeDisabled: {
    name: ['podzemneVodeAtributi', 'vrstaGeoloskogIstrazivanjaId'],
    type: 'select',
    label: t('forma.sekcija.polje.vrsta_geoloskih_istrazivanja'),
    placeholder: t(
      'forma.sekcija.polje.vrsta_geoloskih_istrazivanja_placeholder'
    ),
    selectMode: 'multiple',
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
    disabled: true,
  },
  vrstaGeoloskogIstrazivanjaIdMineralneSirovine: {
    name: ['istrazivanjeAtributi', 'vrstaGeoloskogIstrazivanjaId'],
    type: 'select',
    options: requestsStore.config?.vrsteGeoloskihIstrazivanja['2'],
    label: t('forma.sekcija.polje.vrsta_geoloskih_istrazivanja'),
    placeholder: t(
      'forma.sekcija.polje.vrsta_geoloskih_istrazivanja_placeholder'
    ),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
    disabled: true,
    selectMode: 'multiple',
  },
  vrstaMineralnihSirovinaId: {
    name: ['istrazivanjeAtributi', 'vrstaMineralnihSirovinaId'],
    type: 'select',
    label: t('forma.sekcija.polje.vrstaMineralneSirovineOpisno'),
    placeholder: t(
      'forma.sekcija.polje.vrstaMineralneSirovineOpisno_placeholder'
    ),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
    selectMode: 'multiple',
  },
  vrstaMineralnihSirovinaIdEks: {
    name: ['eksploatacijaMineralnihAtributi', 'vrstaMineralnihSirovinaId'],
    type: 'select',
    label: t('forma.sekcija.polje.vrstaMineralneSirovineOpisno'),
    placeholder: t(
      'forma.sekcija.polje.vrstaMineralneSirovineOpisno_placeholder'
    ),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
    selectMode: 'multiple',
  },
  vrstaMineralneSirovineOpisno: {
    name: ['istrazivanjeAtributi', 'vrstaMineralneSirovineOpisno'],
    type: 'text',
    label: t('forma.sekcija.polje.vrstaMineralneSirovineOpisno'),
    placeholder: t(
      'forma.sekcija.polje.vrstaMineralneSirovineOpisno_placeholder'
    ),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      { max: 100, message: t('forma.sekcija.polje.maksimalan_broj_karaktera') },
    ],
  },
  predmetIstrazivanjaId: {
    name: ['podzemneVodeAtributi', 'predmetIstrazivanjaId'],
    type: 'select',
    label: t('forma.sekcija.polje.predmetIstrazivanjaId'),
    placeholder: t('forma.sekcija.polje.predmetIstrazivanjaId_placeholder'),
    selectMode: 'multiple',
    rules: [
      {
        required: true,
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  duzinaRokaGeoloskogIstrazivanja: {
    name: ['podzemneVodeAtributi', 'duzinaRokaGeoloskogIstrazivanja'],
    type: 'number',
    label: t('forma.sekcija.polje.duzina_roka_geoloskog_istrazivanja'),
    placeholder: t(
      'forma.sekcija.polje.duzina_roka_geoloskog_istrazivanja_placeholder'
    ),
    rules: [
      {
        required: true,
        type: 'integer',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  vremeTrajanjaGeoloskihIstrazivanja: {
    name: ['istrazivanjeAtributi', 'vremeTrajanjaGeoloskogIstrazivanja'],
    type: 'number',
    label: t('forma.sekcija.polje.vremeTrajanjaGeoloskihIstrazivanja'),
    placeholder: t(
      'forma.sekcija.polje.vremeTrajanjaGeoloskihIstrazivanja_placeholder'
    ),
    rules: [
      {
        required: true,
        type: 'integer',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  nazivProjektaPrimenjenihGeoloskihIstrazivanjaPodzemneVode: {
    name: [
      'podzemneVodeAtributi',
      'nazivProjektaPrimenjenihGeoloskihIstrazivanja',
    ],
    type: 'text',
    label: t('forma.sekcija.polje.naziv_projekta_geoloskih_istrazivanja'),
    placeholder: t(
      'forma.sekcija.polje.naziv_projekta_geoloskih_istrazivanja_placeholder'
    ),
    tooltip: t(
      'forma.sekcija.polje.naziv_projekta_geoloskih_istrazivanja_tooltip'
    ),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      { max: 100, message: t('forma.sekcija.polje.maksimalan_broj_karaktera') },
    ],
  },
  nazivProjektaPrimenjenihGeoloskihIstrazivanjaMineralneSirovine: {
    name: [
      'istrazivanjeAtributi',
      'nazivProjektaPrimenjenihGeoloskihIstrazivanja',
    ],
    type: 'text',
    label: t('forma.sekcija.polje.naziv_projekta_geoloskih_istrazivanja'),
    placeholder: t(
      'forma.sekcija.polje.naziv_projekta_geoloskih_istrazivanja_placeholder'
    ),
    tooltip: t(
      'forma.sekcija.polje.naziv_projekta_geoloskih_istrazivanja_tooltip'
    ),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      {
        max: 1000,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  nazivProgramaAktivnosti: {
    name: ['podzemneVodeAtributi', 'nazivProgramaAktivnosti'],
    type: 'text',
    label: t('forma.sekcija.polje.nazivProgramaAktivnosti'),
    placeholder: t('forma.sekcija.polje.nazivProgramaAktivnosti_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      {
        max: 1000,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  nazivProgramaAktivnostiIstrazivanjaMS: {
    name: ['istrazivanjeAtributi', 'nazivProgramaAktivnosti'],
    type: 'text',
    label: t('forma.sekcija.polje.nazivProgramaAktivnosti'),
    placeholder: t('forma.sekcija.polje.nazivProgramaAktivnosti_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      {
        max: 1000,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  liceVrsilacTehnickeKontrolePodzemneVode: {
    name: ['podzemneVodeAtributi', 'liceVrsilacTehnickeKontrole'],
    type: 'text',
    label: t('forma.sekcija.polje.lice_koje_vrsi_kontrolu'),
    placeholder: t('forma.sekcija.polje.lice_koje_vrsi_kontrolu_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      { max: 50, message: t('forma.sekcija.polje.maksimalan_broj_karaktera') },
    ],
  },
  liceVrsilacTehnickeKontroleMineralneSirovine: {
    name: ['istrazivanjeAtributi', 'liceVrsilacTehnickeKontrole'],
    type: 'text',
    label: t('forma.sekcija.polje.lice_koje_vrsi_kontrolu'),
    placeholder: t('forma.sekcija.polje.lice_koje_vrsi_kontrolu_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      { max: 50, message: t('forma.sekcija.polje.maksimalan_broj_karaktera') },
    ],
  },
  opstinaId: {
    name: ['zahtevAtributi', 'opstinaId'],
    type: 'select',
    label: t('forma.sekcija.polje.naziv_opstine_teritorija'),
    placeholder: t('forma.sekcija.polje.naziv_opstine_teritorija_placeholder'),
    selectMode: 'multiple',
    tooltip: t('forma.sekcija.polje.naziv_opstine_teritorija_tooltip'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  visinaAdministrativneTakse: {
    name: ['zahtevAtributi', 'visinaAdministrativneTakse'],
    type: 'numberFloat',
    label: t('forma.sekcija.polje.visina_administrativne_takse'),
    placeholder: t(
      'forma.sekcija.polje.visina_administrativne_takse_placeholder'
    ),
    prefix: t('RSD'),
    rules: [
      {
        required: true,
        type: 'number',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_GEOLOSKA_ISTRAZIVANJA_MINERALNIH_SIROVINA,
        ]),
      ],
      type: 'select-input',
      label: t('forma.sekcija.polje.izborResenja'),
      tooltip: t(
        'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovina_tooltip'
      ),
      placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaPetrogeotermalnihResursa:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_GEOLOSKA_ISTRAZIVANJA_PODZEMNIH_VODA_PETROGEOTERMALNIH_RESURSA,
        ]),
      ],
      type: 'select-input',
      label: t('forma.sekcija.polje.izborResenja'),
      tooltip: t(
        'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPodzemnihVodaPetrogeotermalnihResursa_tooltip'
      ),
      placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskogeoloskaGeotehnickaIstrazivanja:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_INZENJERSKOGEOLOSKA_GEOTEHNICKA_ISTRAZIVANJA,
        ]),
      ],
      type: 'select-input',
      label: t('forma.sekcija.polje.izborResenja'),
      tooltip: t(
        'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskogeoloskaGeotehnickaIstrazivanja_tooltip'
      ),
      placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  izborResenjaOOdobravanjuZahteva: {
    name: ['resenja', String([IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA])],
    type: 'select-input',
    label: t('forma.sekcija.polje.izborResenja'),
    placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
    tooltip: t('forma.sekcija.polje.izborResenjaOOdobravanjuZahteva_tooltip'),
    dropdown: {
      placeholder: {
        input: t('forma.sekcija.polje.unesiteResenje'),
        button: t('forma.sekcija.polje.dodajteResenje'),
      },
    },
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  izborResenjaOOdobravanjuZahtevaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_ZADRZAVANJE_PRAVA_NA_ISTRAZNI_PROSTOR_ZA_MINERALNE_SIROVINE,
        ]),
      ],
      type: 'select-input',
      label: t('forma.sekcija.polje.izborResenja'),
      placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
      tooltip: t(
        'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine_tooltip'
      ),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  izborResenjaOOdobravanjuZahtevaZaIzgradnjuRudarskihObjekataZaIzvodjenjeRudarskihRadova:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_IZGRADNJU_RUDARSKIH_OBJEKATA_ZA_IZVODJENJE_RUDARSKIH_RADOVA,
        ]),
      ],
      type: 'select-input',
      tooltip: t(
        'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaIzgradnjuRudarskihObjekataZaIzvodjenjeRudarskihRadova_tooltip'
      ),
      label: t('forma.sekcija.polje.izborResenja'),
      placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskoGeoloska: {
    name: [
      'resenja',
      String([
        IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_INZENJERSKO_GEOLOSKA,
      ]),
    ],
    type: 'select-input',
    label: t('forma.sekcija.polje.izborResenja'),
    tooltip: t(
      'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaPrimenjenaInzenjerskoGeoloska_tooltip'
    ),
    placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
    dropdown: {
      placeholder: {
        input: t('forma.sekcija.polje.unesiteResenje'),
        button: t('forma.sekcija.polje.dodajteResenje'),
      },
    },
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovinaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_GEOLOSKA_ISTRAZIVANJA_PRIRODNIH_GRADJEVINSKIH_MATERIJALA_ILI_ZADRZAVANJE_PRAVA_NA_ISTRAZNI_PROSTOR_ZA_MINERALNE_SIROVINE,
        ]),
      ],
      type: 'select-input',
      label: t('forma.sekcija.polje.izborResenja'),
      placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
      tooltip: t(
        'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaMineralnihSirovinaZadrzavanjePravaNaIstrazniProstorZaMineralneSirovine_tooltip'
      ),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_PRIMENJENA_GEOLOSKA_ISTRAZIVANJA_PRIRODNIH_GRADJEVINSKIH_MATERIJALA,
        ]),
      ],
      type: 'select-input',
      label: t('forma.sekcija.polje.izborResenja'),
      placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
      tooltip: t(
        'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala_tooltip'
      ),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  izborZahtevaMineralneSirovine: {
    name: ['referentniZahtevId'],
    type: 'select',
    label: t('forma.sekcija.polje.izborZahtevaMineralneSirovine'),
    placeholder: t(
      'forma.sekcija.polje.izborZahtevaMineralneSirovine_placeholder'
    ),
    dropdown: {
      placeholder: {
        input: t('forma.sekcija.polje.unesiteZahtev'),
        button: t('forma.sekcija.polje.dodajteZahtev'),
      },
    },
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  brojZahtevaMineralneSirovine: {
    name: ['zahtevAtributi', 'brojResenja'],
    type: 'text',
    label: t(
      'forma.sekcija.polje.brojResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanja'
    ),
    placeholder: t(
      'forma.sekcija.polje.brojResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanja_placeholder'
    ),
  },
  izborResenjaOOdobravanjuZahtevaZaEksploatacionoPolje: {
    name: [
      'resenja',
      String([
        IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_EKSPLOATACIONO_POLJE,
      ]),
    ],
    type: 'select-input',
    label: t('forma.sekcija.polje.izborResenja'),
    placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
    tooltip: t(
      'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaEksploatacionoPolje_tooltip'
    ),
    dropdown: {
      placeholder: {
        input: t('forma.sekcija.polje.unesiteResenje'),
        button: t('forma.sekcija.polje.dodajteResenje'),
      },
    },
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  izborResenjaOOdobravanjuZahtevaZaZadrzavanjePravaNaIstrazniProstorZaPodzemneVodeIGeotermalneResurse:
    {
      name: [
        'resenja',
        String([
          IzborResenjaEnum.RESENJA_O_ODOBRAVANJU_ZAHTEVA_ZA_ZADRZAVANJE_PRAVA_NA_ISTRAZNI_PROSTOR_ZA_PODZEMNE_VODE_I_GEOTERMALNE_RESURSE,
        ]),
      ],
      type: 'select-input',
      tooltip: t(
        'forma.sekcija.polje.izborResenjaOOdobravanjuZahtevaZaZadrzavanjePravaNaIstrazniProstorZaPodzemneVodeIGeotermalneResurse_tooltip'
      ),
      label: t('forma.sekcija.polje.izborResenja'),
      placeholder: t('forma.sekcija.polje.izborResenja_placeholder'),
      dropdown: {
        placeholder: {
          input: t('forma.sekcija.polje.unesiteResenje'),
          button: t('forma.sekcija.polje.dodajteResenje'),
        },
      },
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  brojResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanja: {
    name: ['zahtevAtributi', 'brojResenja'],
    type: 'text',
    label: t(
      'forma.sekcija.polje.brojResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanja'
    ),
    placeholder: t(
      'forma.sekcija.polje.brojResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanja_placeholder'
    ),
  },
  brojResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala:
    {
      name: ['zahtevAtributi', 'brojResenja'],
      type: 'text',
      label: t(
        'forma.sekcija.polje.brojResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala'
      ),
      placeholder: t(
        'forma.sekcija.polje.brojResenjaOOdobravanjuZahtevaZaPrimenjenaGeoloskaIstrazivanjaPrirodnihGradjevinskihMaterijala_placeholder'
      ),
    },
  brojResenjaOOdobravanjuZahtevaZaEksploatacionoPolje: {
    name: ['zahtevAtributi', 'brojResenja'],
    type: 'text',
    label: t(
      'forma.sekcija.polje.brojResenjaOOdobravanjuZahtevaZaEksploatacionoPolje'
    ),
    placeholder: t(
      'forma.sekcija.polje.brojResenjaOOdobravanjuZahtevaZaEksploatacionoPolje_placeholder'
    ),
  },
  rokVazenjaOdobrenja: {
    name: ['istrazivanjeAtributi', 'rokVazenjaOdobrenja'],
    type: 'date',
    label: t('forma.sekcija.polje.rokVazenjaOdobrenja'),
    placeholder: t('forma.sekcija.polje.rokVazenjaOdobrenja_placeholder'),
    rules: [
      {
        required: true,
        type: 'date',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  brojOdobrenogIstraznogPolja: {
    name: ['istrazivanjeAtributi', 'brojOdobrenogIstraznogPolja'],
    type: 'text',
    label: t('forma.sekcija.polje.brojOdobrenogIstraznogPolja'),
    placeholder: t(
      'forma.sekcija.polje.brojOdobrenogIstraznogPolja_placeholder'
    ),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      { max: 100, message: t('forma.sekcija.polje.maksimalan_broj_karaktera') },
    ],
  },
  brojProduzenja: {
    name: ['istrazivanjeAtributi', 'brojProduzenja'],
    type: 'text',
    label: t('forma.sekcija.polje.brojProduzenja'),
    placeholder: t('forma.sekcija.polje.brojProduzenja_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  prostorObavezni: {
    type: 'location',
    name: [ProstorEnum.PROSTOR_OBAVEZNI],
    span: 24,
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      { max: 50, message: t('forma.sekcija.polje.maksimalan_broj_karaktera') },
    ],
    context: ProstorEnum.PROSTOR_OBAVEZNI,
  },
  prostorObavezniEksploatacioniProstor: {
    type: 'location',
    name: [ProstorEnum.PROSTOR_OBAVEZNI],
    span: 24,
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      { max: 50, message: t('forma.sekcija.polje.maksimalan_broj_karaktera') },
    ],
    context: ProstorEnum.PROSTOR_OBAVEZNI,
    coordinatesTitle: t(
      'forma.sekcija.naslov.koordinate_eksploatacionog_polja'
    ),
  },
  prostorOpcioni: {
    type: 'location',
    name: [ProstorEnum.PROSTOR_OPCIONI],
    span: 24,
    context: ProstorEnum.PROSTOR_OPCIONI,
  },
  prostorOpcioniEksploatacioniProstor: {
    type: 'location',
    name: [ProstorEnum.PROSTOR_OPCIONI],
    span: 24,
    context: ProstorEnum.PROSTOR_OPCIONI,
    coordinatesTitle: t(
      'forma.sekcija.naslov.koordinate_eksploatacionog_polja'
    ),
  },
  lokalitet: {
    type: 'lokalitet',
    name: [ProstorEnum.PROSTOR_OBAVEZNI, 'nazivLokaliteta'],
    span: 24,
    context: ProstorEnum.PROSTOR_OBAVEZNI,
  },
  lokalitetOpcioni: {
    type: 'lokalitet',
    span: 24,
    name: [ProstorEnum.PROSTOR_OPCIONI, 'nazivLokaliteta'],
  },
  koordinate: {
    type: 'koordinate',
    name: [ProstorEnum.PROSTOR_OBAVEZNI, 'koordinate'],
    span: 24,
    context: ProstorEnum.PROSTOR_OBAVEZNI,
  },
  koordinateOpcioni: {
    type: 'koordinate',
    span: 24,
    name: [ProstorEnum.PROSTOR_OPCIONI, 'koordinate'],
  },
  obrazlozenje: {
    type: 'textarea',
    name: ['zahtevAtributi', 'obrazlozenje'],
    span: 24,
    label: t('forma.sekcija.polje.obrazlozenje'),
    placeholder: t('forma.sekcija.polje.obrazlozenje_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      {
        max: 4000,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  liceVrsilacNadzoraPodzemneVode: {
    name: ['podzemneVodeAtributi', 'liceVrsilacNadzora'],
    type: 'text',
    label: t('forma.sekcija.polje.liceVrsilacNadzora'),
    placeholder: t('forma.sekcija.polje.liceVrsilacNadzora_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      { max: 50, message: t('forma.sekcija.polje.maksimalan_broj_karaktera') },
    ],
  },
  liceVrsilacNadzoraMineralneSirovine: {
    name: ['istrazivanjeAtributi', 'liceVrsilacNadzora'],
    type: 'text',
    label: t('forma.sekcija.polje.liceVrsilacNadzora'),
    placeholder: t('forma.sekcija.polje.liceVrsilacNadzora_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      { max: 50, message: t('forma.sekcija.polje.maksimalan_broj_karaktera') },
    ],
  },
  nazivZavrsnogIzvestaja: {
    name: ['zahtevAtributi', 'nazivZavrsnogIzvestajaElaborata'],
    type: 'text',
    label: t('forma.sekcija.polje.nazivZavrsnogIzvestaja'),
    placeholder: t('forma.sekcija.polje.nazivZavrsnogIzvestaja_placeholder'),
    tooltip: t('forma.sekcija.polje.nazivZavrsnogIzvestajaElaborata_tooltip'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      {
        max: 1000,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  nazivZavrsnogIzvestajaElaborata: {
    name: ['zahtevAtributi', 'nazivZavrsnogIzvestajaElaborata'],
    type: 'text',
    label: t('forma.sekcija.polje.nazivZavrsnogIzvestajaElaborata'),
    placeholder: t(
      'forma.sekcija.polje.nazivZavrsnogIzvestajaElaborata_placeholder'
    ),
    tooltip: t('forma.sekcija.polje.nazivZavrsnogIzvestajaElaborata_tooltip'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      {
        max: 1000,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  godinaIzrade: {
    name: ['zahtevAtributi', 'godinaIzrade'],
    type: 'year',
    label: t('forma.sekcija.polje.godinaIzradeProjekta'),
    placeholder: t('forma.sekcija.polje.godinaIzradeProjekta_placeholder'),
    rules: [
      {
        required: true,
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  godinaIzradeStudije: {
    name: ['zahtevAtributi', 'godinaIzrade'],
    type: 'year',
    label: t('forma.sekcija.polje.godinaIzradeStudije'),
    placeholder: t('forma.sekcija.polje.godinaIzradeStudije_placeholder'),
    rules: [
      {
        required: true,
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  godinaIzradeElaborata: {
    name: ['zahtevAtributi', 'godinaIzrade'],
    type: 'year',
    label: t('forma.sekcija.polje.godinaIzradeElaborata'),
    placeholder: t('forma.sekcija.polje.godinaIzradeElaborata_placeholder'),
    rules: [
      {
        required: true,
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  glavniProjektantImePrezime: {
    name: ['istrazivanjeAtributi', 'glavniProjektantImePrezime'],
    type: 'text',
    label: t('forma.sekcija.polje.glavniProjektantImePrezime'),
    placeholder: t(
      'forma.sekcija.polje.glavniProjektantImePrezime_placeholder'
    ),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      {
        max: 50,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  odgovorniProjektantImePrezime: {
    name: ['istrazivanjeAtributi', 'odgovorniProjektantImePrezime'],
    type: 'text',
    label: t('forma.sekcija.polje.odgovorniProjektantImePrezime'),
    placeholder: t(
      'forma.sekcija.polje.odgovorniProjektantImePrezime_placeholder'
    ),
    rules: [
      {
        max: 50,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  glavniProjektantImePrezimeEksploatacijaPolja: {
    name: ['eksploatacijaMineralnihAtributi', 'glavniProjektantImePrezime'],
    type: 'text',
    label: t('forma.sekcija.polje.glavniProjektantImePrezime'),
    placeholder: t(
      'forma.sekcija.polje.glavniProjektantImePrezime_placeholder'
    ),
    rules: [
      {
        max: 50,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  odgovorniProjektantImePrezimeEksploatacijaPolja: {
    name: ['eksploatacijaMineralnihAtributi', 'odgovorniProjektantImePrezime'],
    type: 'text',
    label: t('forma.sekcija.polje.odgovorniProjektantImePrezime'),
    placeholder: t(
      'forma.sekcija.polje.odgovorniProjektantImePrezime_placeholder'
    ),
    rules: [
      {
        max: 500,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  nazivElaborataIstrazivanje: {
    name: ['istrazivanjeAtributi', 'nazivElaborata'],
    type: 'text',
    label: t('forma.sekcija.polje.nazivElaborata'),
    placeholder: t('forma.sekcija.polje.nazivElaborata_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      {
        max: 1000,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  nazivElaborataPodzemneVode: {
    name: ['podzemneVodeAtributi', 'nazivElaborata'],
    type: 'text',
    label: t('forma.sekcija.polje.nazivElaborata'),
    placeholder: t('forma.sekcija.polje.nazivElaborata_placeholder'),
    rules: [
      {
        required: true,
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
      {
        max: 1000,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  autoriElaborata: {
    name: ['istrazivanjeAtributi', 'autoriElaborata'],
    type: 'text',
    label: t('forma.sekcija.polje.autori_elaborata'),
    placeholder: t('forma.sekcija.polje.autori_elaborata_placeholder'),
    rules: [
      {
        required: true,
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
      {
        max: 50,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  datumPocetkaRadovaNaIstrazivanju: {
    name: ['podzemneVodeAtributi', 'datumPocetkaRadovaNaIstrazivanju'],
    type: 'date',
    label: t('forma.sekcija.polje.datumPocetkaRadovaNaIstrazivanju'),
    placeholder: t(
      'forma.sekcija.polje.datumPocetkaRadovaNaIstrazivanju_placeholder'
    ),
    rules: [
      {
        required: true,
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  datumIzradeElaborata: {
    name: ['podzemneVodeAtributi', 'datumIzradeElaborata'],
    type: 'date',
    label: t('forma.sekcija.polje.datumIzradeElaborata'),
    placeholder: t('forma.sekcija.polje.datumIzradeElaborata_placeholder'),
    rules: [
      {
        required: true,
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  datumPocetkaProbnogRadaBusotine: {
    name: ['istrazivanjeAtributi', 'datumPocetkaProbnogRadaBusotine'],
    type: 'date',
    label: t('forma.sekcija.polje.datumPocetkaProbnogRadaBusotine'),
    placeholder: t(
      'forma.sekcija.polje.datumPocetkaProbnogRadaBusotine_placeholder'
    ),
  },
  datumZavrsetkaProbnogRadaBusotine: {
    name: ['istrazivanjeAtributi', 'datumZavrsetkaProbnogRadaBusotine'],
    type: 'date',
    label: t('forma.sekcija.polje.datumZavrsetkaProbnogRadaBusotine'),
    placeholder: t(
      'forma.sekcija.polje.datumZavrsetkaProbnogRadaBusotine_placeholder'
    ),
  },
  datumPocetkaGeoloskogIstrazivanja: {
    name: ['podzemneVodeAtributi', 'datumPocetkaGeoloskogIstrazivanja'],
    type: 'date',
    label: t('forma.sekcija.polje.datumPocetkaGeoloskogIstrazivanja'),
    placeholder: t(
      'forma.sekcija.polje.datumPocetkaGeoloskogIstrazivanja_placeholder'
    ),
    rules: [
      {
        required: true,
        type: 'date',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  datumZavrsetkaGeoloskogIstrazivanja: {
    name: ['podzemneVodeAtributi', 'datumZavrsetkaGeoloskogIstrazivanja'],
    type: 'date',
    label: t('forma.sekcija.polje.datumZavrsetkaGeoloskogIstrazivanja'),
    placeholder: t(
      'forma.sekcija.polje.datumZavrsetkaGeoloskogIstrazivanja_placeholder'
    ),
    rules: [
      {
        required: true,
        type: 'date',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  datumPocetkaIzvrsenjaGeoloskogIstrazivanja: {
    name: [
      'istrazivanjeAtributi',
      'datumPocetkaIzvrsenjaGeoloskogIstrazivanja',
    ],
    type: 'date',
    label: t('forma.sekcija.polje.datumPocetkaIzvrsenjaGeoloskogIstrazivanja'),
    placeholder: t(
      'forma.sekcija.polje.datumPocetkaIzvrsenjaGeoloskogIstrazivanja_placeholder'
    ),
    rules: [
      {
        required: true,
        type: 'date',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  datumZavrsetkaIzvrsenjaGeoloskogIstrazivanja: {
    name: [
      'istrazivanjeAtributi',
      'datumZavrsetkaIzvrsenjaGeoloskogIstrazivanja',
    ],
    type: 'date',
    label: t(
      'forma.sekcija.polje.datumZavrsetkaIzvrsenjaGeoloskogIstrazivanja'
    ),
    placeholder: t(
      'forma.sekcija.polje.datumZavrsetkaIzvrsenjaGeoloskogIstrazivanja_placeholder'
    ),
    rules: [
      {
        required: true,
        type: 'date',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  datumPrijemaResenja: {
    name: ['istrazivanjeAtributi', 'datumPrijemaResenja'],
    type: 'date',
    label: t('forma.sekcija.polje.datumPrijemaResenja'),
    placeholder: t('forma.sekcija.polje.datumPrijemaResenja_placeholder'),
    rules: [
      {
        required: true,
        type: 'date',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  periodTrajanjaGeoloskihIstrazivanja: {
    name: ['istrazivanjeAtributi', 'periodTrajanjaGeoloskihIstrazivanja'],
    type: 'range',
    label: t('forma.sekcija.polje.periodTrajanjaGeoloskihIstrazivanja'),
    rules: [
      {
        required: true,
        type: 'array',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  datumPodnosenjaZahteva: {
    name: ['zahtevAtributi', 'datumPodnosenjaZahteva'],
    type: 'date',
    label: t('forma.sekcija.polje.datumPodnosenjaZahteva'),
    placeholder: t('forma.sekcija.polje.datumPodnosenjaZahteva_placeholder'),
    rules: [
      {
        required: true,
        type: 'date',
        message: t('forma.sekcija.polje.obavezno_polje'),
      },
    ],
  },
  brojIstraznogProstora: {
    name: ['istrazivanjeAtributi', 'brojIstraznogProstora'],
    type: 'text',
    label: t('forma.sekcija.polje.brojIstraznogProstora'),
    placeholder: t('forma.sekcija.polje.brojIstraznogProstora_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      {
        max: 100,
        message: t('forma.sekcija.polje.maksimalan_broj_karaktera'),
      },
    ],
  },
  tipZahteva: {
    name: ['zahtev', 'tipZahteva'],
    type: 'text',
    disabled: true,
    label: t('forma.sekcija.polje.tipZahteva'),
    placeholder: t('forma.sekcija.polje.tipZahteva_placeholder'),
    rules: [
      { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
    ],
  },
  attachments: {
    izvestajOIzvrsenomTehnickomPregleduKomisija: {
      name: ['prilozi', String(RequestDocumentsEnum.IZVESTAJ_TEHNICKI_PREGLED)],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.izvestajOIzvrsenomTehnickomPregleduKomisija'
      ),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_TEHNICKI_PREGLED),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_TEHNICKI_PREGLED),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    drugaAktaPredvidjena: {
      name: ['prilozi', String(RequestDocumentsEnum.DRUGA_AKTA_POSEBNI_ZAKONI)],
      type: 'upload',
      label: t('forma.sekcija.prilog.drugaAktaPredvidjena'),
    },
    izvestajOMineralnimResursimaPrirodnihGradjevinskihMaterijala: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.IZVESTAJ_MINERALNI_RESURSI),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.izvestajOMineralnimResursimaPrirodnihGradjevinskihMaterijala'
      ),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_MINERALNI_RESURSI),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_MINERALNI_RESURSI),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    izvestajNosiocaOdobrenjaOIzvresenjuObaveza: {
      name: ['prilozi', String(RequestDocumentsEnum.IZVESTAJ_OBAVEZE)],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.izvestajNosiocaOdobrenjaOIzvresenjuObaveza'
      ),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_OBAVEZE),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_OBAVEZE),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    poslednjiIzvestajOInspekcijskomNadzoruIzvresnomOdStraneRudarskogInspektoraIInspektoraBezbezdnostiNaRadu:
      {
        name: [
          'prilozi',
          String(RequestDocumentsEnum.IZVESTAJ_INSPEKCIJSKI_NADZOR),
        ],
        type: 'upload',
        label: t(
          'forma.sekcija.prilog.poslednjiIzvestajOInspekcijskomNadzoruIzvresnomOdStraneRudarskogInspektoraIInspektoraBezbezdnostiNaRadu'
        ),
        additionalInputFields: [
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.IZVESTAJ_INSPEKCIJSKI_NADZOR),
              'broj',
            ],
            type: 'text',
            label: t('forma.sekcija.polje.broj'),
            placeholder: t('forma.sekcija.polje.broj_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.IZVESTAJ_INSPEKCIJSKI_NADZOR),
              'datum',
            ],
            type: 'date',
            label: t('forma.sekcija.polje.datum'),
            placeholder: t('forma.sekcija.polje.datum_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
        ],
        rules: [
          { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        ],
      },
    pisanaIzjavaBuducegNosiocaOdobrenjaOPrihvatanjuUslovaPrenosaOdobrenjaSaSvimPravimaIObavezamaKojeProisticuIzNjega:
      {
        name: [
          'prilozi',
          String(RequestDocumentsEnum.PISANA_IZJAVA_BUDUCI_NOSILAC),
        ],
        type: 'upload',
        label: t(
          'forma.sekcija.prilog.pisanaIzjavaBuducegNosiocaOdobrenjaOPrihvatanjuUslovaPrenosaOdobrenjaSaSvimPravimaIObavezamaKojeProisticuIzNjega'
        ),
        additionalInputFields: [
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.PISANA_IZJAVA_BUDUCI_NOSILAC),
              'broj',
            ],
            type: 'text',
            label: t('forma.sekcija.polje.broj'),
            placeholder: t('forma.sekcija.polje.broj_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.PISANA_IZJAVA_BUDUCI_NOSILAC),
              'datum',
            ],
            type: 'date',
            label: t('forma.sekcija.polje.datum'),
            placeholder: t('forma.sekcija.polje.datum_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
        ],
        rules: [
          { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        ],
      },
    topografskaKarta: {
      name: ['prilozi', String(RequestDocumentsEnum.TOPOGRAFSKA_KARTA)],
      type: 'upload',
      label: t('forma.sekcija.prilog.topografska_karta'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    geoloskoIstrazivanje: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.projekat_geo_istrazivanja_mineralnih_sirovina'
      ),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.PROJEKAT_GEOLOSKIH_ISTRAZIVANJA),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
    },
    tehnickaKontrola: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.tehnicka_kontrola'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
    },
    dokazKoriscenjePodataka: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_PODATAKA),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.dokaz_o_pravu_koriscenja_podataka'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_PODATAKA),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    dokazRepublickaTaksa: {
      name: ['prilozi', String(RequestDocumentsEnum.DOKAZ_PLACENA_TAKSA)],
      type: 'upload',
      label: t('forma.sekcija.prilog.dokaz_o_republickoj_taksi'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    aktZaZastituPrirode: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.akt_za_zastitu_prirode'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    // TODO
    aktZaZastituPrirodeOpcioni: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.akt_za_zastitu_prirode'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          // rules: [
          //   {
          //     required: true,
          //     message: t('forma.sekcija.polje.obavezno_polje'),
          //   },
          // ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          // rules: [
          //   {
          //     required: true,
          //     message: t('forma.sekcija.polje.obavezno_polje'),
          //   },
          // ],
        },
      ],
    },
    aktOUslovimaZaZastituSpomenikaKulture: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.akt_o_uslovima_za_zastitu_spomenika_kulture'
      ),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
            'naziv',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.naziv'),
          placeholder: t('forma.sekcija.polje.naziv_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    // TODO
    aktOUslovimaZaZastituSpomenikaKultureOpcioni: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.akt_o_uslovima_za_zastitu_spomenika_kulture'
      ),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
            'naziv',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.naziv'),
          placeholder: t('forma.sekcija.polje.naziv_placeholder'),
          // rules: [
          //   {
          //     required: true,
          //     message: t('forma.sekcija.polje.obavezno_polje'),
          //   },
          // ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZAVOD_SPOMENICI_KULTURE),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
    },
    ispunjenostUslovaZaZastituPrirode: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.ispunjenost_uslova_za_zastituPrirode'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.MISLJENJE_ZASTITA_PRIRODE_SRBIJA),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
        },
      ],
    },
    dokazOPravuKoriscenjaGeoloskeDokumentacije: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DOKUMENTACIJE),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.dokazOPravuKoriscenjaGeoloskeDokumentacije'
      ),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DOKUMENTACIJE),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    izvodIzRegistra: {
      name: ['prilozi', String(RequestDocumentsEnum.IZVOD_REGISTRA_SUBJEKTA)],
      type: 'upload',
      label: t('forma.sekcija.prilog.izvodIzRegistra'),
      accept: ['.pdf'],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    izvestajOStrucnomNadzoru: {
      name: ['prilozi', String(RequestDocumentsEnum.IZVESTAJ_STRUCNI_NADZOR)],
      type: 'upload',
      label: t('forma.sekcija.prilog.izvestajOStrucnomNadzoru'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_STRUCNI_NADZOR),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_STRUCNI_NADZOR),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    izvestajElaborat: {
      name: ['prilozi', String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT)],
      type: 'upload',
      label: t('forma.sekcija.prilog.izvestajElaborat'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
      singingDocument: true,
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ_ELABORAT),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
    },
    izvestajPotvrdaOTehnickojKontroliZavrsnogIzvestaja: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA_ZAVRSNI),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.izvestajPotvrdaOTehnickojKontroliZavrsnogIzvestaja'
      ),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA_ZAVRSNI),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.IZVESTAJ_TEHNICKA_KONTROLA_ZAVRSNI),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    programAktivnosti: {
      name: ['prilozi', String(RequestDocumentsEnum.PROGRAM_AKTIVNOSTI)],
      type: 'upload',
      label: t('forma.sekcija.prilog.programAktivnosti'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.PROGRAM_AKTIVNOSTI),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.PROGRAM_AKTIVNOSTI),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    pisanaIzjavaNovogNosioca: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.pisanaIzjavaNovogNosioca'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.PISANA_IZJAVA_NOVOG_NOSIOCA),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    pismoONamerama: {
      name: ['prilozi', String(RequestDocumentsEnum.PISMO_NAMERE_BANKE)],
      type: 'upload',
      label: t('forma.sekcija.prilog.pismoONamerama'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    // TODO
    dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja'
      ),
      tooltip: t(
        'forma.sekcija.prilog.dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja_tooltip'
      ),
      rules: [
        {
          required: true,
          message: t('forma.sekcija.polje.obavezno_polje'),
        },
      ],
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
    },
    dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnjaOpciono: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja'
      ),
      tooltip: t(
        'forma.sekcija.prilog.dokazOPravuKoriscenjaPodatakaIRezultatuIstrazivajnja_tooltip'
      ),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.DOKAZ_PRAVO_KORISCENJA_DRUGI_SUBJEKAT),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          // rules: [
          //   {
          //     required: true,
          //     message: t('forma.sekcija.polje.obavezno_polje'),
          //   },
          // ],
        },
      ],
    },
    aktOUslugamaZavoda: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.aktOUslugamaZavoda'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_ZASTITA_PRIRODE_SRBIJA),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    uverenjeOPolozenomStrucnomIspitu: {
      name: ['prilozi', String(RequestDocumentsEnum.UVERENJE_STRUCNI_ISPIT)],
      type: 'upload',
      label: t('forma.sekcija.prilog.uverenjeOPolozenomStrucnomIspitu'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    potvrdaOZaposlenjuIImenovanjuGlavnogProjektanta: {
      name: ['prilozi', String(RequestDocumentsEnum.POTVRDA_ZAPOSLENJA)],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.potvrdaOZaposlenjuIImenovanjuGlavnogProjektanta'
      ),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    dokazOIspunjenostiUslovaClana22: {
      name: ['prilozi', String(RequestDocumentsEnum.DOKAZ_IZ_CLANA_22)],
      type: 'upload',
      label: t('forma.sekcija.prilog.dokazOIspunjenostiUslovaClana22'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    situacionaKarta: {
      name: ['prilozi', String(RequestDocumentsEnum.SITUACIONA_KARTA)],
      type: 'upload',
      label: t('forma.sekcija.prilog.situacionaKarta'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    aktOrganaNadleznogZaPosloveZastiteZivotneSredineOObimuISadrzajuStudijeOProceniUticajaEksploatacijeNaZivotnuSredinu:
      {
        name: [
          'prilozi',
          String(RequestDocumentsEnum.AKT_ZIVOTNA_SREDINA_PROCENA_UTICAJA),
        ],
        type: 'upload',
        label: t(
          'forma.sekcija.prilog.aktOrganaNadleznogZaPosloveZastiteZivotneSredineOObimuISadrzajuStudijeOProceniUticajaEksploatacijeNaZivotnuSredinu'
        ),
        additionalInputFields: [
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.AKT_ZIVOTNA_SREDINA_PROCENA_UTICAJA),
              'broj',
            ],
            type: 'text',
            label: t('forma.sekcija.polje.broj'),
            placeholder: t('forma.sekcija.polje.broj_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.AKT_ZIVOTNA_SREDINA_PROCENA_UTICAJA),
              'datum',
            ],
            type: 'date',
            label: t('forma.sekcija.polje.datum'),
            placeholder: t('forma.sekcija.polje.datum_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
        ],
        rules: [
          { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        ],
      },
    aktOrganaNadleznogZaPosloveUrbanizma: {
      name: ['prilozi', String(RequestDocumentsEnum.AKT_URBANIZAM)],
      type: 'upload',
      label: t('forma.sekcija.prilog.aktOrganaNadleznogZaPosloveUrbanizma'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_URBANIZAM),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_URBANIZAM),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_URBANIZAM),
            'opstinaId',
          ],
          type: 'select',
          label: t('forma.sekcija.polje.opstina'),
          placeholder: t('forma.sekcija.polje.opstina_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    aktOrganaNadleznogZaPosloveVodoprivredeKojimSeOdredjujuUsloviZaVrsenjeEksploatacije:
      {
        name: ['prilozi', String(RequestDocumentsEnum.AKT_VODOPRIVREDA_USLOVI)],
        type: 'upload',
        label: t(
          'forma.sekcija.prilog.aktOrganaNadleznogZaPosloveVodoprivredeKojimSeOdredjujuUsloviZaVrsenjeEksploatacije'
        ),
        additionalInputFields: [
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.AKT_VODOPRIVREDA_USLOVI),
              'broj',
            ],
            type: 'text',
            label: t('forma.sekcija.polje.broj'),
            placeholder: t('forma.sekcija.polje.broj_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.AKT_VODOPRIVREDA_USLOVI),
              'datum',
            ],
            type: 'date',
            label: t('forma.sekcija.polje.datum'),
            placeholder: t('forma.sekcija.polje.datum_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
        ],
        rules: [
          { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        ],
      },
    aktOrganaUstanoveNadlezneZaZastituKulturnogNasledjaKojimSeOdredjujuUsloviZaVrsenjeEksploatacije:
      {
        name: ['prilozi', String(RequestDocumentsEnum.AKT_KULTURNO_NASLEDJE)],
        type: 'upload',
        label: t(
          'forma.sekcija.prilog.aktOrganaUstanoveNadlezneZaZastituKulturnogNasledjaKojimSeOdredjujuUsloviZaVrsenjeEksploatacije'
        ),
        additionalInputFields: [
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.AKT_KULTURNO_NASLEDJE),
              'broj',
            ],
            type: 'text',
            label: t('forma.sekcija.polje.broj'),
            placeholder: t('forma.sekcija.polje.broj_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.AKT_KULTURNO_NASLEDJE),
              'datum',
            ],
            type: 'date',
            label: t('forma.sekcija.polje.datum'),
            placeholder: t('forma.sekcija.polje.datum_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.AKT_KULTURNO_NASLEDJE),
              'naziv',
            ],
            type: 'text',
            label: t('forma.sekcija.polje.naziv'),
            placeholder: t('forma.sekcija.polje.naziv_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
        ],
        rules: [
          { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        ],
      },
    studijaIzvodljivostiEksploatacijeLezistaMineralnihSirovina: {
      name: ['prilozi', String(RequestDocumentsEnum.STUDIJA_IZVODLJIVOSTI)],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.studijaIzvodljivostiEksploatacijeLezistaMineralnihSirovina'
      ),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    predlogZaOveruRezerviSaPreglednomKartom: {
      name: ['prilozi', String(RequestDocumentsEnum.PREDLOG_OVERA_REZERVI)],
      type: 'upload',
      label: t('forma.sekcija.prilog.predlogZaOveruRezerviSaPreglednomKartom'),

      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.PREDLOG_OVERA_REZERVI),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.PREDLOG_OVERA_REZERVI),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    elaboratOResursimaIRezervamaMineralnihSirovina: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.ELABORAT_RESURSI_MINERALNE_SIROVINE),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.elaboratOResursimaIRezervamaMineralnihSirovina'
      ),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.ELABORAT_RESURSI_MINERALNE_SIROVINE),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.ELABORAT_RESURSI_MINERALNE_SIROVINE),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    resenjeOImenovanjuStrucnogNadzora: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.RESENJE_IMENOVANJE_STRUCNOG_NADZORA),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.resenjeOImenovanjuStrucnogNadzora'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.RESENJE_IMENOVANJE_STRUCNOG_NADZORA),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.RESENJE_IMENOVANJE_STRUCNOG_NADZORA),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    preglednaKarta: {
      name: ['prilozi', String(RequestDocumentsEnum.PREGLEDNA_KARTA)],
      type: 'upload',
      label: t('forma.sekcija.prilog.preglednaKarta'),
      tooltip: t('forma.sekcija.prilog.preglednaKarta_tooltip'),
      singingDocument: true,
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    preglednaSituacionaKarta: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.PREGLEDNA_SITUACIONA_KARTA),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.preglednaSituacionaKarta'),
      tooltip: t('forma.sekcija.prilog.preglednaKarta_tooltip'),
      singingDocument: true,
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    izvodIzElaborata: {
      name: ['prilozi', String(RequestDocumentsEnum.IZVOD_IZ_ELABORATA)],
      type: 'upload',
      label: t('forma.sekcija.prilog.izvodIzElaborata'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    dokazDaAutorElaborataIspunjavaUslovePropisaneZakonom: {
      name: ['prilozi', String(RequestDocumentsEnum.DOKAZ_AUTOR_ELABORATA)],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.dokazDaAutorElaborataIspunjavaUslovePropisaneZakonom'
      ),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    garancijaBanke: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.GARANCIJE_BANKA_SANACIJA_REKULTIVACIJA),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.garancijaBanke'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
      tooltip: t('forma.sekcija.prilog.garancijaBanke_tooltip'),
    },
    saglasnostNadlezneUstanove: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.SAGLASNOST_ZA_SPOMENIKE_KULTURE),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.saglasnostNadlezneUstanove'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.SAGLASNOST_ZA_SPOMENIKE_KULTURE),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.SAGLASNOST_ZA_SPOMENIKE_KULTURE),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    saglasnostNaTehnickuDokumentaciju: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.SAGLASNOST_TEHNICKA_DOKUMENTACIJA),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.saglasnostNaTehnickuDokumentaciju'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.SAGLASNOST_TEHNICKA_DOKUMENTACIJA),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.SAGLASNOST_TEHNICKA_DOKUMENTACIJA),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    rudarskiProjekat: {
      name: ['prilozi', String(RequestDocumentsEnum.RUDARSKI_PROJEKAT)],
      type: 'upload',
      label: t('forma.sekcija.prilog.rudarskiProjekat'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    saglasnostNosioca: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.SAGLASNOST_NOSIOCA_EKSPLATACIJE),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.saglasnostNosioca'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.SAGLASNOST_NOSIOCA_EKSPLATACIJE),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.SAGLASNOST_NOSIOCA_EKSPLATACIJE),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      singingDocument: true,
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    stanjeBilansnihRezervi: {
      name: ['prilozi', String(RequestDocumentsEnum.STANJE_BILANSNIH_REZERVI)],
      type: 'upload',
      label: t('forma.sekcija.prilog.stanjeBilansnihRezervi'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.STANJE_BILANSNIH_REZERVI),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.STANJE_BILANSNIH_REZERVI),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    aktOrganaNadleznogZaPosloveVodoprivredeKojimSeDajeSaglasnot: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.AKT_VODOPRIVREDA_SAGLASNOST),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.aktOrganaNadleznogZaPosloveVodoprivredeKojimSeDajeSaglasnot'
      ),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_VODOPRIVREDA_SAGLASNOST),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_VODOPRIVREDA_SAGLASNOST),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    aktOrganaNadleznogZaPosloveZastiteZivotneSredineKojimSeDajeSaglasnostNaStudijuOProceniUticajaEksploatacijeNaZivotnuSredinu:
      {
        name: [
          'prilozi',
          String(RequestDocumentsEnum.AKT_ZIVOTNA_SREDINA_SAGLASNOST),
        ],
        type: 'upload',
        label: t(
          'forma.sekcija.prilog.aktOrganaNadleznogZaPosloveZastiteZivotneSredineKojimSeDajeSaglasnostNaStudijuOProceniUticajaEksploatacijeNaZivotnuSredinu'
        ),
        additionalInputFields: [
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.AKT_ZIVOTNA_SREDINA_SAGLASNOST),
              'broj',
            ],
            type: 'text',
            label: t('forma.sekcija.polje.broj'),
            placeholder: t('forma.sekcija.polje.broj_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.AKT_ZIVOTNA_SREDINA_SAGLASNOST),
              'datum',
            ],
            type: 'date',
            label: t('forma.sekcija.polje.datum'),
            placeholder: t('forma.sekcija.polje.datum_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
        ],
        rules: [
          { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        ],
      },
    elaboratOResursimaIRezervamaPodzemnihVodaIGeotermalnihResursa: {
      name: ['prilozi', String(RequestDocumentsEnum.ELABORAT_RESURSI_VODE)],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.elaboratOResursimaIRezervamaPodzemnihVodaIGeotermalnihResursa'
      ),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.ELABORAT_RESURSI_VODE),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.ELABORAT_RESURSI_VODE),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    dokazOPravuSvojine: {
      name: ['prilozi', String(RequestDocumentsEnum.DOKAZ_PRAVO_SVOJINE)],
      type: 'upload',
      label: t('forma.sekcija.prilog.dokazOPravuSvojine'),
      tooltip: t('forma.sekcija.prilog.dokazOPravuSvojine_tooltip'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.DOKAZ_PRAVO_SVOJINE),
            'brojParcele',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj_parcele'),
          placeholder: t('forma.sekcija.polje.broj_parcele_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.DOKAZ_PRAVO_SVOJINE),
            'opstinaId',
          ],
          type: 'select',
          label: t('forma.sekcija.polje.opstina'),
          placeholder: t('forma.sekcija.polje.opstina_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      singingDocument: true,
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    dokazOPravuSvojineSaMestom: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.DOKAZ_SVOJINA_RUDARSKI_OBJEKTI),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.dokazOPravuSvojine'),
      tooltip: t('forma.sekcija.prilog.dokazOPravuSvojineSaMestom_tooltip'),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.DOKAZ_SVOJINA_RUDARSKI_OBJEKTI),
            'brojParcele',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj_parcele'),
          placeholder: t('forma.sekcija.polje.broj_parcele_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.DOKAZ_SVOJINA_RUDARSKI_OBJEKTI),
            'katastarskaOpstinaId',
          ],
          type: 'select',
          label: t('forma.sekcija.polje.opstina'),
          placeholder: t('forma.sekcija.polje.opstina_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.DOKAZ_SVOJINA_RUDARSKI_OBJEKTI),
            'katastarskoMestoId',
          ],
          type: 'select',
          label: t('forma.sekcija.polje.katastarska_opstina'),
          placeholder: t('forma.sekcija.polje.katastarska_opstina_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
      singingDocument: true,
    },
    elaboratOUslovimaEksploatacijePodzemnihVoda: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.ELABORAT_USLOVI_EKSPLOATACIJE),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.elaboratOUslovimaEksploatacijePodzemnihVoda'
      ),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.ELABORAT_USLOVI_EKSPLOATACIJE),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.ELABORAT_USLOVI_EKSPLOATACIJE),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    // TODO
    aktZaPosloveSanitarneZastiteIzvoristaVodosnabdevanja: {
      name: ['prilozi', String(RequestDocumentsEnum.AKT_SANITARNA_ZASTITA)],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.aktZaPosloveSanitarneZastiteIzvoristaVodosnabdevanja'
      ),
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_SANITARNA_ZASTITA),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          // rules: [
          //   {
          //     required: true,
          //     message: t('forma.sekcija.polje.obavezno_polje'),
          //   },
          // ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.AKT_SANITARNA_ZASTITA),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          // rules: [
          //   {
          //     required: true,
          //     message: t('forma.sekcija.polje.obavezno_polje'),
          //   },
          // ],
        },
      ],
    },
    geodetskiPlan: {
      name: ['prilozi', String(RequestDocumentsEnum.GEODETSKI_PLAN)],
      type: 'upload',
      label: t('forma.sekcija.prilog.geodetskiPlan'),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    zavrsniIzvestaj: {
      name: ['prilozi', String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ)],
      type: 'upload',
      label: t('forma.sekcija.prilog.zavrsniIzvestaj'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.ZAVRSNI_IZVESTAJ),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    godisnjiIzvestaj: {
      name: ['prilozi', String(RequestDocumentsEnum.GODISNJI_IZVESTAJ)],
      type: 'upload',
      label: t('forma.sekcija.prilog.godisnjiIzvestaj'),
      tooltip: t('forma.sekcija.prilog.elektronski_potpis_tooltip'),
      singingDocument: true,
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.GODISNJI_IZVESTAJ),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.GODISNJI_IZVESTAJ),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    izvestajNosiocaOdobrenjaZaIstrazivanjeOIzvrsenjuObavezaUVeziSanacijeIRekultivacijeProstora:
      {
        name: [
          'prilozi',
          String(RequestDocumentsEnum.IZVESTAJ_SANACIJA_REKULTIVACIJA),
        ],
        type: 'upload',
        label: t(
          'forma.sekcija.prilog.izvestajNosiocaOdobrenjaZaIstrazivanjeOIzvrsenjuObavezaUVeziSanacijeIRekultivacijeProstora'
        ),
        additionalInputFields: [
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.IZVESTAJ_SANACIJA_REKULTIVACIJA),
              'broj',
            ],
            type: 'text',
            label: t('forma.sekcija.polje.broj'),
            placeholder: t('forma.sekcija.polje.broj_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
          {
            name: [
              'dokumentaAtributi',
              String(RequestDocumentsEnum.IZVESTAJ_SANACIJA_REKULTIVACIJA),
              'datum',
            ],
            type: 'date',
            label: t('forma.sekcija.polje.datum'),
            placeholder: t('forma.sekcija.polje.datum_placeholder'),
            rules: [
              {
                required: true,
                message: t('forma.sekcija.polje.obavezno_polje'),
              },
            ],
          },
        ],
        rules: [
          { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
        ],
      },
    ostalo: {
      name: ['prilozi', String(RequestDocumentsEnum.OSTALO)],
      type: 'upload',
      label: t('forma.sekcija.prilog.ostalo'),
    },
    tehnickaKontrolaRudarskogProjekta: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.TEHNICKA_KONTROLA_RUDARSKI_PROJEKAT),
      ],
      type: 'upload',
      label: t('forma.sekcija.prilog.tehnickaKontrolaRudarskogProjekta'),
      singingDocument: true,
      additionalInputFields: [
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.TEHNICKA_KONTROLA_RUDARSKI_PROJEKAT),
            'broj',
          ],
          type: 'text',
          label: t('forma.sekcija.polje.broj'),
          placeholder: t('forma.sekcija.polje.broj_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
        {
          name: [
            'dokumentaAtributi',
            String(RequestDocumentsEnum.TEHNICKA_KONTROLA_RUDARSKI_PROJEKAT),
            'datum',
          ],
          type: 'date',
          label: t('forma.sekcija.polje.datum'),
          placeholder: t('forma.sekcija.polje.datum_placeholder'),
          rules: [
            {
              required: true,
              message: t('forma.sekcija.polje.obavezno_polje'),
            },
          ],
        },
      ],
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
    potvrdaOResursimaIRezervamaMineralnihSirovina: {
      name: [
        'prilozi',
        String(RequestDocumentsEnum.POTVRDA_REZERVE_MINERALNIH_SIROVINA),
      ],
      type: 'upload',
      label: t(
        'forma.sekcija.prilog.potvrdaOResursimaIRezervamaMineralnihSirovina'
      ),
      rules: [
        { required: true, message: t('forma.sekcija.polje.obavezno_polje') },
      ],
    },
  },
};
