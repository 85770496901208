import { Dispatch, SetStateAction } from 'react';
import { Button, Col, Flex, Form, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { Dayjs } from 'dayjs';
import { t } from 'i18next';

import RequestsNamesSelect from '@/components/RequestsNamesSelect/RequestsNamesSelect';
import RequestsTreeSelect from '@/components/RequestsTreeSelect/RequestsTreeSelect';
import { IResenjaPayload } from '@/modules/acts/acts.types';
import { requestsStore } from '@/modules/requests/requests.store';
import { DATE_FORMAT_UI } from '@/utils/dateFormatter';
import { RangePicker } from '@/components/DatePicker/DatePicker';
import { IFilterAkata } from '@/modules/submittedRequests/submittedRequests.types';
import { INITIAL_ACT_FILTER_PARAMS } from '@/modules/acts/acts.constants.ts';

interface IActsFilters {
  setParams: Dispatch<SetStateAction<IResenjaPayload>>;
  params: IResenjaPayload;
}

const ActsFilters = observer(({ setParams, params }: IActsFilters) => {
  const [form] = Form.useForm();

  // const shouldRenderFilterTipMineralneSirovine = () => {
  //   // return params.zahtevGrupaId === 2 || params.zahtevPodgrupaId === 5;
  //   return false; //Return false until backend is done
  // };

  const handleResetFilterClick = () => {
    form.resetFields();
    setParams(INITIAL_ACT_FILTER_PARAMS);
  };

  return (
    <Form form={form} layout='vertical'>
      <Row gutter={[5, 10]} className='mt-20'>
        <Col span={24}>
          <Form.Item<IFilterAkata> name='zahtevGrupa' className='w-100 m-0'>
            <RequestsTreeSelect<IResenjaPayload> setParams={setParams} />
          </Form.Item>
        </Col>
        {/* <Col span={8}>
          <Form.Item<IFilterAkata>
            name='tipMineralneSirovine'
            className='w-100 m-0'
          >
            <Select
              placeholder={t('tip_mineralne_sirovine_materijala')}
              disabled={!shouldRenderFilterTipMineralneSirovine()}
              options={requestsStore.config?.mineralneSirovine}
              onChange={(value: [Dayjs | null, Dayjs | null]) => {
                setParams((prevParams) => ({
                  ...prevParams,
                  datumOd: value[0] ?? null,
                  datumDo: value[1]
                    ? value[1]
                        .set('hour', 23)
                        .set('minute', 59)
                        .set('second', 59)
                    : null,
                }));
              }}
              allowClear
              className='w-100'
            />
          </Form.Item>
        </Col> */}
        <Col span={4}>
          <Form.Item<IFilterAkata>
            name='zavodniBrZahteva'
            className='w-100 m-0'
          >
            <Input
              placeholder={t('zavodni_broj_akta')}
              allowClear
              className='w-100'
              onChange={({ target }) => {
                setParams((prevParams) => ({
                  ...prevParams,
                  zavodniBroj: target.value || null,
                }));
              }}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item<IFilterAkata> name='nazivZahteva' className='w-100 m-0'>
            <RequestsNamesSelect<IResenjaPayload>
              setParams={setParams}
              params={params}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item<IFilterAkata>
            name='datumPodnosenjaAkta'
            className='w-100 m-0'
          >
            <Flex justify='end' align='center' gap={5}>
              <span className='alignRight'>{t('datum_podnosenja_akta')}</span>
              <RangePicker
                allowClear
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(value: [Dayjs | null, Dayjs | null]) => {
                  setParams((prevParams) => ({
                    ...prevParams,
                    datumOd: value[0] ?? null,
                    datumDo: value[1]
                      ? value[1]
                          .set('hour', 23)
                          .set('minute', 59)
                          .set('second', 59)
                      : null,
                  }));
                }}
                format={DATE_FORMAT_UI}
                placeholder={[t('od'), t('do')]}
              />
            </Flex>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item<IFilterAkata> name='tipAkta' className='w-100 m-0'>
            <Select
              placeholder={t('tip_akta')}
              options={requestsStore.actTypes}
              onChange={(value) => {
                setParams((prevParams) => ({
                  ...prevParams,
                  tipAkta: value || null,
                }));
              }}
              allowClear
              className='w-100'
            />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Flex justify='end'>
            <Button onClick={handleResetFilterClick} type='primary'>
              {t('ponistite_pretragu')}
            </Button>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
});

export default ActsFilters;
