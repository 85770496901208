import { requestsService } from '@/modules/requests/requests.service';
import { TreeSelect, notification } from 'antd';
import { Group } from '@/modules/requests/requests.types.ts';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IRequestsTreeSelect<T> {
  setParams: Dispatch<SetStateAction<T>>;
}

type TreeNode = {
  value: string;
  title: string;
  key: string;
  children?: TreeNode[];
};

type Tree = TreeNode[];

const RequestsTreeSelect = observer(
  <T,>({ setParams }: IRequestsTreeSelect<T>) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [treeValues, setTreeValues] = useState<Tree>();

    const onChangeTree = (value: string) => {
      const ids = value ? value.split('-') : [];
      setParams((prevParams) => ({
        ...prevParams,
        zahtevGrupaId: +ids[0] || null,
        zahtevPodgrupaId: +ids[1] || null,
        zahtevKategorijaPodgrupeId: +ids[2] || null,
        nazivZahtevaId: null,
      }));
    };

    useEffect(() => {
      requestsService
        .getGroupsAndRequests()
        .then((res) => {
          const transformArray = (groups: Group[]): Tree => {
            return groups.map((group) => ({
              value: `${group.id}`,
              title: group.ime,
              key: `${group.id}`,
              children: group.podgrupe.map((subgroup) => ({
                value: `${group.id}-${subgroup.id}`,
                title: subgroup.ime,
                key: `${group.id}-${subgroup.id}`,
                children: subgroup.kategorijePodgrupa?.map((category) => ({
                  value: `${group.id}-${subgroup.id}-${category.id}`,
                  title: category.ime,
                  key: `${group.id}-${subgroup.id}-${category.id}`,
                  children: category.kategorijePodgrupa?.map((subcategory) => ({
                    value: `${group.id}-${subgroup.id}-${category.id}-${subcategory.id}`,
                    title: subcategory.ime,
                    key: `${group.id}-${subgroup.id}-${category.id}-${subcategory.id}`,
                  })),
                })),
              })),
            }));
          };
          setTreeValues(transformArray(res.kategorijeZahteva));
        })
        .catch((err) => {
          notification.error(err);
        })
        .finally(() => setLoading(false));
    }, []);

    return (
      <TreeSelect
        showSearch
        loading={loading}
        disabled={loading}
        className='w-100'
        filterTreeNode={(value, option) => {
          return (option.title as string)
            .toLowerCase()
            .includes(value.toLowerCase());
        }}
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'auto',
        }}
        placeholder={t('izaberite_kategoriju_zahteva')}
        allowClear
        treeDefaultExpandAll
        onChange={onChangeTree}
        treeData={treeValues}
      />
    );
  }
);

export default RequestsTreeSelect;
