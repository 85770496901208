import { AxiosResponse } from 'axios';
import { isDevelopment } from '@/utils/constants.ts';

export const handleDownloadFile = async (
  response: AxiosResponse
): Promise<void> => {
  if (isDevelopment()) {
    return Promise.resolve();
  }
  let contentDisposition = '';
  try {
    if (typeof response?.headers?.get === 'function') {
      contentDisposition = response.headers.get(
        'content-disposition'
      ) as string;

      let fileNameWithType = '';

      if (
        contentDisposition &&
        contentDisposition.indexOf('attachment') !== -1
      ) {
        fileNameWithType = contentDisposition.split(';')[1].split('=')[1];
      } else {
        return Promise.reject();
      }
      const fileNameSplit = fileNameWithType.split('.');

      const fileName = fileNameSplit[0];
      const fileType = fileNameSplit[fileNameSplit.length - 1];

      const fileNameDecoded = decodeURIComponent(decodeURIComponent(fileName));

      const blobMIMEtype = getMIMEType(fileType);
      const blob = new Blob([response.data as ArrayBuffer], {
        type: blobMIMEtype,
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${fileNameDecoded}.${fileType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  } catch (e) {
    return Promise.reject();
  }
};

const getMIMEType = (fileType: string) => {
  switch (fileType) {
    case 'pdf':
      return 'application/pdf';
    case 'xml':
      return 'application/xml';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    default:
      return 'application/octet-stream';
  }
};
