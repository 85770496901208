import { useState } from 'react';
import { Col, Row } from 'antd';
import { RequestDecisionApprovalActions } from '../RequestDecisionApproval/components/RequestDecisionApprovalActions/RequestDecisionApprovalActions';
import { RequestDecisionOdbacivanjeForm } from './RequestDecisionOdbacivanjeForm/RequestDecisionOdbacivanjeForm';
import { ResenjeProps } from '@/modules/requests/requests.types';

interface IRequestDecisionOdbacivanje {
  handleSuccess: () => void;
  requestDecision: Pick<
    ResenjeProps,
    'zavodniBrojResenja' | 'datumUrucenjaResenja'
  > | null;
}

const RequestDecisionOdbacivanje = ({
  requestDecision,
  handleSuccess,
}: IRequestDecisionOdbacivanje) => {
  const [resenjeId, setResenjeId] = useState<number | null>(null);
  return (
    <>
      <Row gutter={25}>
        <Col span={16}>
          <RequestDecisionOdbacivanjeForm
            requestDecision={requestDecision}
            setResenjeId={setResenjeId}
          />
        </Col>
        <Col span={8}>
          <RequestDecisionApprovalActions
            resenjeId={resenjeId}
            handleSuccess={handleSuccess}
          />
        </Col>
      </Row>
    </>
  );
};

export default RequestDecisionOdbacivanje;
